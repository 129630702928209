import React, { useEffect, useRef, useState } from 'react';
import SuggetionBoard from 'components/suggetionborard';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useStringData } from 'providers/stringsprovider';
import Input from 'components/input';
import TextAreaInput from 'components/textAreaInput';
import ButtonTab from 'components/buttontab';
import {
  avathar3,
  closeIcon,
  closeIconWithGrayBg,
  dropDownIcon,
  dropdownGrayIcon,
  macBookIcon,
  raiseTicketProductImg,
  searchIcon,
  uploadImgIcon,
} from 'resources/images/Images';
import Button from 'components/button';
import InputWithPopup from 'components/inputWithDropdown';
import Popup from 'components/popup';
import styles from './styles.module.css';
import RadioButton from 'components/radioButton';
import Divider from 'components/divider';
import SearchInput from 'components/searchInput';
import { useAuthContext } from 'hooks/useAuthContext';
import { useUserDataContext } from 'hooks/userDataContext';
import { raiseTicketApi } from 'networking/api/tickets';
import Modal from 'components/modal';
import { useNavigate } from 'react-router';
import AssetAndUserCard from 'components/assetanduser';
import FileUploadInput from 'components/fileUploadInput';
const RaiseTicket = () => {
  const { company, userType } = useAuthContext();
  const navigate = useNavigate();
  const { users, allAssetsData } = useUserDataContext();
  const { strings } = useStringData();
  const [actionLoading, setActionLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showTagAssetEmployPopup, setShowTagAssetEmployPopup] = useState(false);
  const [showSelectTagAssetPopup, setShowSelectTagAssetPopup] = useState(false);
  const popupRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState('All assets');
  const [filteredEmployeeData, setFilteredEmployeeData] = useState(users);
  const [filteredAssetsData, setFilteredAssetsData] = useState(allAssetsData);
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [selectedAsset, setSelectedAsset] = useState('');
  const [selectedAssetAndEmploy, setSelectedAssetAndEmploy] = useState('');
  const [assetErrorMsg, setAssetErrorMsg] = useState('');
  const [apiError, setApiError] = useState('');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [assetRequiredMsg, setAssetRequiredMsg] = useState('');
  const [raiseTicketResponse, setRaiseTicketResponse] = useState('');
  const [upLoadIssueResolveImage, setUpLoadIssueResolveImage] = useState();

  const raiseTicketSchema = Yup.object().shape({
    asset: Yup.string().notRequired(),
    subject: Yup.string().required('ticket subject is required'),
    note: Yup.string().notRequired(),
    image: Yup.string().notRequired(),
  });

  const tagAssetOptions = ['All assets', 'People', 'Unassigned assets'];

  useEffect(() => {
    setFilteredEmployeeData(users);
  }, [users]);

  useEffect(() => {
    setFilteredAssetsData(allAssetsData);
  }, [allAssetsData]);

  const handleRaiseTicket = async (values, { resetForm }) => {
    if (values.asset) {
      values.image = upLoadIssueResolveImage;
      try {
        setActionLoading(true);

        let raiseTicketResponse = await raiseTicketApi(values);
        if (raiseTicketResponse.data.type === 'success') {
          setRaiseTicketResponse(raiseTicketResponse.data.data);
          setActionLoading(false);
          resetForm();
          setIsSuccessModalOpen(true);
        } else {
          setActionLoading(false);
          setApiError(raiseTicketResponse.data.message);
        }
      } catch (error) {
        setApiError(error.message);
        setActionLoading(false);
        console.log('raise Ticket catch error', error);
      }
    } else {
      setAssetRequiredMsg('asset is required');
    }
  };
  const formik = useFormik({
    initialValues: {
      asset: '',
      subject: '',
      note: '',
      image: '',
    },
    validationSchema: raiseTicketSchema,
    onSubmit: handleRaiseTicket,
  });

  const openAssetModal = () => {
    if (!selectedAssetAndEmploy) {
      setIsOpenModal(true);
    }
  };

  const tapOnRemoveAssetOrDropdown = () => {
    if (selectedAssetAndEmploy) {
      setSelectedAssetAndEmploy('');
      formik.setFieldValue('ticket_asset', '');
    }
  };

  const selectRadioBtn = (option) => {
    setSelectedOption(option);
    setShowTagAssetEmployPopup(false);
    setShowSelectTagAssetPopup(false);
    setSelectedEmployee(null);
    setAssetErrorMsg('');
    if (option === 'All assets') {
      setFilteredAssetsData(allAssetsData);
    } else if (option === 'People') {
      setFilteredAssetsData(null);
    } else {
      setFilteredAssetsData(
        allAssetsData.filter((asset) => asset?.assigned_to === null)
      );
    }
  };

  const filterAssetsData = (e) => {
    if (selectedOption !== 'People') {
      setFilteredAssetsData(
        allAssetsData.filter((asset) =>
          JSON.stringify(asset)
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        )
      );
    } else if (selectedEmployee) {
      setFilteredAssetsData(
        allAssetsData.filter((asset) =>
          JSON.stringify(asset)
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  const filterEmployeeData = (e) => {
    setFilteredEmployeeData(
      users.filter((employee) =>
        JSON.stringify(employee)
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      )
    );
  };

  const employeeSelected = (employee) => {
    setSelectedEmployee(employee);
    setFilteredAssetsData(
      allAssetsData.filter((asset) => asset?.assigned_to?._id === employee._id)
    );
    setShowTagAssetEmployPopup(false);
  };

  const assetSelected = (asset) => {
    if (selectedOption === 'People') {
      setSelectedAsset(asset);
    } else {
      let assignedEmployee = users.find(
        (employee) => employee._id === asset.assigned_to?._id
      );
      let data = {
        asset_id: asset._id,
        assetName: asset.asset_name,
        assetImage: asset.image,
        assetUid: asset.asset_uid,
        assetUserImage: assignedEmployee ? avathar3 : '',
        assignedTo: assignedEmployee ? assignedEmployee?.full_name : '',
      };
      setSelectedAssetAndEmploy(data);
    }
    setShowSelectTagAssetPopup(false);
  };

  const closeTagAssteModal = () => {
    setIsOpenModal(false);
    setSelectedOption('All assets');
    setSelectedAsset('');
    setSelectedEmployee('');
    setAssetErrorMsg('');
    setSelectedAssetAndEmploy('');
    setShowSelectTagAssetPopup(false);
    setShowTagAssetEmployPopup(false);
    setFilteredAssetsData(allAssetsData);
  };

  const tapOnDone = () => {
    if (selectedOption === 'People') {
      if (selectedAsset) {
        formik.setFieldValue('asset', selectedAsset._id);
        let data = {
          asset_id: selectedAsset._id,
          assetName: selectedAsset.asset_name,
          assetImage: selectedAsset.image,
          assetUid: selectedAsset.asset_uid,
          assetUserImage: avathar3,
          assignedTo: selectedEmployee.full_name,
        };
        setSelectedAssetAndEmploy(data);
        // setTimeout(() => {
        setIsOpenModal(false);
        setSelectedAsset('');
        setSelectedEmployee('');
        setSelectedOption('All assets');
        // }, 300);
      } else {
        setAssetErrorMsg('Please select asset');
      }
    } else if (selectedAssetAndEmploy) {
      formik.setFieldValue('asset', selectedAssetAndEmploy.asset_id);
      setIsOpenModal(false);
      setSelectedAsset('');
      setSelectedEmployee('');
      setSelectedOption('All assets');
    } else {
      setAssetErrorMsg('Please select asset');
    }
    setAssetRequiredMsg('');
  };

  const renderSuggestionSection = () => {
    return (
      <SuggetionBoard
        title={strings.suggestionBoardTitleForRaiseTicket}
        desc={strings.suggestionBoardDescForRaiseTicket}
      />
    );
  };

  const renderFormSection = () => {
    return (
      <form
        onSubmit={formik.handleSubmit}
        className={styles.ticketFormWrapperStyle}
      >
        <h2 className={styles.titleTextStyle}>{strings.createNewTicket}</h2>
        {renderTagAssetInputSection()}
        {assetRequiredMsg && (
          <p className={styles.errorTextStyle}>{assetRequiredMsg}</p>
        )}
        <Input
          name="subject"
          lableText={'Ticket subject*'}
          lableTextStyle={styles.lableTextStyle}
          placeholder={'Enter subject here'}
          value={formik.values.subject}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          hasError={
            formik.touched.subject && formik.errors.subject
              ? formik.errors.subject
              : ''
          }
          customInputContainerStyles={styles.inputStyle}
        />
        <TextAreaInput
          name="note"
          lableText={'Ticket notes (Optional)'}
          lableTextStyle={styles.lableTextStyle}
          placeholder={'Description or short note here'}
          value={formik.values.note}
          onChange={formik.handleChange}
          customTextAreaStyles={styles.descriptionTextAreaStyles}
          hasError={
            formik.touched.note && formik.errors.note ? formik.errors.note : ''
          }
        />
        <FileUploadInput
          lable="Upload image (Optional)"
          upLoadImage={upLoadIssueResolveImage}
          setupLoadImage={setUpLoadIssueResolveImage}
          // imgPreViewAction={() => setIsPreViewImg(true)}
        />
        {renderBtns()}
      </form>
    );
  };

  const renderTagAssetInputSection = () => {
    return (
      <div
        onClick={() => {
          openAssetModal();
        }}
        className={styles.tagAssetWrapperStyle}
      >
        <p className={styles.lableTextStyle}>{'Tag an asset*'}</p>
        <div
          className={
            isOpenModal
              ? styles.tagAssetSubWrapperStyle2
              : styles.tagAssetSubWrapperStyle
          }
        >
          {selectedAssetAndEmploy ? (
            <AssetAndUserCard
              assetImage={
                selectedAssetAndEmploy.assetImage
                  ? selectedAssetAndEmploy.assetImage
                  : macBookIcon
              }
              assetName={selectedAssetAndEmploy.assetName}
              assignedTo={selectedAssetAndEmploy.assignedTo}
              assetUserImg={selectedAssetAndEmploy.assetUserImage}
              assetUid={selectedAssetAndEmploy.assetUid}
            />
          ) : (
            <p className={styles.imgUploaddPHTextSTyle}>
              Click here to tag an asset
            </p>
          )}
          <div
            onClick={() => tapOnRemoveAssetOrDropdown()}
            className={
              selectedAssetAndEmploy
                ? styles.closeIconStyle
                : styles.iconViewStyle
            }
          >
            <img
              src={
                selectedAssetAndEmploy ? closeIconWithGrayBg : dropdownGrayIcon
              }
              alt="dropdown"
              className={styles.iconStyle}
            />
          </div>
          {tagAssetModal()}
        </div>
      </div>
    );
  };

  const renderBtns = () => {
    return (
      <div className={styles.btnContainerStyle}>
        <p className={styles.errorTextStyle}>{apiError}</p>
        <div className={styles.btnSubContainerStyle}>
          <Button
            title={strings.cancel}
            onClick={() => {
              // setIsSuccessModalOpen(true);
              console.log('dasdasda');
            }}
            color="light"
            btnStyle={styles.customBtnStyle}
          />
          <Button
            title={actionLoading ? strings.loading : 'Create'}
            type={'submit'}
            btnStyle={styles.customBtnStyle}
          />
        </div>
      </div>
    );
  };

  const tagAssetModal = () => {
    return (
      <Popup
        show={isOpenModal}
        showOverlay={false}
        popupRef={popupRef}
        customPopUpStyles={styles.tagAssetModalStyle}
        customOverLayStyles={styles.tagAssetModalOverLayStyle}
        customPopUpBgStyles={styles.tagAssetCustomPopUpBgStyle}
        popUpOutsideContainerStyles={styles.tagAssetOutsideContainerStyle}
      >
        {renderTagAssetModalContentSection()}
      </Popup>
    );
  };

  const renderTagAssetModalContentSection = () => {
    return (
      <div className={styles.tagAssetModalContentViewStyle}>
        <div className={styles.tagAssetTopSectionStyle}>
          <div className={styles.tagAssetModalHeaderViewStyle}>
            <p className={styles.tagAssetModalHeaderTextStyle}>Tag an asset</p>
            <div
              onClick={() => closeTagAssteModal()}
              className={styles.closeIconViewStyle}
            >
              <img
                src={closeIcon}
                alt="close icon"
                className={styles.closeIconStyle}
              />
            </div>
          </div>
          <div className={styles.middleRadioBtnsAndInputStyles}>
            <div className={styles.radioOptionsViewStyle}>
              {tagAssetOptions.map((item, index) => (
                <div key={index}>
                  <RadioButton
                    lable={item}
                    isActive={selectedOption === item ? true : false}
                    onSelect={() => selectRadioBtn(item)}
                  />
                </div>
              ))}
            </div>
            {selectedOption === 'People' && (
              <>
                {renderAddedEmploySelectSection()}
                {renderMessageSection()}
              </>
            )}
            {renderTagAssetSection()}
          </div>
        </div>
        <div className={styles.doneBtnViewStyle}>
          <Button
            title={'Done'}
            btnStyle={styles.doneBtnStyle}
            onClick={() => tapOnDone()}
          />
        </div>
      </div>
    );
  };

  const renderAddedEmploySelectSection = () => {
    return (
      <InputWithPopup
        placeholder={'Select from the added users'}
        title={selectedEmployee ? selectedEmployee.full_name : ''}
        description={selectedEmployee ? selectedEmployee.employee_type : ''}
        assetUserImg={avathar3}
        rightIcon={dropdownGrayIcon}
        popupOpen={showTagAssetEmployPopup}
        onClick={() => {
          setShowTagAssetEmployPopup(!showTagAssetEmployPopup);
          setShowSelectTagAssetPopup(false);
        }}
        removeSelectedData={() => {
          setSelectedEmployee('');
          setSelectedAsset('');
          setFilteredAssetsData(null);
        }}
      >
        <div className={styles.selectEmployeeSectionViewStyle}>
          <div className={styles.searchInputSectionStyle}>
            <SearchInput
              placeholder={'Search people...'}
              onChange={(e) => filterEmployeeData(e)}
              onRightIconClick={() => setShowTagAssetEmployPopup(false)}
              leftIcon={searchIcon}
              rightIcon={closeIcon}
              customInputViewStyle={styles.customSearchInputViewStyle}
              customInputStyle={styles.customSearchInputStyle}
              customLeftIconViewStyle={
                styles.customSearchINputLeftIconViewStyle
              }
            />
          </div>
          <div className={styles.listStyle}>
            {filteredEmployeeData?.length > 0 &&
              filteredEmployeeData?.map((employee, index) => (
                <div
                  onClick={() => employeeSelected(employee)}
                  key={index}
                  className={
                    employee._id === selectedEmployee?._id
                      ? styles.selectedCardViewStyle
                      : styles.listCardViewStyle
                  }
                >
                  <div className={styles.employeeImgViewStyle}>
                    <img
                      src={avathar3}
                      alt=""
                      className={styles.employeeImgStyle}
                    />
                  </div>
                  <div className={styles.listDetailsStyle}>
                    <p className={styles.listTitleTextStyle}>
                      {employee.full_name}
                    </p>
                    <p className={styles.listSubTitleTextStyle}>
                      {employee.employee_type}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </InputWithPopup>
    );
  };

  const renderMessageSection = () => {
    return (
      <div className={styles.msgTextViewStye}>
        <p className={styles.msgTextStye}>
          Cannot find the required asset from the long list of added assets?
          Select user first to filter out the rest.
        </p>
      </div>
    );
  };

  const renderTagAssetSection = () => {
    return (
      <InputWithPopup
        lableText={'Tag asset*'}
        placeholder={'Select from the added users'}
        title={
          selectedOption === 'People'
            ? selectedAsset.asset_name
            : selectedAssetAndEmploy.assetName
        }
        description={
          selectedOption === 'People'
            ? selectedAsset.asset_uid
            : selectedAssetAndEmploy.assetUid
        }
        assetImage={selectedAsset.image ? selectedAsset.image : macBookIcon}
        assetUserImg={selectedAssetAndEmploy.assetUserImage}
        assetUserName={
          selectedOption !== 'People' ? selectedAssetAndEmploy.assignedTo : ''
        }
        errorMsg={assetErrorMsg}
        rightIcon={dropdownGrayIcon}
        popupOpen={showSelectTagAssetPopup}
        onClick={() => {
          setShowSelectTagAssetPopup(!showSelectTagAssetPopup);
          setShowTagAssetEmployPopup(false);
          setAssetErrorMsg('');
        }}
        removeSelectedData={() => {
          if (selectedOption === 'People') {
            setSelectedAsset('');
          } else {
            setSelectedAssetAndEmploy('');
          }
        }}
      >
        <div className={styles.selectEmployeeSectionViewStyle}>
          <div className={styles.searchInputSectionStyle}>
            <SearchInput
              placeholder={'Select from the added assets'}
              onChange={(e) => filterAssetsData(e)}
              onRightIconClick={() => setShowSelectTagAssetPopup(false)}
              leftIcon={searchIcon}
              rightIcon={closeIcon}
              customInputViewStyle={styles.customSearchInputViewStyle}
              customInputStyle={styles.customSearchInputStyle}
              customLeftIconViewStyle={
                styles.customSearchINputLeftIconViewStyle
              }
            />
          </div>
          <div className={styles.listStyle}>
            {filteredAssetsData?.length > 0 ? (
              filteredAssetsData?.map((asset, index) => (
                <div
                  key={index}
                  onClick={() => assetSelected(asset)}
                  className={
                    asset._id === selectedEmployee?._id
                      ? styles.selectedCardViewStyle
                      : styles.listCardViewStyle
                  }
                >
                  <div className={styles.employeeImgViewStyle}>
                    <img
                      src={macBookIcon}
                      alt=""
                      className={styles.employeeImgStyle}
                    />
                  </div>
                  <div className={styles.listDetailsStyle}>
                    <p className={styles.listTitleTextStyle}>
                      {asset.asset_name}
                    </p>
                    <p className={styles.listSubTitleTextStyle}>
                      #{asset.asset_uid}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <>
                <Divider />
                <div className={styles.noAssetsTextViewStyle}>
                  <p className={styles.noAssetsTextStyle}>
                    assets are not available
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </InputWithPopup>
    );
  };

  const renderSuccessModalContainer = () => {
    return (
      <Popup
        show={isSuccessModalOpen}
        showOverlay={true}
        popupRef={popupRef}
        customPopUpStyles={styles.successPopupCustomStyle}
      >
        <div className={styles.successPopupSubContainerStyle}>
          <div className={styles.successPopupTitleViewStyle}>
            <p
              className={styles.successPopupTitleStyle}
              onClick={() => setIsSuccessModalOpen(false)}
            >
              {strings.ticket}{' '}
              <span className={styles.selectedAssetIdtextStyle}>
                #{selectedAssetAndEmploy.assetUid}
              </span>{' '}
              {strings.ticketHasRaised}
            </p>
            <p className={styles.successPopupSubTitleStyle}>
              {raiseTicketResponse.subject}
            </p>
          </div>
          <div className={styles.timelineContainer}>
            <ul className={styles.timeline}>
              <li className={styles.timelineItem}>
                <div className={styles.indicator}>
                  <div className={styles.innerDot}></div>
                </div>
                {renderTicketDetails()}
              </li>
              <li className={styles.timelineItem}>
                <div className={styles.indicator}>
                  <div className={styles.innerDot}></div>
                </div>
                {renderAssetDetails()}
              </li>
            </ul>
          </div>
          {renderSucccessModalBtns()}
        </div>
      </Popup>
    );
  };

  const renderTicketDetails = () => {
    return (
      <div className={styles.event}>
        <div className={styles.descriptionContainer}>
          <p className={styles.timelineTitleTextStyle}>
            {strings.ticketDDetails}
          </p>
          <div className={styles.ticketAssetDetailsStyle}>
            <div className={styles.assetImageViewStyle}>
              <img
                src={
                  raiseTicketResponse.image
                    ? raiseTicketResponse.image
                    : raiseTicketProductImg
                }
                alt="img"
                className={styles.assetImageStyle}
              />
            </div>
            <div className={styles.assetDetailStyle}>
              <p className={styles.assetDetailTitleStyle}>
                {raiseTicketResponse.subject}
              </p>
              <p className={styles.assetDetailDescriptionStyle}>
                {raiseTicketResponse.note}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAssetDetails = () => {
    return (
      <div className={styles.event}>
        <div className={styles.descriptionContainer}>
          <p className={styles.timelineTitleTextStyle}>
            {strings.assetUserDetails}
          </p>
          <div className={styles.ticketAssetDetailsStyle}>
            <AssetAndUserCard
              assetImage={
                selectedAssetAndEmploy.assetImage
                  ? selectedAssetAndEmploy.assetImage
                  : macBookIcon
              }
              assetName={selectedAssetAndEmploy.assetName}
              assignedTo={selectedAssetAndEmploy.assignedTo}
              assetUserImg={selectedAssetAndEmploy.assetUserImage}
              assetUid={selectedAssetAndEmploy.assetUid}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSucccessModalBtns = () => {
    return (
      <div className={styles.successModalinnerBtnsViewStyle}>
        <Button
          title={'Close'}
          onClick={() => {
            setIsSuccessModalOpen(false);
            setSelectedAssetAndEmploy('');
            setRaiseTicketResponse('');
          }}
          color="light"
          btnStyle={styles.customBtnStyle}
        />
        <Button
          onClick={() => {
            setIsSuccessModalOpen(false);
            setSelectedAssetAndEmploy('');
            setRaiseTicketResponse('');
            navigate('/all-tickets');
          }}
          title={'Go to all tickets'}
          btnStyle={styles.customBtnStyle}
        />
      </div>
    );
  };
  return (
    <div className={styles.mainContainerStyle}>
      {renderSuggestionSection()}
      <div className={styles.formWrapperStyle}>{renderFormSection()}</div>
      {renderSuccessModalContainer()}
    </div>
  );
};
export default RaiseTicket;
