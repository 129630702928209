import axios from 'utils/axios';
import * as endPoints from 'networking/endpoints';

export const assetCreateApi = async (data) =>
  Promise.resolve(axios.post(endPoints.createAsset, data));

export const assetDetailsApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getAssetDetails.replace('{id}', id)));

export const assetListApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getAllAssets + `?company=${id}`));

export const assetUpdateApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updateAsset, data));

export const assetAssignApi = async (data) =>
  Promise.resolve(axios.post(endPoints.assignAsset, data));

export const assetDeleteApi = async (id) =>
  Promise.resolve(axios.delete(endPoints.deleteAsset.replace('{id}', id)));

export const assetUnAssignApi = async (id) =>
  Promise.resolve(axios.put(endPoints.assetUnAssign.replace('{id}', id)));

export const assetAssignToOthersApi = async (data) =>
  Promise.resolve(axios.post(endPoints.assignAssetToOthers, data));

export const uploadAssetXlsFileApi = async (data) =>
  Promise.resolve(axios.post(endPoints.uploadAssetXlsFile, data));

export const markAsLostApi = async (id) =>
  Promise.resolve(axios.put(endPoints.markAsLost.replace('{id}', id)));
