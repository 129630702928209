import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuthContext } from 'hooks/useAuthContext';
import { account_types } from 'utils/constants';
import LoadingSpinner from 'components/loadingSpinner';
const AuthGuard = (props) => {
  const { userType: guardUserType, children } = props;
  const { isLoggedIn, isInitialized, userType } = useAuthContext();
  let loginRoute = 'login';
  if (
    guardUserType.includes(account_types.super_admin) &&
    userType === account_types.super_admin
  ) {
    loginRoute = 'login';
  } else if (
    (guardUserType.includes(account_types.admin) &&
      userType === account_types.admin) ||
    (guardUserType.includes(account_types.employee) &&
      userType === account_types.employee)
  ) {
    loginRoute = 'login';
  }

  if (!isInitialized) {
    return <LoadingSpinner />;
  }

  if (!isLoggedIn) {
    return <Navigate to={loginRoute} />;
  }

  if (isLoggedIn) {
    if (!guardUserType.includes(userType)) {
      return <Navigate to={loginRoute} />;
    }
  }
  return <>{children}</>;
};

AuthGuard.propTypes = {
  userType: PropTypes.arrayOf(
    PropTypes.oneOf([
      account_types.admin,
      account_types.super_admin,
      account_types.employee,
    ])
  ),
  children: PropTypes.node,
};

export default AuthGuard;
