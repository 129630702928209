import { useAuthContext } from 'hooks/useAuthContext';
import { assetListApi } from 'networking/api/asset';
import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { useSpinner } from './spinnerprovider';
import {
  employeeListApi,
  getAllAdminsAndEmployeesApi,
  getAllEmployeesWithLastAssetApi,
} from 'networking/api/employee';
import { account_types } from 'utils/constants';
import { getAlladminDetailsApi } from 'networking/api/admin';

const UserDataContext = createContext();

const UserDataProvider = (props) => {
  const { company, userType, user } = useAuthContext();
  const { setIsShowLoading } = useSpinner();
  const [allAssetsData, setAllAssetsData] = useState(null);
  const [allEmployeesData, setAllEmployeesData] = useState(null);
  const [allAdminsDetails, setAllAdminsDetails] = useState();
  const [filterAdminDetails, setFilterAdminDetails] = useState();
  const [allAssetsCount, setAllAssetsCount] = useState(0);
  const [assignedAssetsCount, setAssignedAssetsCount] = useState(0);
  const [unAssignedAssetsCount, setUnAssignedAssetsCount] = useState(0);
  const [lostAssetCount, setLostAssetCount] = useState(0);
  const [users, setUsers] = useState([]);
  const [otherUsersData, setOtherUsersData] = useState([]);

  useEffect(() => {
    if (userType && company) {
      getAllAssets();
      getAllEmployeesWithLastAsset();
      getAllAdminsAndEmployees();
    }
  }, [company]);

  const getAllAssets = async () => {
    try {
      if (company) {
        setIsShowLoading(true);
        const response = await assetListApi(company._id);
        if (response.data.type === 'success') {
          setAllAssetsData(response.data.data);
          setAllAssetsCount(response.data.data.length);
          setAssignedAssetsCount(
            response.data.data.filter((item) => item.assigned_to !== null)
              .length
          );
          setUnAssignedAssetsCount(
            response.data.data.filter((item) => item.assigned_to === null)
              .length
          );
          setLostAssetCount(
            response.data.data.filter(
              (item) => item.asset_status === 'Marked lost'
            ).length
          );
          setIsShowLoading(false);
        } else {
          console.log('get Asset List catch', response.data.message);
          setIsShowLoading(false);
        }
      }
    } catch (error) {
      setIsShowLoading(false);
      console.log('get Asset List catch', error.response.data);
    }
  };

  const getAllEmployeesWithLastAsset = async () => {
    try {
      if (company) {
        setIsShowLoading(true);
        const response = await getAllEmployeesWithLastAssetApi(company._id);
        if (response.data.type === 'success') {
          setAllEmployeesData(response.data.data);
          setIsShowLoading(false);
        } else {
          console.log('get Employee List', response.data.message);
          setIsShowLoading(false);
        }
      }
    } catch (error) {
      console.log(error.message);
      setIsShowLoading(false);
    }
  };

  // get all admins
  // const getAllAdmins = async () => {
  //   try {
  //     if (company) {
  //       setIsShowLoading(true);
  //       const response = await getAlladminDetailsApi(company._id);
  //       if (response.data.type === 'success') {
  //         setAllAdminsDetails(response.data.data);
  //         const filterAdmins = response.data.data.filter(
  //           (admin) => admin.account_type === 'Admin'
  //         );
  //         setFilterAdminDetails(filterAdmins);
  //         setIsShowLoading(false);
  //       } else {
  //         console.log('get all admins List', response.data.message);
  //         setIsShowLoading(false);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //     setIsShowLoading(false);
  //   }
  // };

  const getAllAdminsAndEmployees = async () => {
    try {
      if (company) {
        setIsShowLoading(true);
        const response = await getAllAdminsAndEmployeesApi(company._id);
        if (response.data.type === 'success') {
          setUsers(response.data.data);
          setOtherUsersData(
            response.data.data.filter((item) => item._id !== user._id)
          );
          const filterAdmins = response.data.data.filter(
            (admin) => admin.account_type === 'Admin' && admin._id !== user._id
          );
          setAllAdminsDetails(filterAdmins);
          setIsShowLoading(false);
        } else {
          console.log('error', response.data.message);
        }
      }
    } catch (error) {
      setIsShowLoading(false);
      console.log(error.message);
    }
  };

  const memoizedValue = useMemo(
    () => ({
      allAssetsCount,
      assignedAssetsCount,
      unAssignedAssetsCount,
      lostAssetCount,
      allAssetsData,
      allEmployeesData,
      allAdminsDetails,
      setAllAssetsData,
      setAllEmployeesData,
      setAllAdminsDetails,
      filterAdminDetails,
      setFilterAdminDetails,
      getAllAssets,
      getAllEmployeesWithLastAsset,
      users,
      setUsers,
      otherUsersData,
      setOtherUsersData,
    }),
    [
      allAssetsCount,
      assignedAssetsCount,
      unAssignedAssetsCount,
      lostAssetCount,
      allAssetsData,
      allEmployeesData,
      allAdminsDetails,
      filterAdminDetails,
      setAllAssetsData,
      setAllEmployeesData,
      setAllAdminsDetails,
      setFilterAdminDetails,
      getAllAssets,
      getAllEmployeesWithLastAsset,
      users,
      setUsers,
      otherUsersData,
      setOtherUsersData,
    ]
  );

  return (
    <UserDataContext.Provider value={memoizedValue}>
      {props.children}
    </UserDataContext.Provider>
  );
};

export { UserDataContext, UserDataProvider };
