import React, { useRef, useState } from 'react';
import Button from 'components/button';
import classNames from 'classnames';
import MoreActionsButton from 'components/moreActionButton';
import styles from './styles.module.css';
import {
  dropDownIcon,
  flagBlackIcon,
  flagIcon,
  forwardArrow,
  forwardIcon,
  rightForwardArrow,
} from 'resources/images/Images';
import { useAuthContext } from 'hooks/useAuthContext';
import Modal from 'components/modal1';
import Image from 'components/image';
import moment from 'moment/moment';
const TicketDetailsCard = (props) => {
  const {
    assetId,
    assetName,
    ticketProductImg,
    subject,
    note,
    taskActionBtnTitleOne,
    taskActionBtnTitleTwo,
    taskActionBtnTitleThree,
    customTaskActionBtnStylesOne,
    customTaskActionBtnStylesTwo,
    taskActionButtons,
    taskActionBtnOneClick,
    taskActionBtnTwoClick,
    taskActionBtnThreeClick,
    forwardedData,
    ticketStatus,
  } = props;
  const { user } = useAuthContext();
  const [showticketForwardTimeline, setShowticketForwardTimeline] =
    useState(false);
  const popupRef = useRef();

  const forwardedText = () => {
    let msg = `${forwardedData[0]?.action_by?.full_name} `;
    if (user?._id === forwardedData[0]?.action_by?._id) {
      msg += `(You) `;
    }
    msg += 'forwarded to ';
    if (forwardedData[0]?.users.length > 0) {
      msg += `${forwardedData[0].users[0].full_name}`;
      if (forwardedData[0].users[0]._id === user._id) {
        msg += ' (You)';
      }
    }
    return msg;
  };

  const renderRaiseTicketProductHeaderSection = () => {
    return (
      <div className={styles.raiseTicketProductHeaderStyles}>
        <p className={styles.productNameTextStyles}>
          {`${assetId}: ${assetName}`}
        </p>
      </div>
    );
  };

  const renderRaiseTicketProductImageSection = () => {
    return (
      <React.Fragment>
        {ticketProductImg && (
          <div className={styles.productImageBlockStyles}>
            <img
              src={ticketProductImg}
              alt="raiseTicketProductImg"
              className={styles.productImageStyles}
            />
          </div>
        )}
      </React.Fragment>
    );
  };

  const renderRaiseTicketProductIssueDetailsSection = () => {
    return (
      <div className={styles.raiseTicketProductIssueBlockStyles}>
        {renderProductIssueDetailsSection()}
        {taskActionButtons && renderTaskActionButtonSection()}
      </div>
    );
  };

  const renderProductIssueDetailsSection = () => {
    return (
      <div className={styles.productIssueDetailsBlockStyles}>
        <h5 className={styles.productIssueHeadingStyles}>{subject}</h5>
        <p className={styles.productIssueDescStyles}>{note}</p>
        {/* {departmentName && (
          <p className={styles.ticketTranferredDeptTextStyles}>
            Dept:
            <span className={styles.ticketTransferDeptNameStyles}>
              {departmentName}
            </span>
          </p>
        )} */}
      </div>
    );
  };

  const renderTaskActionButtonSection = () => {
    return (
      <div className={styles.taskActionBtnsBlockStyles}>
        <Button
          title={taskActionBtnTitleOne}
          btnStyle={classNames(
            styles.taskActionBtnStylesOne,
            customTaskActionBtnStylesOne
          )}
          onClick={taskActionBtnOneClick}
        />
        <Button
          title={taskActionBtnTitleTwo}
          color="light"
          btnStyle={classNames(
            styles.taskActionBtnStylesTwo,
            customTaskActionBtnStylesTwo
          )}
          onClick={taskActionBtnTwoClick}
        />
        <Button
          title={taskActionBtnTitleThree}
          color="light"
          btnStyle={classNames(
            styles.taskActionBtnStylesTwo,
            customTaskActionBtnStylesTwo
          )}
          onClick={taskActionBtnThreeClick}
        />
        {/* <MoreActionsButton /> */}
      </div>
    );
  };

  const taskProgressBgColor = (type) => {
    if (type === 'Forward') {
      return styles.primaryBgStyle;
    } else {
      return styles.redBgStyle;
    }
  };

  const renderForwardedSection = () => {
    return (
      <div className={styles.forwardDataViewStyle}>
        <div className={styles.forwardImgViewStyle}>
          <img
            src={rightForwardArrow}
            alt="arrow"
            className={styles.arrowImageStyles}
          />
        </div>
        <p>{forwardedText()}</p>
        <div
          onClick={() => setShowticketForwardTimeline(true)}
          className={styles.dropDownIconStyle}
        >
          <img
            src={dropDownIcon}
            alt="arrow"
            className={styles.arrowImageStyles}
          />
        </div>
        <Modal
          show={showticketForwardTimeline}
          handleClickOutSide={() => setShowticketForwardTimeline(false)}
          popupRef={popupRef}
          showOverlay
          containerStyle={styles.taskItemActivityModalStyle}
        >
          <div className={styles.timeLineContainerStyle}>
            {forwardedData.map((activity, index) => (
              <div
                key={'act' + index}
                className={styles.timeLineItemWrapperStyle}
              >
                <Image
                  url={activity.type === 'Forward' ? forwardIcon : flagIcon}
                  imgContainerStyle={[
                    styles.timeLineImgContainerStyle,
                    taskProgressBgColor(activity.type),
                  ].join(' ')}
                  imgStyle={styles.timeLineImgStyle}
                />
                <div className={styles.timeLineContentWrapperStyle}>
                  <p className={styles.timeLineTitleStyle}>
                    {activity.type === 'Forward'
                      ? `${activity.action_by.full_name}${
                          activity.users[0]._id === user._id ? '(You)' : ''
                        } forwarded to ${activity.users[0].full_name} ${
                          activity.users[0]._id === user._id ? '(You)' : ''
                        }`
                      : `Complaint raised by ${activity.action_by.full_name}`}
                  </p>
                  {activity.note && (
                    <div className={styles.timelineNoteViewStyle}>
                      <p className={styles.timelineNoteTextStylw}>
                        {activity.note}
                      </p>
                    </div>
                  )}
                  <span className={styles.timeLineSubTitleStyle}>
                    {`${moment(activity.created_at).format(
                      'hh:mm a'
                    )}, ${moment(activity.created_at).fromNow()}`}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </Modal>
      </div>
    );
  };

  return (
    <div className={styles.raiseTicketProductContainerStyles}>
      {forwardedData[0]?.type === 'Forward' &&
        ticketStatus === 'Complaint raised' &&
        renderForwardedSection()}
      <div className={styles.raiseTicketProductSubContainerStyles}>
        <div className={styles.raiseTicketProductBlockStyles}>
          {renderRaiseTicketProductHeaderSection()}
          {renderRaiseTicketProductImageSection()}
        </div>
        {renderRaiseTicketProductIssueDetailsSection()}
      </div>
    </div>
  );
};

export default TicketDetailsCard;
