import React, { useEffect } from 'react';
import Button from 'components/button';
import ButtonTab from 'components/buttontab';
import {
  appleM1Macbook,
  avathar1,
  avathar3,
  deleteIcon,
  editAssetInfoIcon,
} from 'resources/images/Images';
import styles from './styles.module.css';

const Modal = (props) => {
  const {
    open,
    onClose,
    children,
    title,
    desc,
    leftBtnTitle,
    rightBtnTitle,
    onRighBtnClick = () => {},
    onLeftBtnClick = () => {},
    modalBlockStyles,
    customModalContentBlockStyles,
    customModalContentBottomStyles,
    customLeftBtnStyle,
    customRightBtnStyle,
  } = props;
  // After opening the Modal Stop Scrolling of the Page
  useEffect(() => {
    document.body.style.cssText = 'overflow:hidden';
    return () => {
      document.body.style.cssText = 'overflow:auto';
    };
  }, []);

  if (!open) {
    return null;
  }
  return (
    <div className={[styles.modalBlockStyles, modalBlockStyles].join(' ')}>
      {title && desc && (
        <div
          className={[
            styles.modalContentBlockStyles,
            customModalContentBlockStyles,
          ].join(' ')}
        >
          <div className={styles.modalContentTopStyles}>
            {title && <h4 className={styles.modalHeadTextStyles}>{title}</h4>}
            {desc && <p>{desc}</p>}
          </div>
          <div
            className={[
              styles.modalContentBottomStyles,
              customModalContentBottomStyles,
            ].join(' ')}
          >
            {leftBtnTitle && (
              <Button
                title={leftBtnTitle}
                btnStyle={[styles.backBtnStyles, customLeftBtnStyle].join(' ')}
                onClick={onLeftBtnClick}
              />
            )}
            {rightBtnTitle && (
              <Button
                title={rightBtnTitle}
                color="light"
                btnStyle={[styles.summeryBtnStyles, customRightBtnStyle].join(
                  ' '
                )}
                onClick={onRighBtnClick}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
