import React from 'react';
import styles from './styles.module.css';
import {
  avathar3,
  closeIconWithGrayBg,
  macBookIcon,
} from 'resources/images/Images';
const AssetAndUserCard = (props) => {
  // console.log('asdasd', props);
  const {
    assetImage,
    assetName,
    assignedTo,
    assetUid,
    assetUserImg,
    closeAction = () => {},
    closeIcon,
    email,
    customUseIconStyle,
  } = props;
  return (
    <div className={styles.containerStyle}>
      {closeIcon && (
        <div onClick={closeAction} className={styles.closeIconViewStyle}>
          <img
            src={closeIconWithGrayBg}
            alt="avatar"
            className={styles.closeIconStyle}
          />
        </div>
      )}
      {assetImage && (
        <div className={styles.assetIconViewStyle}>
          <div className={styles.assetIconStyle}>
            <img src={assetImage} alt="asset" className={styles.iconStyle} />
          </div>
        </div>
      )}
      {assetUserImg && (
        <div className={[styles.iconViewStyle, customUseIconStyle].join(' ')}>
          <img src={assetUserImg} alt="user" className={styles.iconStyle} />
        </div>
      )}
      <div className={styles.textViewStyle}>
        <p className={styles.titleTextStyle}>{assetName}</p>
        {assetUid && (
          <p className={styles.subTitleTextStyle}>
            {assignedTo
              ? `Assigned to @${assignedTo} - #${assetUid}`
              : assetUid && `#${assetUid}`}
          </p>
        )}
        {email && <p className={styles.subTitleTextStyle}>{email}</p>}
      </div>
    </div>
  );
};

export default AssetAndUserCard;
