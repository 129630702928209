import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/avatar';
import styles from './styles.module.css';

const ConversationCard = (props) => {
  const { avathar, name, message, time } = props;
  return (
    <div className={styles.conversationCardBlockStyle}>
      <div className={styles.conversationLeftBlockStyle}>
        <Avatar url={avathar} />
        <div className={styles.conversationContentStyles}>
          <h2 className={styles.conversationNameTextStyle}>{name}</h2>
          <p className={styles.conversationDescTextStyle}>
            <span>{name}:</span> {message}
          </p>
        </div>
      </div>
      <div className={styles.conversationRightBlockStyle}>
        <p className={styles.timeTextStyle}>{time}</p>
      </div>
    </div>
  );
};

ConversationCard.propTypes = {
  name: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  avathar: PropTypes.string.isRequired,
};
export default ConversationCard;
