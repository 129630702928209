import React, { useEffect, useState } from 'react';
import ButtonTab from 'components/buttontab';
import Input from 'components/input';
import Avatar from 'components/avatar';
import {
  avathar1,
  filterIcon,
  macBookIcon,
  searchIcon,
  selectIcon,
} from 'resources/images/Images';

import { assetListApi } from 'networking/api/asset';
import { useAuthContext } from 'hooks/useAuthContext';
import SearchInput from 'components/searchInput';
import { useAppDataContext } from 'hooks/useAppDataContext';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { useSpinner } from 'providers/spinnerprovider';
import AssetEmployeeCard from 'components/individualAeCard';
import styles from './styles.module.css';
import { useStringData } from 'providers/stringsprovider';
import LoaderForPages from 'components/loaderForPages';
import { account_types } from 'utils/constants';
import DontHaveAccessPage from 'components/dontHaveAccess';
import { useUserDataContext } from 'hooks/userDataContext';
const AssetList = () => {
  //context data
  const { user, company, userType } = useAuthContext();
  const { setIndividualId } = useAppDataContext();
  const { allAssetsData } = useUserDataContext();
  const { setIsShowLoading } = useSpinner();
  const { strings } = useStringData();
  const navigation = useNavigate();
  const [filteredData, setFilteredData] = useState(allAssetsData);
  const [assetListData, setAssetListData] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setFilteredData(allAssetsData);
  }, [allAssetsData]);

  const filterAssetList = (e) => {
    setFilteredData(
      allAssetsData.filter((asset) =>
        JSON.stringify(asset)
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      )
    );
  };

  const renderTopSection = () => {
    return (
      <div className={styles.assetListTopBlockStyle}>
        {/* <Input placeholder="Search for assets" onChange={filterAssetList} /> */}
        <SearchInput
          placeholder={strings.searchAssets}
          onChange={filterAssetList}
          leftIcon={searchIcon}
        />
        <ButtonTab
          icon={filterIcon}
          titleText={strings.filter}
          custButtonTabContainerStyle={styles.filterBtnStyle}
        />
        <ButtonTab
          icon={selectIcon}
          titleText={strings.select}
          custButtonTabContainerStyle={styles.filterBtnStyle}
        />
      </div>
    );
  };

  const renderListSection = () => {
    return (
      <div className={styles.assetListBottomBlockStyle}>
        <table>
          <thead>
            <tr className={styles.headingStyle}>
              <td>{strings.SerialNo}</td>
              <td>{strings.assetInfo}</td>
              <td>{strings.assignedTo}</td>
              <td>{strings.status}</td>
              <td>{strings.lastActivity}</td>
              <td>{strings.upcomingActivity}</td>
            </tr>
          </thead>
          <tbody>
            {filteredData?.length > 0 ? (
              filteredData?.map((item, index) => (
                <tr
                  key={index}
                  className={styles.assetListDataRowStyle}
                  onClick={() => {
                    navigation('/asset-overview', {
                      state: { assetId: item._id },
                    });
                    setIndividualId(item.asset_uid);
                  }}
                >
                  <td className={styles.serialNoStyle}>
                    {'#' + item.asset_uid}
                  </td>
                  <td className={styles.assetInfoStyle}>
                    <p>{item.asset_name}</p>
                    <p className={styles.assetInfoTextStyle}>
                      {item.asset_type}
                    </p>
                  </td>
                  <td className={styles.assignedStyle}>
                    {item.assigned_to ? (
                      <div>
                        <Avatar
                          url={
                            item.assigned_to.image
                              ? item.assigned_to.image
                              : avathar1
                          }
                          imgContainerStyle={styles.avatharStyle}
                        />
                        <p>{item.assigned_to.full_name}</p>
                      </div>
                    ) : (
                      '------'
                    )}
                  </td>
                  <td
                    className={
                      item.asset_status === 'Assigned'
                        ? styles.statusStyle
                        : item.asset_status === 'Unassigned'
                        ? styles.unAssignedStyle
                        : styles.markedLostStyle
                      // item.assigned_to
                      //   ? styles.statusStyle
                      //   : styles.unAssignedStyle
                    }
                  >
                    {item.asset_status}
                  </td>
                  <td className={styles.lastActivityStyle}>
                    <p>{item.last_activity}</p>
                    <p className={styles.lastActivityTimeStyle}>
                      {moment(item.updated_at).fromNow()}
                    </p>
                  </td>
                  <td className={styles.nextActivityStyle}>
                    {/* {item.nextActivity.type && <p>{item.nextActivity.type}</p>}
                     {item.nextActivity.time && (
                      <p className={styles.nextActivityTimeStyle}>
                        {item.nextActivity.time}
                      </p>
                    )} */}
                    --
                  </td>
                </tr>
              ))
            ) : (
              <p className={styles.noAssetsTitleStyles}>No Assets Found </p>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const renderListMobileViewSection = () => {
    return (
      <div className={styles.mobileViewStyles}>
        {filteredData?.length > 0 ? (
          filteredData?.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <AssetEmployeeCard
                  userProfile={macBookIcon}
                  title={item.asset_name}
                  description={`#: ${item.asset_uid}`}
                  leftLable={strings.lastAssigned}
                  leftTitle={
                    item.assigned_to ? item.assigned_to?.full_name : '- - - -'
                  }
                  leftDesc={item.assigned_to?.email}
                  rightLable={strings.status}
                  rightTitle={item.asset_status}
                  icon={true}
                  customAssetBottomStyles={styles.customAssetBottomStyles}
                  customRightTitleTextStyle={
                    item.asset_status === 'Assigned'
                      ? styles.statusStyle
                      : item.asset_status === 'Unassigned'
                      ? styles.unAssignedStyle
                      : styles.markedLostStyle
                  }
                  onClick={() => {
                    navigation('/asset-overview', {
                      state: { assetId: item._id },
                    });
                    setIndividualId(item.asset_uid);
                  }}
                />
              </React.Fragment>
            );
          })
        ) : (
          <p className={styles.noAssetsTitleStyles}>No Assets Found </p>
        )}
      </div>
    );
  };
  return (
    <div className={styles.assetListMainBlockStyle}>
      {/* {userType !== account_types.employee ? (
        <> */}
      {renderTopSection()}
      {renderListSection()}
      {renderListMobileViewSection()}
      {/* </>
      ) : (
        <DontHaveAccessPage />
      )} */}
    </div>
  );
};

export default AssetList;
