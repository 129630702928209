import React, { useRef } from 'react';
import Popup from 'components/popup';
import { avathar1 } from 'resources/images/Images';
import Button from 'components/button';
import styles from './styles.module.css';

const ImagePreview = (props) => {
  const {
    image,
    setImage,
    proceedAction = () => {},
    cancel = () => {},
    closeBtn,
    loader,
    isPreViewImg,
    setIsPreViewImg,
  } = props;

  // ref
  const popupRef = useRef(null);

  // function to close the preview image
  const closeImgPreview = () => {
    setIsPreViewImg(false);
    setImage();
  };
  return (
    <Popup
      show={isPreViewImg}
      showOverlay={true}
      popupRef={popupRef}
      showCloseButton={closeBtn ? true : false}
      onClose={closeImgPreview}
      customPopUpStyles={styles.imgPreViewModalStyle}
      closeButtonStyles={styles.modalCloseButtonStyles}
      customOverLayStyles={styles.previewOverStyles}
    >
      <div className={styles.previewImageBlockStyles}>
        <div className={styles.imgPreViewStyle}>
          <img
            src={image ? image : avathar1}
            alt="close"
            className={styles.imgStyle}
          />
        </div>
        {loader && (
          <div className={styles.loaderBlockStyles}>
            <div className={styles.containerStyle}>
              <div className={styles.loadingSpinner}></div>
            </div>
          </div>
        )}
        {closeBtn === false && (
          <div className={styles.doneOrCancelBtnStyles}>
            <Button
              title={'proceed'}
              onClick={proceedAction}
              btnStyle={styles.proceedBtnStyles}
            />
            <Button
              title={'Cancel'}
              onClick={cancel}
              btnStyle={styles.cancelBtnStyles}
            />
          </div>
        )}
      </div>
    </Popup>
  );
};

export default ImagePreview;
