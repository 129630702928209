import {
  camera,
  contact,
  document,
  galleryBlack,
  location,
  more,
  triangleIcon,
  rhombusIcon,
  hexagonIcon,
  flagIcon,
  rightForwardArrow,
  forwardIcon,
  tickIcon,
  raceIcon,
  timerIcon,
  audio,
  forwardArrow,
  downWardArrowbox,
} from 'resources/images/Images';

export const account_types = {
  super_admin: 'Super Admin',
  admin: 'Admin',
  employee: 'Employee',
};

export const asset_options = {
  raise_a_ticket: 'Raise a ticket',
  return_item: 'Return item',
  asset_details: 'Asset details',
};

export const chat_options = {
  asset_details: 'Asset details',
  mark_as_returned: 'Mark as returned',
  mark_as_recovered: 'Mark as recovered',
  report_chat: 'Report chat',
};

export const tickets_tasks = {
  solved: 'Solved',
  neeed_expertise: 'Need expertise of other dept.',
  cant_solve: `Can't solve`,
};

export const tickets_activity_type = {
  raise: 'Raise',
  assignment: 'Assignment',
  transfer: 'Transfer',
  accept: 'Accept',
  markDone: 'Marked done',
  markedResolved: 'Marked resloved',
};
export const choose_file_options = [
  {
    title: 'Photos',
    image: galleryBlack,
  },
  {
    title: 'Camera',
    image: camera,
  },
  {
    title: 'Location',
    image: location,
  },
  {
    title: 'Contact',
    image: contact,
  },
  {
    title: 'Document',
    image: document,
  },
  {
    title: 'More',
    image: more,
  },
];

export const ticketFilterData = [
  'All',
  'Archived',
  'Complaint raised',
  'Assigned',
  'In progress',
  'In review',
  'Closed',
];

export const taskActionDifficultyLevelData = [
  {
    titleText: 'Easy',
    color1: 'rgba(30, 30, 32, 0.40)',
    color2: '#E8E8E8',
    color3: '#E8E8E8',
    icon: triangleIcon,
  },
  {
    titleText: 'Moderate',
    color1: 'rgba(30, 30, 32, 0.40)',
    color2: 'rgba(30, 30, 32, 0.40)',
    color3: '#E8E8E8',
    icon: rhombusIcon,
  },
  {
    titleText: 'Expert',
    color1: 'rgba(30, 30, 32, 0.40)',
    color2: 'rgba(30, 30, 32, 0.40)',
    color3: 'rgba(30, 30, 32, 0.40)',
    icon: hexagonIcon,
  },
];

export const taskActionPriorityData = [
  {
    priorityOption: 'Yes',
  },
  {
    priorityOption: 'No',
  },
];

export const taskLables = ['Add assignee', 'Task priority', 'Difficulty'];

export const ticketTabs = [
  {
    title: 'Overview',
    value: 'Overview',
  },
  {
    title: 'Task',
    value: 'Task',
  },
  {
    title: 'Timeline',
    value: 'Timeline',
  },
  {
    title: 'Complaint info',
    value: 'Overview',
  },
];

export const timeLineIcons = {
  Raise: flagIcon,
  Forward: forwardIcon,
  Assign: flagIcon,
  Working: flagIcon,
  Completed: timerIcon,
  Revert: flagIcon,
  "Can't solve": flagIcon,
  'Force close': flagIcon,
  Resolved: raceIcon,
  Closed: raceIcon,
};

export const ticketMenuOptions = [
  {
    title: 'Unmute alerts',
    icon: audio,
  },
  {
    title: 'Forward complaint',
    icon: forwardArrow,
  },
  {
    title: 'Archive complaint',
    icon: downWardArrowbox,
  },
];

export const clusterTabs = [
  {
    title: 'Today',
    value: 'Today',
  },
  {
    title: 'Past reports',
    value: 'Past reports',
  },
];
