import React, { useRef, useState } from 'react';
import SelectPopup from 'components/selectpopup';
import {
  OutlineAllAssetsIcon,
  downArrowIcon,
  usersIcon,
} from 'resources/images/Images';
import styles from './styles.module.css';
import { useStringData } from 'providers/stringsprovider';

const RecentlyAdded = (props) => {
  const { strings } = useStringData();
  const { recentlyAddedData } = props;
  const assetOptions = [
    { icon: OutlineAllAssetsIcon, text: 'Assets' },
    { icon: usersIcon, text: 'Users' },
  ];
  const [isStaticPopupOpen, setStaticPopupOpen] = useState(false);
  const [selectStaticOption, setSelectStaticOption] = useState({
    icon: OutlineAllAssetsIcon,
    text: 'Assets',
  });
  const popupRef = useRef(null);

  const toggleStaticPopup = () => {
    setStaticPopupOpen(!isStaticPopupOpen);
  };
  // function to close the popup onclick the outside
  const handleClickOutside = (event) => {
    if (isStaticPopupOpen) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setStaticPopupOpen(false);
      }
    }
  };
  return (
    <div className={styles.recentlyAddedBlockStyles}>
      <div className={styles.recntlyAddedHeadBlockStyles}>
        <h3 className={styles.recntlyAddedHeadTextStyles}>
          {strings.recentlyAdded}
        </h3>
        <SelectPopup
          isPopupOpen={isStaticPopupOpen}
          togglePopup={toggleStaticPopup}
          selectStatsticOption={selectStaticOption}
          downArrowIcon={downArrowIcon}
          options={assetOptions}
          handleStasticOptionItemClick={(option) => {
            console.log(option);
            setSelectStaticOption(option);
            toggleStaticPopup();
          }}
          popupRef={popupRef}
          handleClickOutside={handleClickOutside}
          setPopupOpen={setStaticPopupOpen}
          customStatiscPopUpStyles={styles.recentlyAddedPopUpStyles}
        />
      </div>
      <div className={styles.recentlyAddedContentBlockStyles}>
        {recentlyAddedData &&
          recentlyAddedData.map((item, index) => (
            <div key={index} className={styles.recntlyAddedUsersStyles}>
              <div className={styles.avatharBlockStyles}>
                <img
                  src={item.avathar}
                  alt={strings.ticketIcon}
                  className={styles.avatharImageStyles}
                />
              </div>
              <div>
                <p className={styles.nameTextStyles}>{item.name}</p>
                <p className={styles.emailTextStyles}>{item.email}</p>
              </div>
            </div>
          ))}
      </div>
      <div className={styles.viewallTextBlockStyles}>
        <p className={styles.viewAllTextStyles}>{strings.viewAllPeople}</p>
      </div>
    </div>
  );
};

export default RecentlyAdded;
