import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import {
  OutlineAllAssetsIcon,
  avathar1,
  avathar3,
  closeIcon,
  flagBlackIcon,
  rightArrowIcon,
  sendBlackIcon,
} from 'resources/images/Images';
import { asset_options } from 'utils/constants';
import { useAuthContext } from 'hooks/useAuthContext';
import { assetDetailsApi } from 'networking/api/asset';
import LoaderForPages from 'components/loaderForPages';
import { useNavigate } from 'react-router';
import { useStringData } from 'providers/stringsprovider';
const ScanneddAsset = () => {
  const { isLoggedIn } = useAuthContext();
  const { user } = useAuthContext();
  const { strings } = useStringData();
  const navigation = useNavigate();
  const [assetData, setAssetData] = useState(null);
  const [loader, setLoader] = useState(true);

  let guest_user = localStorage.getItem('guest_user');

  useEffect(() => {
    getAssetData();
  }, []);

  const id = '6594f0ed017d6f68ece8cae8';
  const getAssetData = async () => {
    try {
      setLoader(true);
      let response = await assetDetailsApi(id);
      if (response.data.type === 'success') {
        setAssetData(response.data.data);
        setLoader(false);
      } else {
        console.log('get Asset Data api failed', response.data.message);
        setLoader(false);
      }
    } catch (error) {
      console.log('ressss', error.response.data);
      setLoader(false);
    }
  };

  let optionsData = [
    {
      name: 'Raise a ticket',
      desc: 'Only accessible by registered users',
      icon: flagBlackIcon,
      bgColor: '#F6E5F6',
      isVisible: isLoggedIn ? true : false,
    },
    {
      name: 'Return item',
      desc: 'Click if you found this item',
      icon: sendBlackIcon,
      bgColor: '#E9F6E5',
      isVisible: true,
    },
    {
      name: 'Asset details',
      desc: 'Only accessible by registered users',
      icon: OutlineAllAssetsIcon,
      bgColor: '#E5ECF6',
      isVisible: isLoggedIn ? true : false,
    },
  ];

  const tapOnOption = async (data) => {
    if (data.name === asset_options.raise_a_ticket) {
      navigation('/raiseTicketThroughQr', { state: { assetData: assetData } });
    } else if (data.name === asset_options.return_item) {
      if (user && isLoggedIn) {
        navigation('/returnItem', {
          state: { asset: assetData, guest_email: '' },
        });
      } else if (!user && guest_user) {
        navigation('/returnItem', {
          state: { asset: assetData, guest_email: guest_user },
        });
      } else {
        navigation('/emailRequired', { state: { asset: assetData } });
      }
    } else {
      console.log('data.name', data.name);
    }
  };
  const rendedrHeaderSection = () => {
    return (
      <div className={styles.headerViewStyle}>
        <div
          onClick={() => console.log('close')}
          className={styles.closeIMgViewStyle}
        >
          <img src={closeIcon} alt="close" className={styles.imgStyle} />
        </div>
      </div>
    );
  };

  const renderAssetDetails = () => {
    return (
      <div className={styles.assetDetailsViewStyle}>
        <div className={styles.assetImagesViewStyle}>
          <div className={styles.userImgViewStyle}>
            <img src={avathar1} alt="user" className={styles.userImgStyle} />
          </div>
          <div className={styles.assetImgViewStyle}>
            <img
              src={assetData.image || avathar3}
              alt="asset"
              className={styles.userImgStyle}
            />
          </div>
        </div>
        <div className={styles.assetDetailsSubViewStyle}>
          <p className={styles.titleTextStyle}>{strings.scannedItemTitle}</p>
          <div className={styles.assetNameViewStyle}>
            <p>{assetData.asset_name}</p>
          </div>
          <p className={styles.uniqIdTextStyle}>
            {strings.uniqueId}{' '}
            <span className={styles.textStyle}>{assetData.asset_uid}</span>
          </p>
          <p className={styles.uniqIdTextStyle}>
            {strings.ownedBy}{' '}
            <span className={styles.textStyle}>Vilje Tech </span>
          </p>
        </div>
      </div>
    );
  };

  const renderAssetOptions = () => {
    return (
      <div className={styles.optionsViewStyle}>
        {optionsData.map((item, index) => (
          <React.Fragment key={index}>
            {item.isVisible && (
              <div
                onClick={() => tapOnOption(item)}
                className={styles.eachOptionViewStyle}
              >
                <div className={styles.leftSectionViewStyle}>
                  <div
                    className={styles.optionImgViewStyle}
                    style={{ backgroundColor: item.bgColor }}
                  >
                    <img
                      src={item.icon}
                      alt="img"
                      className={styles.optionImgStyle}
                    />
                  </div>
                  <div className={styles.optionDetailsViewStyle}>
                    <p className={styles.optionTitleTextStyle}>{item.name}</p>
                    <p className={styles.optionDescTextStyle}>{item.desc}</p>
                  </div>
                </div>
                <div className={styles.optionRightImgViewStyle}>
                  <img
                    src={rightArrowIcon}
                    alt="img"
                    className={styles.optionRightImgStyle}
                  />
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.mainContainerStyle}>
      {loader ? (
        <LoaderForPages />
      ) : (
        <>
          {rendedrHeaderSection()}
          {renderAssetDetails()}
          {renderAssetOptions()}
        </>
      )}
    </div>
  );
};

export default ScanneddAsset;
