import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styles from './style.module.css';
import SideBar from 'components/sidebarmenu';
import Header from 'components/header';
import RightSideBar from 'components/rightSideBar';
import { useAppDataContext } from 'hooks/useAppDataContext';
import MobileHeader from 'components/mobileHeader';
import useWindowDimensions from 'hooks/useDimensionHook';

const DashboardLayout = () => {
  const { sideBar = false, navBarOpen } = useAppDataContext();
  const { width } = useWindowDimensions();
  const { pathname } = useLocation();
  
  return (
    <div className={styles.dashboardMainContainerStyle}>
      <div className={styles.dashboardContainerStyle}>
        <SideBar />
        <div className={styles.headerWrapperStyle}>
          <Header />
          <div className={styles.contenWrappertStyle}>
            <Outlet />

            {pathname === '/dashboard' && (
              <MobileHeader mobileView={navBarOpen} />
            )}
          </div>
        </div>
        {sideBar ? width >= 430 ? <RightSideBar /> : null : null}
      </div>
    </div>
  );
};

export default DashboardLayout;
