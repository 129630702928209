import React, { useEffect, useRef, useState } from 'react';
import ActivityCard from 'components/activityCard';
import SuggetionBoard from 'components/suggetionborard';
import StatisticsCard from 'components/statisticscard';
import {
  rightArrowDarkIcon,
  avathar1,
  avathar2,
  avathar3,
  avathar4,
  dashboardEmptyImg,
  addAssetsIcon2,
  addProfileIcon,
  OutlineAllAssetsIcon,
  usersIcon,
  downArrowIcon,
  activityIcon,
  myActivityIcon,
  trakrPremiumPlanImg,
  editDashBoardIcon,
  dashboardIcon,
  selectDashBoardOptionImg,
} from 'resources/images/Images';
import ConversationCard from 'components/conversationcard';
import useWindowDimensions from 'hooks/useDimensionHook';
import { useAppDataContext } from 'hooks/useAppDataContext';
import SelectPopup from 'components/selectpopup';
import { conversationsData } from 'resources/dammyData/dammyData';
import Popup from 'components/popup';
import Button from 'components/button';
import EditDashBoardCard from 'components/editDashBoardCard';
import styles from './style.module.css';
import { useStringData } from 'providers/stringsprovider';
import { useUserDataContext } from 'hooks/userDataContext';

const AdminDashboard = () => {
  /*contexts*/
  const { emptyData } = useAppDataContext();
  const {
    allAssetsCount,
    assignedAssetsCount,
    unAssignedAssetsCount,
    lostAssetCount,
  } = useUserDataContext();
  const { strings } = useStringData();

  /*usedimension hook*/
  const { width } = useWindowDimensions();

  /*based on reference handle onclick outside close the popup*/
  const popupRef = useRef(null);

  /*states*/
  const [isStaticPopupOpen, setStaticPopupOpen] = useState(false);
  const [isActivityPopupOpen, setIsActivityPopupOpen] = useState(false);
  const [selectStaticOption, setSelectStaticOption] = useState({
    icon: OutlineAllAssetsIcon,
    text: 'Assets',
  });
  const [selectActivityOption, setSelectActivityOption] = useState({
    icon: activityIcon,
    text: 'All activity',
  });
  const [isSuggestionBoardOpen, setIsSuggestionBoardOpen] = useState(true);
  const [istrakrPremiumPlanOpen, setIstrakrPremiumPlanOpen] = useState(false);
  const [showEditDashBoard, setShowEditDashBoard] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  /*function to open trakrPremium plan modal*/
  const toggleTrakrPremiumModal = () => {
    setIstrakrPremiumPlanOpen(!istrakrPremiumPlanOpen);
  };

  /*function to close the suggestion board onclick the [dontshow or hide button]*/
  const handleSuggestionBoardClose = () => {
    setIsSuggestionBoardOpen(false);
  };

  /*function to toggle edit dashboard  */
  const toggleEditDashBoard = () => {
    setShowEditDashBoard(!showEditDashBoard);
  };

  // /function to select the edit dashboard options

  const handleOptionSelect = (optionText, isSelected) => {
    if (isSelected) {
      setSelectedOptions((prevOptions) => [...prevOptions, optionText]);
    } else {
      setSelectedOptions((prevOptions) =>
        prevOptions.filter((text) => text !== optionText)
      );
    }
    localStorage.setItem('selectedOptions', JSON.stringify(selectedOptions));
  };

  // Load selected options from local storage when the component mounts
  useEffect(() => {
    const savedOptions = localStorage.getItem('selectedOptions');
    if (savedOptions) {
      setSelectedOptions(JSON.parse(savedOptions));
    }
  }, []);

  /*asset options data*/
  const assetOptions = [
    { icon: OutlineAllAssetsIcon, text: 'Assets' },
    { icon: usersIcon, text: 'Users' },
  ];

  /*activitylog options data*/
  const activityLogOptions = [
    { icon: activityIcon, text: 'All activity' },
    { icon: myActivityIcon, text: 'My activity log' },
  ];

  /*edit dashboard data*/
  const editDashBoardData = [
    {
      option: strings.statistics,
    },
    {
      option: strings.activity,
    },
    {
      option: strings.recentConversation,
    },
    {
      option: strings.quickSettings,
    },
    {
      option: strings.contactUs,
    },
  ];

  // function toggle stastic popup
  const toggleStaticPopup = () => {
    setStaticPopupOpen(!isStaticPopupOpen);
    setIsActivityPopupOpen(false);
  };

  // function toggle activitylog popup
  const toggleActivityPopup = () => {
    setIsActivityPopupOpen(!isActivityPopupOpen);
    setStaticPopupOpen(false);
  };

  // function to close static popup onClick outside

  const handleClickOutsideStaticPopup = (event) => {
    if (isStaticPopupOpen) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setStaticPopupOpen(false);
      }
    }
  };

  // function to close Activity popup onClick outside

  const handleClickOutsideActivityPopup = (event) => {
    if (isActivityPopupOpen) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsActivityPopupOpen(false);
      }
    }
  };

  // function to close the edit dashboard modal onClick the outside
  const handleClickOutSideEditDashBoardModal = (event) => {
    if (showEditDashBoard) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowEditDashBoard(false);
      }
    }
  };

  const renderEmptyDashBoardSection = () => {
    return (
      <SuggetionBoard
        dashBoardimage={dashboardEmptyImg}
        suggetionBoardStyle={styles.emptyDashBoardStyles}
        suggetionBoardBottomStyles={styles.suggestionBoardBottomStyles}
        title={strings.welcomeToTrakrTitle}
        desc={strings.welcomeToTrakrDescp}
        leftBtnTitle={strings.addAssets}
        leftBtnImg={addAssetsIcon2}
        btnStyles1={styles.addAssetAndEmployeeBtnStyles}
        btnStyles2={styles.addAssetAndEmployeeBtnStyles}
        rightBtnTitle={strings.addEmployees}
        rightBtnImg={addProfileIcon}
      />
    );
  };

  const renderSuggestionBoard = () => {
    if (isSuggestionBoardOpen) {
      return (
        <SuggetionBoard
          title={strings.title}
          desc={strings.descp}
          leftBtnTitle={strings.learnMore}
          rightBtnTitle={width <= 480 ? strings.hide : strings.dontShowAgain}
          onRightButtonClick={handleSuggestionBoardClose}
        />
      );
    }
  };

  const renderTrakrPremiumPlanSection = () => {
    return (
      <Popup
        show={istrakrPremiumPlanOpen}
        showOverlay={true}
        onClose={toggleTrakrPremiumModal}
        showCloseButton={true}
        popupRef={popupRef}
        closeButtonStyles={styles.trakrPremiumCloseBtnStyles}
        closeIconStyles={styles.closeIconStyles}
        customPopUpStyles={styles.tarkrPremiumModalStyles}
        customOverLayStyles={styles.trakrPremiumOverLayStyles}
      >
        <SuggetionBoard
          dashBoardimage={width <= 480 ? '' : trakrPremiumPlanImg}
          suggetionBoardStyle={styles.trakrPremiumSugestionBoardStyles}
          suggetionBoardBottomStyles={styles.suggestionBoardBottomStyles}
          title={strings.trakrExpiryTitle}
          desc={strings.trakrExpiryDescp}
          leftBtnTitle={strings.renewPlan}
          btnStyles1={styles.addAssetAndEmployeeBtnStyles}
          btnStyles2={styles.addAssetAndEmployeeBtnStyles}
          rightBtnTitle={strings.browsePlans}
        />
      </Popup>
    );
  };

  const renderStaticsSection = () => {
    return (
      <div className={styles.dashboardSectionStyle}>
        <div className={styles.labelWrapperStyle}>
          <p className={styles.labelTitleStyle}>{strings.statisticsheader}</p>
          <SelectPopup
            isPopupOpen={isStaticPopupOpen}
            togglePopup={toggleStaticPopup}
            selectStatsticOption={selectStaticOption}
            downArrowIcon={downArrowIcon}
            options={assetOptions}
            handleStasticOptionItemClick={(option) => {
              console.log(option);
              setSelectStaticOption(option);
              toggleStaticPopup();
            }}
            stasticsOptionImg={true}
            popupRef={popupRef}
            handleClickOutside={handleClickOutsideStaticPopup}
            setPopupOpen={setStaticPopupOpen}
          />
        </div>
        <div className={styles.staticCardStyles}>
          <StatisticsCard count={allAssetsCount} title={strings.totalAssets} />
          <StatisticsCard
            count={assignedAssetsCount}
            title={strings.assignedAssets}
          />
          <StatisticsCard
            count={unAssignedAssetsCount}
            title={strings.unAssignedAssets}
          />
          <StatisticsCard count={'NA'} title={strings.damagedAssets} />
          <StatisticsCard count={lostAssetCount} title={strings.lostAssets} />
        </div>
      </div>
    );
  };

  const renderActivityLogSection = () => {
    return (
      <div className={styles.dashboardSectionStyle}>
        <div className={styles.dashboardSectionHeaderStyle}>
          <div className={styles.activityLabelWrapperStyle}>
            <p className={styles.labelTitleStyle}>
              {strings.activityLogHeader}
            </p>
            <SelectPopup
              isPopupOpen={isActivityPopupOpen}
              togglePopup={toggleActivityPopup}
              selectStatsticOption={selectActivityOption}
              downArrowIcon={downArrowIcon}
              options={activityLogOptions}
              handleStasticOptionItemClick={(option) => {
                console.log(option);
                setSelectActivityOption(option);
                toggleActivityPopup();
              }}
              stasticsOptionImg={true}
              popupRef={popupRef}
              handleClickOutside={handleClickOutsideActivityPopup}
              setPopupOpen={setIsActivityPopupOpen}
            />
          </div>
          <div className={styles.actionWrapperStyle}>
            <p className={styles.actionTitleStyle}>{strings.viewAll}</p>
            <div className={styles.actionImgWraperStyle}>
              <img
                src={rightArrowDarkIcon}
                className={styles.actionImgStyle}
                alt={strings.viewAll}
              />
            </div>
          </div>
        </div>
        <div className={styles.activityCardWrapperStyle}>
          <ActivityCard
            header="AST-111: Apple M1 Macbook 13"
            description="Reservation request from James Carter is approved."
            images={[avathar1, avathar2, avathar3]}
            time="09:00 pm"
          />
          <ActivityCard
            header="AST-111: Apple M1 Macbook 13"
            description="Reservation request from James Carter is approved."
            images={[avathar1, avathar2, avathar4]}
            time="09:00 pm"
          />
          <ActivityCard
            header="AST-111: Apple M1 Macbook 13"
            description="Reservation request from James Carter is approved."
            images={[avathar2, avathar3, avathar1]}
            time="09:00 pm"
          />
        </div>
      </div>
    );
  };

  const renderRecentConversationSection = () => {
    return (
      <div className={styles.dashboardSectionStyle}>
        <div className={styles.dashboardSectionHeaderStyle}>
          <div className={styles.labelWrapperStyle}>
            <p className={styles.labelTitleStyle}>
              {strings.recentConversation}
            </p>
          </div>
          <div className={styles.actionWrapperStyle}>
            <p className={styles.actionTitleStyle}>{strings.viewAll}</p>
            <div className={styles.actionImgWraperStyle}>
              <img
                src={rightArrowDarkIcon}
                className={styles.actionImgStyle}
                alt={strings.viewAll}
              />
            </div>
          </div>
        </div>
        <div
          className={[
            styles.conversationMainWrapperStyle,
            styles.conversationWrapperStyle,
          ].join(' ')}
        >
          {conversationsData.map((item, index) => (
            <div
              key={'conv' + index}
              className={styles.conversationCardMainBlockStyle}
            >
              <ConversationCard
                name={item.name}
                message={item.message}
                time={item.time}
                avathar={item.avatar}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderEditDashBoardBtnSection = () => {
    return (
      <div className={styles.editBtnStyles}>
        <Button
          title="Edit dashboard"
          image={editDashBoardIcon}
          btnStyle={styles.editDashBoardBtnStyles}
          onClick={toggleEditDashBoard}
        />
      </div>
    );
  };

  const renderEditDashBoardModalSection = () => {
    return (
      <Popup
        show={showEditDashBoard}
        showOverlay={true}
        onClose={toggleEditDashBoard}
        popupRef={popupRef}
        customPopUpStyles={styles.editDashBoardModalStyles}
        customOverLayStyles={styles.editDashBoardOverLayStyles}
        // handleClickOutside={handleClickOutSideEditDashBoardModal}
      >
        {renderEditDashBoardContentSection()}
      </Popup>
    );
  };

  const renderEditDashBoardContentSection = () => {
    return (
      <div className={styles.editDashBoardContainerStyles}>
        <div className={styles.editDashBoardHeadingAndOptionStyles}>
          <h3 className={styles.editDashHeadingStyles}>
            {strings.dashBoardSectionHeader}
          </h3>
          <div className={styles.chooseSectionHeadingAndOptionsStyles}>
            <p className={styles.chooseSectionTextStyles}>
              {strings.chooseSection}
            </p>
            <div className={styles.editDashBoardOptionStyles}>
              {editDashBoardData &&
                editDashBoardData.map((item, index) => {
                  return (
                    <EditDashBoardCard
                      key={index}
                      optionText={item.option}
                      optionImage={selectDashBoardOptionImg}
                      onOptionSelect={handleOptionSelect}
                    />
                  );
                })}
            </div>
          </div>
        </div>
        {renderCancelAndSavePreferenceBtnSection()}
      </div>
    );
  };

  const renderCancelAndSavePreferenceBtnSection = () => {
    return (
      <div className={styles.cancelAndSavepreferenceButtonsStyles}>
        <Button
          title={strings.cancel}
          btnStyle={styles.cancelBtnStyles}
          onClick={toggleEditDashBoard}
        />
        <Button
          title={strings.savePrefernce}
          btnStyle={styles.savePreferencesBtnStyles}
          onClick={() => {
            alert(`selectedoption :${selectedOptions}`);
            console.log('Selected options:', selectedOptions);
            toggleEditDashBoard();
          }}
        />
      </div>
    );
  };

  return (
    <div className={styles.dashboardContainerStyle}>
      {emptyData ? (
        <> {renderEmptyDashBoardSection()}</>
      ) : (
        <>
          {renderSuggestionBoard()}
          {renderTrakrPremiumPlanSection()}
          {renderStaticsSection()}
          {renderActivityLogSection()}
          {renderRecentConversationSection()}
          {renderEditDashBoardBtnSection()}
          {renderEditDashBoardModalSection()}
        </>
      )}
    </div>
  );
};

export default AdminDashboard;
