import axios from 'utils/axios';
import * as endPoints from 'networking/endpoints';

// all tickets api
export const getAllTicketsApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getAllTickets + `?company=${id}`));

// get ticket data
export const getTicketDataApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getTicketData.replace('{id}', id)));

// assign ticket to admins
export const forwardTicketApi = async (data) =>
  Promise.resolve(axios.post(endPoints.forwardTicket, data));

// take action to assign task admins and employees
export const takeActionToAssignTaskApi = async (data) =>
  Promise.resolve(axios.post(endPoints.assignTicket, data));

// accept the ticket
export const acceptTicketApi = async (data) =>
  Promise.resolve(axios.post(endPoints.acceptTicket, data));

// solve the ticket
export const solveTicketApi = async (data) =>
  Promise.resolve(axios.post(endPoints.solveTicket, data));

// ticket,  update task status as : [solved]
export const markAsTicketResolvedApi = async (data) =>
  Promise.resolve(axios.post(endPoints.markAsTicketResolved, data));

// ticket,  update task status as : [cant solve]
export const markAsTicketCantSolveApi = async (data) =>
  Promise.resolve(axios.post(endPoints.markAsTicketCantSolve, data));

// ticket,  update task status as : [needExpert]
export const needExpertApi = async (data) =>
  Promise.resolve(axios.post(endPoints.needExpert, data));

// ticket,  update task inner state : [check,un check]
export const updateTaskItemStateApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updateTaskItemState, data));

// ticket,  update task inner state : [check,un check]
export const deleteCheckListApi = async (id) =>
  Promise.resolve(axios.delete(endPoints.deleteTask.replace('{id}', id)));

// ticket, add comment
export const addCommentApi = async (data) =>
  Promise.resolve(axios.post(endPoints.addComment, data));

// ticket, closed if canonot solved
export const closeTicketApi = async (data) =>
  Promise.resolve(axios.post(endPoints.closeTicket, data));
