import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useAppDataContext } from 'hooks/useAppDataContext';
import {
  ClockIcon,
  NotificationIcon,
  ProfileIcon,
  TicketsIcon,
  WhatsNewIcon,
} from 'resources/svg/svg';
import styles from './styles.module.css';

const MobileHeader = (props) => {
  /*contexts*/
  const { emptyData, setMobileHeaderClicked } = useAppDataContext();

  /*routing*/
  const navigate = useNavigate();
  const location = useLocation();

  /*states*/
  const [activeRoute, setActiveRoute] = useState();

  /*based on pathname update the active route state*/

  useEffect(() => {
    setActiveRoute(location.pathname);
  }, [location.pathname]);

  /*mobile menu data [Bottom Header in the mobile view, dash board is not empty]*/

  const mobileMenuData = [
    {
      menuImage: (
        <ClockIcon
          colorType={activeRoute === '/recent' ? '#236bfe' : '#1E1E20'}
        />
      ),
      menuText: 'Recent',
      route: '/recent',
    },
    {
      menuImage: (
        <TicketsIcon
          colorType={activeRoute === '/tickets' ? '#236bfe' : '#1E1E20'}
        />
      ),
      menuText: 'Tickets',
      route: '/tickets',
    },
    {
      menuImage: (
        <NotificationIcon
          colorType={activeRoute === '/alerts' ? '#236bfe' : '#1E1E20'}
        />
      ),
      menuText: 'Alerts',
      route: '/alerts',
    },
    {
      menuImage: (
        <ProfileIcon
          colorType={activeRoute === '/profile' ? '#236bfe' : '#1E1E20'}
        />
      ),
      menuText: 'Profile',
      route: '/profile',
    },
  ];

  /*empty dashboard data [Bottom Header in the mobile view if dashboard is empty]*/

  const emptyDashBoardMenuData = [
    {
      menuImage: (
        <WhatsNewIcon
          colorType={activeRoute === '/whatsnew' ? '#236bfe' : '#1E1E20'}
        />
      ),
      menuText: 'What’s new',
      route: '/whatsnew',
    },
    {
      menuImage: (
        <NotificationIcon
          colorType={activeRoute === '/alerts' ? '#236bfe' : '#1E1E20'}
        />
      ),
      menuText: 'Alerts',
      route: '/alerts',
    },
    {
      menuImage: (
        <ProfileIcon
          colorType={activeRoute === '/profile' ? '#236bfe' : '#1E1E20'}
        />
      ),
      menuText: 'Profile',
      route: '/profile',
    },
  ];

  /*handle Menu Item click [change route- show backarrow navigate to dashboard]*/

  const handleMenuItemClick = (route) => {
    setMobileHeaderClicked(true);
    setActiveRoute(route);
    navigate(route);
  };

  const renderEmptyDashBoardMenuDataSection = () => {
    return (
      <>
        {emptyDashBoardMenuData.map((item, index) => (
          <div
            key={index}
            className={
              item.route === activeRoute
                ? styles.activeRouteStyles
                : styles.mobileMenuBlockStyles
            }
            onClick={() => handleMenuItemClick(item.route)}
          >
            <div className={styles.menuImgBlockStyles}>{item.menuImage}</div>
            <p
              className={
                item.route === activeRoute
                  ? styles.activeMenuDescStyles
                  : styles.menuItemDescStyles
              }
            >
              {item.menuText}
            </p>
          </div>
        ))}
      </>
    );
  };

  const renderMobileMenuDataSection = () => {
    return (
      <>
        {mobileMenuData.map((item, index) => (
          <div
            key={index}
            className={
              item.route === activeRoute
                ? styles.activeRouteStyles
                : styles.mobileMenuBlockStyles
            }
            onClick={() => handleMenuItemClick(item.route)}
          >
            <div className={styles.menuImgBlockStyles}>{item.menuImage}</div>
            <p
              className={
                item.route === activeRoute
                  ? styles.activeMenuDescStyles
                  : styles.menuItemDescStyles
              }
            >
              {item.menuText}
            </p>
          </div>
        ))}
      </>
    );
  };

  return (
    <div
      className={
        props.mobileView
          ? styles.mobileHeaderContainerStyles2
          : styles.mobileHeaderContainerStyles
      }
    >
      {emptyData ? (
        <>{renderEmptyDashBoardMenuDataSection()}</>
      ) : (
        <>{renderMobileMenuDataSection()}</>
      )}
    </div>
  );
};

export default MobileHeader;
