import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Input from 'components/input';
import SuggetionBoard from 'components/suggetionborard';
import ButtonTab from 'components/buttontab';
import {
  addCategoryIcon,
  addManuallyBlackIcon,
  addManuallyGrayIcon,
  addUserIcon,
  checkInOutIcon,
  defaultImg,
  employeeAssetIcon,
  importGrayIcon,
  scheduledAssetIcon,
  xlFileBlackIcon,
  xlFileGrayIcon,
} from 'resources/images/Images';
import Button from 'components/button';
import CheckBox from 'components/checkbox';
import TextAreaInput from 'components/textAreaInput';
import Select from 'react-select';
import { useAuthContext } from 'hooks/useAuthContext';
import { employeeListApi } from 'networking/api/employee';
import Modal from 'components/modal';
import { useNavigate } from 'react-router';
import { assetCreateApi } from 'networking/api/asset';
import styles from './styles.module.css';
import moment from 'moment';
import { useSpinner } from 'providers/spinnerprovider';
import { uploadImageApi } from 'networking/api/upload';
import UploadXLSFile from 'components/uploadXLSFile';
import { downloadassetsXLSFile } from 'networking/endpoints';
import { useStringData } from 'providers/stringsprovider';
import { account_types } from 'utils/constants';
import DontHaveAccessPage from 'components/dontHaveAccess';
import { useUserDataContext } from 'hooks/userDataContext';
const AssetRegistration = () => {
  const { company, userType } = useAuthContext();
  const { setIsShowLoading } = useSpinner();
  const { otherUsersData, allAssetsData, setAllAssetsData } =
    useUserDataContext();
  const { strings } = useStringData();
  const navigate = useNavigate();
  const imgInputRef = useRef();
  const [imgFile, setImgFile] = useState(null);
  const [imgPreview, setImgPreview] = useState(null);
  const [imgUploadError, setImgUploadError] = useState(null);
  const [xlFileTab, setXlFileTab] = useState(true);
  const [addManuallyTab, setAddmanuallyTab] = useState(false);
  const [importTab, setImportTab] = useState(false);
  const [step, setStep] = useState(1);
  const [autoAddNote, setAutoAddNote] = useState(false);
  // const [asset, setAsset] = useState({
  //   assetName: '',
  //   category: '',
  //   type: '',
  //   uniqueIdentityCode: '',
  //   autoAdduniqueIdentityCode: false,
  //   note: '',
  //   autoAddNote: false,
  // });
  const [selectedAssetType, setSelectedAssetType] = useState('');
  const Categories = [
    {
      title: strings.laptop,
      custom: false,
    },
    {
      title: strings.handBag,
      custom: false,
    },
    {
      title: strings.mobile,
      custom: false,
    },
    {
      title: strings.tab,
      custom: false,
    },
  ];
  const AssetTypes = [
    {
      title: strings.employeeAsset,
      key: 'Employee asset',
      img: employeeAssetIcon,
    },
    {
      title: strings.checkInOut,
      key: 'Check-in and out',
      img: checkInOutIcon,
    },
    {
      title: strings.scheduledAsset,
      key: 'Scheduled asset',
      img: scheduledAssetIcon,
    },
  ];
  const [assetTypes, setAssetTypes] = useState(AssetTypes);
  const [defaultCategories, setDefaultCategories] = useState(Categories);
  const [customCategory, setCustomCategory] = useState('');
  const [is_AddCategory, setIs_AddCategory] = useState(false);
  const [employeesList, setEmployeesList] = useState(otherUsersData);
  const [selectedOptions, setSelectedOptions] = useState();
  const [actionLoading, setActionLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [schemaObj, setSchemaObj] = useState({
    asset_name: Yup.string().required(strings.assetNameRequired),
    asset_category: Yup.string().required(strings.assetcategoryRequired),
  });

  useEffect(() => {
    if (otherUsersData) {
      let newData = [];
      otherUsersData.map((employee) => {
        newData.push({
          value: employee._id,
          label: employee.full_name,
        });
      });
      setEmployeesList(newData);
    }
  }, [otherUsersData]);

  useEffect(() => {
    if (step === 1) {
      setSchemaObj({
        ...schemaObj,
        asset_name: Yup.string().required(strings.assetNameRequired),
        asset_category: Yup.string().required(strings.assetNameRequired),
        asset_type: undefined,
      });
    } else if (step === 2) {
      setSchemaObj({
        ...schemaObj,
        asset_type: Yup.string().required(strings.assetTypeRequired),
        note_to_finder: undefined,
      });
    } else if (step === 3) {
      setSchemaObj({
        ...schemaObj,
        note_to_finder: Yup.string().required(strings.finderIsRequired),
      });
    } else if (step === 4) {
      setSchemaObj({
        ...schemaObj,
        assigned_to: Yup.string(),
      });
    }
  }, [step]);

  const handleNextAndSubmitBtn = () => {
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      setStep(3);
    } else if (step === 3) {
      setStep(4);
    } else {
    }
  };

  const handleAssetCreation = async (values, { resetForm }) => {
    try {
      setApiError('');
      handleNextAndSubmitBtn();
      if (step === 4) {
        setActionLoading(true);
        setIsShowLoading(true);
        const response = await assetCreateApi(values);
        if (response.data.type === 'success') {
          setApiError('');
          resetForm();
          setIsModalOpen(true);
          setIsShowLoading(false);
          setAllAssetsData([response.data.data, ...allAssetsData]);
        } else {
          setApiError(response.data.message);
          setIsShowLoading(false);
        }
        setActionLoading(false);
      }
    } catch (error) {
      console.log('API call error:', error);
      setActionLoading(false);
      setApiError(error.message);
      setIsShowLoading(false);
    }
  };
  const assetCreationSchema = Yup.object().shape(schemaObj);
  const formik = useFormik({
    initialValues: {
      asset_name: '',
      asset_category: '',
      asset_type: '',
      image: '',
      assigned_to: '',
      company: company ? company._id : '',
      purchased_on: '',
      note_to_finder: '',
      description: '',
    },
    validationSchema: assetCreationSchema,
    onSubmit: handleAssetCreation,
  });
  useEffect(() => {
    setApiError('');
  }, [formik.values]);
  const renderTabButtons = () => {
    return (
      <div className={styles.tabWrapperStyle}>
        <ButtonTab
          onClick={() => {
            setXlFileTab(true);
            setAddmanuallyTab(false);
            setImportTab(false);
          }}
          icon={xlFileTab ? xlFileBlackIcon : xlFileGrayIcon}
          titleText={strings.importXLSFile}
          custButtonTabContainerStyle={
            xlFileTab
              ? styles.activeCustButtonTabContainerStyle
              : styles.custButtonTabContainerStyle
          }
          customtitleTextStyle={
            xlFileTab
              ? styles.activeCustomtitleTextStyle
              : styles.customtitleTextStyle
          }
        />
        <ButtonTab
          onClick={() => {
            setXlFileTab(false);
            setAddmanuallyTab(true);
            setImportTab(false);
          }}
          icon={addManuallyTab ? addManuallyBlackIcon : addManuallyGrayIcon}
          titleText={strings.addManually}
          custButtonTabContainerStyle={
            addManuallyTab
              ? styles.activeCustButtonTabContainerStyle
              : styles.custButtonTabContainerStyle
          }
          customtitleTextStyle={
            addManuallyTab
              ? styles.activeCustomtitleTextStyle
              : styles.customtitleTextStyle
          }
        />
        <ButtonTab
          onClick={() => {
            setXlFileTab(false);
            setAddmanuallyTab(false);
            setImportTab(true);
          }}
          icon={importGrayIcon}
          titleText={strings.importFromAPI}
          custButtonTabContainerStyle={
            importTab
              ? styles.activeCustButtonTabContainerStyle
              : styles.custButtonTabContainerStyle
          }
          customtitleTextStyle={
            importTab
              ? styles.activeCustomtitleTextStyle
              : styles.customtitleTextStyle
          }
        />
      </div>
    );
  };

  const renderUploadXlFileContainer = () => {
    return (
      <div className={styles.xlsSectionStyle}>
        <UploadXLSFile
          link={downloadassetsXLSFile}
          fileType={'assets'}
          setUploadedSuccess={() => setIsModalOpen(true)}
        />
      </div>
    );
  };

  const renderAddManuallyContainer = () => {
    return (
      <form
        onSubmit={formik.handleSubmit}
        className={styles.assetFormWrapperStyle}
      >
        <h2 className={styles.assetDetailsTitleTextStyle}>
          {step === 1
            ? strings.chooseNameAndCategory
            : step === 2
            ? strings.assetType
            : step === 3
            ? strings.lostAndFoundDetails
            : strings.assignAssetTo}
        </h2>
        {step === 1
          ? renderStepOne()
          : step === 2
          ? renderStepTwo()
          : step === 3
          ? renderStepThree()
          : renderStepFour()}
        <p className={styles.errorTextStyle}>{apiError}</p>
        <div className={styles.btnContainerStyle}>
          {renderPagination()}
          {renderBtns()}
        </div>
      </form>
    );
  };

  const renderStepOne = () => {
    return (
      <div className={styles.assetdetailsSubWrapperStyle}>
        <div className={styles.assetSerialNoAndNameStyles}>
          <Input
            name="asset_name"
            lableText={strings.assetLabelNameRequired}
            lableTextStyle={styles.lableTextStyle}
            placeholder={strings.assetTypeItem}
            value={formik.values.asset_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={
              formik.touched.asset_name && formik.errors.asset_name
                ? formik.errors.asset_name
                : ''
            }
            // customStyle={styles.assetNameStyles}
            customInputContainerStyles={styles.inputStyle}
          />
        </div>
        {renderPurchaseDateSection()}
        {renderDescriptionSection()}
        {renderAssetCategories()}
      </div>
    );
  };

  const renderPurchaseDateSection = () => {
    return (
      <Input
        name="purchased_on"
        type="date"
        lableText={strings.purchaseDateRequired}
        lableTextStyle={styles.lableTextStyle}
        placeholder={strings.selectPurchaseDate}
        value={formik.values.purchased_on}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        hasError={
          formik.touched.purchased_on && formik.errors.purchased_on
            ? formik.errors.purchased_on
            : ''
        }
        // customStyle={styles.assetNameStyles}
        customInputContainerStyles={styles.inputStyle}
      />
    );
  };

  const renderDescriptionSection = () => {
    return (
      <TextAreaInput
        name="description"
        type="text"
        lableText={strings.descriptionOptional}
        lableTextStyle={styles.DescriptionLableTextStyle}
        value={formik.values.description}
        onChange={formik.handleChange}
        placeholder={strings.enterAssetDescription}
        customTextAreaStyles={styles.descriptionTextAreaStyles}
      />
    );
  };

  const renderStepTwo = () => {
    return (
      <div className={styles.assetdetailsSubWrapperStyle}>
        <div className={styles.selectAssetTypeContainerStyle}>
          {formik.values.asset_type.length > 0 ? (
            <ButtonTab
              titleText={selectedAssetType.title}
              icon={selectedAssetType.img}
              customtitleTextStyle={styles.customtitleTextStyle}
              custButtonTabContainerStyle={styles.selectedAssetTypeCustomStyle}
              closeBtn={true}
              closeAction={() => removeSelectedAssetType()}
            />
          ) : (
            <p className={styles.assetTypePlaceholderTextStyle}>
              {strings.selectAvailableItems}
            </p>
          )}
        </div>
        <div className={styles.assetTypeswrapperStyle}>
          {assetTypes.map((type, index) => (
            <div key={index}>
              <ButtonTab
                titleText={type.title}
                icon={type.img}
                customtitleTextStyle={styles.customtitleTextStyle}
                custButtonTabContainerStyle={styles.assetTypeCustomStyle}
                onClick={() => {
                  addAssetType(type);
                }}
              />
            </div>
          ))}
        </div>
        <p className={styles.errorTextStyle}>
          {formik.touched.asset_type && formik.errors.asset_type
            ? formik.errors.asset_type
            : ''}
        </p>
      </div>
    );
  };

  const renderStepThree = () => {
    return (
      <div className={styles.assetdetailsSubWrapperStyle}>
        {renderUploadImgSection()}
        {/* {renderUniqueCodeSection()} */}
        {renderNoteSection()}
      </div>
    );
  };

  const renderStepFour = () => {
    return (
      <div className={styles.inviteWrapperStyle}>
        <p className={styles.lableTextStyle}>{strings.sendAssignmentInvite}</p>
        <div className={styles.inviteSubWrapperStyle}>
          <div className={styles.dropdownContainer}>
            <Select
              className={styles.selectStyle}
              options={employeesList !== null ? employeesList : []}
              placeholder={strings.enterNameOrMailAddUser}
              value={selectedOptions}
              onChange={handleSelect}
              isSearchable={true}
              styles={{
                placeholder: (base) => ({
                  ...base,
                  fontSize: '1em',
                  color: '#1e1e2066',
                  fontWeight: 400,
                }),
              }}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <ButtonTab
            titleText={strings.addNewuser}
            icon={addUserIcon}
            customtitleTextStyle={styles.customtitleTextStyle}
            custButtonTabContainerStyle={styles.addUserBtnStyle}
          />
        </div>
      </div>
    );
  };

  const handleSelect = (data) => {
    formik.setFieldValue('assigned_to', data.value);
    // setSelectedOptions(data);
  };

  const handleFileChange = (event) => {
    try {
      if (event.target.files && event.target.files.length > 0) {
        setImgUploadError(null);
        const file = event.target.files[0];
        setImgFile(file);
        setImgPreview(URL.createObjectURL(file));
      }
    } catch (error) {
      setImgUploadError(error.message);
    }
  };

  const handleUploadImg = async () => {
    try {
      // api call if file exists
      if (imgFile) {
        let formData = new FormData();
        formData.append('image', imgFile);
        let response = await uploadImageApi(formData);
        if (response.data.type === 'success') {
          setImgUploadError(null);
          formik.setFieldValue('image', response.data.data);
        }
      } else {
        setImgUploadError('Please select img file');
      }
    } catch (error) {
      setImgUploadError(error.message);
    }
  };

  const renderUploadImgSection = () => {
    return (
      <div className={styles.imageUploadContainerStyle}>
        <div className={styles.defaultImgWrapperStyle}>
          <img
            src={imgPreview || defaultImg}
            className={styles.defaultImgStyle}
          />
        </div>
        <p className={styles.imgSizeTextSTyle}>{strings.imageNote}</p>
        <input
          type="file"
          accept=".jpg,.jpeg,.png"
          hidden
          onChange={handleFileChange}
          ref={imgInputRef}
        />
        {imgUploadError && (
          <p className={styles.errorTextStyle}>{imgUploadError}</p>
        )}
        <div className={styles.uploadBtnWrapperStyle}>
          <Button
            title={imgPreview ? strings.changeImage : strings.selectImage}
            btnStyle={styles.customBtnStyle}
            onClick={() => imgInputRef.current?.click()}
          />
          {imgPreview && (
            <Button
              title={strings.uploadImage}
              btnStyle={styles.customBtnStyle}
              onClick={handleUploadImg}
            />
          )}
        </div>
      </div>
    );
  };

  // const renderUniqueCodeSection = () => {
  //   return (
  //     <div className={styles.uniqueIdentityCodeWrapperStyle}>
  //       <Input
  //         lableText={'Unique identification code*'}
  //         lableTextStyle={styles.lableTextStyle}
  //         value={asset.uniqueIdentityCode}
  //         onChange={formik.handleChange}
  //         placeholder={'Enter unique code on QR sticker'}
  //       />
  //       <CheckBox
  //         is_CheckBoxActive={asset.autoAdduniqueIdentityCode}
  //         descText="Auto-add the details when assignee scans the QR sticker attached to assigned asset"
  //         onClick={() =>
  //           setAsset({
  //             ...asset,
  //             autoAdduniqueIdentityCode: !asset.autoAdduniqueIdentityCode,
  //           })
  //         }
  //       />
  //     </div>
  //   );
  // };

  const renderNoteSection = () => {
    return (
      <div className={styles.uniqueIdentityCodeWrapperStyle}>
        <TextAreaInput
          name="note_to_finder"
          lableText={strings.finderRequired}
          lableTextStyle={styles.lableTextStyle}
          value={formik.values.note_to_finder}
          onChange={formik.handleChange}
          placeholder={strings.descriptionHere}
          customTextAreaStyles={styles.descriptionTextAreaStyles}
          hasError={
            formik.touched.note_to_finder && formik.errors.note_to_finder
              ? formik.errors.note_to_finder
              : ''
          }
        />
        <CheckBox
          is_CheckBoxActive={autoAddNote}
          descText={strings.defaultNote}
          onClick={addAutoNote}
        />
      </div>
    );
  };

  const addAutoNote = () => {
    if (autoAddNote) {
      setAutoAddNote(false);
    } else {
      setAutoAddNote(true);
      setTimeout(() => {
        formik.setFieldValue('note_to_finder', 'Please give it back to me');
      }, 300);
    }
  };

  const addAssetType = (assetType) => {
    let oleSelectedAsset = selectedAssetType;
    setSelectedAssetType(assetType);
    formik.setFieldValue('asset_type', assetType.key);
    setAssetTypes(assetTypes.filter((data) => data.key !== assetType.key));
    if (oleSelectedAsset !== '') {
      setAssetTypes((prev) => [...prev, oleSelectedAsset]);
    }
  };

  const removeSelectedAssetType = () => {
    formik.setFieldValue('asset_type', '');
    setAssetTypes((prev) => [...prev, selectedAssetType]);
    setTimeout(() => {
      setSelectedAssetType('');
    }, 200);
  };

  const renderAssetCategories = () => {
    return (
      <div className={styles.assetCategoriesContainerStyle}>
        <p className={styles.lableTextStyle}>Asset category*</p>
        <div className={styles.assetCategoriesSubContainerStyle}>
          <div className={styles.eachTypeContainerStyle}>
            {defaultCategories.map((item, index) => (
              <div key={index}>
                <ButtonTab
                  titleText={item.title}
                  customtitleTextStyle={
                    selected(item) && 'asset_category'
                      ? styles.selectTitleTextStyle
                      : null
                  }
                  onClick={() => {
                    formik.setFieldValue('asset_category', item.title);
                  }}
                  custButtonTabContainerStyle={
                    selected(item)
                      ? styles.selectedCategoryStyle
                      : styles.unSelectedCategoryStyle
                  }
                  closeBtn={item.custom}
                  closeAction={() => removeCustomCategory(item)}
                />
              </div>
            ))}
          </div>
          <div>
            {!is_AddCategory ? (
              <ButtonTab
                titleText={strings.addNewCategory}
                icon={addCategoryIcon}
                onClick={() => setIs_AddCategory(true)}
                customtitleTextStyle={styles.activeCustomtitleTextStyle}
              />
            ) : (
              <div className={styles.categoryInputStyle}>
                <Input
                  lableTextStyle={styles.lableTextStyle}
                  value={customCategory}
                  onChange={(e) => setCustomCategory(e.target.value)}
                  placeholder={strings.addAssetCategory}
                />
                <Button
                  title={strings.add}
                  onClick={() => {
                    addCategory();
                  }}
                  btnStyle={styles.customBtnStyle}
                />
                <Button
                  title={strings.close}
                  onClick={() => {
                    setIs_AddCategory(false);
                    setCustomCategory('');
                  }}
                  color="light"
                  btnStyle={styles.customBtnStyle}
                />
              </div>
            )}
          </div>
        </div>
        <p className={styles.errorTextStyle}>
          {formik.touched.asset_category && formik.errors.asset_category
            ? formik.errors.asset_category
            : ''}
        </p>
      </div>
    );
  };

  const addCategory = () => {
    let data = {
      title: customCategory,
      custom: true,
    };
    formik.setFieldValue('asset_category', customCategory);
    setTimeout(() => {
      setIs_AddCategory(false);
      setCustomCategory('');
      setDefaultCategories((prve) => [...prve, data]);
    }, 200);
  };

  const removeCustomCategory = (item) => {
    if (item.title === formik.values.asset_category) {
      formik.setFieldValue('asset_category', '');
    }
    setDefaultCategories(
      defaultCategories.filter((data) => data.title !== item.title)
    );
  };

  const selected = (item) => {
    if (formik.values.asset_category === item.title) {
      return true;
    } else {
      return false;
    }
  };

  const renderBtns = () => {
    return (
      <div className={styles.btnSubContainerStyle}>
        <Button
          title={step === 1 ? strings.cancel : strings.back}
          onClick={() => {
            cancelAction();
          }}
          color="light"
          btnStyle={styles.customBtnStyle}
        />
        <Button
          title={
            step === 1
              ? strings.continue
              : step === 2
              ? strings.nextStep
              : step === 3
              ? strings.nextStep
              : actionLoading
              ? strings.loading
              : strings.finishAdding
          }
          type={'submit'}
          btnStyle={styles.customBtnStyle}
        />
      </div>
    );
  };

  const continueAction = () => {
    if (xlFileTab) {
    } else if (addManuallyTab) {
      if (step === 1) {
        setStep(2);
      } else if (step === 2) {
        setStep(3);
      } else if (step === 3) {
        setStep(4);
      } else {
        // console.log('final');
      }
    } else {
      // console.log('c');
    }
  };

  const cancelAction = () => {
    if (xlFileTab) {
    } else if (addManuallyTab) {
      if (step === 1) {
      } else if (step === 2) {
        setStep(1);
      } else if (step === 3) {
        setStep(2);
      } else if (step === 4) {
        setStep(3);
      }
    } else {
      // console.log('c');
    }
  };

  const renderPagination = () => {
    return (
      <div className={styles.paginationContainerStyle}>
        <div
          className={step === 1 ? styles.activeDotBtnStyle : styles.dotBtnStyle}
        ></div>
        <div
          className={step === 2 ? styles.activeDotBtnStyle : styles.dotBtnStyle}
        ></div>
        <div
          className={step === 3 ? styles.activeDotBtnStyle : styles.dotBtnStyle}
        ></div>
        <div
          className={step === 4 ? styles.activeDotBtnStyle : styles.dotBtnStyle}
        ></div>
      </div>
    );
  };

  const renderImportFromApiContainer = () => {
    return <div>{strings.importFromAPI}</div>;
  };

  const renderSuggetionBoxContainer = () => {
    return (
      <SuggetionBoard
        title={
          xlFileTab
            ? strings.addingMuiltipleXlsFile
            : strings.manualExcelSheetFile
        }
        desc={strings.suggestionBoardDescp}
      />
    );
  };

  const renderModalContainer = () => {
    return (
      <Modal
        open={isModalOpen}
        title={strings.newAssetAddedSuccessfull}
        desc={strings.suggestionBoardDescp}
        leftBtnTitle={strings.backToDashBoard}
        rightBtnTitle={strings.showSummary}
        onLeftBtnClick={() => {
          setIsModalOpen(false);
          navigate('/dashboard');
        }}
        onRighBtnClick={() => setIsModalOpen(false)}
      />
    );
  };
  return (
    <div className={styles.addAssetmainContainerStyle}>
      {userType !== account_types.employee ? (
        <>
          {renderSuggetionBoxContainer()}
          <div className={styles.subContainerStyle}>
            {renderTabButtons()}
            <div
              className={
                xlFileTab ? styles.assetWrapperStyle2 : styles.assetWrapperStyle
              }
            >
              {xlFileTab
                ? renderUploadXlFileContainer()
                : addManuallyTab
                ? renderAddManuallyContainer()
                : renderImportFromApiContainer()}
            </div>
          </div>
          {renderModalContainer()}
        </>
      ) : (
        <DontHaveAccessPage />
      )}
    </div>
  );
};

export default AssetRegistration;
