import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import {
  OutlineAllAssetsIcon,
  avathar3,
  closeIcon,
  dropdownGrayIcon,
  searchIcon,
  success,
} from 'resources/images/Images';
import LoaderForPages from 'components/loaderForPages';
import InputWithPopup from 'components/inputWithDropdown';
import SearchInput from 'components/searchInput';
import { useUserDataContext } from 'hooks/userDataContext';
import Button from 'components/button';
import { useStringData } from 'providers/stringsprovider';
import { useNavigate } from 'react-router';
import { useAuthContext } from 'hooks/useAuthContext';
const LinkAsset = () => {
  const { allAssetsData } = useUserDataContext();
  const { user } = useAuthContext();
  const { strings } = useStringData();
  const [loader, setLoader] = useState(true);
  const [isShowDropDown, setIsShowDropDown] = useState(false);
  const [unLinkedAssets, setUnLinkedAssets] = useState();
  const [selectedAsset, setSelectedAsset] = useState(false);
  const [filteredAssetData, setFilteredAssetData] = useState(unLinkedAssets);
  const [assetLinked, setAssetLinked] = useState(false);
  const navigation = useNavigate();
  useEffect(() => {
    if (user && allAssetsData) {
      setUnLinkedAssets(allAssetsData.filter((asset) => asset.qr_id === null));
      setLoader(false);
    } else {
      setLoader(false);
    }
  }, [allAssetsData]);

  useEffect(() => {
    if (unLinkedAssets) {
      setFilteredAssetData(unLinkedAssets);
    }
  }, [unLinkedAssets]);

  const filterAssetData = (e) => {
    setFilteredAssetData(
      unLinkedAssets.filter((asset) =>
        JSON.stringify(asset)
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      )
    );
  };

  const rendedrHeaderSection = () => {
    return (
      <div className={styles.headerViewStyle}>
        <div
          onClick={() => console.log('close')}
          className={styles.closeIMgViewStyle}
        >
          <img src={closeIcon} alt="close" className={styles.imgStyle} />
        </div>
      </div>
    );
  };

  const renderMiddleSection = () => {
    return (
      <div className={styles.middleSectionViewStyle}>
        <div className={styles.middleSubSectionViewStyle}>
          <p className={styles.headerTextStyle}>
            {strings.qrNotLinkedTitleText}
          </p>
          {user ? (
            <div className={styles.descViewStyle}>
              <p className={styles.descTextStyle}>
                {strings.qrNotLinkedDDescText}
              </p>
              <p className={styles.descTextStyle}>
                <span className={styles.noteTextStyle}>{strings.noteText}</span>{' '}
                {strings.linkaAssetNote}
              </p>
            </div>
          ) : (
            <div className={styles.descViewStyle}>
              <p className={styles.descTextStyle}>
                If the QR you scanned is permanently attached to an
                asset/item/product then please mail us at{' '}
                <span>info@trakr.se</span>
              </p>
              <p className={styles.descTextStyle}>
                If you are a registered trakr user and are currently logged out,{' '}
                <span>click here</span> to login
              </p>
            </div>
          )}
        </div>
        {user && renderSelecctAssetSection()}
      </div>
    );
  };

  const renderSelecctAssetSection = () => {
    return (
      <div className={styles.selectAssetViewStyle}>
        <InputWithPopup
          placeholder={'Select asset*'}
          title={selectedAsset ? selectedAsset.asset_name : ''}
          assetImage={selectedAsset.image || OutlineAllAssetsIcon}
          assetUserName={''}
          rightIcon={dropdownGrayIcon}
          popupOpen={isShowDropDown}
          onClick={() => {
            setIsShowDropDown(!isShowDropDown);
          }}
          removeSelectedData={() => {
            setSelectedAsset('');
          }}
        >
          <div className={styles.selectAssetSectionViewStyle}>
            <div className={styles.searchInputSectionStyle}>
              <SearchInput
                placeholder={'Choose an unlinked asset...'}
                onChange={(e) => filterAssetData(e)}
                onRightIconClick={() => setIsShowDropDown(false)}
                leftIcon={searchIcon}
                rightIcon={closeIcon}
                customInputViewStyle={styles.customSearchInputViewStyle}
                customInputStyle={styles.customSearchInputStyle}
                customLeftIconViewStyle={
                  styles.customSearchInputLeftIconViewStyle
                }
              />
            </div>
            <div className={styles.listStyle}>
              {filteredAssetData?.length > 0 &&
                filteredAssetData?.map((asset, index) => (
                  <div
                    onClick={() => {
                      setSelectedAsset(asset);
                      setIsShowDropDown(false);
                    }}
                    key={index}
                    className={
                      asset._id === selectedAsset?._id
                        ? styles.selectedCardViewStyle
                        : styles.listCardViewStyle
                    }
                  >
                    <div className={styles.assetImgViewStyle}>
                      <div className={styles.assetImgSubViewStyle}>
                        <img
                          src={asset.image || OutlineAllAssetsIcon}
                          alt=""
                          className={styles.ImgStyle}
                        />
                      </div>
                    </div>
                    <div className={styles.listDetailsStyle}>
                      <p className={styles.listTitleTextStyle}>
                        {asset.asset_name}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </InputWithPopup>
      </div>
    );
  };

  const renderBtnsSection = () => {
    return (
      <Button
        title={strings.confirm}
        disabled={selectedAsset ? false : true}
        onClick={() => setAssetLinked(true)}
      />
    );
  };

  const renderAssetLinkedAlert = () => {
    return (
      <div className={styles.assetAlertViewStyle}>
        <div className={styles.successImgViewStyle}>
          <img src={success} alt="success" className={styles.imgStyle} />
        </div>
        <div className={styles.alertTextViewStyle}>
          <p className={styles.alertTextStyle}>
            {strings.assetLinkedAlertText}
          </p>
          <div className={styles.alertDescViewStyle}>
            <p className={styles.descTextStyle2}>
              <span>{strings.name}</span> LG Curved Monitor
            </p>
            <p className={styles.descTextStyle2}>
              <span>{strings.uniqueId2}</span> VASTN98760
            </p>
          </div>
        </div>
        <div className={styles.alertNoteViewStyle}>
          <p className={styles.alertNoteTextStyle}>
            {strings.assetLinkedAlertNoteText}
          </p>
        </div>
        <Button
          title={strings.scanQR}
          color="light"
          onClick={() => navigation('/qrLinkedOrNOt')}
          btnStyle={styles.customBtnStyle}
        />
      </div>
    );
  };
  return (
    <div className={styles.mainContainerStyle}>
      {loader ? (
        <LoaderForPages />
      ) : (
        <>
          {assetLinked ? (
            renderAssetLinkedAlert()
          ) : (
            <>
              {rendedrHeaderSection()}
              <div className={styles.subContainerStyle}>
                {renderMiddleSection()}
                {user && renderBtnsSection()}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default LinkAsset;
