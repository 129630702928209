import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import classNames from 'classnames';
import { closeIcon } from 'resources/images/Images';

function Popup({
  show,
  onClose,
  children,
  showOverlay,
  customOverLayStyles,
  customPopUpStyles,
  closeButtonStyles,
  closeIconStyles,
  showCloseButton,
  popupRef,
  handleClickOutside,
  customPopUpBgStyles,
  popUpOutsideContainerStyles,
}) {
  useEffect(() => {
    if (show && popupRef.current) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show, handleClickOutside, popupRef]);

  if (!show) return null;

  // Add a click event handler to prevent event propagation
  const handleClickInsidePopup = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      className={showOverlay && classNames(customOverLayStyles, styles.overlay)}
    >
      <div
        onClick={onClose}
        className={classNames(styles.popUpBgStyles, customPopUpBgStyles)}
      ></div>
      <div
        ref={popupRef}
        className={classNames(styles.popup, customPopUpStyles)}
      >
        {showCloseButton && (
          <div
            className={classNames(styles.closeButtonStyles, closeButtonStyles)}
            onClick={onClose}
          >
            <div
              className={classNames(styles.closeIconStyles, closeIconStyles)}
            >
              <img
                src={closeIcon}
                alt="closeIcon"
                className={styles.imageWidth}
              />
            </div>
          </div>
        )}
        <div
          className={classNames(popUpOutsideContainerStyles)}
          onClick={handleClickInsidePopup}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

Popup.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  showOverlay: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  popupRef: PropTypes.object.isRequired,
  handleClickOutside: PropTypes.func,
};

export default Popup;
