import React, { useState, useEffect } from 'react';
import styles from './style.module.css';
import Modal from 'components/modal';
import SuggetionBoard from 'components/suggetionborard';
import { useNavigate } from 'react-router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuthContext } from 'hooks/useAuthContext';
import { useSpinner } from 'providers/spinnerprovider';
import Input from 'components/input';
import NumberInput from 'components/numberInput';
import Button from 'components/button';
import { createCompanyApi } from 'networking/api/company';
import { useStringData } from 'providers/stringsprovider';
import { account_types } from 'utils/constants';
import DontHaveAccessPage from 'components/dontHaveAccess';
const AddCompany = () => {
  const { strings } = useStringData();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { user, setUser, company, userType } = useAuthContext();
  const { setIsShowLoading } = useSpinner();
  const [actionLoading, setActionLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const [country_code, setCountry_code] = useState('+91');

  const companyRegistrationSchema = Yup.object().shape({
    company_name: Yup.string().required(strings.companyNameRequired),
    email: Yup.string()
      .email(strings.validEmail)
      .required(strings.emailRequired),
    phone_number: Yup.string().required(strings.phoneNumRequired),
  });

  const handleCompanyRegistration = async (values, { resetForm }) => {
    try {
      setIsShowLoading(true);
      setActionLoading(true);

      const response = await createCompanyApi({
        company_name: values.company_name,
        email: values.email,
        phone_number: { country_code: '+91', value: values.phone_number },
        admin: user ? user._id : '',
      });
      if (response.data.type === 'success') {
        setApiError('');
        resetForm();
        setIsModalOpen(true);
        setIsShowLoading(false);
        setUser({
          ...user,
          companies: [response.data.data, ...user?.companies],
        });
      } else {
        setApiError(response.data.message);
        setIsShowLoading(false);
      }
      setActionLoading(false);
    } catch (error) {
      setIsShowLoading(false);
      setActionLoading(false);
      setApiError(error.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      company_name: '',
      email: '',
      phone_number: '',
      admin: user ? user._id : '',
    },
    validationSchema: companyRegistrationSchema,
    onSubmit: handleCompanyRegistration,
  });
  useEffect(() => {
    setApiError('');
  }, [formik.values]);

  const renderSuggestionSection = () => {
    return (
      <SuggetionBoard
        title={'Ideal for adding a few companyes.'}
        desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque auctor diam ipsum, vitae condimentum leo egestas id. Pellentesque quis nunc sit amet erat luctus ultricies convallis porta magna. Cras egestas eros quam, at volutpat ante molestie sed."
      />
    );
  };

  const rendderInputSection = () => {
    return (
      <form
        onSubmit={formik.handleSubmit}
        className={styles.companyFormWrapperStyle}
      >
        <h2 className={styles.companyDetailsTitleTextStyle}>
          {strings.companyDetails}
        </h2>
        <div className={styles.companyGapStyle}>
          <Input
            name="company_name"
            lableText={'Full name of the company*'}
            lableTextStyle={styles.lableTextStyle}
            placeholder={'Ex: Vilje Tech'}
            value={formik.values.company_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={
              formik.touched.company_name && formik.errors.company_name
                ? formik.errors.company_name
                : ''
            }
            customInputContainerStyles={styles.inputStyle}
          />
        </div>
        <div className={styles.employeeGapStyle}>
          <Input
            name="email"
            lableText={strings.emailIdCompanyCompulsory}
            lableTextStyle={styles.lableTextStyle}
            placeholder={strings.mailIdExample}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ''
            }
            customInputContainerStyles={styles.inputStyle}
          />
        </div>

        <NumberInput
          name="phone_number"
          lableText={strings.phoneNumCompulsory}
          lableTextStyle={styles.lableTextStyle}
          placeholder={strings.phoneNumHere}
          value={formik.values.phone_number}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          hasError={
            formik.touched.phone_number && formik.errors.phone_number
              ? formik.errors.phone_number
              : ''
          }
        />
        <p className={styles.errorTextStyle}>{apiError}</p>
        {renderBtns()}
      </form>
    );
  };

  const renderBtns = () => {
    return (
      <div className={styles.btnContainerStyle}>
        <Button
          onClick={() => formik.resetForm()}
          title={strings.cancel}
          color="light"
          btnStyle={styles.customBtnStyle}
        />
        <Button
          title={actionLoading ? strings.loading : strings.addCompanyBtn}
          type={'submit'}
          btnStyle={styles.customBtnStyle}
        />
      </div>
    );
  };

  const renderModalSection = () => {
    return (
      <Modal
        open={isModalOpen}
        title={strings.suggestionBoardTitleForAddCompany}
        desc={strings.suggestionBoardDescForAddCompany}
        leftBtnTitle={strings.backToDashBoard}
        rightBtnTitle={strings.showSummary}
        onLeftBtnClick={() => {
          setIsModalOpen(false);
          navigate('/dashboard');
        }}
        onRighBtnClick={() => setIsModalOpen(false)}
      />
    );
  };

  return (
    <div className={styles.mainContainerStyle}>
      {userType === account_types.super_admin ? (
        <>
          {renderSuggestionSection()}
          <div className={styles.subContainerStyle}>
            {rendderInputSection()}
          </div>
          {renderModalSection()}
        </>
      ) : (
        <DontHaveAccessPage />
      )}
    </div>
  );
};

export default AddCompany;
