import React from 'react';
import Image from 'components/image';
import { clusterIcon } from 'resources/images/Images';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
const ClusterCard = (props) => {
  const { onClick = () => {} } = props;
  const data = [
    {
      title: 'Cluster name',
      maintenance: 'Daily',
      assets: 6,
      date: '23 December (Today)',
      checked_assets: 6,
      complaints: null,
    },
    {
      title: 'Cluster name',
      maintenance: 'Weekly',
      assets: 8,
      date: '12 - 18 December',
      checked_assets: 7,
      complaints: 1,
    },
    {
      title: 'Cluster name',
      maintenance: 'Monthly',
      assets: 10,
      date: '29 Nov - 29 Dec',
      checked_assets: 7,
      complaints: null,
    },
    {
      title: 'Cluster name',
      maintenance: 'Custom (60d)',
      assets: 8,
      date: '17 Oct - 21 Dec',
      checked_assets: 6,
      complaints: 2,
    },
    {
      title: 'Cluster name',
      maintenance: 'Daily',
      assets: 6,
      date: '23 December (Today)',
      checked_assets: 6,
      complaints: null,
    },
    {
      title: 'Cluster name',
      maintenance: 'Weekly',
      assets: 8,
      date: '12 - 18 December',
      checked_assets: 7,
      complaints: 1,
    },
    {
      title: 'Cluster name',
      maintenance: 'Monthly',
      assets: 10,
      date: '29 Nov - 29 Dec',
      checked_assets: 7,
      complaints: null,
    },
    {
      title: 'Cluster name',
      maintenance: 'Custom (60d)',
      assets: 8,
      date: '17 Oct - 21 Dec',
      checked_assets: 6,
      complaints: 2,
    },
    {
      title: 'Cluster name',
      maintenance: 'Daily',
      assets: 6,
      date: '23 December (Today)',
      checked_assets: 6,
      complaints: null,
    },
    {
      title: 'Cluster name',
      maintenance: 'Weekly',
      assets: 8,
      date: '12 - 18 December',
      checked_assets: 7,
      complaints: 1,
    },
    {
      title: 'Cluster name',
      maintenance: 'Monthly',
      assets: 10,
      date: '29 Nov - 29 Dec',
      checked_assets: 7,
      complaints: null,
    },
    {
      title: 'Cluster name',
      maintenance: 'Custom (60d)',
      assets: 8,
      date: '17 Oct - 21 Dec',
      checked_assets: 6,
      complaints: 2,
    },
  ];

  const getBgClolor = (type) => {
    if (type === 'Daily') {
      return styles.dailyBgStyle;
    } else if (type === 'Weekly') {
      return styles.weeklyBgStyle;
    } else if (type === 'Monthly') {
      return styles.monthlyBgStyle;
    } else {
      return styles.customBgStyle;
    }
  };
  const getTextClolor = (type) => {
    if (type === 'Daily') {
      return styles.dailyTextStyle;
    } else if (type === 'Weekly') {
      return styles.weeklyTextStyle;
    } else if (type === 'Monthly') {
      return styles.monthlyTextStyle;
    } else {
      return styles.customTextStyle;
    }
  };

  const renderTopSection = (cluster) => {
    return (
      <div className={styles.clusterDetailsTopViewStyle}>
        <p className={styles.titleTextStyle}>{cluster.title}</p>
        <div className={styles.clusterDetailsTabsViewStyle}>
          <div className={styles.clusterTabViewStyle}>
            <p
              className={[
                styles.maintenanceTextStyle,
                getTextClolor(cluster.maintenance),
              ].join(' ')}
            >
              {cluster.maintenance}
            </p>
          </div>
          <div className={styles.clusterTabViewStyle}>
            <p className={styles.assetsTextStyle}>
              Assets:{' '}
              {cluster.assets >= 10 ? cluster.assets : `0${cluster.assets}`}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderBottomSection = (cluster) => {
    return (
      <div className={styles.clusterDetailsBottomViewStyle}>
        <p className={styles.timeTextStyle}>{cluster.date}</p>
        <div className={styles.checkedAssetsViewStyle}>
          <p className={styles.checkedAssetsTextStyle}>Checked assets:</p>
          <div className={styles.progressViewStyle}>
            <div
              className={styles.activeProgressViewStyle}
              style={{
                width: `${(cluster.checked_assets / cluster.assets) * 100}%`,
              }}
            ></div>
          </div>
          <p className={styles.titleTextStyle}>
            {cluster.checked_assets}
            <span>/{cluster.assets}</span>
          </p>
        </div>
        {cluster.complaints !== null && (
          <div className={styles.checkedAssetsViewStyle}>
            <p className={styles.checkedAssetsTextStyle}>Checked assets:</p>
            <div className={styles.complaintsViewStyle}>
              <p
                className={styles.titleTextStyle}
              >{`${cluster.complaints} raised`}</p>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      {data.map((cluster, index) => (
        <div
          key={index}
          onClick={onClick}
          className={styles.clusterCardViewStyle}
        >
          <Image
            url={clusterIcon}
            alt="cl-icon"
            imgContainerStyle={[
              styles.clusterImgViewStyle,
              getBgClolor(cluster.maintenance),
            ].join(' ')}
            imgStyle={styles.imgViewStyle}
          />
          <div className={styles.clusterDetailsViewStyle}>
            {/* top view */}
            {renderTopSection(cluster)}
            {/* bottom view */}
            {renderBottomSection(cluster)}
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

ClusterCard.propTypes = {
  onClick: PropTypes.func,
};

export default ClusterCard;
