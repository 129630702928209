import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from 'routes';
import { AppDataProvider } from 'providers/appdataprovider';
import { AuthProvider } from 'providers/authprovider';
import SpinnerProvider from 'providers/spinnerprovider';
import { AppStringsProvider } from 'providers/stringsprovider';
import { UserDataProvider } from 'providers/userdataprovider';
import { ChatDataProvider } from 'providers/chatprovider';
import { ToastProvider } from 'providers/toastProvider';

const App = () => {
  return (
    <ToastProvider>
      <SpinnerProvider>
        <AppStringsProvider>
          <AppDataProvider>
            <AuthProvider>
              <UserDataProvider>
                <ChatDataProvider>
                  <BrowserRouter>
                    <AppRoutes />
                  </BrowserRouter>
                </ChatDataProvider>
              </UserDataProvider>
            </AuthProvider>
          </AppDataProvider>
        </AppStringsProvider>
      </SpinnerProvider>
    </ToastProvider>
  );
};

export default App;
