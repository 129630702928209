import React, { useState } from 'react';
import styles from './styles.module.css';
import Input from 'components/input';

const EditDashBoardCard = (props) => {
  const { optionText, optionImage, onOptionSelect } = props;
  const [isSelected, setIsSelected] = useState(false);
  const handleOptionSelect = () => {
    setIsSelected(!isSelected);
    onOptionSelect(optionText, !isSelected);
  };
  return (
    <div className={styles.editDashBoardContainerStyles}>
      <div className={styles.editDaskBoardOptionLeftSectionStyles}>
        <div className={styles.checkBoxStyles}>
          <Input
            type="checkbox"
            customStyle={styles.checkBoxStyles}
            checked={isSelected}
            onChange={handleOptionSelect}
          />
        </div>
        <p className={styles.optionTextStyles}>{optionText}</p>
      </div>
      <div className={styles.editDaskBoardOptionRightSectionStyles}>
        <img
          src={optionImage}
          alt="editDaskOptionImg"
          className={styles.imageWidth}
        />
      </div>
    </div>
  );
};

export default EditDashBoardCard;
