import LoaderForPages from 'components/loaderForPages';
import styles from './styles.module.css';
import React, { useEffect, useState, useRef } from 'react';
import {
  purpleSuccess,
  sendIcon,
  time,
  uploadIcon,
} from 'resources/images/Images';
import Input from 'components/input';
import Button from 'components/button';
import { useStringData } from 'providers/stringsprovider';
import { useLocation, useNavigate } from 'react-router';
import { useAuthContext } from 'hooks/useAuthContext';
import ChatHeader from 'components/chatHeader';
import {
  getAssetChatDataApi,
  getChatMessagesApi,
  markAsRecoveredApi,
  markAsRetunredApi,
  sendMessageApi,
} from 'networking/api/chat';
import moment from 'moment';
import { chat_options } from 'utils/constants';
import Modal from 'components/modal';
import { socket } from 'providers/chatprovider';
const ReturnItem = () => {
  const { user } = useAuthContext();
  const { strings } = useStringData();
  const navigation = useNavigate();
  const location = useLocation();
  const asset = location.state.asset;
  const guestEmail = location.state.guest_email;
  const [loader, setLoader] = useState(true);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [isReturnedModalOpen, setIsReturnedModalOpen] = useState(false);
  const [acknowledged, setAcknowledge] = useState(false);
  const [chatData, setChatData] = useState('');

  useEffect(() => {
    getChatData();
    socketListner();
    socket.emit('join_room', asset._id);
    return () => {
      socket.emit('leave_room', asset._id);
    };
  }, []);

  const socketListner = () => {
    socket.on('new_message_received', (newMessage) => {
      console.log('newMessage', newMessage);
      setMessages((messages) => [...messages, newMessage]);
      scrollToBottom('smooth');
    });
  };

  const actionOptions = [
    {
      text: strings.assetDetails,
    },
    {
      text: strings.markAsReturned,
    },
    {
      text: strings.reportChat,
    },
  ];
  const messagesEndRef = useRef(null);

  const scrollToBottom = (property) => {
    messagesEndRef.current?.scrollIntoView({ behavior: property });
  };

  const getChatData = async () => {
    try {
      let chatResponse = await getAssetChatDataApi(asset._id);
      if ((chatResponse.data.type = 'success')) {
        console.log('getChatData api', chatResponse.data.data);
        setChatData(chatResponse.data.data);
        if (chatResponse.data.data) {
          getChatMessages(chatResponse.data.data._id);
        } else {
          setLoader(false);
        }
      } else {
        setLoader(false);
        console.log('getChatData api failed', chatResponse.data.message);
      }
    } catch (error) {
      setLoader(false);
      console.log('getChatData api catch', error);
    }
  };

  const getChatMessages = async (chat_id) => {
    try {
      let messagesResponse = await getChatMessagesApi(chat_id);
      if ((messagesResponse.data.type = 'success')) {
        setMessages(messagesResponse.data.data);
        scrollToBottom('auto');
        setLoader(false);
        console.log('getChatMessages api', messagesResponse.data.data);
      } else {
        setLoader(false);
        console.log(
          'getChatMessages api failed',
          messagesResponse.data.message
        );
      }
    } catch (error) {
      setLoader(false);
      console.log('getChatMessages api catch', error.response.data);
    }
  };

  const sendMessage = async () => {
    try {
      let response = await sendMessageApi({
        asset: asset._id,
        text: message,
        files: [],
        guest_email: guestEmail,
      });
      if (response.data.type === 'success') {
        console.log('send Message Response', response.data.data);
        // setMessages([...messages, response.data.data]);
        // scrollToBottom('smooth');
        setMessage('');
        socket.emit('new_message', response.data.data);
      } else {
        console.log('send Message api fail', response.data.message);
      }
    } catch (error) {
      console.log('send Message api catch', error);
    }
  };

  const topOnOption = (data) => {
    if (data === chat_options.asset_details) {
      console.log('chat_options.asset_details', chat_options.asset_details);
    } else if (data === chat_options.mark_as_returned) {
      console.log(
        'chat_options.mark_as_returned',
        chat_options.mark_as_returned
      );
      setIsReturnedModalOpen(true);
    } else {
      console.log('chat_options.report_chat', chat_options.report_chat);
    }
  };

  const tapOnAcknowledge = () => {
    setAcknowledge(true);
    setIsReturnedModalOpen(true);
  };

  const tapOnProceed = async () => {
    if (acknowledged) {
      let data = {
        asset: asset._id,
      };
      try {
        let markAsRecoveredResponse = await markAsRecoveredApi(data);
        if (markAsRecoveredResponse.data.type === 'success') {
          console.log(
            'mark As Recovered Api',
            markAsRecoveredResponse.data.data
          );
          setChatData(markAsRecoveredResponse.data.data);
        } else {
          console.log(
            'mark As Recovered Api failed',
            markAsRecoveredResponse.data.data
          );
        }
      } catch (error) {
        console.log('mark As Recovered Api catch', error.response.data);
      }
    } else {
      try {
        let data = {
          asset: asset._id,
          guest_email: guestEmail ? guestEmail : undefined,
        };
        let response = await markAsRetunredApi(data);
        if (response.data.type === 'success') {
          setChatData(response.data.data);
          console.log('mark As Retunred Api', response.data.data);
        } else {
          console.log('mark As Retunred Api failed', response.data.data);
        }
      } catch (error) {
        console.log('mark As Retunred Api catch', error.response.data);
      }
    }
  };

  const tapOnArchiveChat = () => {
    console.log('tap On Archive Chat');
  };

  const checkMessagePosition = (message) => {
    if (user) {
      if (message.sender?._id === user._id) {
        return styles.rightStyle;
      } else {
        return styles.leftStyle;
      }
    } else {
      if (message.guest_sender?.email === guestEmail) {
        return styles.rightStyle;
      } else {
        return styles.leftStyle;
      }
    }
  };

  const renderChatHeadder = () => {
    return (
      <ChatHeader
        image={asset?.image}
        title={asset?.asset_name}
        assetUid={asset?.asset_uid}
        // actionOptions={actionOptions}
        actionOptions={
          chatData?.recovered
            ? actionOptions.filter(
                (item) => item.text != chat_options.mark_as_returned
              )
            : actionOptions
        }
        setSelectedOption={(e) => topOnOption(e)}
        backAction={true}
        tabOnBackAction={() => navigation(-1)}
      />
    );
  };

  const renderReturnedRecoveredSection = () => {
    return (
      <div
        className={[
          chatData?.recovered
            ? styles.assetRecoveredViewStyle
            : styles.assetReturnedViewStyle,
        ]}
      >
        <div className={styles.imgViewStyle}>
          <img
            src={chatData?.recovered ? purpleSuccess : time}
            alt="img"
            className={styles.imgStyle}
          />
        </div>
        {user ? (
          <p className={styles.assetStatusTextStyle2}>
            {chatData?.recovered
              ? strings.assetRecoveredTextForUser
              : strings.assetReturnedTextForUser}{' '}
            <span
              className={[
                chatData?.recovered
                  ? styles.onClickTextStyle
                  : styles.onClickTextStyle2,
              ]}
              onClick={() => {
                chatData?.recovered ? tapOnArchiveChat() : tapOnAcknowledge();
              }}
            >
              {strings.clickHere}
            </span>
          </p>
        ) : chatData?.recovered ? (
          <p className={styles.assetStatusTextStyle2}>
            {strings.assetRecoveredTextForFinder}{' '}
            <span className={styles.tenSecTextStyle}>{strings.secs}</span>
          </p>
        ) : (
          <p className={styles.assetStatusTextStyle}>
            {strings.assetReturnedTextForFinder}{' '}
            <span>{strings.assetReturnedTextForFinder2}</span>
          </p>
        )}
      </div>
    );
  };

  let previousDate = '';
  const shouldDisplayDate = (message, index) => {
    if (index > 0) {
      if (previousDate === moment(message.created_at).format('L')) {
        return false;
      } else {
        previousDate = moment(message.created_at).format('L');
        return true;
      }
    } else {
      previousDate = moment(message.created_at).format('L');
      return true;
    }
  };

  const displayGroupMessageDate = (date) => {
    var today = moment();
    var yesterday = moment().subtract(1, 'day');
    if (moment(date).isSame(today, 'd')) {
      return 'Today';
    } else if (moment(date).isSame(yesterday, 'd')) {
      return 'Yesterday';
    } else {
      return moment(date).format('MMM DD Y');
    }
  };

  let previousTime = '';
  const shouldDisplayTime = (message, index) => {
    if (index > 0) {
      if (previousTime === moment(message.created_at).format('HH:mm')) {
        return false;
      } else {
        previousTime = moment(message.created_at).format('HH:mm');
        return true;
      }
    } else {
      previousTime = moment(message.created_at).format('HH:mm');
      return true;
    }
  };

  const displayGroupMessageTime = (date) => {
    return moment(date).format('hh:mm a');
  };

  const renderMessagesContainer = () => {
    return (
      <div className={styles.chatViewStyle}>
        {messages.length > 0 &&
          messages.map((message, index) => {
            return (
              <React.Fragment key={index}>
                {shouldDisplayDate(message, index) && (
                  <p className={styles.chatMessgeGroupdate}>
                    {displayGroupMessageDate(message.created_at)}
                  </p>
                )}
                <div
                  className={`${styles.chat__message} ${checkMessagePosition(
                    message
                  )}`}
                >
                  {message.image ? (
                    <img
                      className={styles.chat__messageImg}
                      src={message?.image}
                      alt=""
                    />
                  ) : null}
                  <p className={styles.chat__messageText}>{message.text}</p>
                </div>
                {shouldDisplayTime(message, index) && (
                  <div
                    className={`${
                      styles.msgTimeViewStyle
                    } ${checkMessagePosition(message)}`}
                  >
                    <p className={styles.chatMessageTime}>
                      {displayGroupMessageTime(message.created_at)}
                    </p>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        {(chatData?.recovered || chatData?.returned) &&
          renderReturnedRecoveredSection()}
        <div ref={messagesEndRef} />
      </div>
    );
  };

  const emptyViewStyle = () => {
    return (
      <div className={styles.emptySectionViewStyle}>
        <p className={styles.emptyDataTextStyle}>
          No conversations in this chat. Type your message below and hit send to
          start conversing with the item owner.
        </p>
      </div>
    );
  };

  const renderInputSecction = () => {
    return (
      <div className={styles.chatInputAndSendSectionStyle}>
        <Input
          placeholder="Type message here ..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          image={uploadIcon}
          rightImage={sendIcon}
          onLeftImageClick={() => console.log('onLeftImageClick')}
          onRightImageClick={() => sendMessage()}
          customStyle={styles.inputViewStyle}
          customImageContainerStyles={styles.customImageContainerStyles}
          customRightImageContainerStyles={styles.inputRightIconViewStyle}
        />
      </div>
    );
  };

  const renderModal = () => {
    return (
      <Modal
        open={isReturnedModalOpen}
        title={
          acknowledged ? strings.itemRecovered : strings.markItemAsReturned
        }
        desc={
          acknowledged
            ? strings.userAcknowledgeAlertText
            : strings.finderReturnAlertText
        }
        leftBtnTitle={strings.proceed}
        onLeftBtnClick={() => {
          setIsReturnedModalOpen(false);
          tapOnProceed();
        }}
        rightBtnTitle={strings.cancel}
        onRighBtnClick={() => {
          setIsReturnedModalOpen(false);
          setAcknowledge(false);
        }}
        customModalContentBlockStyles={styles.customModalBlockStyles}
        customModalContentBottomStyles={styles.modalBtnsViewStyles}
        customLeftBtnStyle={styles.modalCustomBtnStyle}
        customRightBtnStyle={styles.modalCustomBtnStyle}
      />
    );
  };

  return (
    <div className={styles.mainContainerStyle}>
      {loader ? (
        <LoaderForPages />
      ) : (
        <div className={styles.containerStyle}>
          {renderChatHeadder()}
          {messages.length > 0 ? renderMessagesContainer() : emptyViewStyle()}
          {renderInputSecction()}
        </div>
      )}
      {renderModal()}
    </div>
  );
};

export default ReturnItem;
