import React, { useRef, useState } from 'react';
import styles from './styles.module.css';
import { xlFileGrayIcon } from 'resources/images/Images';
import Button from 'components/button';
import axiosInstance from 'utils/axios';
import { uploadUserXlsFileApi } from 'networking/api/employee';
import { useAuthContext } from 'hooks/useAuthContext';
import ButtonTab from 'components/buttontab';
import { uploadAssetXlsFileApi } from 'networking/api/asset';
import { useStringData } from 'providers/stringsprovider';
const UploadXLSFile = (props) => {
  const { strings } = useStringData();
  const { link, setUploadedSuccess, fileType } = props;
  const fileInputRef = useRef();
  const { company } = useAuthContext();
  const [xlsFile, setXlsFile] = useState(null);
  const [xlsFileError, setXlsFileError] = useState(null);

  const handleFileChange = (event) => {
    try {
      if (event.target.files && event.target.files.length > 0) {
        setXlsFileError(null);
        const file = event.target.files[0];
        console.log('file', file);
        setXlsFile(file);
      }
    } catch (error) {
      setXlsFileError(error.message);
    }
  };

  const uploadXlsFileData = async () => {
    try {
      // api call if file exists
      if (xlsFile) {
        let formData = new FormData();
        formData.append('file', xlsFile);
        formData.append('company', company._id);
        let response;
        if (fileType === 'assets') {
          response = await uploadAssetXlsFileApi(formData);
        } else {
          response = await uploadUserXlsFileApi(formData);
        }
        if (response.data.type === 'success') {
          setXlsFileError(null);
          setUploadedSuccess(response.data.message);
          console.log('file', response.data.data);
        }
      } else {
        setXlsFileError('Please select file');
      }
    } catch (error) {
      setXlsFileError(error.message);
    }
  };

  return (
    <div className={styles.xlsSectionStyle}>
      <div className={styles.imageUploadContainerStyle}>
        <div className={styles.imgContainerStyle}>
          <img src={xlFileGrayIcon} className={styles.imageStyle} />
        </div>
        <p className={styles.textStyle}>
          {strings.xlsFileDescp}{' '}
          {fileType === 'assets' ? 'assets' : 'employee list'}?
          <span
            onClick={() => window.open(axiosInstance.defaults.baseURL + link)}
            className={styles.textStyle2}
          >
            {' '}
            {strings.downLoadXls}
          </span>
        </p>
        <input
          type="file"
          accept=".xlsx,.xls"
          hidden
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        {xlsFile ? (
          <ButtonTab
            titleText={xlsFile.name}
            closeBtn={true}
            closeAction={() => setXlsFile(null)}
            custButtonTabContainerStyle={styles.custButtonTabContainerStyle}
          />
        ) : (
          <Button
            onClick={() => {
              fileInputRef.current?.click();
            }}
            title={strings.uploadXLsFile}
            btnStyle={styles.customBtnStyle}
          />
        )}
      </div>
      <div className={styles.bottomViewStyle}>
        <p>{xlsFileError}</p>
        <div className={styles.xlsBtnSViewStyle}>
          <Button
            title={strings.cancel}
            onClick={() => setXlsFile(null)}
            btnStyle={styles.customBtnStyle}
            color={'light'}
          />
          <Button
            title={fileType === 'assets' ? 'Add asset(s)' : 'Add user(s)'}
            onClick={() => uploadXlsFileData()}
            btnStyle={styles.customBtnStyle}
          />
        </div>
      </div>
    </div>
  );
};

export default UploadXLSFile;
