import React from 'react';
import styles from './style.module.css';
import PropTypes from 'prop-types';

const StatisticsCard = (props) => {
  const { count, title, containerStyle, countStyle, titleStyle } = props;
  return (
    <div
      className={[styles.statisticCardWrapperStyle, containerStyle].join(' ')}
    >
      <p className={[styles.statsCountStyle, countStyle].join(' ')}>
        {count ? count.toString().padStart(2, 0) : 'NA'}
      </p>
      <p className={[styles.statsTitleStyle, titleStyle].join(' ')}>{title}</p>
    </div>
  );
};

StatisticsCard.propTypes = {
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  containerStyle: PropTypes.object,
  countStyle: PropTypes.object,
  titleStyle: PropTypes.object,
};

export default StatisticsCard;
