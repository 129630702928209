import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Input from 'components/input';
import Button from 'components/button';
import { useNavigate } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import { useAuthContext } from 'hooks/useAuthContext';
import {
  avathar2,
  googleIcon,
  rightSlideDarkIcon,
} from 'resources/images/Images';
import { sendLoginOtpApi } from 'networking/api/admin';
import { sendUserLoginOtpApi } from 'networking/api/auth';
import ButtonTab from 'components/buttontab';
import { useSpinner } from 'providers/spinnerprovider';
import styles from './style.module.css';
import { useStringData } from 'providers/stringsprovider';

const Login = () => {
  //context data
  const { strings } = useStringData();
  const navigate = useNavigate();
  const {
    superAdminLogin,
    authError,
    multipleCompanies,
    user,
    setIsLoggedIn,
    setCompany,
    setAuthError,
    userLogin,
  } = useAuthContext();
  const { setIsShowLoading } = useSpinner();
  const [actionLoading, setActionLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [schemaObj, setSchemaObj] = useState({
    email: Yup.string()
      .email(strings.validEmail)
      .required(strings.emailRequired),
  });

  useEffect(() => {
    if (step === 1) {
      setSchemaObj({
        email: Yup.string()
          .email(strings.validEmail)
          .required(strings.emailRequired),
      });
    }
    if (step === 2) {
      setSchemaObj({
        ...schemaObj,
        otp: Yup.string().required(strings.otpRequired),
      });
    }
  }, [step]);

  const buttonTabsData = [
    {
      company_name: 'Open VILJE’s account',
    },
    {
      company_name: 'Open NorthTech’s account',
    },
  ];

  const signInSchema = Yup.object().shape(schemaObj);

  const handleLogin = async (values, { resetForm }) => {
    try {
      if (step === 1) {
        sendLoginOtp(values);
      } else if (step === 2) {
        setActionLoading(true);
        await userLogin(values);
        setActionLoading(false);
      }
    } catch (error) {
      setActionLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      otp: '',
    },
    validationSchema: signInSchema,
    onSubmit: handleLogin,
  });

  const sendLoginOtp = async (data) => {
    try {
      let otpParamsData = {
        email: data.email,
      };
      setIsShowLoading(true);
      let response = await sendUserLoginOtpApi(otpParamsData);
      if (response.data.type === 'success') {
        console.log('send Login Otp Api response', response.data);
        setStep(2);
        formik.setTouched({});
        setIsShowLoading(false);
      } else {
        console.log('send Login Otp failed response', response.data.message);
        setAuthError(response.data.message);
        setIsShowLoading(false);
      }
    } catch (error) {
      setIsShowLoading(false);
      setAuthError(error.message);
      console.log('send Login Otp catch response', error);
    }
    console.log();
  };

  const resendOtp = () => {
    sendLoginOtp(formik.values);
  };

  const tapOnCompany = async (companyData) => {
    setIsLoggedIn(true);
    localStorage.setItem('company', companyData._id);
    setCompany(companyData);
  };

  const renderSignUpSection = () => {
    return (
      <React.Fragment>
        <p className={styles.signUpTextStyles}>
          {strings.haveNoAccount}{' '}
          <span onClick={() => navigate('/admin/signup')}>
            {strings.signUp}
          </span>
        </p>
      </React.Fragment>
    );
  };

  const renderInputSection = () => {
    return (
      <React.Fragment>
        {step == 1 && (
          <Input
            type="email"
            name="email"
            lableText={strings.emailLabel}
            lableTextStyle={styles.emailLabelStyles}
            placeholder={strings.enterEmailId}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={() => formik.setFieldError('email', '')}
            hasError={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ''
            }
          />
        )}
      </React.Fragment>
    );
  };

  const renderOtpSection = () => {
    return (
      <React.Fragment>
        {step === 2 && (
          <div className={styles.otpSectionViewStyle}>
            <Input
              type="text"
              name="otp"
              lableText={strings.enterOtpLabel}
              lableTextStyle={styles.otpLabelStyles}
              placeholder={strings.otpPlaceHolder}
              value={formik.values.otp}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={() => formik.setFieldError('otp', '')}
              hasError={
                formik.touched.otp && formik.errors.otp ? formik.errors.otp : ''
              }
            />
            <p className={styles.resendEditEmailTextViewStyle}>
              <span onClick={() => resendOtp()}>Resend</span>or{' '}
              <span onClick={() => setStep(1)}>edit email</span>
            </p>
          </div>
        )}
      </React.Fragment>
    );
  };

  const renderTopSection = () => {
    return (
      <div className={styles.signUpTopStyles}>
        <h4 className={styles.loginHeadTextStyles}>
          Log in to your trakr account.
        </h4>
        {/* // for mobile view */}
        <div className={styles.signUpBlockStyles}>{renderSignUpSection()}</div>
        {/* // for mobile view end */}
      </div>
    );
  };
  // user have multiple account screen ui section
  const renderUserBlogSection = () => {
    return (
      <React.Fragment>
        <div className={styles.accountTypeStyles}>
          <h3 className={styles.userTitleStyles}>
            Daniel Watt, good to see you again.
          </h3>
          <div className={styles.userProfileStyles}>
            <div className={styles.userLeftStyles}>
              <img src={avathar2} alt="" className={styles.imageWidth} />
            </div>
            <div className={styles.userRightStyles}>
              <p className={styles.userNameStyles}>Daniel Watt</p>
              <p className={styles.userMailIdStyles}>danielwatt@cmail.com</p>
            </div>
          </div>
          <div className={styles.userAccountStyles}>
            {user?.companies.map((company, index) => {
              return (
                <React.Fragment key={index}>
                  <ButtonTab
                    titleText={`Open ${company.company_name}'s account`}
                    icon={rightSlideDarkIcon}
                    custButtonTabContainerStyle={styles.tabContainerStyles}
                    customtitleTextStyle={styles.customTextStyles}
                    onClick={() => tapOnCompany(company)}
                  />
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderFormSection = () => {
    return (
      <form className={styles.loginFormStyle} onSubmit={formik.handleSubmit}>
        {renderInputSection()}
        {renderOtpSection()}
        <div>
          {authError && <p className={styles.errorTextStyle}>{authError}</p>}
          <Button
            type="submit"
            btnStyle={styles.proceedBtnStyles}
            title={actionLoading ? 'Loading' : 'Proceed'}
            color="dark"
          />
          <Button
            type="submit"
            image={googleIcon}
            btnStyle={styles.googleBtnStyles}
            title="Login with Google"
            color="light"
          />
        </div>
      </form>
    );
  };

  return (
    <AuthLayout>
      <div className={styles.loginBlockStyles}>
        <div className={styles.loginOuterBlockStyles}>
          <div className={styles.loginInnerBlockStyles}>
            {multipleCompanies ? (
              renderUserBlogSection()
            ) : (
              <>
                {renderTopSection()}
                {renderFormSection()}
                <div className={styles.signUpBottomStyles}>
                  {renderSignUpSection()}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Login;
