import React, { useEffect, useRef, useState } from 'react';
import {
  avathar1,
  closeIcon,
  copyIcon,
  downArrowGrayIcon,
} from 'resources/images/Images';
import Avatar from 'components/avatar';
import { account_types } from 'utils/constants';
import Popup from 'components/popup';
import MultiSelectInput from 'components/multiSearch';
import { useUserDataContext } from 'hooks/userDataContext';
import Button from 'components/button';
import {
  inviteTicketMembersAPi,
  updateUserTicketAccessApi,
} from 'networking/api/tickets';
import { useAuthContext } from 'hooks/useAuthContext';
import Spinner from 'components/spinner';
import styles from './styles.module.css';
const Members = (props) => {
  const { ticket, setTicketUpdatedData, closeAction = () => {} } = props;
  const { user } = useAuthContext();
  const { otherUsersData } = useUserDataContext();
  const [showAccessPopup, setShowAccessPopup] = useState(false);
  const [showUserAccessPopup, setShowUserAccessPopup] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState('');
  const popupRef = useRef();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showSelectedUsersAccessPopup, setShowSelectedUsersAccessPopup] =
    useState(false);
  const [ticketData, setTicketData] = useState(ticket);
  const [availableMembers, setAvailableMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUsersAccessState, setSelectedUsersAccessState] = useState('');
  useEffect(() => {
    if (ticketData) {
      const filteredArr = [...otherUsersData].filter((item) =>
        ticketData.ticket_users.every(
          (filterItem) => filterItem.user._id !== item._id
        )
      );
      setAvailableMembers(filteredArr);
    }
  }, [ticketData]);

  const closeUserAccessOptions = (event) => {
    if (showUserAccessPopup) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowUserAccessPopup(false);
      }
    }
  };
  const closeAccessOptions = (event) => {
    if (showAccessPopup) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowAccessPopup(false);
      }
    }
  };

  const closeSelectedUsersAccessOptions = (event) => {
    if (showSelectedUsersAccessPopup) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowSelectedUsersAccessPopup(false);
      }
    }
  };
  let accessOptions = [
    {
      key: 'Can view',
    },
    {
      key: 'Can edit',
    },
  ];

  const updateUserAccess = async (user, option) => {
    setIsLoading(true);
    let data = {};
    if (option === 'Can edit') {
      data = {
        user: user._id,
        ticket: ticketData._id,
        can_edit: true,
        can_view: false,
      };
    } else {
      data = {
        user: user._id,
        ticket: ticketData._id,
        can_edit: false,
        can_view: true,
      };
    }
    try {
      let updateResponse = await updateUserTicketAccessApi(data);
      if (updateResponse.data.type === 'success') {
        setTicketData(updateResponse.data.data);
        setShowUserAccessPopup(false);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.log('update User Ticket Access api failed', updateResponse);
      }
    } catch (error) {
      setIsLoading(false);
      console.log('update User Ticket Access api catch', error);
    }
  };

  const checkInviteAction = () => {
    let XYZ = ticketData.ticket_users.find(
      (data) => data.user._id === user?._id
    );
    console.log('XYZ', XYZ);
    if (XYZ) {
      if (XYZ.can_edit) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const inviteUsersAction = async () => {
    setIsLoading(true);
    let data = { ticket: ticketData._id, users: [] };
    selectedUsers.map((item) => {
      let data1 = {
        user: item.value,
        can_view: true,
        can_edit: selectedUsersAccessState === 'Can edit' ? true : false,
      };
      data.users.push(data1);
    });
    try {
      let response = await inviteTicketMembersAPi(data);
      if (response.data.type === 'success') {
        console.log('invite members api response', response.data.data);
        setTicketUpdatedData({ ...ticketData, ...response.data.data });
        setTicketData({ ...ticketData, ...response.data.data });
        setSelectedUsers([]);
        setSelectedUsersAccessState('');
        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.log('invite members api failed', response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.log('invite members api catch', error);
    }
  };

  const renderMsgOrInputSection = () => {
    return (
      <>
        {ticketData.ticket_status === 'Complaint raised' ? (
          <div className={styles.msgContainerViewStyle}>
            <p className={styles.messageTextStyle}>
              Convert the raised complaint into a ticket first to enable adding
              more members to the ticket. However, you can forward the complaint
              to another admin from the overview.
            </p>
          </div>
        ) : (
          <div className={styles.inviteUserSecctionStyle}>
            <div className={styles.searchBoxViewStyle}>
              <MultiSelectInput
                options={availableMembers.map((user) => {
                  return {
                    label: user.full_name,
                    value: user._id,
                    employeeType: user.employee_type,
                  };
                })}
                placeholder="Enter name or email ..."
                selectedOptions={selectedUsers}
                setSelectedOptions={(data) => {
                  setSelectedUsers(data);
                }}
              />
              {renderSetSelectedUsersAccess()}
            </div>
            <Button
              onClick={() => inviteUsersAction()}
              title="invite"
              disabled={selectedUsers.length > 0 ? checkInviteAction() : true}
              btnStyle={styles.btnViewStyle}
            />
          </div>
        )}
      </>
    );
  };

  const renderSetSelectedUsersAccess = () => {
    return (
      <div
        onClick={() => {
          setShowSelectedUsersAccessPopup(true);
        }}
        className={styles.accessControlViewStyle}
      >
        <p className={styles.accessTextStyle}>
          {selectedUsersAccessState === 'Can edit' ? 'Edit' : 'View'}
        </p>
        <div className={styles.downArrowViewStyle}>
          <img
            src={downArrowGrayIcon}
            alt="img"
            className={[
              styles.imgStyle,
              showSelectedUsersAccessPopup && styles.arrowRotateViewStyle,
            ].join(' ')}
          />
        </div>
        <Popup
          show={showSelectedUsersAccessPopup}
          popupRef={popupRef}
          handleClickOutside={closeSelectedUsersAccessOptions}
          customPopUpStyles={styles.accessDropdownViewStyle}
        >
          <div className={styles.accessOptionsViewStyle}>
            {accessOptions.map((option, index) => (
              <div
                onClick={() => {
                  setSelectedUsersAccessState(option.key);
                  setShowSelectedUsersAccessPopup(false);
                }}
                key={index}
                className={[
                  styles.optionViewStyle,
                  selectedUsersAccessState === option.key &&
                    styles.selectedOptionStyle,
                ].join(' ')}
              >
                <p className={styles.optionTextStyle}>{option.key}</p>
              </div>
            ))}
          </div>
        </Popup>
      </div>
    );
  };

  const renderUsersSection = () => {
    return (
      <div className={styles.usersViewStyle}>
        {ticketData.ticket_users?.length > 0 ? (
          <p className={styles.usersSectionHeaderTextStyle}>Added members</p>
        ) : (
          <p className={styles.emptyMembersTextStyle}>members not available</p>
        )}
        {ticketData.ticket_users?.length > 0 &&
          ticketData.ticket_users.map((item, index) => (
            <div className={styles.userViewStyle}>
              <div className={styles.userLeftSectionStyle}>
                <Avatar url={avathar1} />
                <div className={styles.userDetailsStyle}>
                  <div className={styles.userTitleViewStyle}>
                    <p className={styles.titleTextStyle}>
                      {item.user.full_name}
                    </p>
                    <div
                      className={[
                        styles.userTypeViewStyle,
                        item.user.account_type === account_types.admin &&
                          styles.adminTypeStyle,
                      ].join(' ')}
                    >
                      <p className={styles.userTypeTextStyle}>
                        {item.user.account_type}
                      </p>
                    </div>
                  </div>
                  <p className={styles.userDescTextStyle}>{item.user.email}</p>
                </div>
              </div>
              {item.user._id !== user?._id && (
                <div className={styles.userrightSectionStyle}>
                  {renderAccessState(item, index)}
                </div>
              )}
            </div>
          ))}
      </div>
    );
  };

  const renderAccessState = (item, index) => {
    return (
      <div
        onClick={() => {
          setShowUserAccessPopup(true);
          setSelectedIndex(index);
        }}
        className={styles.accessControlViewStyle}
      >
        <p className={styles.accessTextStyle}>
          {item.can_edit === true ? 'Edit' : 'View'}
        </p>
        <div className={styles.downArrowViewStyle}>
          <img
            src={downArrowGrayIcon}
            alt="img"
            className={[
              styles.imgStyle,
              showUserAccessPopup &&
                selectedIndex === index &&
                styles.arrowRotateViewStyle,
            ].join(' ')}
          />
        </div>
        {selectedIndex === index && (
          <Popup
            show={showUserAccessPopup}
            popupRef={popupRef}
            handleClickOutside={closeUserAccessOptions}
            customPopUpStyles={styles.accessDropdownViewStyle}
          >
            <div className={styles.accessOptionsViewStyle}>
              {accessOptions.map((option, index) => (
                <div
                  onClick={() => updateUserAccess(item.user, option.key)}
                  key={index}
                  className={[
                    styles.optionViewStyle,
                    item.can_edit &&
                      option.key === 'Can edit' &&
                      styles.activeOptionStyle,
                    item.can_view &&
                      option.key === 'Can view' &&
                      styles.activeOptionStyle,
                  ].join(' ')}
                >
                  <p className={styles.optionTextStyle}>{option.key}</p>
                </div>
              ))}
            </div>
          </Popup>
        )}
      </div>
    );
  };

  const renderLinkSection = () => {
    return (
      <div className={styles.linkSectionViewStyle}>
        <div className={styles.linkleftSectionStyle}>
          <p className={styles.linkLeftTextStyle}>
            Anyone with link{' '}
            <span onClick={() => setShowAccessPopup(true)}>can view</span>
          </p>
          <div
            onClick={() => setShowAccessPopup(true)}
            className={styles.downArrowViewStyle}
          >
            <img
              src={downArrowGrayIcon}
              alt="img"
              className={[
                styles.imgStyle,
                showAccessPopup && styles.arrowRotateViewStyle,
              ].join(' ')}
            />
          </div>
          {renderOptionsPopup()}
        </div>
        <div className={styles.linkRightSectionStyle}>
          <div
            onClick={() => setShowAccessPopup(true)}
            className={styles.copyIconViewStyle}
          >
            <img src={copyIcon} alt="img" className={styles.imgStyle} />
          </div>
          <p className={styles.linkRightTextStyle}>Copy</p>
        </div>
      </div>
    );
  };

  const renderOptionsPopup = () => {
    return (
      <Popup
        show={showAccessPopup}
        popupRef={popupRef}
        handleClickOutside={closeAccessOptions}
        customPopUpStyles={styles.accessDropdownViewStyle}
      >
        <div className={styles.accessOptionsViewStyle}>
          {accessOptions.map((option, index) => (
            <div
              onClick={() => console.log('link access')}
              key={index}
              className={styles.optionViewStyle}
            >
              <p className={styles.optionTextStyle}>{option.key}</p>
            </div>
          ))}
        </div>
      </Popup>
    );
  };

  return (
    <div className={styles.containerStyle}>
      {isLoading && <Spinner />}
      <div className={styles.topSectionViewStyle}>
        <div className={styles.headerViewStyle}>
          <p>Members</p>
          <div onClick={closeAction} className={styles.closeImgViewStyle}>
            <img src={closeIcon} alt="img" className={styles.imgStyle} />
          </div>
        </div>
        {renderMsgOrInputSection()}
        {renderUsersSection()}
      </div>
      <div className={styles.deviderStyles}></div>
      {renderLinkSection()}
    </div>
  );
};

export default Members;
