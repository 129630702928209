import { UserDataContext } from 'providers/userdataprovider';
import { useContext } from 'react';

export const useUserDataContext = () => {
  const context = useContext(UserDataContext);

  if (!context)
    throw new Error('useAuthContext context must be use inside AuthProvider');

  return context;
};
