import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/avatar';
import styles from './styles.module.css';

const ActivityCard = (props) => {
  const { header, description, images = [], time } = props;

  return (
    <div className={styles.mainContainerStyle}>
      {time && <p className={styles.timeStyle}>{time}</p>}
      <div className={styles.cardContainerStyle}>
        <div className={styles.topContainerStyle}>
          <h2 className={styles.titleTextStyle}>{header}</h2>
          <p className={styles.descTextStyle}>{description}</p>
        </div>
        <div className={styles.avatarContainerStyle}>
          {images.map((url) => (
            <Avatar key={url} url={url} imgContainerStyle={styles.imgStyle} />
          ))}
        </div>
      </div>
    </div>
  );
};

ActivityCard.propTypes = {
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  time: PropTypes.string,
};

export default ActivityCard;
