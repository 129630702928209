import React, { useEffect, useRef, useState } from 'react';
import {
  OutlineAllAssetsIcon,
  avathar1,
  dottedMenuIcon,
  downArrowIcon,
  filterIcon,
  plansPricingIcon,
  plusIcon,
  purpleSuccess,
  scanIcon,
  searchIcon,
  selectIcon,
  sendIcon,
  time,
  uploadIcon,
} from 'resources/images/Images';
import SearchInput from 'components/searchInput';
import ButtonTab from 'components/buttontab';
import { useAuthContext } from 'hooks/useAuthContext';
import { employeeListApi } from 'networking/api/employee';
import { lostAndFoundData } from 'resources/dammyData/dammyData';
import { useStringData } from 'providers/stringsprovider';
import Input from 'components/input';
import Button from 'components/button';
import {
  archiveChatApi,
  getAssetsChatListApi,
  markAsRecoveredApi,
  sendMessageApi,
} from 'networking/api/chat';
import styles from './style.module.css';
import LoaderForPages from 'components/loaderForPages';
import AllTicketsCard from 'components/allticketscard';
import moment from 'moment';
import ChatHeader from 'components/chatHeader';
import Modal from 'components/modal';
import { chat_options, choose_file_options } from 'utils/constants';
import { socket } from 'providers/chatprovider';
import Popup from 'components/popup';
import useWindowDimensions from 'hooks/useDimensionHook';

const LostAndFound = () => {
  const { strings } = useStringData();
  const { user, company } = useAuthContext();
  const [message, setMessage] = useState('');
  const [chatList, setChatList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [selectedChat, setSelectedChat] = useState();
  const [isRecoveredModalOpen, setIsRecoveredModalOpen] = useState(false);
  const [acknowledged, setAcknowledge] = useState(false);
  const [isOpenChooseFile, setIsOpenChooseFile] = useState(false);
  const { width } = useWindowDimensions();
  const messagesEndRef = useRef(null);

  const popupRef = useRef(null);

  useEffect(() => {
    socketListner();
  }, []);

  useEffect(() => {
    if (company) {
      getChatListData();
    }
    return () => {
      socket.emit('leave_room', selectedChat?._id);
    };
  }, [company]);

  useEffect(() => {
    if (chatList.length > 0) {
      setSelectedChat(chatList[0]);
    }
  }, [chatList]);

  useEffect(() => {
    scrollToBottom('auto');
  }, [selectedChat]);

  const socketListner = () => {
    socket.on('new_message_received', (newMessage) => {
      if (selectedChat?.lf_asset._id === newMessage.lfa_id) {
        setSelectedChat((prev) => ({
          ...prev,
          messages: [...prev.messages, newMessage],
        }));
        scrollToBottom('smooth');
      }
    });
  };

  const scrollToBottom = (property) => {
    let scrollDiv = document.getElementById('scroll');
    scrollDiv?.lastElementChild.scrollIntoView({ behavior: property });
  };

  const actionOptions = [
    {
      text: strings.assetDetails,
    },
    {
      text: strings.markAsRecovered,
    },
    {
      text: strings.reportChat,
    },
  ];

  const getChatListData = async () => {
    try {
      let response = await getAssetsChatListApi(company._id);
      if (response.data.type === 'success') {
        setChatList(response.data.data);
        console.log('getAssetsChatListApi', response.data.data);
        setLoader(false);
        if (response.data.data.length > 0) {
          socket.emit('join_room', response.data.data[0]._id);
        }
        scrollToBottom('auto');
      } else {
        setLoader(false);
        console.log('getAssetsChatListApi failed', response.data.message);
      }
    } catch (error) {
      setLoader(false);
      console.log('getAssetsChatListApi catch', error.message);
    }
  };

  const onSelectChat = async (item) => {
    if (selectedChat) {
      socket.emit('leave_room', selectedChat?._id);
    }
    setSelectedChat(item);
    socket.emit('join_room', item?._id);
  };

  const sendMessage = async () => {
    if (message !== '') {
      try {
        let response = await sendMessageApi({
          asset: selectedChat._id,
          text: message,
          files: [],
          guest_email: '',
        });
        if (response.data.type === 'success') {
          console.log('send Message Response', response.data.data);
          setMessage('');
          socket.emit('new_message', response.data.data);
        } else {
          console.log('send Message api fail', response.data.message);
        }
      } catch (error) {
        console.log('send Message api catch', error.response.data);
      }
    }
  };

  const topOnOption = (data) => {
    if (data === chat_options.asset_details) {
      console.log('chat_options.asset_details', chat_options.asset_details);
    } else if (data === chat_options.mark_as_recovered) {
      console.log(
        'chat_options.mark_as_returned',
        chat_options.mark_as_returned
      );
      setIsRecoveredModalOpen(true);
    } else {
      console.log('chat_options.report_chat', chat_options.report_chat);
    }
  };

  const tapOnAcknowledge = () => {
    setIsRecoveredModalOpen(!isRecoveredModalOpen);
  };

  const tapOnArchiveChat = async () => {
    console.log('tap On Archive Chat');
    try {
      let data = {
        asset: selectedChat._id,
      };
      let chatArchiveResponse = await archiveChatApi(data);
      if (chatArchiveResponse.data.type === 'success') {
        console.log('archive Chat api response', chatArchiveResponse.data.data);
      } else {
        console.log(
          'archive Chat api failed',
          chatArchiveResponse.data.message
        );
      }
    } catch (error) {
      console.log('archive Chat api catch', error.response.data);
    }
  };

  const tapOnProceed = async () => {
    let data = {
      asset: selectedChat?._id,
    };
    try {
      let markAsRecoveredResponse = await markAsRecoveredApi(data);
      if (markAsRecoveredResponse.data.type === 'success') {
        console.log('mark As Recovered Api', markAsRecoveredResponse.data.data);
        let updatedList = chatList.map((item) => {
          if (item._id === selectedChat?._id) {
            return {
              ...item,
              lf_asset: markAsRecoveredResponse.data.data,
            };
          } else {
            return item;
          }
        });
        setChatList(updatedList);
        setSelectedChat(
          updatedList.find((item) => item._id === selectedChat?._id)
        );
      } else {
        console.log(
          'mark As Recovered Api failed',
          markAsRecoveredResponse.data.data
        );
      }
    } catch (error) {
      console.log('mark As Recovered Api catch', error.response.data);
    }
  };

  const closeChooseFile = () => {
    setIsOpenChooseFile(!isOpenChooseFile);
  };

  let previousDate = '';
  const shouldDisplayDate = (message, index) => {
    if (index > 0) {
      if (previousDate === moment(message.created_at).format('L')) {
        return false;
      } else {
        previousDate = moment(message.created_at).format('L');
        return true;
      }
    } else {
      previousDate = moment(message.created_at).format('L');
      return true;
    }
  };

  const displayGroupMessageDate = (date) => {
    var today = moment();
    var yesterday = moment().subtract(1, 'day');
    if (moment(date).isSame(today, 'd')) {
      return 'Today';
    } else if (moment(date).isSame(yesterday, 'd')) {
      return 'Yesterday';
    } else {
      return moment(date).format('MMM DD Y');
    }
  };

  let previousTime = '';
  const shouldDisplayTime = (message, index) => {
    if (index > 0) {
      if (previousTime === moment(message.created_at).format('HH:mm')) {
        return false;
      } else {
        previousTime = moment(message.created_at).format('HH:mm');
        return true;
      }
    } else {
      previousTime = moment(message.created_at).format('HH:mm');
      return true;
    }
  };

  const displayGroupMessageTime = (date) => {
    return moment(date).format('hh:mm a');
  };

  // --------------- left section views ---------------------

  const renderLeftTopSection = () => {
    return (
      <div className={styles.letfTopBlockStyle}>
        <SearchInput
          placeholder={strings.searchHereInput}
          // onChange={filterEmployeeList}
          leftIcon={searchIcon}
        />
        <ButtonTab
          icon={filterIcon}
          // titleText={strings.filter}
          custButtonTabContainerStyle={styles.filterBtnStyle}
        />
      </div>
    );
  };

  const renderLeftBottomTopSection = () => {
    return (
      <div className={styles.leftBottomTopSectionViewStyle}>
        <p className={styles.headerTextStyle}>Lost & Found</p>
        <div className={styles.selectOptionBlockStyles}>
          <p className={styles.allTextStyles}>All</p>
          <div className={styles.downArrowImgStyles}>
            <img
              src={downArrowIcon}
              alt="downArrowIcon"
              className={styles.imageStyles}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderLeftBottomSecction = () => {
    return (
      <div className={styles.renderLeftBottomSectionViewStyle}>
        {renderLeftBottomTopSection()}
        <div className={styles.chatListViewStyle}>
          {chatList.length > 0 &&
            chatList.map((item, index) => {
              return (
                <AllTicketsCard
                  key={index}
                  leftImage={scanIcon}
                  TicketSerialNo={item?.asset_name}
                  ticketStatusTitle={item?.asset_status}
                  ticketProductTitle={`#${item?.asset_uid}`}
                  ticketSubject={item?.last_activity}
                  onClick={() => {
                    onSelectChat(item);
                  }}
                  selected={selectedChat?._id === item._id}
                />
              );
            })}
        </div>
      </div>
    );
  };

  const currrentStatusColor = (data) => {
    if (data.current_status === 'Marked lost') {
      return 'rgba(236, 0, 0, 1)';
    } else if (data.current_status === 'Asset recovered') {
      return 'rgba(42, 198, 112, 1)';
    } else if (data.current_status === 'Asset deleted') {
      return 'rgba(30, 30, 32, 0.6)';
    } else {
      return 'rgba(35, 107, 254, 1)';
    }
  };

  const renderLeftContainer = () => {
    return (
      <div className={styles.leftContainerViewStyle}>
        {renderLeftTopSection()}
        {renderLeftBottomSecction()}
      </div>
    );
  };

  // --------------- right section views ---------------------

  const renderRightTopSection = () => {
    return (
      <ChatHeader
        image={selectedChat?.image}
        title={selectedChat?.asset_name}
        assetUid={selectedChat?.asset_uid}
        actionOptions={
          selectedChat?.lf_asset?.recovered
            ? actionOptions.filter(
                (item) => item.text !== chat_options.mark_as_recovered
              )
            : actionOptions
        }
        backAction={width <= 480 ? true : false}
        tabOnBackAction={() => setSelectedChat(null)}
        setSelectedOption={(e) => topOnOption(e)}
      />
    );
  };

  const checkMessagePosition = (message) => {
    if (user) {
      if (message.sender?._id === user?._id) {
        return styles.rightStyle;
      } else {
        return styles.leftStyle;
      }
    }
  };

  const renderChatBox = () => {
    return (
      <div className={styles.chatBoxViewStyle}>
        <div id="scroll" className={styles.chatViewStyle}>
          {selectedChat?.messages.length > 0 &&
            selectedChat?.messages.map((message, index) => {
              return (
                <React.Fragment key={index}>
                  {shouldDisplayDate(message, index) && (
                    <p className={styles.chatMessgeGroupdate}>
                      {displayGroupMessageDate(message.created_at)}
                    </p>
                  )}
                  <div
                    key={message._id}
                    className={`${styles.chat__message} ${checkMessagePosition(
                      message
                    )}`}
                  >
                    {message.image ? (
                      <img
                        className={styles.chat__messageImg}
                        src={message?.image}
                        alt=""
                      />
                    ) : null}
                    <p className={styles.chat__messageText}>{message.text}</p>
                  </div>
                  {shouldDisplayTime(message, index) && (
                    <div
                      className={`${
                        styles.msgTimeViewStyle
                      } ${checkMessagePosition(message)}`}
                    >
                      <p className={styles.chatMessageTime}>
                        {displayGroupMessageTime(message.created_at)}
                      </p>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          {(selectedChat?.lf_asset?.recovered ||
            selectedChat?.lf_asset?.returned) &&
            renderReturnedRecoveredSection()}
          <div ref={messagesEndRef} />
        </div>
      </div>
    );
  };

  const renderReturnedRecoveredSection = () => {
    return (
      <div
        className={[
          selectedChat?.lf_asset?.recovered
            ? styles.assetRecoveredViewStyle
            : styles.assetReturnedViewStyle,
        ]}
      >
        <div className={styles.imgViewStyle}>
          <img
            src={selectedChat?.lf_asset?.recovered ? purpleSuccess : time}
            alt="img"
            className={styles.imgStyle}
          />
        </div>
        {user ? (
          <p className={styles.assetStatusTextStyle2}>
            {selectedChat?.lf_asset?.recovered
              ? strings.assetRecoveredTextForUser
              : strings.assetReturnedTextForUser}{' '}
            <span
              className={[
                selectedChat?.lf_asset?.recovered
                  ? styles.onClickTextStyle
                  : styles.onClickTextStyle2,
              ]}
              onClick={() => {
                selectedChat?.lf_asset?.recovered
                  ? tapOnArchiveChat()
                  : tapOnAcknowledge();
              }}
            >
              {strings.clickHere}
            </span>
          </p>
        ) : (
          <p className={styles.assetStatusTextStyle}>
            {strings.assetReturneddTextForFinder}{' '}
            <span>{strings.assetReturneddTextForFinder2}</span>
          </p>
        )}
      </div>
    );
  };

  const renderChatInputSection = () => {
    return (
      <div className={styles.chatInputAndSendSectionStyle}>
        <Input
          placeholder="Type message here ..."
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          image={uploadIcon}
          rightImage={sendIcon}
          onLeftImageClick={() => setIsOpenChooseFile(true)}
          onRightImageClick={() => sendMessage()}
          customStyle={styles.inputViewStyle}
          customImageContainerStyles={styles.customImageContainerStyles}
          customRightImageContainerStyles={styles.inputRightIconViewStyle}
        />
        {renderFileOption()}
      </div>
    );
  };

  const renderFileOption = () => {
    return (
      <Popup
        show={isOpenChooseFile}
        showOverlay={false}
        onClose={closeChooseFile}
        showCloseButton={false}
        popupRef={popupRef}
        customPopUpStyles={styles.chooseFilePopUpStyles}
      >
        <div className={styles.assignPopUpStyles}>
          {choose_file_options.map((item, index) => {
            return (
              <div className={styles.chooseFileOptionStyle}>
                <div className={styles.chooseFileOptionImgStyle}>
                  <img
                    src={item.image}
                    alt="img"
                    className={styles.imageStyles}
                  />
                </div>
                <p
                  key={index}
                  onClick={() => {
                    setIsOpenChooseFile(false);
                  }}
                  className={styles.optionTextViewStyle}
                >
                  {item.title}
                </p>
              </div>
            );
          })}
        </div>
      </Popup>
    );
  };

  const renderRightContainer = () => {
    return (
      <div className={styles.rightContainerViewStyle}>
        {renderRightTopSection()}
        {selectedChat?.messages?.length > 0
          ? renderChatBox()
          : rendedrChatEmptySection()}
        {renderChatInputSection()}
      </div>
    );
  };

  // --------------- empty section views ---------------------
  const rendedrEmptySection = () => {
    return (
      <div className={styles.emptySectionViewStyle}>
        <p className={styles.emptyDataTextStyle}>
          Select an asset from the list to view detailed-updates, messages, and
          much more. To learn more about Lost & Found and how it will help your
          business{' '}
          <span className={styles.clickHereTextStyle}> click here </span>
        </p>
      </div>
    );
  };

  const rendedrChatEmptySection = () => {
    return (
      <div className={styles.emptySectionViewStyle}>
        <p className={styles.emptyDataTextStyle}>0 conversations</p>
        <p className={styles.emptyDataTextStyle}>
          No finder has reported about this lost item/asset yet
        </p>
      </div>
    );
  };

  const renderModal = () => {
    return (
      <Modal
        open={isRecoveredModalOpen}
        title={strings.itemRecovered}
        desc={strings.userAcknowledgeAlertText}
        leftBtnTitle={strings.proceed}
        onLeftBtnClick={() => {
          setIsRecoveredModalOpen(false);
          tapOnProceed();
        }}
        rightBtnTitle={strings.cancel}
        onRighBtnClick={() => {
          setIsRecoveredModalOpen(false);
        }}
        modalBlockStyles={styles.customModalBlockStyles}
        customModalContentBottomStyles={styles.modalBtnsViewStyles}
      />
    );
  };

  return (
    <div className={styles.conatinerStyle}>
      {loader ? (
        <LoaderForPages />
      ) : (
        <>
          {chatList.length > 0 ? (
            <div className={styles.mainContainerViewStyle}>
              {(!selectedChat || width > 480) && renderLeftContainer()}
              {selectedChat && renderRightContainer()}
            </div>
          ) : (
            rendedrEmptySection()
          )}
        </>
      )}
      {renderModal()}
    </div>
  );
};
export default LostAndFound;
