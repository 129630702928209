import axios from 'utils/axios';
import * as endPoints from 'networking/endpoints';

export const sendRegistrationOtpApi = async (data) =>
  Promise.resolve(axios.post(endPoints.sendRegistrationOtp, data));

export const superAdminRegisterApi = async (data) =>
  Promise.resolve(axios.post(endPoints.superAdminRegister, data));

export const sendLoginOtpApi = async (data) =>
  Promise.resolve(axios.post(endPoints.sendLoginOtp, data));

export const superAdminLoginApi = async (data) =>
  Promise.resolve(axios.post(endPoints.superAdminLogin, data));

export const superAdminDetailsApi = async () =>
  Promise.resolve(axios.get(endPoints.getSuperAdminDetails));

// export const getAlladminDetailsApi = async (id) =>
//   Promise.resolve(axios.get(endPoints.getAllAdminsDetails + `?company=${id}`));

export const adminUpdateApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updateSuperAdminDetails, data));

export const adminUpdatePasswordApi = async (data) =>
  Promise.resolve(axios.post(endPoints.superAdminChangePassword, data));
