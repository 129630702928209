import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from 'layouts/dashboard';
import AdminDashboard from 'pages/dashboard';
import EmployeeRegistration from 'pages/employee/registration';
import AssetRegistration from 'pages/asset/registration';
import AuthGuard from 'guards/authguard';
import GuestGuard from 'guards/guestguard';
import AssetList from 'pages/asset/list';
import EmployeeList from 'pages/employee/list';
import LostAndFound from 'pages/lost&found';
import EmployeeOverView from 'pages/employee/employeeoverview';
import AssetOverView from 'pages/asset/assetoverview';
import Recent from 'pages/usermenu/recent';
import Tickets from 'pages/usermenu/tickets';
import Notification from 'pages/usermenu/notifications';
import Profile from 'pages/usermenu/profile';
import WhatsNew from 'pages/usermenu/whatsnew';
import { account_types } from 'utils/constants';
import RaiseTicket from 'pages/raiseticket';
import AddCompany from 'pages/company/addcompany';
import CompaniesList from 'pages/company/companieslist';
import AllTickets from 'pages/alltickets';
import ScanneddAsset from 'pages/scannedasset';
import ReturnItem from 'pages/returnitem';
import EmailRequired from 'pages/emailrequired';
import QrLinkedOrNot from 'pages/qrlinkedornot';
import LinkAsset from 'pages/linkasset';
import RaiseTicketThroughQR from 'pages/raiseticketthroughQR';
import Login from 'pages/auth/login';
import SignUp from 'pages/auth/signup';
import Maintenance from 'pages/maintenance';
const AppRoutes = () => {
  const routes = useRoutes([
    {
      path: 'signup',
      element: <SignUp />,
    },
    {
      path: 'login',
      element: (
        <GuestGuard
          userType={[
            account_types.employee,
            account_types.admin,
            account_types.super_admin,
          ]}
        >
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: 'qrLinkedOrNOt',
      element: <QrLinkedOrNot />,
    },
    {
      path: 'scannedAsset',
      element: <ScanneddAsset />,
    },
    {
      path: 'emailRequired',
      element: <EmailRequired />,
    },
    {
      path: 'returnItem',
      element: <ReturnItem />,
    },
    {
      path: 'raiseTicketThroughQr',
      element: <RaiseTicketThroughQR />,
    },
    {
      path: 'linkAsset',
      element: <LinkAsset />,
    },

    {
      path: '',
      element: (
        <AuthGuard
          userType={[
            account_types.admin,
            account_types.super_admin,
            account_types.employee,
          ]}
        >
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={'/dashboard'} replace />,
          index: true,
        },
        { path: '/dashboard', element: <AdminDashboard /> },
        { path: '/add-company', element: <AddCompany /> },
        { path: '/adduser', element: <EmployeeRegistration /> },
        { path: '/addasset', element: <AssetRegistration /> },
        { path: '/companiesList', element: <CompaniesList /> },
        { path: '/raise-ticket', element: <RaiseTicket /> },
        { path: '/all-tickets', element: <AllTickets /> },
        { path: '/assetlist', element: <AssetList /> },
        { path: '/employee-list', element: <EmployeeList /> },
        { path: '/lost&found', element: <LostAndFound /> },
        { path: '/maintenance', element: <Maintenance /> },
        { path: '/recent', element: <Recent /> },
        { path: '/tickets', element: <Tickets /> },
        { path: '/alerts', element: <Notification /> },
        { path: '/profile', element: <Profile /> },
        { path: '/whatsnew', element: <WhatsNew /> },
        {
          path: '/employee-overview',
          element: <EmployeeOverView />,
        },
        {
          path: '/asset-overview',
          element: <AssetOverView />,
        },
      ],
    },
  ]);

  return routes;
};

export default AppRoutes;
