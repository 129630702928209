import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/popup';
import styles from './styles.module.css';
import classNames from 'classnames';
import { useAppDataContext } from 'hooks/useAppDataContext';

const SelectPopup = ({
  isPopupOpen,
  togglePopup,
  selectStatsticOption,
  downArrowIcon,
  options,
  handleStasticOptionItemClick,
  popupRef,
  handleClickOutside,
  setPopupOpen,
  stasticsOptionImg,
  customStatiscPopUpStyles,
  customSelectStyles,
  customDownArrowImgStyles,
  customSelectOptionTextStyles,
  customOptionStyles,
}) => {
  const { showOverLayModel } = useAppDataContext();
  return (
    <div
      className={classNames(
        showOverLayModel
          ? styles.selectStyles2
          : isPopupOpen
          ? styles.selectHiddenStyles
          : styles.selectStyle,
        customSelectStyles
      )}
      onClick={togglePopup}
    >
      <p
        className={classNames(
          styles.selectedOptionTextStyles,
          customSelectOptionTextStyles
        )}
      >
        {selectStatsticOption && selectStatsticOption.text}
      </p>
      <div
        className={classNames(
          styles.downArrowImgStyles,
          customDownArrowImgStyles
        )}
      >
        <img
          src={downArrowIcon}
          alt="downArrowIcon"
          className={styles.imageWidth}
        />
      </div>
      <Popup
        show={isPopupOpen}
        onClose={togglePopup}
        showCloseButton={false}
        popupRef={popupRef}
        customPopUpStyles={classNames(
          styles.stasticPopStyles,
          customStatiscPopUpStyles
        )}
        handleClickOutside={handleClickOutside}
      >
        <div className={styles.stasticMainContainerStyles}>
          {options.map((option, index) => (
            <div
              className={
                selectStatsticOption &&
                selectStatsticOption.text === option.text
                  ? styles.activeOptionStyles
                  : styles.stasticOptionContainerStyles
              }
              key={index}
              onClick={() => {
                handleStasticOptionItemClick(option);
                setPopupOpen(false);
              }}
            >
              {stasticsOptionImg && (
                <div className={styles.stasticsOptionImg}>
                  <img src={option.icon} alt="" className={styles.imageWidth} />
                </div>
              )}
              <p
                className={classNames(
                  styles.stasticOptionTextStyles,
                  customOptionStyles
                )}
              >
                {option.text}
              </p>
            </div>
          ))}
        </div>
      </Popup>
    </div>
  );
};

SelectPopup.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  togglePopup: PropTypes.func.isRequired,
  selectStatsticOption: PropTypes.object,
  downArrowIcon: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  handleStasticOptionItemClick: PropTypes.func.isRequired,
  popupRef: PropTypes.object,
  handleClickOutside: PropTypes.func,
  setSelectStatsticOption: PropTypes.func,
  setPopupOpen: PropTypes.func,
};

export default SelectPopup;
