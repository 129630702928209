import React from 'react';
import styles from './styles.module.css';
import PropTypes from 'prop-types';
const TextAreaInput = (props) => {
  const {
    type,
    name,
    value,
    onChange = () => {},
    onFocus = () => {},
    placeholder,
    hasError,
    lableText,
    lableTextStyle,
    customTextAreaStyles,
    numberOfRows,
    ...rest
  } = props;

  return (
    <div className={styles.inputContainerStyle}>
      {lableText && (
        <p className={[lableTextStyle, styles.lableTextStyle].join(' ')}>
          {lableText}
        </p>
      )}
      <textarea
        type={type || 'text'}
        name={name}
        className={[styles.inputStyle, customTextAreaStyles].join(' ')}
        value={value}
        onChange={onChange}
        placeholder={placeholder || ''}
        rows={numberOfRows || 5}
        onFocus={onFocus}
        {...rest}
      />
      {hasError && <p className={styles.errorTextStyle}>{hasError}</p>}
    </div>
  );
};

TextAreaInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  hasError: PropTypes.string,
  lableText: PropTypes.string,
  lableTextStyle: PropTypes.string,
};

export default TextAreaInput;
