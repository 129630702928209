import React, { useState } from 'react';
import classNames from 'classnames';
import Button from 'components/button';
import styles from './styles.module.css';
import { priorityIcon } from 'resources/images/Images';

const AllTicketsCard = (props) => {
  const {
    leftImage,
    TicketSerialNo,
    ticketStatusTitle,
    ticketProductTitle,
    ticketSubject,
    // rightTicketStatusIcon,
    priority,
    customTicketSerialNoTextStyles,
    customTicketProductBtnStyles,
    customTicketSubjectStyles,
    onClick = () => {},
    selected,
  } = props;

  const getBgClolor = () => {
    if (
      ticketStatusTitle === 'Unresolved' ||
      ticketStatusTitle === 'Found, Unrecovered'
    ) {
      return styles.unresolvedBgViewStyles;
    } else if (ticketStatusTitle === 'Assigned') {
      return styles.assigenedBgViewStyles;
    } else if (
      ticketStatusTitle === 'In progress' ||
      ticketStatusTitle === 'Recovered'
    ) {
      return styles.inProgressBgViewStyles;
    } else if (ticketStatusTitle === 'In review') {
      return styles.inReviewBgViewStyles;
    } else if (
      ticketStatusTitle === 'Marked lost' ||
      ticketStatusTitle === 'Complaint raised' ||
      ticketStatusTitle === 'In review*'
    ) {
      return styles.inMarkAsLostBgViewStyles;
    } else if (ticketStatusTitle === 'Closed') {
      return styles.inClosedBgViewStyles;
    } else {
      return styles.primaryBgStyle;
    }
  };
  const getTextClolor = () => {
    if (
      ticketStatusTitle === 'Unresolved' ||
      ticketStatusTitle === 'Found, Unrecovered'
    ) {
      return styles.unresolvedTextStyles;
    } else if (ticketStatusTitle === 'Assigned') {
      return styles.assigenedTextStyles;
    } else if (
      ticketStatusTitle === 'In progress' ||
      ticketStatusTitle === 'Recovered'
    ) {
      return styles.inProgressTextStyles;
    } else if (ticketStatusTitle === 'In review') {
      return styles.inReviewBgViewStyles;
    } else if (
      ticketStatusTitle === 'Marked lost' ||
      ticketStatusTitle === 'Complaint raised' ||
      ticketStatusTitle === 'In review*'
    ) {
      return styles.inMarkAsLostTextStyles;
    } else if (ticketStatusTitle === 'Closed') {
      return styles.inClosedTextStyles;
    }
  };

  return (
    <div
      onClick={onClick}
      className={
        selected
          ? styles.selectedTicketsCardStyles
          : styles.allTicketsCardContainerStyles
      }
    >
      <div className={styles.ticketDetailsViewStyle}>
        <div className={classNames(styles.leftBlockStyles, getBgClolor())}>
          <img
            src={leftImage}
            alt={'ticketIcon'}
            className={styles.imageStyle}
          />
        </div>

        <div className={styles.middleBlockStyles}>
          <p
            className={classNames(
              styles.ticketSerialNoStyles,
              customTicketSerialNoTextStyles
            )}
          >
            {TicketSerialNo}
          </p>
          <div className={styles.ticketStatusStyles}>
            <Button
              title={ticketStatusTitle}
              btnStyle={classNames(
                styles.ticketStatusBtnStyles,
                getTextClolor()
              )}
            />
            <Button
              title={ticketProductTitle}
              btnStyle={classNames(
                styles.ticketProductBtnStyles,
                customTicketProductBtnStyles
              )}
              onClick={() => console.log('cc')}
            />
            {priority && (
              <div className={styles.priorityViewStyle}>
                <img
                  src={priorityIcon}
                  alt="priority"
                  className={styles.priorityImgStyle}
                />
              </div>
            )}
          </div>
          <p
            className={classNames(
              styles.ticketSubjectStyles,
              customTicketSubjectStyles
            )}
          >
            {ticketSubject}
          </p>
        </div>
      </div>
      {/* {rightTicketStatusIcon && ( */}
      <div className={classNames(styles.rightBlockStyles, getBgClolor())}></div>
    </div>
  );
};

export default AllTicketsCard;
