import React from 'react';
import styles from './styles.module.css';
const Spinner = () => {
  return (
    <div className={styles.loadingspinneroverlay}>
      <div className={styles.loadingspinner}></div>
    </div>
  );
};

export default Spinner;
