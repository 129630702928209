import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from "./styles.module.css";

const Overlay = ({ show, children, onClose, overLayStyles, overLayContentStyles }) => {
    if (!show) {
        return null;
    }

    return (
        <div className={classNames(styles.overlay, overLayStyles)} onClick={onClose}>
            <div className={classNames(styles.content, overLayContentStyles)} onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );
};

Overlay.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
};

export default Overlay;
