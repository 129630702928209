import React from 'react';
import styles from './styles.module.css';
import {
  deleteIcon,
  rightArrowFillIcon,
  whiteDownArrow,
} from 'resources/images/Images';
import Button from 'components/button';
import moment from 'moment';
import { useStringData } from 'providers/stringsprovider';

const AssetEmployeeCard = (props) => {
  const { strings } = useStringData();
  const {
    icon,
    title,
    description,
    rightLable,
    rightLableType,
    rightTitle,
    rightDesc,
    userProfile,
    customAssetBottomStyles,
    onClick = () => {},
    btnAction = () => {},
    button,
    leftLable,
    leftLableType,
    leftTitle,
    leftDesc,
    deleteAction,
    customRightTitleTextStyle,
    btnDisabled,
  } = props;

  const renderTopSection = () => {
    return (
      <div className={styles.assetBannerStyles}>
        <div className={styles.userImgStyles}>
          <img src={userProfile} alt="" className={styles.imageWidth} />
        </div>
        <div className={styles.userDetailsStyles}>
          <p className={styles.titleTextStyles}>{title}</p>
          <p className={styles.descTextStyles}>{description}</p>
        </div>
      </div>
    );
  };

  const renderBottomSection = () => {
    return (
      <div
        className={[styles.assetBottomStyles, customAssetBottomStyles].join(
          ' '
        )}
      >
        {renderLeftSection()}
        {renderRightSection()}
      </div>
    );
  };

  const renderLeftSection = () => {
    return (
      <div className={styles.leftStyles}>
        <p className={styles.lableTextStyles}>
          {leftLable ? leftLable : strings.DateAndTimeRequired}
        </p>
        <div className={styles.gapStyles}>
          <p className={styles.subTitleTextStyles}>{leftTitle}</p>
          <p className={styles.descTextStyles}>{leftDesc}</p>
        </div>
      </div>
    );
  };

  const renderRightSection = () => {
    return (
      <div className={styles.rightStyles}>
        {button ? renderButtonSection() : renderOtherSection()}
      </div>
    );
  };

  const renderButtonSection = () => {
    return (
      <>
        {icon ? (
          <div className={styles.imgDeleteStyles} onClick={deleteAction}>
            <img src={deleteIcon} alt="" className={styles.imageWidth} />
          </div>
        ) : (
          <Button
            title={strings.actions}
            btnStyle={styles.customMobileBtnStyles}
            image={whiteDownArrow}
            customImageStyle={styles.customMobileImageStyles}
            onClick={btnAction}
            disabled={btnDisabled}
          />
        )}
      </>
    );
  };

  const renderOtherSection = () => {
    return (
      <div className={styles.leftStyles}>
        <p className={styles.lableTextStyles}>
          {rightLable ? rightLable : strings.DateAndTimeRequired}
        </p>
        <div className={styles.gapStyles}>
          <p
            className={[
              styles.subTitleTextStyles,
              customRightTitleTextStyle,
            ].join(' ')}
          >
            {rightTitle}
          </p>
          <p className={styles.descTextStyles}>{rightDesc}</p>
        </div>
      </div>
    );
  };
  return (
    <div onClick={onClick} className={styles.assetEmployeeStyles}>
      {renderTopSection()}
      {renderBottomSection()}
    </div>
  );
};

export default AssetEmployeeCard;
