import React, { useRef, useState } from 'react';
import { avathar1, gallery, uploadImgIcon } from 'resources/images/Images';
import Button from 'components/button';
import ButtonTab from 'components/buttontab';
import { uploadImageApi } from 'networking/api/upload';
import styles from './styles.module.css';
import Popup from 'components/popup';
import LoaderForPages from 'components/loaderForPages';
import ImagePreview from 'components/ImagePreview';

const FileUploadInput = (props) => {
  const {
    upLoadImage,
    setupLoadImage,
    lable,
    imgPreViewAction = () => {},
  } = props;

  // states
  const [image, setImage] = useState();
  const [uploadedFile, setUpLoadedFile] = useState();
  const [isPreViewImg, setIsPreViewImg] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showCloseBtn, setShowCloseBtn] = useState(false);

  // function to handleUppload ticket [solved] image
  const handleUploadTicketSolvedImg = async () => {
    try {
      setLoader(true);
      const formData = new FormData();
      formData.append('image', uploadedFile);
      const response = await uploadImageApi(formData);
      if (response.data.type === 'success') {
        // console.log('response', response);
        setupLoadImage(response.data.data);
        setLoader(false);
        setIsPreViewImg(false);
      } else {
        console.log('error', response.data.message);
        setLoader(false);
      }
    } catch (error) {
      console.log('error', error);
      setLoader(false);
    }
  };

  // ref for fileUpload
  const fileInputRef = useRef();

  // function to handle to fileupload when upload an image text click
  const handleUploadImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // function to handle fileUpload Selection

  const handleImageUploadSelection = (e) => {
    const file = e.target.files[0];
    setUpLoadedFile(file);
    setImage(URL.createObjectURL(file));
    setIsPreViewImg(true);
    // handleUploadTicketSolvedImg(file);
  };

  return (
    <div className={styles.upLoadResolvingImgBlockStyles}>
      <p className={styles.uploadImgLabelStyles}>{lable}</p>
      <div className={styles.uploadImgSubBlockStyles}>
        {!upLoadImage && (
          <p className={styles.imageUploadFormatDescStyles}>
            JPG, JPEG, or PNG (Less than 5MB)
          </p>
        )}
        <div className={styles.imageUploadViewStyles}>
          {image ? (
            <ButtonTab
              icon={image}
              titleText={image}
              closeBtn={true}
              closeAction={() => {
                setImage();
                setupLoadImage();
                setShowCloseBtn(false);
                fileInputRef.current.value = '';
              }}
              customtitleTextStyle={styles.upLoadImgNameTextStyles}
              custButtonTabContainerStyle={styles.upLoadImgButtonTabStyles}
              customDividerStyles={styles.upLoadDividerStyles}
            />
          ) : (
            <Button
              title={'Upload an image'}
              btnStyle={styles.uploadImgBtnStyles}
              image={uploadImgIcon}
              onClick={handleUploadImageClick}
              customImageStyle={styles.leftUploadImgStyles}
            />
          )}
          <input
            type="file"
            accept="image/jpeg, image/jpg, image/png"
            placeholder="Upload an image"
            ref={fileInputRef}
            onChange={handleImageUploadSelection}
            className={styles.uploadInputStyles}
          />
        </div>
      </div>
      {upLoadImage && (
        <div onClick={imgPreViewAction} className={styles.imgPreviewBtnStyle}>
          <div className={styles.imgViewStyle}>
            <img src={gallery} alt="img" className={styles.imgStyle} />
          </div>
          <p
            className={styles.previewImgTextStyle}
            onClick={() => {
              setIsPreViewImg(true);
              setShowCloseBtn(true);
            }}
          >
            Click to preview image
          </p>
        </div>
      )}
      <ImagePreview
        image={image}
        proceedAction={() => handleUploadTicketSolvedImg()}
        cancel={() => {
          setIsPreViewImg(false);
          setImage();
          fileInputRef.current.value = '';
        }}
        loader={loader}
        isPreViewImg={isPreViewImg}
        setIsPreViewImg={setIsPreViewImg}
        closeBtn={showCloseBtn}
        setImage={setImage}
      />
    </div>
  );
};

export default FileUploadInput;
