import React, { useRef, useState } from 'react';
import {
  searchIcon,
  globeIcon,
  notificationIcon,
  profileIcon,
  hambergerIcon,
  searchIcon2,
  backkArrowImg,
  crossIcon,
  logoutIcon,
  downArrowIcon,
} from 'resources/images/Images';
import { useAppDataContext } from 'hooks/useAppDataContext';
import SideBar from 'components/sidebarmenu';
import { useNavigate } from 'react-router';
import Popup from 'components/popup';
import Input from 'components/input';
import Button from 'components/button';
import { useAuthContext } from 'hooks/useAuthContext';
import Overlay from 'components/overlay';
import styles from './style.module.css';
import classNames from 'classnames';
import Modal from 'components/modal1';

const Header = () => {
  const {
    screenName,
    individualId,
    setIndividualId,
    navBarOpen,
    setNavBarOpen,
    mobileHeaderClicked,
    setMobileHeaderClicked,
    showOverLayModel,
    setShowOverLayModel,
    isOverlayVisible,
    setOverlayVisible,
    showOverLay,
    setShowOverLay,
    btnHtml,
    selectedTicket,
  } = useAppDataContext();
  const { logout, user, userType, company, setCompany } = useAuthContext();
  const navigate = useNavigate();
  const [isOpenProfile, setIsOpenProfile] = useState(false);
  const [swicthCompany, setSwitchCompany] = useState(false);
  const popupRef = useRef(null);

  // function to toggle a ProfilePopUp
  const toggleProfilePopup = () => {
    setIsOpenProfile(!isOpenProfile);
  };

  // function to close the profile popup onclick the outside
  const handleClickOutsidePopup = (event) => {
    if (
      isOpenProfile &&
      popupRef.current &&
      !popupRef.current.contains(event.target)
    ) {
      toggleProfilePopup();
    }
  };
  // function to handle logout user account
  const logOutAccount = async () => {
    await logout();
  };

  //function to toggle sidebar [hambergermenu]

  const toggleSidebar = () => {
    setNavBarOpen(!navBarOpen);
    setShowOverLay(!showOverLay);
    setShowOverLayModel(!showOverLayModel);
  };

  // function to handle back button click [in mobile header]

  const handleBackButtonClick = () => {
    navigate('dashboard');
    setMobileHeaderClicked(!mobileHeaderClicked);
  };

  // function to handle backbutton click in the invidual employee header

  const tapOnIndvidualEmployeeHeader = () => {
    navigate(-1);
    setIndividualId(!individualId);
  };

  const renderSearchPopUpSection = () => {
    return (
      <Overlay
        show={isOverlayVisible}
        onClose={() => setOverlayVisible(false)}
        overLayStyles={styles.searchOverLayStyles}
        overLayContentStyles={styles.searchOverLayContentStyles}
      >
        <Input
          name="search"
          placeholder={'Type anything here . . .'}
          // value={values.serach}
          // onChange={values.handleChange}
          // onBlur={values.handleBlur}
          customInputContainerStyles={styles.searchInputContainerStyles}
          customStyle={styles.searchInputStyles}
          customImageContainerStyles={styles.searchImageStyles}
          customRightImageContainerStyles={styles.crossImageStyles}
          image={searchIcon2}
          rightImage={crossIcon}
          onRightImageClick={() => setOverlayVisible(!isOverlayVisible)}
        />
      </Overlay>
    );
  };

  const renderProfilePopSection = () => {
    return (
      <Modal
        show={isOpenProfile}
        popupRef={popupRef}
        handleClickOutSide={handleClickOutsidePopup}
        containerStyle={styles.profilePopStyles}
      >
        <div className={styles.profileContainerStyles}>
          <div className={styles.userProfileBlockStyles}>
            <div className={styles.userProfileSubBlockStyles}>
              <div className={styles.userProfileImgStyles}>
                <img
                  src={profileIcon}
                  alt="addProfileIcon"
                  className={styles.imageWidth}
                />
              </div>
              <div className={styles.userProfileDetailsStyles}>
                <h5 className={styles.userNameStyles}>{user?.full_name}</h5>
                <p className={styles.userEmailStyles}>{user?.email}</p>
              </div>
            </div>
            <Button title={userType} btnStyle={styles.adminBtnStyles} />
          </div>
          {user?.companies.length > 1 && (
            <div className={styles.switchAccWrapperStyle}>
              <div
                className={styles.accountWrapperStyle}
                onClick={() => setSwitchCompany(!swicthCompany)}
              >
                <p className={styles.accTextStyle}>{company?.company_name}</p>
                <div className={styles.downArrowImgStyle}>
                  <img
                    src={downArrowIcon}
                    alt="downArrowIcon"
                    className={styles.imageWidth}
                  />
                </div>
              </div>
              {swicthCompany && (
                <div className={styles.companyListWrapperStyle}>
                  {user?.companies.map((item) => (
                    <div
                      onClick={() => {
                        setCompany(item);
                        localStorage.setItem('company', item._id);
                        setSwitchCompany(!swicthCompany);
                      }}
                      className={classNames(
                        styles.accDataWrapperStyle,
                        item._id === company._id &&
                          styles.selectedAccDataWrapperStyle
                      )}
                    >
                      {item?.company_name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          <div className={styles.logOutBlockStyles}>
            <div className={styles.logOutImgStyles}>
              <img
                src={logoutIcon}
                alt="logoutIcon"
                className={styles.imageWidth}
              />
            </div>
            <p className={styles.logOutTextStyles} onClick={logOutAccount}>
              Log out
            </p>
          </div>
        </div>
      </Modal>
    );
  };

  const renderTabViewHeaderSection = () => {
    return (
      <>
        {/* tab view hamberger menu start */}
        {mobileHeaderClicked ? (
          <>
            <div
              className={styles.backButtonTabImgBlock}
              onClick={handleBackButtonClick}
            >
              <img
                src={backkArrowImg}
                alt="backArrowImg"
                className={styles.imageWidth}
              />
            </div>
          </>
        ) : (
          <>
            <div className={styles.leftHamberIconAndTextInTabStyles}>
              <div
                className={styles.hambergerImgStyles}
                onClick={toggleSidebar}
              >
                <img
                  src={hambergerIcon}
                  alt="hambergerIcon"
                  className={styles.imageWidth}
                />
              </div>

              <div className={styles.dashBoardTextStyles}>
                <p className={styles.headerTitleStyle}>
                  {screenName ? screenName : ''}&nbsp;&nbsp;
                  {individualId && '/'}&nbsp;&nbsp;
                </p>
                <p className={styles.headerSubTitleStyle}>
                  {individualId && '#' + individualId}
                </p>
              </div>
              <div className={styles.leftSideMenuBarStyles}>
                <SideBar
                  toggleSidebar={toggleSidebar}
                  isOpen={navBarOpen}
                  showCloseIcon={true}
                  showSearchBlock={true}
                />
              </div>
            </div>
          </>
        )}
        {/* tab view hamberger menu end */}
      </>
    );
  };

  const renderMobileHeaderSection = () => {
    return (
      <div className={styles.mobileHeaderContainerStyles}>
        {mobileHeaderClicked ? (
          <>{renderMobileHeaderClickedSection()}</>
        ) : individualId && screenName ? (
          <>{renderInvidualAssetAndEmployeeHeaderSection()}</>
        ) : (
          <>{!selectedTicket && renderMobileHambergerMenuSection()}</>
        )}
      </div>
    );
  };

  const renderMobileHeaderClickedSection = () => {
    return (
      <div
        className={styles.backButtonImgBlock}
        onClick={handleBackButtonClick}
      >
        <img
          src={backkArrowImg}
          alt="backArrowImg"
          className={styles.imageWidth}
        />
      </div>
    );
  };

  const renderInvidualAssetAndEmployeeHeaderSection = () => {
    return (
      <div className={styles.invidualAssetAndEmployeeStyles}>
        <div className={styles.individualEmployeeHeaderStyles}>
          <div
            className={styles.backButtonIndividualImgBlock}
            onClick={tapOnIndvidualEmployeeHeader}
          >
            <img
              src={backkArrowImg}
              alt="backArrowImg"
              className={styles.imageWidth}
            />
          </div>
          <p className={styles.individualEmployeeBackTextStyles}>Back</p>
        </div>
        <div className={styles.btnHtml}>{btnHtml && btnHtml}</div>
      </div>
    );
  };

  const renderMobileHambergerMenuSection = () => {
    return (
      <>
        <div className={styles.leftHamberIconAndTextStyles}>
          <div className={styles.hambergerImgStyles} onClick={toggleSidebar}>
            <img
              src={hambergerIcon}
              alt="hambergerIcon"
              className={styles.imageWidth}
            />
          </div>
          <div className={styles.dashBoardTextStyles}>
            <p className={styles.headerTitleStyle}>
              {screenName ? screenName : ''}&nbsp;&nbsp;
              {individualId && '/'}&nbsp;&nbsp;
            </p>
            <p className={styles.headerSubTitleStyle}>
              {individualId && '#' + individualId}
            </p>
          </div>
        </div>

        {screenName === 'Dashboard' && (
          <div
            className={styles.rightSearchIconStyles}
            onClick={() => setOverlayVisible(true)}
          >
            <img
              src={searchIcon2}
              alt="searchIcon"
              className={styles.imageWidth}
            />
          </div>
        )}
        <div className={styles.leftSideMenuBarStyles}>
          <SideBar
            toggleSidebar={toggleSidebar}
            isOpen={navBarOpen}
            showCloseIcon={true}
            showSearchBlock={true}
          />
        </div>
        {renderSearchPopUpSection()}
      </>
    );
  };

  return (
    <div
      className={
        selectedTicket
          ? styles.hideHeaderContainerStyles
          : styles.headerContainerStyle
      }
    >
      {renderSearchPopUpSection()}
      <div className={styles.headerTextViewStyle}>
        <p className={styles.headerTitleStyle}>
          {screenName ? screenName : ''}&nbsp;&nbsp;
          {individualId && '/'}&nbsp;&nbsp;
        </p>
        <p className={styles.headerSubTitleStyle}>
          {individualId && '#' + individualId}
        </p>
      </div>
      {/* tab view hamberger menu start */}
      {renderTabViewHeaderSection()}
      {/* tab view hamberger menu end */}
      <div className={styles.actionContainerStyle}>
        <div
          className={styles.actionImgWrapperStyle}
          onClick={() => setOverlayVisible(true)}
        >
          <img
            src={searchIcon}
            alt="Search here"
            className={styles.actionImgStyle}
          />
        </div>
        <div className={styles.actionImgWrapperStyle}>
          <img
            src={globeIcon}
            alt="Language"
            className={styles.actionImgStyle}
          />
        </div>
        <div className={styles.actionImgWrapperStyle}>
          <img
            src={notificationIcon}
            alt="Notifications"
            className={styles.actionImgStyle}
          />
        </div>
        <div
          className={[
            styles.actionImgWrapperStyle,
            styles.profileImgWrapperStyle,
          ].join(' ')}
          onClick={toggleProfilePopup}
        >
          <img
            src={profileIcon}
            alt="Profile"
            className={styles.actionImgStyle}
          />
          {renderProfilePopSection()}
        </div>
      </div>

      {showOverLay && (
        <div className={styles.overlay} onClick={toggleSidebar}></div>
      )}
      {/* mobile header start */}
      {renderMobileHeaderSection()}
      {/* mobile header end */}
    </div>
  );
};

export default Header;
