import React from 'react';
import styles from './styles.module.css';
import { loaderGif } from 'resources/images/Images';

const LoaderForPages = () => {
  return (
    <div className={styles.containerStyle}>
      <div className={styles.loadingSpinner}></div>
    </div>
  );
};

export default LoaderForPages;
