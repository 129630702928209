import React, { useEffect, useRef, useState } from 'react';
import {
  arrowLineLeftIcon,
  avathar1,
  avathar2,
  avathar3,
  avathar4,
  closeGrayTransperentIcon,
  closeIcon,
  crossIcon,
  dottedMenuIcon,
  downArrowIcon,
  dropDownIcon,
  dropdownGrayIcon,
  editAssetInfoIcon,
  editPencilBlueIcon,
  editPencilGrayIcon,
  filterIcon,
  flagIcon,
  horizontalDottedMenu,
  horizontalDottedMenuGrayIcon,
  leftArrow,
  raceIcon,
  priorityIcon,
  refreshIcon,
  searchIcon,
  searchIcon2,
  selectIcon,
  tickIcon,
  tickMarkBgImg,
  ticketFlagImg,
  ticketSolveImg,
  timerIcon,
  unCheckIcon,
  downArrowGrayIcon,
  downArrowDarkIcon,
  crossWhiteIcon,
  infoWhiteIcon,
  downArrowColorIcon,
} from 'resources/images/Images';
import ButtonTab from 'components/buttontab';
import { useStringData } from 'providers/stringsprovider';
import SearchInput from 'components/searchInput';
import {
  taskActionDifficultyLevelData,
  taskActionPriorityData,
  taskLables,
  ticketFilterData,
  ticketMenuOptions,
  ticketTabs,
  timeLineIcons,
} from 'utils/constants';
import AllTicketsCard from 'components/allticketscard';
import Button from 'components/button';
import Popup from 'components/popup';
import InputWithPopup from 'components/inputWithDropdown';
import TextAreaInput from 'components/textAreaInput';
import { useUserDataContext } from 'hooks/userDataContext';

import {
  closeTicketApi,
  deleteCheckListApi,
  forwardTicketApi,
  getAllTicketsApi,
  getTicketDataApi,
  markAsTicketResolvedApi,
  takeActionToAssignTaskApi,
  updateTaskItemStateApi,
} from 'networking/api/alltickets';
import moment from 'moment/moment';
import { useSpinner } from 'providers/spinnerprovider';
import { DifficultyLevelIcon, WarningIcon } from 'resources/svg/svg';
import SelectProfileCard from 'components/selectProfileCard';
import { useAuthContext } from 'hooks/useAuthContext';
import TicketDetailsCard from 'components/ticketDetailsCard';
import useWindowDimensions from 'hooks/useDimensionHook';
import { useAppDataContext } from 'hooks/useAppDataContext';
import LoaderForPages from 'components/loaderForPages';
import { account_types } from 'utils/constants';
import styles from './styles.module.css';
import classNames from 'classnames';
import ChatInput from 'components/chatInput';
import Members from 'components/members';
import Input from 'components/input';
import MultiSelectInput from 'components/multiSearch';
import Image from 'components/image';
import TicketStatus from 'components/ticketStatus';
import Capsule from 'components/capsule';
import Modal from 'components/modal1';
import Avatar from 'components/avatar';
import { sendTicketMessageApi } from 'networking/api/tickets';
import AvatarGroup from 'components/avatarGroup';

const AllTickets = ({ navigation }) => {
  /*context*/
  const { strings } = useStringData();
  const { otherUsersData, allAdminsDetails } = useUserDataContext();
  const { setIsShowLoading } = useSpinner();
  const { user, userType, company } = useAuthContext();
  const {
    showOverLay,
    navBarOpen,
    isOverlayVisible,
    selectedTicket,
    setSelectedTicket,
  } = useAppDataContext();

  /*References for modals */
  const popupRef = useRef();
  const popUpInnerRef = useRef();
  const popUpNestedRef = useRef();

  /*usedimension hook*/
  const { width } = useWindowDimensions();

  /*states*/

  // loader states
  const [loader, setLoader] = useState(false);
  const [initialLoader, setInitialLoader] = useState(false);

  // Tickets states
  const [ticketsData, setTicketsData] = useState();
  const [filteredTicketsData, setFilteredTicketsData] = useState([]);
  const [ticketsDataForSearch, setTicketsDataForSearch] = useState([]);
  const [selectedTicketData, setSelectedTicketData] = useState();
  const [searchText, setSearchText] = useState();
  const [selectedTab, setSelectedTab] = useState('');
  const [userCanEdit, setUserCanEdit] = useState(false);
  const [adminCanEdit, setAdminCanEdit] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [filterPopUp, setFilterPopup] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('All');

  // Assign To states
  const [showForwardToModal, setShowForwardToModal] = useState(false);
  const [showRegisteredAdminPopup, setShowRegisteredAdminPopup] =
    useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState([]);
  const [addComment, setAddComment] = useState('');
  const [errors, setErrors] = useState();
  const [adminsData, setAdminsData] = useState();
  const [filteredAdmins, setFilteredAdmins] = useState(allAdminsDetails);
  // const [assignTicketData, setAssignTicketData] = useState();
  const [lastForwardedData, setLastForwardedData] = useState(null);
  const [forwardErrorMsg, setForwardErrorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // Task Action states
  const [showTakeActionModal, setShowTakeActionModal] = useState(false);
  // const [showAllDepartments, setShowAllDepartments] = useState(false);
  // const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [openSelectUserPopup, setSelectOpenUserPopup] = useState(false);
  const [ticketSubject, setTicketSubject] = useState(
    selectedTicketData?.subject || ''
  );
  const [ticketNote, setTicketNote] = useState(selectedTicketData?.note || '');
  const [selectDifficultyLevel, setSelectDifficultyLevel] = useState(null);
  const [selectTaskPriority, setSelectTaskPriority] = useState();
  const [taskActionComment, setTaskActionComment] = useState();
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [filterEmployeesData, setFilterEmployeesData] =
    useState(otherUsersData);
  const [taskActionErrors, setTaskActionErrors] = useState({
    departments: '',
    employees: '',
    taskPriority: '',
    difficultyLevel: '',
  });
  const [isMembersShown, setIsMembersShown] = useState(false);
  const [taskData, setTaskData] = useState({
    subject: selectedTicketData?.subject || '',
    note: selectedTicketData?.note || '',
    check_list: selectedTicketData?.check_list || [],
    users: [],
    is_high_priority: selectedTicketData?.is_high_priority || '',
    difficulty: selectedTicketData?.difficulty || '',
  });
  const [selectedLabels, setSelectedLabels] = useState(['Add assignee']);
  const [checkListModal, setCheckListModal] = useState(false);
  const [checkListTitle, setCheckListTitle] = useState('');
  const [showInputIndex, setShowInputIndex] = useState(-1);
  const [clItemTitle, setClItemTitle] = useState('');
  const [clTitleMenu, setClTitleMenu] = useState(false);
  const [clTitleIndex, setClTitleIndex] = useState(-1);
  const [clTitleModal, setClTitleModal] = useState(false);
  const [clDeleteModal, setClDeleteModal] = useState(false);
  const [clModalTitle, setClModalTitle] = useState('');
  const [clItemTitleIndex, setClItemTitleIndex] = useState(-1);
  const [clItemTitleModal, setClItemTitleModal] = useState(false);
  const [clInnerItemTitle, setClInnerItemTitle] = useState('');
  const [showCheckListMenu, setShowCheckListMenu] = useState([]);
  const [showCheckList, setShowCheckList] = useState([]);
  const [labelPopUp, setLabelPopUp] = useState(false);
  const [assignPreview, setAssignPreview] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showTicketStatusPopuUp, setShowTicketStatusPopuUp] = useState(false);
  const [taskActivityModal, setTaskActivityModal] = useState(null);
  const avatars = [
    { src: avathar1, label: 'A' },
    { src: avathar2, label: 'B' },
  ];
  const [message, setMessage] = useState('');
  const [threadId, setThreadId] = useState('');
  const [taskConversations, setTaskConversations] = useState([]);
  const [complaintConversations, setComplaintConversations] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [selectedThreadIndex, setSelectedThreadIndex] = useState();
  const [showTicketMenuOptions, setShowTicketMenuOptions] = useState(false);
  const [ticketForwardTimeline, setTicketForwardTimeline] = useState([]);
  /*useEffects:*/

  useEffect(() => {
    if (selectedTicketData) {
      setShowSuggestion(false);
      setTicketSubject(selectedTicketData.subject);
      setTicketNote(selectedTicketData.note);
      setSelectedTab(
        selectedTicketData.ticket_status !== 'Complaint raised'
          ? 'Task'
          : 'Overview'
      );
      checkEditAccess();
      if (
        selectedTicketData.ticket_status === 'In review' ||
        selectedTicketData.ticket_status === 'In review*'
      ) {
        setShowSuggestion(true);
      }
      getTicketForwardTimeLine();
    }
  }, [selectedTicketData]);

  useEffect(() => {
    if (allAdminsDetails) {
      setFilteredAdmins(allAdminsDetails);
    }
  }, [allAdminsDetails]);

  useEffect(() => {
    // onload call the getAllTickets api
    setSelectedTicketData(null);
    getAllTickets();
  }, []);

  const checkEditAccess = () => {
    if (selectedTicketData) {
      const userPresent = selectedTicketData.ticket_users.find(
        (u) => u.user._id === user._id
      );
      if (userPresent && userPresent.can_edit) {
        setUserCanEdit(true);
        if (
          userPresent.account_type === account_types.admin ||
          userPresent.account_type === account_types.super_admin
        ) {
          setAdminCanEdit(true);
        } else {
          setAdminCanEdit(false);
        }
      } else {
        setUserCanEdit(false);
      }
    }
  };

  useEffect(() => {
    if (selectedTab === 'Task') {
      setConversations(taskConversations);
    } else {
      setConversations(complaintConversations);
    }
  }, [selectedTab, selectedTicketData]);
  // assignTo form validations
  const confirmForwardAction = async () => {
    if (selectedAdmin.length > 0) {
      const selectedAdminIds = selectedAdmin.map((item) => item._id);
      try {
        setIsLoading(true);
        const data = {
          users: selectedAdminIds,
          note: addComment,
          ticket: selectedTicketData?._id,
        };
        const response = await forwardTicketApi(data);
        if (response.data.type === 'success') {
          setSelectedTicketData(response.data.data);
          // setAssignTicketData(response.data.data);
          setShowForwardToModal(false);
          setSelectedAdmin([]);
          setIsLoading(false);
        } else {
          console.log('error', response.data.message);
          setForwardErrorMsg(response.data.message);
          setIsLoading(false);
        }
      } catch (error) {
        console.log('error', error);
        setIsLoading(false);
        setForwardErrorMsg(error.message);
      }
    } else {
      setErrors('Please Select at leaset one admin ');
    }
  };

  useEffect(() => {
    if (selectedFilter === 'All') {
      setFilteredTicketsData(ticketsData);
      setTicketsDataForSearch(ticketsData);
    } else {
      let tData = ticketsData.filter(
        (item) => item.ticket_status === selectedFilter
      );
      setFilteredTicketsData(tData);
      setTicketsDataForSearch(tData);
    }
  }, [selectedFilter, ticketsData]);

  const getTicketForwardTimeLine = () => {
    setLastForwardedData(
      selectedTicketData.activity.filter(
        (item) => item.type === 'Forward' || item.type === 'Raise'
      )
    );
  };

  const handleSearchTickets = (text) => {
    setSearchText(text);
    if (text != '') {
      setFilteredTicketsData(
        ticketsDataForSearch.filter((ticket) =>
          JSON.stringify(ticket).toLowerCase().includes(text.toLowerCase())
        )
      );
    } else {
      setFilteredTicketsData(ticketsDataForSearch);
    }
  };

  /*function to handle ticket selection*/

  // const handleTicketSlection = (item) => {
  //   setSelectedTicket(item);
  // };

  /*function to handle filter admins data*/
  const filterAdminsData = (e) => {
    const searchText = e.target.value.toLowerCase();
    const filteredData = allAdminsDetails.filter((admin) => {
      return JSON.stringify(admin).toLowerCase().includes(searchText);
    });
    setFilteredAdmins(filteredData);
  };

  /*function to handle select and deselect the admins*/

  const handleSelectAdmin = (item) => {
    // const value = selectedAdmin.find((i) => i._id === item._id);
    // if (!value) {
    //   // If the item is not already selected, replace the currently selected item with the new one
    //   setSelectedAdmin([item]);
    //   setErrors('');
    // } else {
    //   setSelectedAdmin([]);
    // }
    const isSelected = selectedAdmin.some((i) => i._id === item._id);
    if (!isSelected) {
      // If the item is not already selected, add it to the selectedAdmin array
      setSelectedAdmin([...selectedAdmin, item]);
    } else {
      // If the item is already selected, remove it from the selectedAdmin array
      const updatedAdmins = selectedAdmin.filter(
        (i) => i.full_name !== item.full_name
      );
      setSelectedAdmin(updatedAdmins);
    }
    setErrors('');
  };

  // function to remove the admins from the multiple selected admins

  const removeAdmin = (index) => {
    const updatedAdminDetails = [...selectedAdmin];
    updatedAdminDetails.splice(index, 1);
    setSelectedAdmin(updatedAdminDetails);
  };

  //  get all tickets api
  const getAllTickets = async () => {
    try {
      setInitialLoader(true);
      if (company) {
        const response = await getAllTicketsApi(company._id);
        if (response.data.type === 'success') {
          console.log('allTickets', response.data.data);
          setInitialLoader(false);
          setTicketsData(response.data.data);
        } else console.log('error', response.data.message);
        setInitialLoader(false);
      }
    } catch (error) {
      setInitialLoader(false);
      console.log('error', error);
    }
  };

  // handle get ticket data
  const getTicketData = async (id) => {
    setTaskConversations([]);
    setComplaintConversations([]);
    setConversations([]);
    try {
      setLoader(true);
      const response = await getTicketDataApi(id);
      if (response.data.type === 'success') {
        console.log('ticketData', response.data.data);
        setSelectedTicketData(response.data.data);
        setLastForwardedData(
          response.data.data.activity
            ? response.data.data.activity.find(
                (item) => item.type === 'Forward'
              )
            : null
        );
        if (response.data.data.conversations.length > 0) {
          setTaskConversations(
            response.data.data.conversations.filter(
              (conv) => conv.type === 'Task'
            )
          );
          setComplaintConversations(
            response.data.data.conversations.filter(
              (conv) => conv.type === 'Complaint'
            )
          );
        }
        setLoader(false);
      } else {
        setLoader(false);
        console.log('error', response.data.message);
      }
    } catch (error) {
      setLoader(false);
      console.log('error', error);
    }
  };

  //  time stamp of ticket data

  const timeStampOfTicket = (date) => {
    var today = moment();
    var yesterday = moment().subtract(1, 'day');
    if (moment(date).isSame(today, 'd')) {
      return 'Today';
    } else if (moment(date).isSame(yesterday, 'd')) {
      return 'Yesterday';
    } else {
      return moment(date).format('MMM DD Y');
    }
  };

  const handleSelectEmployees = (item) => {
    const employeeName = item.full_name;
    setSelectedEmployees((prevSelected) => {
      if (
        prevSelected.some((selected) => selected.full_name === employeeName)
      ) {
        return prevSelected;
      } else {
        return [...prevSelected, item];
      }
    });
    setTaskActionErrors((errors) => ({ ...errors, employees: '' }));
  };

  // function to remove the employees from the multiple selected employees

  const removeEmployees = (index) => {
    const updatedEmployeeDetails = [...selectedEmployees];
    updatedEmployeeDetails.splice(index, 1);
    setSelectedEmployees(updatedEmployeeDetails);
  };

  // function to handle filter the employee data
  const handleFilterEmployeeData = (e) => {
    const searchText = e.target.value.toLowerCase();
    const filteredData = allAdminsDetails.filter((admin) => {
      return JSON.stringify(admin).toLowerCase().includes(searchText);
    });
    setFilteredAdmins(filteredData);
  };

  // function to handle task priority selections

  const handleTaskPrioritySelection = (item) => {
    setSelectTaskPriority((prevSelected) => {
      if (prevSelected === item) {
        return null;
      } else {
        return item;
      }
    });
    setTaskActionErrors((errors) => ({ ...errors, taskPriority: '' }));
  };

  //function to handle difficulty level

  const handleSelectDifficultyLevel = (item) => {
    setSelectDifficultyLevel((prevSelected) => {
      if (prevSelected === item) {
        return null;
      } else {
        return item;
      }
    });
    setTaskActionErrors((errors) => ({ ...errors, difficultyLevel: '' }));
  };

  // function to handle Validations in the TaskAction Modal

  const taskActionModalValidation = () => {
    const errors = {
      subject: !ticketSubject && 'Please enter subject',
      note: !ticketNote && 'Please enter subject',
      employees:
        selectedEmployees.length <= 0 && 'Please select at least one employee',
    };

    setTaskActionErrors(errors);
    if (Object.values(errors).every((error) => !error)) {
      // call task action api call here after check all validations
      setAssignPreview(true);
      // handleTakeActionToAllocateTicket();
    }
  };

  // resetTaskAction ModalData

  const resetTaskActionData = () => {
    setSelectedEmployees([]);
    setSelectTaskPriority('');
    setTaskActionComment('');
    setSelectOpenUserPopup(false);
  };

  // function to handle Take Action  apicall
  const handleTakeActionToAllocateTicket = async () => {
    try {
      setLoader(true);
      const selectedEmployeeId = selectedEmployees.map((item) => item.value);
      const data = {
        ticket: selectedTicketData._id,
        subject: ticketSubject,
        note: ticketNote,
        users: selectedEmployeeId,
        is_high_priority:
          selectTaskPriority?.priorityOption === 'Yes' ? true : false,
        check_list: taskData.check_list,
      };
      if (selectDifficultyLevel) {
        data.difficulty = selectDifficultyLevel.titleText;
      }
      const response = await takeActionToAssignTaskApi(data);
      if (response.data.type === 'success') {
        setLoader(false);
        setSelectedTicket(response.data.data);
        setSelectedTicketData(response.data.data);
        let xxx = [...ticketsData];
        let ticketIndex = xxx.findIndex(
          (data) => data._id === selectedTicketData?._id
        );
        xxx[ticketIndex].ticket_status = 'Assigned';
        xxx[ticketIndex].last_activity = response.data.data.activity[0];
        setTicketsData(xxx);
        resetTaskActionData();
        setShowTakeActionModal(false);
      } else {
        console.log('error', response.data.message);
      }
    } catch (error) {
      setLoader(false);
      console.log('error', error);
    }
  };

  const checkEditAction = () => {
    let is_assigned = selectedTicketData?.ticket_status !== 'Complaint raised';
    let is_visible = false;
    let data = selectedTicketData?.ticket_users.find(
      (item) => item.user._id === user._id
    );
    if (data?.can_edit === true) {
      is_visible = true;
    } else {
      is_visible = false;
    }
    return (
      !is_assigned && (is_visible || userType === account_types.super_admin)
    );
  };

  // handle Admin marked ticket resolved api
  const handleAdminMarkedTicketResolved = async () => {
    try {
      setLoader(true);
      const data = {
        ticket: selectedTicketData._id,
      };
      const response = await markAsTicketResolvedApi(data);
      if (response.data.type === 'success') {
        setSelectedTicketData({
          ...selectedTicketData,
          activity: [response.data.data, ...selectedTicketData?.activity],
        });
        setTicketsData(
          [...ticketsData].map((ticket) => {
            if (ticket?._id === response?.data?.data?.ticket_id) {
              return { ...ticket, ticket_status: 'Closed' };
            } else {
              return ticket;
            }
          })
        );
        setLoader(false);
      } else {
        console.log('error', response.data.message);
      }
    } catch (error) {
      console.log('error', error);
      setLoader(false);
    }
  };

  // handle Admin marked ticket resolved api
  const handleCloseTicketCantSolve = async () => {
    try {
      setLoader(true);
      const data = {
        ticket: selectedTicketData._id,
      };
      const response = await closeTicketApi(data);
      if (response.data.type === 'success') {
        setSelectedTicketData({
          ...selectedTicketData,
          activity: [response.data.data, ...selectedTicketData?.activity],
        });
        setTicketsData(
          [...ticketsData].map((ticket) => {
            if (ticket?._id === response?.data?.data?.ticket_id) {
              return { ...ticket, ticket_status: 'Closed' };
            } else {
              return ticket;
            }
          })
        );
        setLoader(false);
      } else {
        console.log('error', response.data.message);
      }
    } catch (error) {
      console.log('error', error);
      setLoader(false);
    }
  };

  const closeTicketStatusPopUp = (event) => {
    if (showTicketStatusPopuUp) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowTicketStatusPopuUp(false);
      }
    }
  };

  const closerMembersModal = (event) => {
    if (isMembersShown) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsMembersShown(false);
      }
    }
  };

  const closeTicketMenuOptions = (event) => {
    if (showTicketMenuOptions) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowTicketMenuOptions(false);
      }
    }
  };

  /* send message Method */
  const sendMessage = async () => {
    if (message) {
      try {
        let data = {
          type: selectedTab === 'Task' ? 'Task' : 'Complaint',
          ticket: selectedTicketData?._id,
          comment: message,
          thread_id: threadId,
        };
        let sendMessageResponse = await sendTicketMessageApi(data);
        if (sendMessageResponse.data.type === 'success') {
          console.log('msgResponse', sendMessageResponse.data.data);
          setConversations([sendMessageResponse.data.data]);
          if (sendMessageResponse.data.data.type === 'Task') {
            setTaskConversations([sendMessageResponse.data.data]);
          } else {
            setComplaintConversations([sendMessageResponse.data.data]);
          }
          setMessage('');
        } else {
          console.log(
            'send Message api failed',
            sendMessageResponse.data.message
          );
        }
      } catch (error) {
        console.log('send Message api catch', error);
      }
    }
  };

  const getBgClolor = (type) => {
    if (type === 'Raise' || type === "Can't solve") {
      return styles.pinkBgStyle;
    } else if (type === 'Forward') {
      return styles.grayBgStyle;
    } else if (type === 'Assign') {
      return styles.primaryBgStyle;
    } else if (type === 'Working' || type === 'Revert') {
      return styles.greenBgStyle;
    } else if (type === 'Completed') {
      return styles.orangeBgStyle;
    } else if (type === 'Resolved') {
      return styles.lightGrayBgStyle;
    } else {
      return styles.primaryBgStyle;
    }
  };

  const taskProgressBgColor = (type) => {
    if (type === 'Assigned') {
      return styles.primaryBgStyle;
    } else if (type === 'In progress') {
      return styles.greenBgStyle;
    } else if (type === 'In review') {
      return styles.orangeBgStyle;
    } else if (type === 'In review*') {
      return styles.redBgStyle;
    } else if (type === 'Closed') {
      return styles.lightGrayBgStyle;
    } else {
      return styles.primaryBgStyle;
    }
  };

  const getTimeLineString = (activity) => {
    if (activity.type === 'Raise') {
      return `Complaint raised by ${
        activity.action_by
          ? activity.action_by.full_name
          : activity.action_by_guest
      }`;
    } else if (activity.type === 'Forward') {
      return `${activity.action_by.full_name} forwarded to ${activity.users[0]?.full_name}`;
    } else if (activity.type === 'Assign') {
      return `${activity.action_by.full_name} assigned to ${activity.users[0]?.full_name}`;
    } else if (activity.type === 'Working') {
      return `${activity.action_by.full_name} changed the task status to working`;
    } else if (activity.type === 'Revert') {
      return `${activity.action_by.full_name} changed the status to Revert to working`;
    } else if (activity.type === 'Completed') {
      return `${activity.action_by.full_name} changed the status to Complete`;
    } else if (activity.type === 'Resolved') {
      return `${activity.action_by.full_name} closed the ticket permanently`;
    } else if (activity.type === "Can't solve") {
      return `${activity.action_by.full_name} changed the task status to can't solve`;
    } else {
      return '';
    }
  };

  const updateTaskInnerState = async (task, taskItem, checked) => {
    try {
      if (task && taskItem && userCanEdit) {
        let data = {
          ticket: selectedTicketData._id,
          task: task._id,
          inner_task: taskItem._id,
          checked,
        };
        let response = await updateTaskItemStateApi(data);
        if (response.data.type === 'success') {
          let tasksData = selectedTicketData.tasks;
          let taskIndex = tasksData.findIndex((item) => item._id === task._id);
          if (taskIndex !== -1) {
            tasksData[taskIndex] = response.data.data;
            setSelectedTicketData({
              ...selectedTicketData,
              tasks: tasksData,
            });
          }
        } else {
          console.log('error', response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleCheckList = (id) => {
    if (showCheckList.includes(id)) {
      setShowCheckList((prev) => prev.filter((a) => a !== id));
    } else {
      setShowCheckList((prev) => [...prev, id]);
    }
    setShowCheckListMenu([]);
  };

  const deleteCheckList = async (id) => {
    try {
      if (adminCanEdit) {
        let response = await deleteCheckListApi(id);
        if (response.data.type === 'success') {
          setSelectedTicketData({
            ...selectedTicketData,
            tasks: [...selectedTicketData.tasks].filter(
              (task) => task._id !== id
            ),
          });
        } else {
          console.log('error', response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTicketSubject = (data) => {
    let msg = '';
    if (data.ticket_status === 'Complaint raised') {
      msg = `${
        data.created_by
          ? data.created_by.full_name +
            (data.created_by._id === user?._id ? ' (You)' : '')
          : data.created_by_guest.full_name
      } has raised a complaint with subject “${data.subject}”`;
    } else if (data.ticket_status === 'Assigned') {
      msg = `${
        data.last_activity.action_by.full_name +
        (data.last_activity.action_by._id === user?._id ? ' (You)' : '')
      } assigned the ticket to ${
        data.last_activity.users.length > 0 &&
        data.last_activity.users[0].full_name
      }`;
    } else {
      msg = `${
        data.last_activity.action_by.full_name +
        (data.last_activity.action_by._id === user?._id ? ' (You)' : '')
      } changed the task status to "${data.last_activity.type}"`;
    }
    return msg;
  };

  const handleTicketStatusModal = () => {
    let ticketStatus = selectedTicketData?.ticket_status;
    if (userType === account_types.super_admin) {
      setShowTicketStatusPopuUp(false);
    } else if (userType === account_types.admin) {
      setShowTicketStatusPopuUp(true);
    } else if (userType === account_types.employee) {
      if (ticketStatus === 'Assigned' || ticketStatus === 'In progress') {
        setShowTicketStatusPopuUp(true);
      }
    }
  };

  const handleTicketMenuOptions = (data) => {
    console.log(data);
    setShowTicketMenuOptions(false);
  };

  const renderAllTicketsLeftSection = () => {
    return (
      <div className={styles.allTicketsLeftContainerStyles}>
        <div
          className={
            (showOverLay && navBarOpen) || isOverlayVisible
              ? styles.alleTicketsLeftHideStyles
              : styles.allTicketsLeftSubContainerStyles
          }
        >
          {renderTopSearchAndFilterSection()}
          {renderBottomTicketHeaderSection()}
        </div>
        <div className={styles.bottomAllTicketsBlockStyles}>
          {renderAllTicketsDetailsSection()}
        </div>
      </div>
    );
  };

  const renderTopSearchAndFilterSection = () => {
    return (
      <div className={styles.topSearchAndFilterBlockStyles}>
        <SearchInput
          placeholder={'Search tickets'}
          onChange={(e) => handleSearchTickets(e.target.value)}
          value={searchText}
          leftIcon={searchIcon}
          customLeftIconViewStyle={styles.searchIconStyles}
          customInputContainerStyles={styles.searchInputContainerStyles}
          customInputViewStyle={styles.searchInputViewStyles}
          customInputStyle={styles.searchInputStyles}
        />
        <ButtonTab
          icon={filterIcon}
          custButtonTabContainerStyle={styles.filterBtnStyle}
        />
      </div>
    );
  };

  const renderBottomTicketHeaderSection = () => {
    return (
      <div className={styles.bottomTicketsHeaderStyles}>
        <p className={styles.ticketsHeadingStyles}>Tickets</p>
        <div
          className={styles.ticketSelectOptionBlockStyles}
          onClick={() => setFilterPopup(true)}
        >
          <p className={styles.allTextStyles}>{selectedFilter}</p>
          <div className={styles.downArrowImgStyles}>
            <img
              src={downArrowIcon}
              alt="downArrowIcon"
              className={styles.imageStyles}
            />
          </div>
          <Modal
            show={filterPopUp}
            popupRef={popupRef}
            containerStyle={styles.filterModalStyle}
            handleClickOutSide={(event) => {
              if (filterPopUp) {
                if (
                  popupRef.current &&
                  !popupRef.current.contains(event.target)
                ) {
                  setFilterPopup(false);
                }
              }
            }}
          >
            {ticketFilterData.map((item, index) => (
              <div key={'label' + index} className={styles.filterWrapperStyle}>
                <input
                  type="checkbox"
                  className={classNames(styles.customCheckBoxStyle)}
                  checked={selectedFilter === item}
                  onChange={(e) => {
                    setSelectedFilter(item);
                    setFilterPopup(false);
                  }}
                />
                <span className={styles.filterTextStyle}>{item}</span>
              </div>
            ))}
          </Modal>
        </div>
      </div>
    );
  };

  const renderAllTicketsDetailsSection = () => {
    // const filteredTickets = filterTicket();
    return (
      <div className={styles.allTicketsCardContainerStyles}>
        {filteredTicketsData?.length > 0 ? (
          filteredTicketsData?.map((item, index) => {
            return (
              <AllTicketsCard
                key={index}
                leftImage={flagIcon}
                TicketSerialNo={`#${item.ticket_uid}`}
                ticketStatusTitle={item.ticket_status}
                ticketProductTitle={item?.asset?.asset_name}
                ticketSubject={handleTicketSubject(item)}
                onClick={() => {
                  getTicketData(item._id);
                  setMessage('');
                  setThreadId('');
                  setSelectedThreadIndex('');
                }}
                selected={selectedTicketData?._id === item._id}
                priority={item.is_high_priority}
              />
            );
          })
        ) : (
          // : width < 500 && (
          <p className={styles.noTicketsTitleStyles}>No Tickets Found</p>
        )}
      </div>
    );
  };

  const renderAllTicketsRightSection = () => {
    return (
      <div className={styles.allTicketsRightContainerStyles}>
        {loader ? (
          <LoaderForPages />
        ) : (
          <React.Fragment>
            {renderAllTicketsRightTopHeaderSection()}
            {renderTicketActivitiesContainer()}
          </React.Fragment>
        )}
      </div>
    );
  };

  const renderAllTicketsRightTopHeaderSection = () => {
    return (
      <React.Fragment>
        <div className={styles.rightTopHeaderContainerStyles}>
          {selectedTicketData && (
            <div className={styles.leftTicketArrowAndDescBlockStyles}>
              <div
                className={styles.leftArrowImgStyles}
                onClick={() => setSelectedTicketData(null)}
              >
                <img
                  src={leftArrow}
                  alt="leftArrow"
                  className={styles.imageStyles}
                />
              </div>
              <div className={styles.LeftTicketDescBlockStyles}>
                <div className={styles.ticketTitleViewStyle}>
                  <h5 className={styles.tickeTitleStyles}>
                    {`# ${selectedTicketData?.ticket_uid}`}
                  </h5>
                  {selectedTicketData.is_high_priority && (
                    <Image
                      url={priorityIcon}
                      alt={'priority'}
                      imgContainerStyle={styles.priorityViewStyle}
                    />
                  )}
                </div>
                {/* ticcket Status view */}
                <div
                  onClick={() => {
                    handleTicketStatusModal();
                  }}
                  className={styles.ticcketStatusViewStyle}
                >
                  <p className={styles.ticcketStatusTextStyle}>
                    {selectedTicketData
                      ? selectedTicketData?.ticket_status
                      : ''}
                  </p>
                  <div className={styles.downArrowStyles}>
                    <img src={dropDownIcon} className={styles.imageStyle} />
                  </div>
                  {renderTicketStatusUpdatePopup()}
                </div>
              </div>
            </div>
          )}
          <div className={styles.rightTicketImgesBlockStyles}>
            {/* memberscount */}
            <AvatarGroup
              data={avatars}
              onClick={() => setIsMembersShown(true)}
            />
            <div
              onClick={() => setShowTicketMenuOptions(true)}
              className={styles.rightImgBlockStyles}
            >
              <img
                src={dottedMenuIcon}
                alt="menuIcon"
                className={styles.imageStyles}
              />
            </div>
            {renderMembersModal()}
            {renderTicketMenuOptions()}
          </div>
        </div>
        {showSuggestion && (
          <div className={styles.suggestionWrapperStyle}>
            <div className={styles.suggestionLeftWrapperStyle}>
              <Image
                url={infoWhiteIcon}
                imgContainerStyle={styles.suggestionImgWrapperStyle}
              />
              <p className={styles.suggestionTitleStyle}>
                Review the ticket completion and change the status
              </p>
            </div>
            <Image
              url={crossWhiteIcon}
              imgContainerStyle={styles.suggestionImgWrapperStyle}
              onClick={() => setShowSuggestion(false)}
            />
          </div>
        )}
      </React.Fragment>
    );
  };

  const renderTicketStatusUpdatePopup = () => {
    return (
      <Modal
        show={showTicketStatusPopuUp}
        showOverlay={true}
        popupRef={popupRef}
        containerStyle={styles.ticketStatusPopUpViewStyle}
        handleClickOutSide={closeTicketStatusPopUp}
      >
        <TicketStatus
          ticketData={selectedTicketData}
          allTickets={ticketsData}
          setTicketData={setSelectedTicketData}
          setAllTicketsData={setTicketsData}
          setClosePopUp={setShowTicketStatusPopuUp}
        />
      </Modal>
    );
  };

  const renderMembersModal = () => {
    return (
      <Modal
        show={isMembersShown}
        showOverlay={true}
        popupRef={popupRef}
        containerStyle={styles.membersModalStyles}
        handleClickOutSide={closerMembersModal}
      >
        <Members
          ticket={selectedTicketData}
          setTicketUpdatedData={setSelectedTicketData}
          closeAction={() => setIsMembersShown(false)}
        />
      </Modal>
    );
  };

  const renderTicketMenuOptions = () => {
    return (
      <Modal
        show={showTicketMenuOptions}
        showOverlay={true}
        popupRef={popupRef}
        containerStyle={styles.ticketMenuOptionsModalStyle}
        handleClickOutSide={closeTicketMenuOptions}
      >
        <div className={styles.ticketMenuOptinsViewStyle}>
          {ticketMenuOptions.map((item, index) => (
            <div
              key={index}
              onClick={() => handleTicketMenuOptions(item.title)}
              className={styles.eachItemStyle}
            >
              <div className={styles.iconStyle}>
                <img src={item.icon} alt="img" className={styles.imgStyle} />
              </div>
              <p className={styles.ticketMenuOptinsTextStyle}>{item.title}</p>
            </div>
          ))}
        </div>
      </Modal>
    );
  };

  const renderTicketActivitiesContainer = () => {
    return (
      <div className={styles.rightBottomContainerStyles}>
        <div
          className={
            showOverLay || isOverlayVisible
              ? styles.hideRightTicketMainStyles
              : styles.rightTicketMainContainerStyles
          }
        >
          {renderTabs()}
          {selectedTab === 'Task' && renderTask()}
          {selectedTab === 'Overview' && renderRaiseTicketDetailsSection()}
          {selectedTab === 'Timeline' && renderTimeLine()}
          {selectedTab !== 'Timeline' && renderConversationsContainer()}
        </div>
      </div>
    );
  };

  const renderRaiseTicketDetailsSection = () => {
    return (
      <div className={styles.raiseTicketDetailsBlockStyles}>
        <div className={styles.ticketRaiseByViewStyles}>
          <div
            className={classNames(
              selectedTicketData?.ticket_status === 'Complaint raised'
                ? styles.complaintRaisedStyle
                : selectedTicketData?.ticket_status === 'Assigned' &&
                    styles.assignedStatusStyle,
              styles.ticketRaiseIconViewStyle
            )}
          >
            <img
              src={flagIcon}
              alt={'ticketIcon'}
              className={styles.imageStyle}
            />
          </div>
          <div className={styles.ticketRaiseByUserStyles}>
            <p className={styles.ticketRaiseTextStyles}>{`Complaint raised by ${
              selectedTicketData?.created_by
                ? selectedTicketData?.created_by.full_name
                : selectedTicketData?.created_by_guest.full_name
            }`}</p>
            <p className={styles.ticketRaiseDateTextStyles}>
              {`${moment(selectedTicketData?.created_at).format(
                'hh:mm a'
              )}, ${timeStampOfTicket(selectedTicketData?.created_at)}`}
            </p>
          </div>
        </div>
        <div className={styles.tickeViewStyles}>
          <div className={styles.curvedViewStyle}></div>
          <TicketDetailsCard
            assetId={`#${selectedTicketData?.asset?.asset_uid}`}
            assetName={selectedTicketData?.asset?.asset_name}
            ticketProductImg={
              selectedTicketData?.image
                ? selectedTicketData?.image
                : ticketSolveImg
            }
            ticketStatus={selectedTicketData?.ticket_status}
            subject={selectedTicketData?.subject}
            note={selectedTicketData?.note}
            forwardedData={lastForwardedData}
            byOrFrom={'by'}
            taskActionButtons={checkEditAction()}
            taskActionBtnTitleOne={'Take action'}
            taskActionBtnTitleTwo={'Forward to'}
            taskActionBtnTitleThree={'Archive'}
            taskActionBtnOneClick={() => setShowTakeActionModal(true)}
            taskActionBtnTwoClick={() => {
              setShowForwardToModal(true);
            }}
            taskActionBtnThreeClick={() => console.log('cccs')}
          />
          {renderTakeActionSection()}
        </div>

        {renderForwardToSection()}
      </div>
    );
  };

  const renderForwardToSection = () => {
    return (
      <Popup
        show={showForwardToModal}
        showOverlay={true}
        popupRef={popupRef}
        customPopUpStyles={styles.forwardToModalStyles}
      >
        {renderForwardToModalContentSection()}
      </Popup>
    );
  };

  const renderForwardToModalContentSection = () => {
    return (
      <React.Fragment>
        {renderTopForwardToHeaderSection()}
        {renderForwardToInputsAndButtonsSections()}
      </React.Fragment>
    );
  };

  const renderTopForwardToHeaderSection = () => {
    return (
      <div className={styles.topForwardToHeaderViewStyles}>
        <p className={styles.forwardToTextStyles}>Forward to</p>
        <div
          className={styles.crossImgStyles}
          onClick={() => {
            setShowForwardToModal(false);
            setErrors('');
            setSelectedAdmin([]);
            setShowRegisteredAdminPopup(false);
          }}
        >
          <img src={crossIcon} alt="crossIcon" className={styles.imageStyles} />
        </div>
      </div>
    );
  };

  const renderForwardToInputsAndButtonsSections = () => {
    return (
      <div className={styles.forwardToInputsAndBtnBlockStyles}>
        {renderForwardToMiddleSection()}
        {renderForwardToBottomSection()}
      </div>
    );
  };

  const renderForwardToMiddleSection = () => {
    return (
      <div className={styles.forwardToMiddleViewStyles}>
        <p className={styles.adminInfoTextStyles}>
          Have no time to pay attention to this ticket or it doesn't belong to
          your department? Don't worry, as a admin, you can assign the ticket to
          different admin(s).
        </p>
        <InputWithPopup
          lableText="Forward to*"
          adminDetails={selectedAdmin}
          placeholder={'Select registered admin(s)'}
          rightIcon={dropdownGrayIcon}
          popupOpen={showRegisteredAdminPopup}
          customSubWrapperStyles={styles.forwardToInputStyles}
          customPopUpContainerStyles={styles.forwardToPopupContainerStyles}
          customInputAndPopupBlockStyles={styles.forwardToInputAndPopupStyles}
          onClick={() => {
            setShowRegisteredAdminPopup(!showRegisteredAdminPopup);
          }}
          removeSelectedAdmin={removeAdmin}
          errorMsg={errors}
        >
          {renderSelectRegisterAdminsSection(selectedAdmin)}
        </InputWithPopup>

        <TextAreaInput
          lableText="Add comment (Optional)"
          placeholder="Write your message here"
          lableTextStyle={styles.addCommentLabelStyles}
          customTextAreaStyles={styles.addCommentTextAreaStyles}
          onChange={(e) => setAddComment(e.target.value)}
        ></TextAreaInput>
      </div>
    );
  };

  const renderSelectRegisterAdminsSection = (selectedAdmin) => {
    return (
      <div className={styles.selectRegisterAdminViewStyles}>
        <div className={styles.searchInputSectionStyle}>
          <SearchInput
            placeholder={
              userType !== account_types.employee
                ? 'Search admins...'
                : 'Search employees...'
            }
            onChange={(e) => filterAdminsData(e)}
            onRightIconClick={() => setShowRegisteredAdminPopup(false)}
            leftIcon={searchIcon2}
            rightIcon={closeIcon}
            customInputViewStyle={styles.customSearchInputViewStyle}
            customInputStyle={styles.customSearchInputStyle}
            customLeftIconViewStyle={styles.customSearchInputLeftIconViewStyle}
          />
        </div>
        <div className={styles.adminDetailsViewStyles}>
          {otherUsersData.length > 0 &&
            otherUsersData.map((item, index) => {
              return (
                <SelectProfileCard
                  key={index}
                  image={avathar1}
                  profileName={item.full_name}
                  profileDesignation={item.employee_type}
                  onSelectProfile={() => {
                    handleSelectAdmin(item);
                    setShowRegisteredAdminPopup(false);
                  }}
                  isSelected={selectedAdmin.includes(item)}
                />
              );
            })}
        </div>
      </div>
    );
  };

  const renderForwardToBottomSection = () => {
    return (
      <div className={styles.forwarddActionBtnsViewStyle}>
        <p className={styles.errorTextStyle}>{forwardErrorMsg}</p>
        <div className={styles.bottomCancelAndConfirmBtnStyles}>
          <Button
            title="Cancel"
            btnStyle={styles.cancelBtnStyles}
            onClick={() => {
              setShowForwardToModal(false);
              setErrors('');
              setSelectedAdmin([]);
              setShowRegisteredAdminPopup(false);
            }}
          />
          <Button
            title={isLoading ? 'Loading....' : 'Confirm'}
            btnStyle={
              selectedAdmin.length > 0
                ? styles.disabledConfirmBtnStyles
                : styles.confirmBtnStyles
            }
            onClick={confirmForwardAction}
          />
        </div>
      </div>
    );
  };

  const renderTakeActionSection = () => {
    return (
      <Modal
        show={showTakeActionModal}
        showOverlay={true}
        popupRef={popupRef}
        containerStyle={styles.taskActionModalStyles}
        handleClickOutside={() => setShowTakeActionModal(false)}
      >
        {renderTaskActionModalContentSection()}
      </Modal>
    );
  };

  const renderTaskActionModalContentSection = () => {
    return (
      <div className={styles.taskActionModalContainerStyles}>
        {assignPreview && renderPreviewSection()}
        {!assignPreview && (
          <>
            <div className={styles.topTaskActionHeaderSectionStyles}>
              <p className={styles.takeActionTextStyles}>Take action</p>
              {width > 480 && renedrTopTaskActionHeaderSection()}
            </div>
            <div className={styles.taskActionInputsAndBtnsBlockStyles}>
              <div className={styles.takeActionMiddleWrapperStyles}>
                <div className={styles.taskInfoWrapperStyles}>
                  <div className={styles.taskLeftWrapperStyle}>
                    <p className={styles.taskLeftTitleStyles}>Task info*</p>
                  </div>
                  <div className={styles.taskRightWrapperStyles}>
                    <div className={styles.taskAssetStyles}>
                      <span className={styles.taskAssetTitleStyles}>
                        {`#${selectedTicketData?.ticket_uid}: ${selectedTicketData?.asset?.asset_name}`}
                      </span>
                      <div className={styles.taskAssetImgWrapperStyles}>
                        <img
                          src={selectedTicketData?.asset?.image}
                          alt={selectedTicketData?.asset?.asset_name}
                          className={styles.taskAssetImgStyles}
                        />
                      </div>
                    </div>
                    <Input
                      lableText="Task subject*"
                      name="subject"
                      value={ticketSubject}
                      onChange={(e) => setTicketSubject(e.target.value)}
                      lableTextStyle={styles.taskLabelStyles}
                    />
                    <TextAreaInput
                      lableText="Description or Note*"
                      name="note"
                      value={ticketNote}
                      onChange={(e) => setTicketNote(e.target.value)}
                      lableTextStyle={styles.taskLabelStyles}
                      customTextAreaStyles={styles.customTextAreaViewStyle}
                    />
                  </div>
                </div>
                <div className={styles.taskCLWrapperStyle}>
                  <div className={styles.taskLeftWrapperStyle}>
                    <p className={styles.taskLeftTitleStyles}>Checklist</p>
                    <Button
                      type="button"
                      title="Add checklist"
                      onClick={() => {
                        setCheckListModal(true);
                      }}
                      image={selectIcon}
                      color="light"
                      btnStyle={styles.taskLeftBtnStyle}
                    />
                    {renderCheckListModal()}
                  </div>
                  <div className={styles.taskCLRightWraperStyle}>
                    {taskData.check_list.length == 0 && (
                      <div className={styles.emptyCheckListStyle}>
                        Checklists are optional but great if you have a to-do
                        list that the person responsible for the ticket has to
                        run through while resolving the issue.{' '}
                        <span>Show more</span>
                      </div>
                    )}
                    {taskData.check_list.map((cl, cl_index) => (
                      <div
                        key={'cl' + cl_index}
                        className={styles.taskCLContentWrapperStyle}
                        onClick={() => {
                          setShowInputIndex(cl_index);
                          setClItemTitle('');
                        }}
                      >
                        <div className={styles.taskCLTitleWrapperStyle}>
                          <p className={styles.taskCLTitleStyle}>{cl?.title}</p>
                          <div
                            className={styles.taskCLActionImgWrapperStyle}
                            onClick={() => {
                              setClTitleIndex(cl_index);
                            }}
                          >
                            <img
                              src={horizontalDottedMenu}
                              alt=""
                              className={styles.taskCLActionImgStyle}
                            />
                            <Modal
                              show={clTitleIndex === cl_index}
                              showOverlay
                              containerStyle={styles.taskChecklistModalStyle}
                              overlayStyle={styles.customOverlayStyle}
                              popupRef={popUpInnerRef}
                              handleClickOutSide={(event) => {
                                if (clTitleIndex === cl_index) {
                                  if (
                                    popUpInnerRef.current &&
                                    !popUpInnerRef.current.contains(
                                      event.target
                                    )
                                  ) {
                                    setClTitleIndex(-1);
                                  }
                                }
                              }}
                            >
                              <div className={styles.menuWrapperStyle}>
                                <div
                                  className={styles.menuItemStyle}
                                  onClick={() => {
                                    setClTitleModal(true);
                                    setClModalTitle(cl.title);
                                  }}
                                >
                                  Edit title
                                  <Modal
                                    show={clTitleModal}
                                    showOverlay
                                    containerStyle={
                                      styles.checkListTitleModalStyle
                                    }
                                    overlayStyle={styles.customOverlayStyle}
                                    popupRef={popUpNestedRef}
                                    handleClickOutSide={(event) => {
                                      if (clTitleModal) {
                                        if (
                                          popUpNestedRef.current &&
                                          !popUpNestedRef.current.contains(
                                            event.target
                                          )
                                        ) {
                                          setClTitleModal(false);
                                        }
                                      }
                                    }}
                                  >
                                    <Input
                                      lableText="Edit checklist title*"
                                      value={clModalTitle}
                                      onChange={(e) => {
                                        setClModalTitle(e.target.value);
                                      }}
                                    />
                                    <div
                                      className={
                                        styles.checkListModalBtnWrapperStyle
                                      }
                                    >
                                      <Button
                                        title="Cancel"
                                        color="light"
                                        onClick={() => setClTitleModal(false)}
                                        btnStyle={styles.checkListModalBtnStyle}
                                      />
                                      <Button
                                        title="Save"
                                        color="primary"
                                        btnStyle={styles.checkListModalBtnStyle}
                                        onClick={() => {
                                          setClTitleModal(false);
                                          let task = taskData;
                                          task.check_list[clTitleIndex].title =
                                            clModalTitle;
                                          setTaskData(task);
                                          setClTitleIndex(-1);
                                          setClModalTitle('');
                                        }}
                                      />
                                    </div>
                                  </Modal>
                                </div>
                                <div
                                  className={styles.menuItemStyle}
                                  onClick={() => setClDeleteModal(true)}
                                >
                                  Delete checklist
                                  <Modal
                                    show={clDeleteModal}
                                    showOverlay
                                    containerStyle={
                                      styles.checkListTitleModalStyle
                                    }
                                    overlayStyle={styles.customOverlayStyle}
                                    popupRef={popUpNestedRef}
                                    handleClickOutSide={(event) => {
                                      if (clDeleteModal) {
                                        if (
                                          popUpNestedRef.current &&
                                          !popUpNestedRef.current.contains(
                                            event.target
                                          )
                                        ) {
                                          setClDeleteModal(false);
                                        }
                                      }
                                    }}
                                  >
                                    <p className={styles.clModalDescStyle}>
                                      {`Are you sure you want to delete the
                                      "${cl.title}" permanently?`}{' '}
                                      <span
                                        className={styles.clModalDesc1Style}
                                      >
                                        (Deleting checklist will also delete the
                                        items within the checklist)
                                      </span>
                                    </p>
                                    <div
                                      className={
                                        styles.checkListModalBtnWrapperStyle
                                      }
                                    >
                                      <Button
                                        title="Cancel"
                                        color="light"
                                        onClick={() => setClDeleteModal(false)}
                                        btnStyle={styles.checkListModalBtnStyle}
                                      />
                                      <Button
                                        title="Yes"
                                        btnStyle={styles.dangerBtnStyle}
                                        onClick={() => {
                                          let task = taskData;
                                          task.check_list.splice(
                                            clTitleIndex,
                                            1
                                          );
                                          setTaskData(task);
                                          setClTitleIndex(-1);
                                          setClDeleteModal(false);
                                        }}
                                      />
                                    </div>
                                  </Modal>
                                </div>
                              </div>
                            </Modal>
                          </div>
                        </div>
                        <div className={styles.taskCLItemsWrapperStyle}>
                          {cl &&
                            cl.items?.map((cli, cli_index) => (
                              <div
                                key={'cli' + cli_index}
                                className={styles.taskCLItemWrapperStyle}
                              >
                                <ul className={styles.taskCLItemUlStyle}>
                                  <li className={styles.taskCLItemTitleStyle}>
                                    {cli.item_title}
                                  </li>
                                </ul>
                                <div
                                  className={
                                    styles.taskCLItemActionWrapperStyle
                                  }
                                >
                                  <Image
                                    url={editPencilGrayIcon}
                                    alt=""
                                    imgContainerStyle={
                                      styles.clItemsImgWrapperStyle
                                    }
                                    onClick={() => {
                                      setClInnerItemTitle(cli.item_title);
                                      setClItemTitleModal(true);
                                      setClItemTitleIndex(cli_index);
                                    }}
                                  >
                                    <Modal
                                      show={
                                        cl_index === showInputIndex &&
                                        cli_index === clItemTitleIndex &&
                                        clItemTitleModal
                                      }
                                      showOverlay
                                      containerStyle={
                                        styles.checkListTitleModalStyle
                                      }
                                      overlayStyle={styles.customOverlayStyle}
                                      popupRef={popUpInnerRef}
                                      handleClickOutSide={(event) => {
                                        if (cli_index === clItemTitleIndex) {
                                          if (
                                            popUpInnerRef.current &&
                                            !popUpInnerRef.current.contains(
                                              event.target
                                            )
                                          ) {
                                            setClItemTitleModal(false);
                                            setClItemTitleIndex(-1);
                                            setClInnerItemTitle('');
                                          }
                                        }
                                      }}
                                    >
                                      <Input
                                        lableText="Edit item*"
                                        value={clInnerItemTitle}
                                        onChange={(e) => {
                                          setClInnerItemTitle(e.target.value);
                                        }}
                                      />
                                      <div
                                        className={
                                          styles.checkListModalBtnWrapperStyle
                                        }
                                      >
                                        <Button
                                          title="Cancel"
                                          color="light"
                                          onClick={() => {
                                            setClItemTitleModal(false);
                                            setClInnerItemTitle('');
                                          }}
                                          btnStyle={
                                            styles.checkListModalBtnStyle
                                          }
                                        />
                                        <Button
                                          title="Save"
                                          color="primary"
                                          btnStyle={
                                            styles.checkListModalBtnStyle
                                          }
                                          onClick={() => {
                                            setClItemTitleModal(false);
                                            let task = taskData;
                                            task.check_list[cl_index].items[
                                              cli_index
                                            ].item_title = clInnerItemTitle;
                                            setTaskData(task);
                                            setClItemTitleModal(false);
                                            setClItemTitleIndex(-1);
                                            setClInnerItemTitle('');
                                          }}
                                        />
                                      </div>
                                    </Modal>
                                  </Image>
                                  <Image
                                    url={closeGrayTransperentIcon}
                                    imgContainerStyle={
                                      styles.clItemsImgWrapperStyle
                                    }
                                    onClick={() => {
                                      let task = { ...taskData };
                                      let filtered = task.check_list[
                                        cl_index
                                      ].items.filter(
                                        (_, index) => index !== cli_index
                                      );
                                      task.check_list[cl_index].items =
                                        filtered;
                                      setTaskData(task);
                                    }}
                                    alt=""
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                        {showInputIndex === cl_index && (
                          <div className={styles.itemInputWrapperStyle}>
                            <Input
                              value={clItemTitle}
                              onChange={(e) => setClItemTitle(e.target.value)}
                              placeholder="Add a checklist-item"
                              customStyle={styles.addItemInputStyle}
                            />
                            <div className={styles.itemInputWrapperBtnsStyle}>
                              <Button
                                type="button"
                                title="Add"
                                btnStyle={styles.addItemBtnStyle}
                                onClick={() => {
                                  let task = taskData;
                                  task.check_list[showInputIndex].items.push({
                                    item_title: clItemTitle,
                                  });
                                  setTaskData(task);
                                  setClItemTitle('');
                                }}
                              />
                              <Button
                                type="button"
                                title="Clear"
                                btnStyle={styles.clearBtnStyle}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.taskAssignWrapperStyle}>
                  <div className={styles.taskLeftWrapperStyle}>
                    <p className={styles.taskLeftTitleStyles}>Assign to*</p>
                    <Button
                      type="button"
                      title="Add new field"
                      onClick={() => {
                        setLabelPopUp(true);
                      }}
                      image={editAssetInfoIcon}
                      color="light"
                      btnStyle={styles.taskLeftBtnStyle}
                    />
                    {renderLabelPopUp()}
                  </div>
                  <div className={styles.taskRightWrapperStyles}>
                    <div className={styles.selectUsersBlockViewStyles}>
                      <p className={styles.titleTextStyle}>Select assignee*</p>
                      <MultiSelectInput
                        options={otherUsersData.map((user) => {
                          return {
                            label: user.full_name,
                            value: user._id,
                            employeeType: user.employee_type,
                          };
                        })}
                        placeholder="Type email or name of the person"
                        selectedOptions={selectedEmployees}
                        setSelectedOptions={(data) =>
                          setSelectedEmployees(data)
                        }
                        customeMultiInputStyles={
                          styles.customeMultiInputViewStyles
                        }
                      />
                    </div>
                    {selectedLabels.includes('Task priority') &&
                      renderTicketPrioritySection()}
                    {selectedLabels.includes('Difficulty') &&
                      renderTicketDifficultLevelSection()}
                  </div>
                </div>
              </div>
              {width < 480 && renedrTopTaskActionHeaderSection()}
            </div>
          </>
        )}
      </div>
    );
  };

  const renderPreviewSection = () => {
    return (
      <React.Fragment>
        <div className={styles.previewHeaderWrapperStyle}>
          <div className={styles.phLeftWrapperStyle}>
            <Image
              url={arrowLineLeftIcon}
              imgContainerStyle={styles.phLeftIconWrapperStyle}
              onClick={() => setAssignPreview(false)}
            />
            <span className={styles.phTitleStyle}>Preview</span>
          </div>
          <Button
            title="Publish task"
            btnStyle={styles.phPublishBtnStyle}
            onClick={() => handleTakeActionToAllocateTicket()}
          />
        </div>
        <div className={styles.previewContentWrapperStyle}>
          <div className={styles.pcSubWrapperStyle}>
            <div className={styles.pcsHeaderWrapperStyle}>
              <span className={styles.pchTitleStyle}>Task info</span>
              <div
                className={styles.editTaskBtnWrapperStyle}
                onClick={() => setAssignPreview(false)}
              >
                <Image url={editPencilBlueIcon} alt="Edit" />
                <span className={styles.editTitleStyle}>Edit</span>
              </div>
            </div>
            <div className={styles.pcsContentWrapperStyle}>
              <div className={styles.taskAssetStyles}>
                <span className={styles.taskAssetTitleStyles}>
                  {`#${selectedTicketData?.ticket_uid}: ${selectedTicketData?.asset?.asset_name}`}
                </span>
                <div className={styles.taskAssetImgWrapperStyles}>
                  <img
                    src={selectedTicketData?.asset?.image}
                    alt={selectedTicketData?.asset?.asset_name}
                    className={styles.taskAssetImgStyles}
                  />
                </div>
              </div>
              <div>
                <p className={styles.pciSubjectStyle}>
                  {selectedTicketData?.subject}
                </p>
                <span className={styles.pciNoteStyle}>
                  {selectedTicketData?.note}
                </span>
              </div>
            </div>
          </div>
          {taskData.check_list.length > 0 &&
            taskData.check_list.map((cl, index) => (
              <div key={'clp' + index} className={styles.pcSubWrapperStyle}>
                <div className={styles.pcsHeaderWrapperStyle}>
                  <span className={styles.pchTitleStyle}>{cl.title}</span>
                  <div
                    className={styles.editTaskBtnWrapperStyle}
                    onClick={() => setAssignPreview(false)}
                  >
                    <Image url={editPencilBlueIcon} alt="Edit" />
                    <span className={styles.editTitleStyle}>Edit</span>
                  </div>
                </div>
                <div className={styles.pcsContentWrapperStyle}>
                  {cl?.items?.length > 0 &&
                    cl.items.map((cli, index) => (
                      <div
                        key={'clip' + index}
                        className={styles.labelWrapperStyle}
                      >
                        <input
                          type="checkbox"
                          className={styles.customCheckBoxStyle}
                          checked={false}
                          onChange={(e) => {}}
                        />
                        <span className={styles.labelTextStyle}>
                          {cli.item_title}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          <div className={styles.pcSubWrapperStyle}>
            <div className={styles.pcsHeaderWrapperStyle}>
              <span className={styles.pchTitleStyle}>Assign to</span>
              <div
                className={styles.editTaskBtnWrapperStyle}
                onClick={() => setAssignPreview(false)}
              >
                <Image url={editPencilBlueIcon} alt="Edit" />
                <span className={styles.editTitleStyle}>Edit</span>
              </div>
            </div>
            <div className={styles.pcsContentWrapperStyle}>
              <div>
                <p className={styles.pcsAssignTitleStyle}>
                  {'Select assignee: '}
                  <span className={styles.pcsAssignSubTitleStyle}>
                    {selectedEmployees[0]?.label}
                  </span>
                </p>
                {selectedLabels.includes('Task priority') && (
                  <p className={styles.pcsAssignTitleStyle}>
                    {'High priority: '}
                    <span className={styles.pcsAssignSubTitleStyle}>
                      {selectTaskPriority?.priorityOption}
                    </span>
                  </p>
                )}
                {selectedLabels.includes('Difficulty') && (
                  <p className={styles.pcsAssignTitleStyle}>
                    {'Ticket difficulty: '}
                    <span className={styles.pcsAssignSubTitleStyle}>
                      {selectDifficultyLevel?.titleText}
                    </span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderCheckListModal = () => {
    return (
      <Popup
        show={checkListModal}
        popupRef={popupRef}
        customPopUpBgStyles={styles.taskCLModalBgStyle}
        customPopUpStyles={styles.taskCLModalStyle}
        onClose={() => setCheckListModal(false)}
      >
        <div className={styles.taskCLFormStyle}>
          <Input
            lableText="Checklist title*"
            value={checkListTitle}
            onChange={(e) => setCheckListTitle(e.target.value)}
            placeholder="Ex: Checklist 01"
          />
          <div className={styles.taskCLModalActionStyle}>
            <Button
              title="Cancel"
              btnStyle={styles.resetButtonStyles}
              onClick={() => {
                setCheckListModal(false);
                setCheckListTitle('');
              }}
            />
            <Button
              title="Save"
              btnStyle={
                checkListTitle
                  ? styles.activeProceedButtonStyles
                  : styles.proceedButtonStyles
              }
              onClick={() => {
                setTaskData({
                  ...taskData,
                  check_list: [
                    ...taskData.check_list,
                    {
                      title: checkListTitle,
                      items: [],
                    },
                  ],
                });
                setCheckListTitle('');
                setCheckListModal(false);
              }}
            />
          </div>
        </div>
      </Popup>
    );
  };

  const renderLabelPopUp = () => {
    return (
      <Popup
        show={labelPopUp}
        popupRef={popupRef}
        customPopUpStyles={styles.labelPopUpStyle}
        onClose={() => setLabelPopUp(false)}
      >
        {taskLables.map((item, index) => (
          <div key={'label' + index} className={styles.labelWrapperStyle}>
            <input
              type="checkbox"
              className={[
                styles.customCheckBoxStyle,
                item === 'Add assignee' && styles.disabledCheckBoxStyle,
              ].join(' ')}
              checked={selectedLabels.includes(item)}
              disabled={item === 'Add assignee'}
              onChange={(e) =>
                setSelectedLabels((prev) => {
                  if (prev.includes(item)) {
                    let data = [...prev];
                    return data.filter((label) => label != item);
                  } else {
                    return [...prev, item];
                  }
                })
              }
            />
            <span className={styles.labelTextStyle}>{item}</span>
          </div>
        ))}
      </Popup>
    );
  };

  const renedrTopTaskActionHeaderSection = () => {
    return (
      <div className={styles.takeActionHeaderRightWrapperStyles}>
        <Button
          type="button"
          onClick={() => {
            setShowTakeActionModal();
            resetTaskActionData();
            setTaskActionErrors('');
          }}
          title="Cancel"
          btnStyle={styles.resetButtonStyles}
        />
        <Button
          type="submit"
          onClick={() => taskActionModalValidation()}
          title={'Continue'}
          btnStyle={
            selectedEmployees.length > 0 && ticketSubject && ticketNote
              ? styles.activeProceedButtonStyles
              : styles.proceedButtonStyles
          }
        />
      </div>
    );
  };

  const renderTicketPrioritySection = () => {
    return (
      <div className={styles.ticketPrirotyBlockStyles}>
        <p className={styles.ticketProrityTextStyles}>
          Is this ticket a high priority🔺*
        </p>
        <div className={styles.yesOrNoButtonTabStyles}>
          {taskActionPriorityData &&
            taskActionPriorityData.map((item, index) => {
              return (
                <ButtonTab
                  key={index}
                  titleText={item.priorityOption}
                  custButtonTabContainerStyle={
                    selectTaskPriority === item
                      ? styles.activeTaskPriorityStyles
                      : styles.taskPriorityButtonTabStyles
                  }
                  onClick={() => handleTaskPrioritySelection(item)}
                  customtitleTextStyle={
                    selectTaskPriority === item
                      ? styles.activeTaskPriorityTextStyles
                      : styles.priorityOptionTextStyles
                  }
                />
              );
            })}
        </div>
        {taskActionErrors.taskPriority && (
          <p className={styles.errorTextStyle}>
            {taskActionErrors.taskPriority}
          </p>
        )}
      </div>
    );
  };

  const renderTicketDifficultLevelSection = () => {
    return (
      <div className={styles.ticketDifficultLevelBlockStyles}>
        <p className={styles.ticketDifficultyTextStyles}>Ticket difficulty*</p>
        <div className={styles.difficultyLevelBlockStyles}>
          {taskActionDifficultyLevelData &&
            taskActionDifficultyLevelData.map((item, index) => {
              return (
                <ButtonTab
                  key={index}
                  titleText={item.titleText}
                  image={false}
                  icon={
                    selectDifficultyLevel?.titleText === item.titleText
                      ? item.icon
                      : null
                  }
                  custButtonTabContainerStyle={
                    selectDifficultyLevel
                      ? selectDifficultyLevel.titleText === 'Easy' &&
                        selectDifficultyLevel.titleText === item.titleText
                        ? styles.easyBgStyle
                        : selectDifficultyLevel.titleText === 'Moderate' &&
                          selectDifficultyLevel.titleText === item.titleText
                        ? styles.moderateBgStyle
                        : selectDifficultyLevel.titleText === 'Expert' &&
                          selectDifficultyLevel.titleText === item.titleText
                        ? styles.expertBgStyle
                        : styles.defaultDifficultyBgStyle
                      : styles.defaultDifficultyBgStyle
                  }
                  customtitleTextStyle={
                    selectDifficultyLevel
                      ? selectDifficultyLevel.titleText === 'Easy' &&
                        selectDifficultyLevel.titleText === item.titleText
                        ? styles.easyTitleStyle
                        : selectDifficultyLevel.titleText === 'Moderate' &&
                          selectDifficultyLevel.titleText === item.titleText
                        ? styles.moderateTitleStyle
                        : selectDifficultyLevel.titleText === 'Expert' &&
                          selectDifficultyLevel.titleText === item.titleText
                        ? styles.expertTitleStyle
                        : styles.defaultDifficultyTitleStyle
                      : styles.defaultDifficultyTitleStyle
                  }
                  customimgContainerStyle={styles.difficultyImgWrapperStyle}
                  onClick={() => {
                    handleSelectDifficultyLevel(item);
                  }}
                />
              );
            })}
        </div>
        {taskActionErrors.difficultyLevel && (
          <p className={styles.errorTextStyle}>
            {taskActionErrors.difficultyLevel}
          </p>
        )}
      </div>
    );
  };

  const renderTaskActionBottomButtonsSection = () => {
    return (
      <div className={styles.topTaskActionBottonButtonsStyles}>
        {width < 480 ? (
          <ButtonTab
            image={false}
            icon={refreshIcon}
            custButtonTabContainerStyle={styles.resetIconBtnStyles}
            customimgContainerStyle={styles.resetImgStyles}
            onClick={resetTaskActionData}
          />
        ) : (
          <Button
            type="button"
            onClick={() => {
              resetTaskActionData();
            }}
            title="Reset"
            btnStyle={styles.resetButtonStyles}
          />
        )}
        <Button
          type="submit"
          onClick={() => taskActionModalValidation()}
          title={width < 500 ? 'Transfer ticket' : 'Proceed'}
          btnStyle={
            selectedEmployees.length > 0 &&
            selectTaskPriority &&
            selectDifficultyLevel
              ? styles.activeProceedButtonStyles
              : styles.proceedButtonStyles
          }
        />
      </div>
    );
  };

  const addNotesCommentSection = () => {
    return (
      <div className={styles.addNotesBlockStyles}>
        <TextAreaInput
          type="text"
          name="optionalDescription"
          value={taskActionComment}
          onChange={(e) => {
            setTaskActionComment(e.target.value);
          }}
          placeholder="Description here"
          // hasError={error}
          lableText={'Add notes (Optional)'}
          lableTextStyle={styles.optionalLabelStyles}
          customTextAreaStyles={styles.optionalTextAreaStyles}
        />
      </div>
    );
  };

  const renderEmptySection = () => {
    return (
      <div className={styles.emptySectionViewStyle}>
        <p className={styles.emptyDataTextStyle}>
          Select a ticket from the list to view detailed-updates and much more.
        </p>
      </div>
    );
  };

  const renderConversationsContainer = () => {
    return (
      <div className={styles.conversationsSectionViewStyle}>
        <p className={styles.conversationsTitleStyle}>Conversations</p>
        <div className={styles.conversationsBoxViewStyle}>
          <ChatInput
            setValue={(text) => {
              setMessage(text);
              setThreadId('');
              setSelectedThreadIndex('');
            }}
            onInputFocus={() => setSelectedThreadIndex('')}
            value={message}
            sendAction={() => sendMessage()}
          />
          <div className={styles.conversationsViewStyle}>
            {conversations.length > 0 &&
              conversations[0].threads.map((conv, index) => (
                <div key={index} className={styles.eachConversationStyle}>
                  <div className={styles.chatProfileViewStyle}>
                    <div className={styles.imgContainerStyle}>
                      <img
                        src={avathar2}
                        alt="avatar"
                        className={styles.imageStyles}
                      />
                    </div>
                    <p className={styles.chatProfileTextStyle}>
                      {conv.user.full_name}
                    </p>
                    <div className={styles.chatTimeStampStyle}>
                      <div className={styles.dotStyle}></div>
                      <p className={styles.chatTimeStampTextStyle}>
                        {`${moment(conv.created_at).fromNow()}`}
                      </p>
                    </div>
                  </div>
                  <p className={styles.chatMsgTextStyle}>{conv.comment}</p>
                  <div
                    onClick={() => {
                      setMessage('');
                      setSelectedThreadIndex(index);
                      setThreadId(conv._id);
                    }}
                    className={styles.replayViewStyle}
                  >
                    <p className={styles.replayTextStyle}>
                      {conv.replies.length > 0
                        ? `${conv.replies.length} ${
                            conv.replies.length === 1 ? 'replie' : 'replies'
                          }`
                        : 'Reply'}
                    </p>
                    {conv.replies.length > 0 && (
                      <div className={styles.downArrowImgStyles}>
                        <img
                          src={downArrowColorIcon}
                          alt="avatar"
                          className={[
                            styles.imageStyles,
                            selectedThreadIndex === index &&
                              styles.rotatedDownArrowStyle,
                          ].join(' ')}
                        />
                      </div>
                    )}
                  </div>
                  {selectedThreadIndex === index && (
                    <>
                      {conv.replies.length > 0 &&
                        conv.replies.map((tread, index) => (
                          <div
                            key={index}
                            className={styles.threadConversationViewStyle}
                          >
                            <div className={styles.curvedViewStyle2}></div>
                            <div className={styles.eachConversationStyle2}>
                              <div className={styles.chatProfileViewStyle}>
                                <div className={styles.imgContainerStyle}>
                                  <img
                                    src={avathar2}
                                    alt="avatar"
                                    className={styles.imageStyles}
                                  />
                                </div>
                                <p className={styles.chatProfileTextStyle}>
                                  {tread.user.full_name}
                                </p>
                                <div className={styles.chatTimeStampStyle}>
                                  <div className={styles.dotStyle}></div>
                                  <p className={styles.chatTimeStampTextStyle}>
                                    {`${moment(tread.created_at).fromNow()}`}
                                  </p>
                                </div>
                              </div>
                              <p className={styles.chatMsgTextStyle}>
                                {tread.comment}
                              </p>
                            </div>
                          </div>
                        ))}
                      <ChatInput
                        value={message}
                        setValue={(text) => setMessage(text)}
                        sendAction={() => sendMessage()}
                      />
                    </>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  const renderTabs = () => {
    return (
      <div className={styles.tabsWrapperStyle}>
        {ticketTabs
          .filter((tab) => {
            return selectedTicketData?.ticket_status !== 'Complaint raised'
              ? tab.title !== 'Overview'
              : tab.title === 'Overview' || tab.title === 'Timeline';
          })
          .map((item, index) => (
            <Capsule
              key={index}
              title={item.title}
              isActive={item.value === selectedTab}
              onClick={() => setSelectedTab(item.value)}
            />
          ))}
      </div>
    );
  };

  const renderTask = () => {
    const assignedData = selectedTicketData?.activity?.filter(
      (a) => a.type === 'Assign'
    )[0];
    const otherData = selectedTicketData?.activity
      ?.filter(
        (a) =>
          a.type === 'Completed' ||
          a.type === 'Resolved' ||
          a.type === 'Revert' ||
          a.type === "Can't solve" ||
          a.type === 'Closed'
      )
      .reverse();
    const bgColorStyle = taskProgressBgColor(selectedTicketData?.ticket_status);
    return (
      assignedData && (
        <div
          className={classNames(
            styles.taskProgressContainerStyle,
            otherData.length && styles.taskProgressContainerReverseStyle
          )}
        >
          <div className={styles.raiseTicketDetailsBlockStyles}>
            <div className={styles.ticketRaiseByViewStyles}>
              <div
                className={classNames(
                  styles.ticketRaiseIconViewStyle,
                  bgColorStyle
                )}
              >
                <img
                  src={flagIcon}
                  alt={'ticketIcon'}
                  className={styles.imageStyle}
                />
              </div>
              <div className={styles.ticketRaiseByUserStyles}>
                <p
                  className={styles.ticketRaiseTextStyles}
                >{`${assignedData.action_by.full_name} assigned this task`}</p>
                <p className={styles.ticketRaiseDateTextStyles}>
                  {`${moment(assignedData?.created_at).format(
                    'hh:mm a'
                  )}, ${timeStampOfTicket(assignedData?.created_at)}`}
                </p>
              </div>
            </div>
            <div className={styles.tickeViewStyles}>
              <div className={styles.curvedViewStyle}></div>
              <TicketDetailsCard
                assetId={`#${selectedTicketData?.asset?.asset_uid}`}
                assetName={selectedTicketData?.asset?.asset_name}
                ticketProductImg={
                  selectedTicketData?.image
                    ? selectedTicketData?.image
                    : ticketSolveImg
                }
                ticketStatus={selectedTicketData?.ticket_status}
                subject={selectedTicketData?.subject}
                note={selectedTicketData?.note}
                forwardedData={lastForwardedData}
                byOrFrom={'by'}
              />
            </div>
          </div>
          {selectedTicketData?.tasks?.length > 0 && (
            <div className={styles.taskChecklistContainerStyle}>
              {selectedTicketData?.tasks.map((task, index) => (
                <div
                  key={'task' + index}
                  className={styles.taskChecklistWrapperStyle}
                >
                  <div className={styles.taskChecklistHeaderWrapperStyle}>
                    <div className={styles.taskChecklistTitleInfoWrapperStyle}>
                      <Image
                        url={tickIcon}
                        imgContainerStyle={classNames(
                          styles.activityTypeImgContainerStyle,
                          bgColorStyle
                        )}
                      />
                      <div className={styles.taskChecklistTitleWrapperStyle}>
                        <p className={styles.taskChecklistTitleStyle}>
                          {task.title}
                        </p>
                        <span className={styles.taskChecklistSubTitleStyle}>{`${
                          (task.items.filter((item) => item.checked).length /
                            task.items.length) *
                          100
                        }% complete. ${
                          task.items.filter((item) => item.checked).length
                        } of ${task.items.length} checked`}</span>
                      </div>
                    </div>
                    {selectedTicketData?.ticket_status === 'In progress' ||
                    selectedTicketData?.ticket_status === 'Assigned' ? (
                      <Image
                        url={horizontalDottedMenu}
                        imgContainerStyle={
                          styles.activityActionImgContainerStyle
                        }
                        onClick={() =>
                          setShowCheckListMenu((prev) => [...prev, task._id])
                        }
                      >
                        <Modal
                          show={showCheckListMenu?.includes(task._id)}
                          showOverlay
                          containerStyle={styles.taskChecklistModalStyle}
                          popupRef={popupRef}
                          handleClickOutSide={(event) => {
                            if (showCheckListMenu?.includes(task._id)) {
                              if (
                                popupRef.current &&
                                !popupRef.current.contains(event.target)
                              ) {
                                setShowCheckListMenu((prev) =>
                                  prev?.filter((a) => a !== task._id)
                                );
                              }
                            }
                          }}
                        >
                          <div className={styles.menuWrapperStyle}>
                            <p
                              className={styles.menuItemStyle}
                              onClick={() => toggleCheckList(task._id)}
                            >
                              {showCheckList?.includes(task._id)
                                ? 'Unhide checklist'
                                : 'Hide checklist'}
                            </p>
                            {adminCanEdit && (
                              <p
                                className={styles.menuItemStyle}
                                onClick={() => deleteCheckList(task._id)}
                              >
                                Delete
                              </p>
                            )}
                          </div>
                        </Modal>
                      </Image>
                    ) : (
                      <Image
                        url={downArrowDarkIcon}
                        imgContainerStyle={classNames(
                          styles.taskToggleImgContainerStyle,
                          showCheckList.includes(task._id) &&
                            styles.taskToggleImgRevContainerStyle
                        )}
                        onClick={() => toggleCheckList(task._id)}
                      />
                    )}
                  </div>
                  {task.items.length > 0 &&
                    !showCheckList?.includes(task._id) && (
                      <div
                        key={'taskItem' + index}
                        className={styles.tickeViewStyles}
                      >
                        <div className={styles.curvedViewStyle}></div>
                        <div className={styles.taskCLIContentWrapperStyle}>
                          {task.items.map((taskItem, index) => (
                            <div className={styles.taskItemWrapperStyle}>
                              <input
                                type="checkbox"
                                className={classNames(
                                  styles.customCheckBoxStyle,
                                  taskItem.checked && bgColorStyle
                                )}
                                disabled={!userCanEdit}
                                checked={taskItem.checked}
                                onChange={(e) =>
                                  updateTaskInnerState(
                                    task,
                                    taskItem,
                                    e.target.checked
                                  )
                                }
                              />
                              <div className={styles.taskItemTitleWrapperStyle}>
                                <p
                                  className={classNames(
                                    styles.taskItemTitleStyle,
                                    taskItem.checked &&
                                      styles.taskItemTitleStrikeStyle
                                  )}
                                >
                                  {taskItem.item_title}
                                </p>
                                {taskItem.activity.length > 0 && (
                                  <div
                                    className={
                                      styles.taskItemActivityWrapperStyle
                                    }
                                  >
                                    <span
                                      className={styles.taskItemSubTitleStyle}
                                    >
                                      {`${
                                        taskItem.activity[0].type === 'Check'
                                          ? 'Checked'
                                          : 'Unchecked'
                                      } by ${
                                        taskItem.activity[0].by.full_name
                                      }`}
                                    </span>
                                    {taskItem.activity.length > 1 && (
                                      <Image
                                        url={horizontalDottedMenuGrayIcon}
                                        imgContainerStyle={
                                          styles.taskItemActionContainerStyle
                                        }
                                        onClick={() =>
                                          setTaskActivityModal(taskItem)
                                        }
                                      >
                                        <Modal
                                          show={
                                            taskItem._id ===
                                            taskActivityModal?._id
                                          }
                                          handleClickOutSide={() =>
                                            setTaskActivityModal(null)
                                          }
                                          popupRef={popupRef}
                                          showOverlay
                                          containerStyle={
                                            styles.taskItemActivityModalStyle
                                          }
                                        >
                                          <div
                                            className={
                                              styles.timeLineContainerStyle
                                            }
                                          >
                                            {taskItem.activity.map(
                                              (activity, index) => (
                                                <div
                                                  key={'act' + index}
                                                  className={
                                                    styles.timeLineItemWrapperStyle
                                                  }
                                                >
                                                  <Image
                                                    url={
                                                      activity.type === 'Check'
                                                        ? tickIcon
                                                        : unCheckIcon
                                                    }
                                                    imgContainerStyle={[
                                                      styles.timeLineImgContainerStyle,
                                                      activity.type ===
                                                        'Check' && bgColorStyle,
                                                    ].join(' ')}
                                                    imgStyle={
                                                      styles.timeLineImgStyle
                                                    }
                                                  />
                                                  <div
                                                    className={
                                                      styles.timeLineContentWrapperStyle
                                                    }
                                                  >
                                                    <p
                                                      className={
                                                        styles.timeLineTitleStyle
                                                      }
                                                    >
                                                      {`${
                                                        activity.type ===
                                                        'Check'
                                                          ? 'Checked'
                                                          : 'Unchecked'
                                                      } by ${
                                                        activity.by.full_name
                                                      }`}
                                                    </p>
                                                    <span
                                                      className={
                                                        styles.timeLineSubTitleStyle
                                                      }
                                                    >
                                                      {`${moment(
                                                        activity.at
                                                      ).format(
                                                        'hh:mm a'
                                                      )}, ${moment(
                                                        activity.at
                                                      ).fromNow()}`}
                                                    </span>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </Modal>
                                      </Image>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                </div>
              ))}
            </div>
          )}
          {otherData.map((activity, index) => (
            <div key={index} className={styles.signleActivityContainerStyle}>
              {(activity.type === 'Completed' ||
                activity.type === "Can't solve") && (
                <React.Fragment>
                  <div className={styles.ticketRaiseByViewStyles}>
                    <div
                      className={classNames(
                        styles.ticketRaiseIconViewStyle,
                        bgColorStyle
                      )}
                    >
                      <img
                        src={timerIcon}
                        alt={'Complete'}
                        className={styles.imageStyle}
                      />
                    </div>
                    <div className={styles.ticketRaiseByUserStyles}>
                      <p className={styles.ticketRaiseTextStyles}>{`${
                        activity.action_by.full_name
                      } changed task status to ${
                        activity.type === 'Completed'
                          ? 'Complete'
                          : "Can't solve"
                      }`}</p>
                      <p className={styles.ticketRaiseDateTextStyles}>
                        {`${moment(assignedData?.created_at).format(
                          'hh:mm a'
                        )}, ${timeStampOfTicket(assignedData?.created_at)}`}
                      </p>
                    </div>
                  </div>
                  <div className={styles.tickeViewStyles}>
                    <div className={styles.curvedViewStyle} />
                    <div className={styles.pcsContentWrapperStyle}>
                      <div className={styles.taskAssetStyles}>
                        <span className={styles.taskAssetTitleStyles}>
                          {`#${selectedTicketData?.asset?.asset_uid}: ${selectedTicketData?.asset?.asset_name}`}
                        </span>
                        <div className={styles.taskAssetImgWrapperStyles}>
                          <img
                            src={
                              activity.image
                                ? activity.image
                                : selectedTicketData?.asset?.image
                            }
                            alt={selectedTicketData?.asset?.asset_name}
                            className={styles.taskAssetImgStyles}
                          />
                        </div>
                      </div>
                      <div className={styles.noteBorderWrapperStyle}>
                        <div className={styles.noteBorderStyle} />
                        <span className={styles.pciNoteStyle}>
                          {activity?.note}
                        </span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
              {activity.type === 'Resolved' && (
                <div className={styles.ticketRaiseByViewStyles}>
                  <div
                    className={classNames(
                      styles.ticketRaiseIconViewStyle,
                      bgColorStyle
                    )}
                  >
                    <img
                      src={raceIcon}
                      alt={'Closed'}
                      className={styles.imageStyle}
                    />
                  </div>
                  <div className={styles.ticketRaiseByUserStyles}>
                    <p
                      className={styles.ticketRaiseTextStyles}
                    >{`${activity.action_by.full_name} closed the ticket permanently`}</p>
                    <p className={styles.ticketRaiseDateTextStyles}>
                      {`${moment(activity?.created_at).format(
                        'hh:mm a'
                      )}, ${timeStampOfTicket(activity?.created_at)}`}
                    </p>
                  </div>
                </div>
              )}
              {activity.type === 'Revert' && (
                <React.Fragment>
                  <div className={styles.ticketRaiseByViewStyles}>
                    <div
                      className={classNames(
                        styles.ticketRaiseIconViewStyle,
                        bgColorStyle
                      )}
                    >
                      <img
                        src={flagIcon}
                        alt={'Revert'}
                        className={styles.imageStyle}
                      />
                    </div>
                    <div className={styles.ticketRaiseByUserStyles}>
                      <p
                        className={styles.ticketRaiseTextStyles}
                      >{`${activity.action_by.full_name} changed the status to working`}</p>
                      <p className={styles.ticketRaiseDateTextStyles}>
                        {`${moment(assignedData?.created_at).format(
                          'hh:mm a'
                        )}, ${timeStampOfTicket(assignedData?.created_at)}`}
                      </p>
                    </div>
                  </div>
                  {activity?.note && (
                    <div className={styles.tickeViewStyles}>
                      <div className={styles.curvedViewStyle} />
                      <div className={styles.pcsContentWrapperStyle}>
                        <div className={styles.noteBorderWrapperStyle}>
                          <div className={styles.noteBorderStyle} />
                          <span className={styles.pciNoteStyle}>
                            {activity?.note}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          ))}
        </div>
      )
    );
  };

  const renderTimeLine = () => {
    return (
      <div className={styles.timeLineContainerStyle}>
        {selectedTicketData?.activity.map((activity, index) => (
          <div key={'act' + index} className={styles.timeLineItemWrapperStyle}>
            <Image
              url={timeLineIcons[activity.type]}
              imgContainerStyle={[
                styles.timeLineImgContainerStyle,
                getBgClolor(activity.type),
              ].join(' ')}
              imgStyle={styles.timeLineImgStyle}
            />
            <div className={styles.timeLineContentWrapperStyle}>
              <p className={styles.timeLineTitleStyle}>
                {getTimeLineString(activity)}
              </p>
              <span className={styles.timeLineSubTitleStyle}>
                {`${moment(activity.created_at).format('hh:mm a')}, ${moment(
                  activity.created_at
                ).fromNow()}`}
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.mainContainerStyle}>
      {initialLoader ? (
        <LoaderForPages />
      ) : (
        <div className={styles.containerStyle}>
          {(!selectedTicketData || width > 480) &&
            renderAllTicketsLeftSection()}
          {!selectedTicketData && width > 480 && renderEmptySection()}
          {selectedTicketData && renderAllTicketsRightSection()}
        </div>
      )}
    </div>
  );
};

export default AllTickets;
