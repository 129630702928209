import React from 'react';
import styles from './styles.module.css';
import PropTypes from 'prop-types';
import { searchIcon } from 'resources/images/Images';
import { useAppDataContext } from 'hooks/useAppDataContext';

const Input = (props) => {
  const { navBarOpen, showOverLayModel } = useAppDataContext();
  const {
    type,
    name,
    value,
    onChange = () => {},
    placeholder,
    hasError,
    lableText,
    lableTextStyle,
    customStyle,
    image,
    rightImage,
    rightIconAction = () => {},
    customImageContainerStyles,
    customInputContainerStyles,
    customRightImageContainerStyles,
    onRightImageClick,
    onLeftImageClick,
    onKeyDown = () => {},
    ...rest
  } = props;

  return (
    <div
      className={[
        navBarOpen && showOverLayModel
          ? styles.inputHiddenStyles
          : styles.inputContainerStyle,
        customInputContainerStyles,
      ].join(' ')}
    >
      {lableText && (
        <p className={[styles.lableTextStyle, lableTextStyle].join(' ')}>
          {lableText}
        </p>
      )}
      <input
        type={type || 'text'}
        name={name}
        // className={styles.inputStyle}
        className={[
          navBarOpen && showOverLayModel
            ? styles.inputHideStyles
            : styles.inputStyle,
          customStyle,
        ].join(' ')}
        value={value}
        onChange={onChange}
        placeholder={placeholder || ''}
        onKeyDown={onKeyDown}
        {...rest}
      />
      {image && (
        <div
          className={[
            styles.imageContainerStyles,
            customImageContainerStyles,
          ].join(' ')}
        >
          <img
            src={image}
            alt="icon"
            className={styles.imageWidth}
            onClick={onLeftImageClick}
          />
        </div>
      )}

      {rightImage && (
        <div
          className={[
            styles.rightImageContainerStyles,
            customRightImageContainerStyles,
          ].join(' ')}
        >
          <img
            src={rightImage}
            alt="icon"
            className={styles.imageWidth}
            onClick={onRightImageClick}
          />
        </div>
      )}

      {hasError && <p className={styles.errorTextStyle}>{hasError}</p>}
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  hasError: PropTypes.string,
  lableText: PropTypes.string,
  lableTextStyle: PropTypes.string,
  onRightImageClick: PropTypes.func,
};
export default Input;
