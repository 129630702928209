import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from 'components/button';
import Input from 'components/input';
import NumberInput from 'components/numberInput';
import AuthLayout from 'layouts/auth';
import styles from './style.module.css';
import {
  sendRegistrationOtpApi,
  superAdminRegisterApi,
} from 'networking/api/admin';
import { googleIcon } from 'resources/images/Images';
import { useSpinner } from 'providers/spinnerprovider';
import { useStringData } from 'providers/stringsprovider';

const SignUp = () => {
  //context data
  const { strings } = useStringData();
  const { setIsShowLoading } = useSpinner();
  const navigate = useNavigate();
  const [actionLoading, setActionLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const [step, setStep] = useState(1);
  const [schemaObj, setSchemaObj] = useState({
    full_name: Yup.string().required(strings.fullNameRequired),
    company_name: Yup.string().required(strings.companyNameRequired),
  });

  useEffect(() => {
    if (step === 1) {
      setSchemaObj({
        full_name: Yup.string().required(strings.fullNameRequired),
        company_name: Yup.string().required(strings.companyNameRequired),
      });
    }
    if (step === 2) {
      formik.setTouched({});
      setSchemaObj({
        ...schemaObj,
        email: Yup.string()
          .email(strings.validEmail)
          .required(strings.emailRequired),
        phone_number: Yup.object().shape({
          country_code: Yup.string().required(strings.countryCodeRequired),
          value: Yup.string().required(strings.phoneNumRequired),
        }),
        otp: undefined,
      });
    }
    if (step === 3) {
      setSchemaObj({
        ...schemaObj,
        otp: Yup.string().required(strings.otpRequired),
      });
    }
  }, [step]);

  const signUpSchema = Yup.object().shape(schemaObj);

  const handleRegistration = async (values, { resetForm }) => {
    try {
      handleNextAndSubmitBtn(values);
      if (step === 3) {
        setActionLoading(true);
        setIsShowLoading(true);
        const response = await superAdminRegisterApi(values);
        if (response.data.type === 'success') {
          console.log(values, '.....');
          setApiError('');
          resetForm();
          navigate('/login');
          setIsShowLoading(false);
        } else {
          setApiError(response.data.message);
          setIsShowLoading(false);
        }
        setActionLoading(false);
      }
    } catch (error) {
      console.log('API call error:', error);
      setActionLoading(false);
      setApiError(error.message);
      setIsShowLoading(false);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      full_name: '',
      company_name: '',
      email: '',
      phone_number: {
        country_code: '+91',
        value: '',
      },
      otp: '',
    },
    validationSchema: signUpSchema,
    onSubmit: handleRegistration,
  });

  const handleNextAndSubmitBtn = (data) => {
    if (step === 1) {
      formik.setTouched({});
      setStep(2);
    } else if (step === 2) {
      formik.setTouched({});
      sendRegistrationOtp(data);
    } else {
    }
  };

  const sendRegistrationOtp = async (data) => {
    try {
      setIsShowLoading(true);
      let updateData = { ...data, otp: undefined };
      console.log('updateData', updateData);
      let response = await sendRegistrationOtpApi(updateData);
      console.log('response', response);
      if (response.data.type === 'success') {
        setStep(3);
        setIsShowLoading(false);
      } else {
        console.log('send RegistrationOtp catch', response.data.message);
        setIsShowLoading(false);
      }
    } catch (error) {
      console.log('send RegistrationOtp catch', error.response.data);
      setIsShowLoading(false);
    }
  };

  const handleBackClick = () => {
    setStep(step - 1);
  };

  const shouldShowBackText =
    step !== 1 && window.innerWidth <= 420 ? true : false;

  const resendOtp = () => {
    sendRegistrationOtp();
  };

  const renderPagination = () => {
    return (
      <div className={styles.paginationContainerStyle}>
        {Array.from({ length: 3 }, (_, i) => (
          <div
            key={'pagination' + i + 1}
            className={
              step === i + 1 ? styles.activeDotBtnStyle : styles.dotBtnStyle
            }
          />
        ))}
      </div>
    );
  };

  const renderInputContainer = () => {
    return (
      <div className={styles.inputContainerStyle}>
        <div className={styles.signUpTopStyles}>
          <h2 className={styles.titleTestStyle}>
            {strings.trakrSignUpHeader}
            {/* {step === 1 || step == 2
            ? 'Create a trakr account in just a few steps.'
            : `We sent an OTP to ${user.email}`} */}
          </h2>
          <div className={styles.signUpBlockStyles}>
            {renderHaveAnAccountContent()}
          </div>
        </div>
        <div className={styles.inputSubContainerStyle}>
          {step === 1
            ? renderStepOneContent()
            : step === 2
            ? renderStepTwoContent()
            : renderStepThreeContent()}
        </div>
        {renderBtnContainer()}
        <div className={styles.signUpBottomStyles}>
          {renderHaveAnAccountContent()}
        </div>
      </div>
    );
  };

  const renderBtnContainer = () => {
    return (
      <div>
        {apiError && <p className={styles.errorTextStyle}>{apiError}</p>}
        <Button
          title={
            step === 1
              ? strings.nextStep
              : step === 2
              ? strings.continue
              : strings.finishSetup
          }
          type="submit"
          color="dark"
          btnStyle={styles.nextStepBtnStyles}
          onClick={() => {}}
        />
        <Button
          title={strings.googleSignUp}
          image={googleIcon}
          type="submit"
          color="light"
          btnStyle={styles.signUpGoogleBtnStyles}
        />
      </div>
    );
  };

  const renderHaveAnAccountContent = () => {
    return (
      <div className={styles.haveAnAccountStyle}>
        <p className={styles.haveAnAccountTextStyle}>
          {strings.haveAnAccount}
          <span
            onClick={() => navigate('/login')}
            className={styles.loginTextStyle}
          >
            {strings.logIn}
          </span>
        </p>
      </div>
    );
  };

  const renderStepOneContent = () => {
    return (
      <div className={styles.inputwrapperStyle}>
        <Input
          name="full_name"
          lableText={strings.fullNameLabel}
          placeholder={strings.enterFullName}
          value={formik.values.full_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={() => formik.setFieldError('full_name', '')}
          hasError={
            formik.touched.full_name && formik.errors.full_name
              ? formik.errors.full_name
              : ''
          }
        />
        <Input
          name="company_name"
          lableText={strings.companyNameLabel}
          placeholder={strings.enterCompanyName}
          value={formik.values.company_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={() => formik.setFieldError('company_name', '')}
          hasError={
            formik.touched.company_name && formik.errors.company_name
              ? formik.errors.company_name
              : ''
          }
        />
      </div>
    );
  };

  const renderStepTwoContent = () => {
    return (
      <div className={styles.inputwrapperStyle}>
        <Input
          name="email"
          lableText={strings.emailLabel}
          placeholder={strings.enterEmailId}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={() => formik.setFieldError('email', '')}
          hasError={
            formik.touched.email && formik.errors.email
              ? formik.errors.email
              : ''
          }
        />
        <NumberInput
          name="phone_number.value"
          lableText={strings.phoneNumLabel}
          placeholder={strings.enterPhNum}
          value={formik.values.phone_number?.value}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={() => formik.setFieldError('phone_number.value', '')}
          hasError={
            formik.touched.phone_number?.value &&
            formik.errors.phone_number?.value
              ? formik.errors.phone_number?.value
              : ''
          }
          lableTextStyle={styles.phoneNoLabelStyles}
        />
      </div>
    );
  };

  const renderStepThreeContent = () => {
    return (
      <div className={styles.otpContainerStyle}>
        {/* <Input
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          placeholder={'6-digit OTP here'}
        /> */}
        <Input
          name="otp"
          lableText={strings.enterOtpLabel}
          placeholder={strings.otpPlaceHolder}
          value={formik.values.otp}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={() => formik.setFieldError('otp', '')}
          hasError={
            formik.touched.otp && formik.errors.otp ? formik.errors.otp : ''
          }
        />
        <p className={styles.resendEditEmailTextViewStyle}>
          <span onClick={() => resendOtp()}>{strings.reSend}</span>
          {strings.or}{' '}
          <span
            onClick={() => {
              setStep(2);
              formik.setTouched({});
            }}
          >
            {strings.editEmail}
          </span>
        </p>
        {/* <div className={styles.resendTextContainerStyle}>
          <p className={styles.resendTextStyle}>Resend</p>
          <p className={styles.orTextStyle}>or</p>
          <p onClick={() => setStep(2)} className={styles.resendTextStyle}>
            edit email
          </p>
        </div> */}
      </div>
    );
  };

  return (
    <AuthLayout showBackText={shouldShowBackText} onBackClick={handleBackClick}>
      <div className={styles.mainContainerStyle}>
        {renderPagination()}
        <form onSubmit={formik.handleSubmit}>{renderInputContainer()}</form>
      </div>
    </AuthLayout>
  );
};

export default SignUp;
