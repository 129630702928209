import React, { useEffect, useRef, useState } from 'react';

import Button from 'components/button';
import {
  avathar1,
  avathar2,
  avathar4,
  deleteIcon,
  dropDownIcon,
  dummyTabIcon,
  macBookIcon,
  rightArrowFillIcon,
  whiteDownArrow,
} from 'resources/images/Images';
import ButtonTab from 'components/buttontab';
import { AssignedHistoryIcon } from 'resources/svg/svg';
import useWindowDimensions from 'hooks/useDimensionHook';
import AssetEmployeeCard from 'components/individualAeCard';
import Divider from 'components/divider';
import Popup from 'components/popup';
import AssignAssetPopUp from 'components/assignAssetPopup';
import { useAppDataContext } from 'hooks/useAppDataContext';
import styles from './style.module.css';
import Modal from 'components/modal';
import { useSpinner } from 'providers/spinnerprovider';
import { getemployeeDetailsWithAssetsApi } from 'networking/api/employee';
import { useLocation } from 'react-router';
import moment from 'moment';
import { assetAssignToOthersApi, assetUnAssignApi } from 'networking/api/asset';
import { useStringData } from 'providers/stringsprovider';
import LoaderForPages from 'components/loaderForPages';
import { account_types } from 'utils/constants';
import DontHaveAccessPage from 'components/dontHaveAccess';
import { useAuthContext } from 'hooks/useAuthContext';
const EmployeeOverView = () => {
  //context data
  const { userType } = useAuthContext();
  const { navBarOpen, showOverLayModel } = useAppDataContext();
  const { strings } = useStringData();
  const { width } = useWindowDimensions();
  const popupRef = useRef(null);
  const location = useLocation();
  const individual_employee_id = location.state.empolyeeId;
  //state
  const [activeButton, setActiveButton] = useState(0);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [activePopUp, setActiveOption] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [unAssignPopUp, setUnAssignPopUp] = useState(false);
  const [employeeData, setEmployeeData] = useState();
  const [selectedAsset, setSelectedAsset] = useState();
  const [selectedDifferentUser, setSelectedDifferentUser] = useState('');
  const [loader, setLoader] = useState(true);
  //mapping
  const tabsData = [
    {
      image: (
        <AssignedHistoryIcon
          colorType={activeButton === 0 ? '#000000' : 'rgba(30, 30, 32, 0.4)'}
        />
      ),
      text: strings.assignedTo,
      categeory: true,
    },
    {
      image: dummyTabIcon,
      text: strings.tabTwo,
      categeory: false,
    },
    {
      image: dummyTabIcon,
      text: strings.tabThree,
      categeory: false,
    },
  ];

  const previousAssignData = [
    {
      date: 'Mar 11, 2023',
      time: '09:23 am',
      profile: macBookIcon,
      name: 'Emma Watson',
      mail: 'emmawatson@cmail.com',
    },
    {
      date: 'Apr 23, 2023',
      time: '11:23 am',
      profile: macBookIcon,
      name: 'James Carter',
      mail: 'jamescarter@cmail.com',
    },
    {
      date: 'Jan 22, 2023 ',
      time: '11:11 am',
      profile: macBookIcon,
      name: 'James Carter',
      mail: 'jamescartmnvhbnnbver@cmail.com',
    },
    {
      date: 'Mar 11, 2023',
      time: '09:23 am',
      profile: macBookIcon,
      name: 'Emma Watson',
      mail: 'emmawatson@cmail.com',
    },
    {
      date: 'Mar 11, 2023',
      time: '09:23 am',
      profile: macBookIcon,
      name: 'Emma Watson',
      mail: 'emmawatson@cmail.com',
    },
    {
      date: 'Mar 11, 2023',
      time: '09:23 am',
      profile: macBookIcon,
      name: 'Emma Watson',
      mail: 'emmawatson@cmail.com',
    },
  ];

  const actionOptions = [
    {
      text: strings.unAssign,
    },
    {
      text: strings.assignToOther,
    },
    {
      text: strings.more,
    },
  ];

  useEffect(() => {
    getEmployeeDetails();
  }, []);

  const getEmployeeDetails = async () => {
    setLoader(true);
    try {
      let response = await getemployeeDetailsWithAssetsApi(
        individual_employee_id
      );
      if (response.data.type === 'success') {
        console.log('get Employee Details', response.data.data);
        setEmployeeData(response.data.data);
        setLoader(false);
      } else {
        setLoader(false);
        console.log('get Employee Details api failed', response.data.message);
      }
    } catch (error) {
      setLoader(false);
      console.log('get Employee Details api catch', error.response.data);
    }
  };

  const handleClick = (index) => {
    if (index === 0) {
      setActiveButton(index);
    }
  };

  // function to open a popup
  const actionPopUp = (data) => {
    setPopupOpen(!isPopupOpen);
    setSelectedAsset(data);
  };

  const tapOnOption = (index) => {
    setActiveOption(index);
    if (index == 0) {
      setTimeout(() => {
        setUnAssignPopUp(true);
        setActiveOption(null);
        setPopupOpen(false);
      }, 200);
    } else if (index === 1) {
      setTimeout(() => {
        setIsModalOpen(true);
        setActiveOption(null);
        setPopupOpen(false);
      }, 200);
    } else {
      setTimeout(() => {
        setActiveOption(null);
        setPopupOpen(false);
      }, 200);
      console.log('tap on more');
    }
  };

  const tapOnUnAssign = async () => {
    try {
      let unassignedResponse = await assetUnAssignApi(selectedAsset?._id);
      if (unassignedResponse.data.type === 'success') {
        // console.log('Asset UnAssign', unassignedResponse.data.data);
        // setAssetData(unassignedResponse.data.data);
        getEmployeeDetails();
      } else {
        console.log(
          'Asset UnAssign api failed',
          unassignedResponse.data.message
        );
      }
    } catch (error) {
      console.log('Asset UnAssign api catch', error.response.message);
    }
  };

  const comfirmAssetAssignToOthers = async () => {
    if (selectedDifferentUser) {
      try {
        let apiData = {
          asset_id: selectedAsset?._id,
          employee_id: selectedDifferentUser,
        };
        let assignToOthersResponse = await assetAssignToOthersApi(apiData);
        if (assignToOthersResponse.data.type === 'success') {
          console.log(
            'asset Assign To Others Api',
            assignToOthersResponse.data.data
          );
          getEmployeeDetails();
          setIsModalOpen(false);
        } else {
          console.log(
            'asset Assign To Others Api failed',
            assignToOthersResponse.data.message
          );
        }
      } catch (error) {
        console.log('asset Assign To Others Api catch', error.response.message);
      }
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setUnAssignPopUp(false);
  };

  const renderTopSection = () => {
    return (
      <div className={styles.topSectionViewStyle}>
        <div className={styles.topLeftSectionStyle}>
          <div className={styles.userImgViewStyle}>
            <img src={avathar2} className={styles.userImgStyle} alt="" />
          </div>
          <div className={styles.userTextViewStyle}>
            <div className={styles.userTextSubViewStyle}>
              <p className={styles.userTitleTextStyle}>
                {employeeData?.full_name}
              </p>
              <div className={styles.dropDownImgViewStyle}>
                <img
                  src={dropDownIcon}
                  className={styles.dropDownImgStyle}
                  alt={strings.dropDownImage}
                />
              </div>
            </div>
            <p className={styles.userSubTitleTextStyle}>
              {strings.accountType} {employeeData?.account_type}
            </p>
          </div>
        </div>
        <div>
          <Button
            title={strings.deleteAccount}
            image={deleteIcon}
            onClick={() => {
              console.log('Delete account tapped');
            }}
            color="light"
            btnStyle={styles.customDeleteBtnStyle}
          />
          <Button
            image={deleteIcon}
            onClick={() => {
              console.log('Delete account tapped');
            }}
            color="light"
            btnStyle={styles.deleteIconStyle}
          />
        </div>
      </div>
    );
  };

  const renderTabSection = () => {
    return (
      <div className={styles.tabSectionViewStyle}>
        {tabsData.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <ButtonTab
                onClick={() => handleClick(index)}
                image={item.categeory}
                icon={item.image}
                titleText={item.text}
                custButtonTabContainerStyle={
                  activeButton === index
                    ? styles.activeTabStyle
                    : styles.inActiveTabStyle
                }
                customtitleTextStyle={
                  activeButton === index
                    ? styles.activeTextStyle
                    : styles.inActiveTextStyle
                }
              />
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  const currentAssignedSection = () => {
    return (
      <div
        className={
          navBarOpen && showOverLayModel
            ? styles.currentAssetHidenStyles
            : styles.currentAssetStyles
        }
      >
        <div className={styles.dateAndTimeTextStyles}>
          <p className={styles.currentlyAssignedTextStyles}>
            {strings.currentlyAssign}
          </p>
          <p className={styles.dateTextStyles}>{strings.dateAndTime}</p>
          <div className={styles.actionBtnLableStyle}></div>
        </div>
        {employeeData?.currently_assigned.map((item, index) => (
          <div key={index} className={styles.assetsDetailsStyles}>
            <div className={styles.assetDetailsRightSectionStyles}>
              <div className={styles.userImgStyles}>
                <img src={macBookIcon} alt="" className={styles.imageWidth} />
              </div>
              <div className={styles.userDetailsStyles}>
                <p className={styles.assetTitleTextStyles}>{item.asset_name}</p>
                <p className={styles.currentMailTextStyles}>
                  #{item.asset_uid}
                </p>
              </div>
            </div>
            <div className={styles.cuurentAssetLeftStyles}>
              <p className={styles.currentMonthTextStyles}>
                {moment(item.assigned_at).format('ll')}
              </p>
              <p className={styles.currentTimeTextStyles}>
                {moment(item.assigned_at).format('LT')}
              </p>
            </div>
            <div
              className={[
                styles.previousAssetLeftStyles,
                styles.btnViewStyle,
              ].join(' ')}
            >
              <Button
                title={strings.actions}
                onClick={() => actionPopUp(item)}
                btnStyle={styles.customBtnStyles}
                image={whiteDownArrow}
                customImageStyle={styles.customImageStyles}
              />
            </div>
          </div>
        ))}
        {renderpopUpSection()}
      </div>
    );
  };

  const renderPreviouslyAssigneedSection = () => {
    return (
      <div
        className={
          navBarOpen && showOverLayModel
            ? styles.currentAssetHidenStyles
            : styles.currentAssetStyles
        }
      >
        <div className={styles.dateAndTimeTextStyles}>
          <p className={styles.currentlyAssignedTextStyles}>
            {strings.previouslyAssign}
          </p>
          <p className={styles.fromDateTextStyles}>{strings.fromDateAndTime}</p>
          <div className={styles.actionBtnLableStyle}>
            <p className={styles.dateTextStyles}>{strings.toDateAndTime}</p>
          </div>
        </div>
        {employeeData?.previously_assigned.map((item, index) => (
          <div className={styles.assetsDetailsStyles}>
            <div className={styles.assetDetailsRightSectionStyles}>
              <div className={styles.userImgStyles}>
                <img src={macBookIcon} alt="" className={styles.imageWidth} />
              </div>
              <div className={styles.userDetailsStyles}>
                <p className={styles.assetTitleTextStyles}>
                  {item.asset.asset_name}
                </p>
                <p className={styles.currentMailTextStyles}>
                  #{item.asset.asset_uid}
                </p>
              </div>
            </div>
            <div className={styles.cuurentAssetLeftStyles}>
              <p className={styles.currentMonthTextStyles}>
                {moment(item.assigned_at).format('ll')}
              </p>
              <p className={styles.currentTimeTextStyles}>
                {moment(item.assigned_at).format('LT')}
              </p>
            </div>
            <div
              className={[
                styles.previousAssetLeftStyles,
                styles.previousAssetLeftStyles2,
              ].join(' ')}
            >
              <div className={styles.cuurentAssetLeftStyles}>
                <p className={styles.currentMonthTextStyles}>
                  {moment(item.un_assigned_at).format('ll')}
                </p>
                <p className={styles.currentTimeTextStyles}>
                  {moment(item.un_assigned_at).format('LT')}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const mobileCurrentSection = () => {
    return (
      <div
        className={
          navBarOpen && showOverLayModel
            ? styles.mobileCurrentStylesTwo
            : styles.mobileCurrentStyles
        }
      >
        <div className={styles.currentDescpStyles}>
          <p className={styles.currentlyTextStyles}>
            {strings.currentlyAssignLabel}
          </p>
          <div className={styles.upArrowImgStyles}>
            <img
              src={rightArrowFillIcon}
              alt=""
              className={styles.imageWidth}
            />
          </div>
        </div>
        {employeeData?.currently_assigned.map((item, index) => (
          <React.Fragment key={index}>
            <AssetEmployeeCard
              userProfile={avathar1}
              title={item.asset_name}
              description={item.asset_uid}
              leftTitle={moment(item.assigned_at).format('ll')}
              leftDesc={moment(item.assigned_at).format('LT')}
              button={true}
              btnAction={() => actionPopUp(item)}
              leftLable={strings.assignedOn}
            />
          </React.Fragment>
        ))}
        {renderpopUpSection()}
      </div>
    );
  };

  const mobilePreviousSection = () => {
    return (
      <div className={styles.mobilePreviousStyles}>
        <div className={styles.previousDescpStyles}>
          <p className={styles.previousTextStyles}>
            {strings.previouslyAssignedTo}
          </p>
          <div
            className={
              showOverLayModel
                ? styles.upArrowImgHideStyles
                : styles.upArrowImgStyles
            }
          >
            <img
              src={rightArrowFillIcon}
              alt=""
              className={styles.imageWidth}
            />
          </div>
        </div>
        <div className={styles.mobilePreviousGapStyles}>
          {employeeData?.previously_assigned.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <AssetEmployeeCard
                  userProfile={macBookIcon}
                  title={item.asset.asset_name}
                  description={item.asset.asset_uid}
                  leftTitle={moment(item.assigned_at).format('ll')}
                  leftDesc={moment(item.assigned_at).format('LT')}
                  rightTitle={moment(item.un_assigned_at).format('ll')}
                  rightDesc={moment(item.un_assigned_at).format('LT')}
                  icon={true}
                  customAssetBottomStyles={styles.customAssetBottomStyles}
                  leftLable={strings.fromDate}
                  rightLable={strings.toDate}
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  };

  const renderBottomSection = () => {
    return (
      <div className={styles.bottomSectionViewStyle}>
        {renderTabSection()}
        {employeeData?.currently_assigned.length > 0 && (
          <>
            {currentAssignedSection()}
            {mobileCurrentSection()}
          </>
        )}
        {employeeData?.previously_assigned.length > 0 && (
          <>
            {renderPreviouslyAssigneedSection()}
            {mobilePreviousSection()}
          </>
        )}
        {width <= 430 ? <Divider /> : ''}
      </div>
    );
  };

  const renderpopUpSection = () => {
    return (
      <Popup
        show={isPopupOpen}
        showOverlay={false}
        onClose={actionPopUp}
        showCloseButton={false}
        popupRef={popupRef}
        customPopUpStyles={styles.employeePopUpStyles}
      >
        <div className={styles.assignPopUpStyles}>
          {actionOptions.map((item, index) => {
            return (
              <p
                key={index}
                onClick={() => {
                  tapOnOption(index);
                }}
                className={
                  activePopUp === index
                    ? styles.unAssignActiveStyles
                    : styles.unAssignUnActiveStyles
                }
              >
                {item.text}
              </p>
            );
          })}
        </div>
      </Popup>
    );
  };

  const renderModalSection = () => {
    return (
      <Popup
        show={isModalOpen}
        showOverlay={true}
        showCloseButton={false}
        popupRef={popupRef}
        customPopUpStyles={styles.popUpStyles}
      >
        <AssignAssetPopUp
          data={selectedAsset}
          onCancel={closeModal}
          setSelectedUser={(data) => {
            setSelectedDifferentUser(data.value);
          }}
          assigned_asset={false}
          onConfirm={comfirmAssetAssignToOthers}
        />
      </Popup>
    );
  };

  const renderUnAssignedMoadal = () => {
    return (
      <Modal
        open={unAssignPopUp}
        title={`${strings.areUnAssignAsset} ${selectedAsset?.asset_uid}?`}
        desc={`${strings.theAssetIsCurrentlyUnAssign} @${employeeData?.full_name}. ${strings.confirmUnAssignAsset}`}
        leftBtnTitle={strings.confirm}
        rightBtnTitle={strings.cancel}
        onLeftBtnClick={() => {
          setUnAssignPopUp(false);
          tapOnUnAssign();
        }}
        onRighBtnClick={() => setUnAssignPopUp(false)}
        modalBlockStyles={styles.unAssignModalStyles}
      />
    );
  };

  const renddedrEmptyDataSection = () => {
    return (
      <div className={styles.emptyDataViewStyle}>
        <p className={styles.emptyDataTextStyle}>No data available</p>
      </div>
    );
  };

  return (
    <div className={styles.conatinerStyle}>
      {userType !== account_types.employee ? (
        <>
          {renderTopSection()}
          {loader ? (
            <LoaderForPages />
          ) : (
            <>
              {employeeData?.currently_assigned.length > 0 ||
              employeeData?.previously_assigned.length > 0
                ? renderBottomSection()
                : renddedrEmptyDataSection()}
              {renderModalSection()}
              {renderUnAssignedMoadal()}
            </>
          )}
        </>
      ) : (
        <DontHaveAccessPage />
      )}
    </div>
  );
};
export default EmployeeOverView;
