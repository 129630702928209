import React from 'react';
import styles from './styles.module.css';
import { useStringData } from 'providers/stringsprovider';

const AssetAndWhatsNew = ({ AssetAndWhatsNewData }) => {
  const { strings } = useStringData();
  return (
    <>
      {AssetAndWhatsNewData &&
        AssetAndWhatsNewData.map((item, index) => (
          <div key={index} className={styles.assetCardStyles}>
            <div>
              <h4 className={styles.assetHeadTextStyles}>{item.name}</h4>
            </div>
            <div className={styles.assetCardBottomStyles}>
              <div className={styles.assetImageBlockStyles}>
                <img
                  src={item.assetImage}
                  alt={strings.loadingImage}
                  className={styles.assetImageStyles}
                />
              </div>
              <div className={styles.assetCardDescBlockStyles}>
                <p className={styles.assetCardDescStyles}>{item.assetDesc}</p>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default AssetAndWhatsNew;
