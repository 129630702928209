import React, { useEffect, useState } from 'react';
import Spinner from 'components/spinner';
import Button from 'components/button';
import {
  avathar1,
  avathar2,
  dottedMenuIcon,
  downArrowIcon,
  plusIcon,
  searchIcon,
} from 'resources/images/Images';
import ClusterCard from 'components/maintenance/cluster-card';
import styles from './styles.module.css';
import AvatarGroup from 'components/avatarGroup';
import Image from 'components/image';
import Capsule from 'components/capsule';
import { clusterTabs } from 'utils/constants';

const Maintenance = () => {
  const [loader, setLoader] = useState(false);
  const [activeClusters, setActiveClusters] = useState('All');
  const [selectedClusterData, setSelectedClusterData] = useState();
  const [isMembersShown, setIsMembersShown] = useState(false);
  const [selectedTab, setSelectedTab] = useState('');
  const avatars = [
    { src: avathar1, label: 'A' },
    { src: avathar2, label: 'B' },
  ];
  useEffect(() => {
    setSelectedTab('Today');
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 500);
  }, [selectedClusterData]);

  const renderLeftSection = () => {
    return (
      <div className={styles.leftSectionViewStyle}>
        <div className={styles.leftTopViewStyle}>
          <div className={styles.topBtnsViewStyle}>
            <Button
              title={'Create cluster'}
              image={plusIcon}
              color="light"
              btnStyle={styles.btnViewStyle}
            />
            <Button
              image={searchIcon}
              color="light"
              btnStyle={styles.btnViewStyle}
            />
          </div>
          {renderLeftHeaderSection()}
        </div>
        {renderClustersSection()}
      </div>
    );
  };

  const renderLeftHeaderSection = () => {
    return (
      <div className={styles.clustersHeaderViewStyle}>
        <p className={styles.clustersHeaderTextStyle}>Clusters</p>
        <div
          className={styles.clustersOptionViewStyles}
          onClick={() => console.log('ccc')}
        >
          <p className={styles.optionsTextStyles}>{activeClusters}</p>
          <div className={styles.downArrowImgStyles}>
            <img
              src={downArrowIcon}
              alt="downArrowIcon"
              className={styles.imageStyles}
            />
          </div>
        </div>
      </div>
    );
  };
  const renderClustersSection = () => {
    return (
      <div className={styles.clustersViewStyle}>
        <ClusterCard onClick={() => console.log('action')} />
      </div>
    );
  };

  const renderRightSection = () => {
    return (
      <div className={styles.rightSectionViewStyle}>
        {renderRightHeaderSection()}
        <div className={styles.rightSectionBottomViewStyle}>
          {renderTabsSection()}
          {renderClusterDetails()}
        </div>
      </div>
    );
  };

  const renderRightHeaderSection = () => {
    return (
      <div className={styles.rightHeaderViewStyle}>
        <div className={styles.rightHeaderDetailsViewStyle}>
          <p className={styles.rightHeaderTitleTextStyle}>Cluster name</p>
          <p className={styles.rightHeaderDescTextStyle}>
            Daily maintenance • 6 Assets
          </p>
        </div>
        <div className={styles.rightHeaderOptionsViewStyle}>
          <AvatarGroup data={avatars} onClick={() => setIsMembersShown(true)} />
          <Image
            url={dottedMenuIcon}
            alt="menu"
            onClick={() => console.log('kkk')}
            imgContainerStyle={styles.menuViewStyles}
            imgStyle={styles.imageStyles}
          />
        </div>
      </div>
    );
  };

  const renderTabsSection = () => {
    return (
      <div className={styles.tabsWrapperStyle}>
        {clusterTabs.map((item, index) => (
          <Capsule
            key={index}
            title={item.title}
            isActive={item.value === selectedTab}
            onClick={() => setSelectedTab(item.value)}
          />
        ))}
      </div>
    );
  };

  const renderClusterDetails = () => {
    return <div className={styles.clusterDetailsViewStyle}></div>;
  };

  return (
    <React.Fragment>
      {loader ? (
        <Spinner />
      ) : (
        <div className={styles.mainContainerStyle}>
          {renderLeftSection()}
          {renderRightSection()}
        </div>
      )}
    </React.Fragment>
  );
};

export default Maintenance;
