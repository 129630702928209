import React, { useContext, createContext, useEffect, useState } from 'react';
import EnglishStrings from 'resources/strings/strings-eng.json';
import SwedishStrings from 'resources/strings/strings-swe.json';

export const AppStringsContext = createContext({
  strings: EnglishStrings,
  currentLanguage: '',
  setLanguage: (_value) => {
    return null;
  },
});

export const AppStringsProvider = (props) => {
  const defaultLang = 'EN';
  const [language, setLanguage] = useState(defaultLang);

  const initialize = async () => {
    setLanguage(localStorage.getItem('language') || defaultLang);
  };

  useEffect(() => {
    initialize();
  }, []);

  const strings = () => {
    console.log('language', language);
    switch (language) {
      case 'EN':
        return EnglishStrings;
      case 'SE':
        return SwedishStrings;
      default:
        return EnglishStrings;
    }
  };

  return (
    <AppStringsContext.Provider
      value={{
        strings: strings(),
        currentLanguage: language,
        setLanguage: (value) => {
          localStorage.setItem('language', value);
          setLanguage(value);
        },
      }}
    >
      {props.children}
    </AppStringsContext.Provider>
  );
};

export const useStringData = () => useContext(AppStringsContext);
