import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import styles from './styles.module.css';
import { useStringData } from 'providers/stringsprovider';
import {
  avathar1,
  backkArrowImg,
  macBookIcon,
  raiseTicketProductImg,
  scanner,
} from 'resources/images/Images';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { raiseTicketApi } from 'networking/api/tickets';
import Input from 'components/input';
import TextAreaInput from 'components/textAreaInput';
import FileUploadInput from 'components/fileUploadInput';
import Button from 'components/button';
import Popup from 'components/popup';
import AssetAndUserCard from 'components/assetanduser';
import { useAuthContext } from 'hooks/useAuthContext';
import Modal from 'components/modal';
const RaiseTicketThroughQR = () => {
  const { strings } = useStringData();
  const navigation = useNavigate();
  const { user } = useAuthContext();
  const location = useLocation();
  const asset = location.state.assetData;
  const [actionLoading, setActionLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const [upLoadIssueResolveImage, setUpLoadIssueResolveImage] = useState('');
  const [isPreViewImg, setIsPreViewImg] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [step, setStep] = useState(1);
  const popupRef = useRef(null);

  const raiseTicketSchema = Yup.object().shape({
    asset: Yup.string().notRequired(),
    subject: Yup.string().required('ticket subject is required'),
    note: Yup.string().required('ticket note is required'),
    image: Yup.string().notRequired(),
  });

  const handleSubmit = async (values, { resetForm }) => {
    if (step === 1) {
      setStep(2);
    } else {
      await handleRaiseTicket(values);
    }
  };

  const handleRaiseTicket = async (values) => {
    try {
      setActionLoading(true);
      let raiseTicketResponse = await raiseTicketApi(values);
      if (raiseTicketResponse.data.type === 'success') {
        setActionLoading(false);
        setIsModalOpen(true);
      } else {
        setApiError(raiseTicketResponse.data.message);
        setActionLoading(false);
      }
    } catch (error) {
      setApiError(error.message);
      setActionLoading(false);
      console.log('raise Ticket catch error', error);
    }
  };
  const formik = useFormik({
    initialValues: {
      asset: asset._id,
      subject: '',
      note: '',
      image: upLoadIssueResolveImage,
    },
    validationSchema: raiseTicketSchema,
    onSubmit: handleSubmit,
  });

  const closeImgPreview = () => {
    setIsPreViewImg(false);
  };

  const tapOnBackArrow = () => {
    if (step === 1) {
      navigation(-1);
    } else {
      setStep(1);
    }
  };

  const rendedrHeaderSection = () => {
    return (
      <div className={styles.headerViewStyle}>
        <div className={styles.headerLeftViewStyle}>
          <div
            onClick={() => tapOnBackArrow()}
            className={styles.backImgViewStyle}
          >
            <img src={backkArrowImg} alt="close" className={styles.imgStyle} />
          </div>
          <p>{strings.back}</p>
        </div>
      </div>
    );
  };

  const renderAssetSection = () => {
    return (
      <div className={styles.assetSectionViewStyle}>
        <div className={styles.assetDetailsViewStyle}>
          <p className={styles.titleTextStyle}>Raise a ticket</p>
          <p className={styles.subTitleTextStyle}>{asset.asset_name}</p>
          <div className={styles.assetIdViewStyle}>
            <p className={styles.assetIdTextStyle}>#{asset.asset_uid}</p>
          </div>
        </div>
        <div className={styles.scannerImgViewStyle}>
          <div className={styles.scannerViewStyle}>
            <img src={scanner} alt="sccanner" className={styles.imgStyle} />
          </div>
        </div>
      </div>
    );
  };

  const renderStepOne = () => {
    return (
      <div className={styles.setpOneSectionViewStyle}>
        <Input
          name="subject"
          lableText={'Subject*'}
          lableTextStyle={styles.lableTextStyle}
          placeholder={'Enter subject'}
          value={formik.values.subject}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          hasError={
            formik.touched.subject && formik.errors.subject
              ? formik.errors.subject
              : ''
          }
        />
        <TextAreaInput
          name="note"
          lableText={'Message or Note*'}
          lableTextStyle={styles.lableTextStyle}
          placeholder={'Enter description here'}
          value={formik.values.note}
          onChange={formik.handleChange}
          customTextAreaStyles={styles.descriptionTextAreaStyles}
          hasError={
            formik.touched.note && formik.errors.note ? formik.errors.note : ''
          }
        />
        <FileUploadInput
          lable="Upload image (Optional)"
          upLoadImage={upLoadIssueResolveImage}
          setupLoadImage={setUpLoadIssueResolveImage}
        />
      </div>
    );
  };

  const renderBtnSection = () => {
    return (
      <div className={styles.btnViewStyle}>
        <p className={styles.errorTextStyle}>{apiError}</p>
        <Button
          type="submit"
          title={
            step === 1
              ? 'Preview'
              : actionLoading
              ? 'Loading...'
              : 'Raise complaint'
          }
          btnStyle={styles.customBtnStyle}
        />
      </div>
    );
  };

  const renderTicketPreview = () => {
    return (
      <div className={styles.ticketPreviewContainerStyle}>
        <div className={styles.timelineContainer}>
          <ul className={styles.timeline}>
            <li className={styles.timelineItem}>
              <div className={styles.indicator}>
                <div className={styles.innerDot}></div>
              </div>
              {renderTicketDetails()}
            </li>
            <li className={styles.timelineItem}>
              <div className={styles.indicator}>
                <div className={styles.innerDot}></div>
              </div>
              {renderAssetDetails()}
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const renderTicketDetails = () => {
    return (
      <div className={styles.event}>
        <div className={styles.descriptionContainer}>
          <p className={styles.timelineTitleTextStyle}>
            {strings.ticketDDetails}
          </p>
          <div className={styles.ticketAssetDetailsStyle}>
            <div className={styles.assetImageViewStyle}>
              <img
                src={
                  formik.values.image
                    ? formik.values.image
                    : raiseTicketProductImg
                }
                alt="img"
                className={styles.assetImageStyle}
              />
            </div>
            <div className={styles.assetDetailStyle}>
              <p className={styles.assetDetailTitleStyle}>
                {formik.values.subject}
              </p>
              <p className={styles.assetDetailDescriptionStyle}>
                {formik.values.note}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAssetDetails = () => {
    return (
      <div className={styles.event}>
        <div className={styles.descriptionContainer}>
          <p className={styles.timelineTitleTextStyle}>
            {strings.assetUserDetails}
          </p>
          <div className={styles.ticketAssetDetailsStyle}>
            <AssetAndUserCard
              assetUserImg={avathar1}
              assetName={user?.full_name}
              email={user?.email}
              customUseIconStyle={styles.customUseIconStyle}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderModalContainer = () => {
    return (
      <Modal
        open={isModalOpen}
        title={'A ticket has been raised'}
        desc={`Your complaint has reached the respective authorities and they are working on resolving it at the earliest. Check your all tickets page for further updates on #${asset.asset_uid}.`}
        leftBtnTitle={'Continue'}
        onLeftBtnClick={() => {
          setIsModalOpen(false);
          navigation('/all-tickets');
        }}
        modalBlockStyles={styles.modalStyle}
      />
    );
  };

  return (
    <div className={styles.mainContainerStyle}>
      {rendedrHeaderSection()}
      {renderAssetSection()}
      <form
        onSubmit={formik.handleSubmit}
        className={styles.ticketDetailsViewStyle}
      >
        {step == 1 ? renderStepOne() : renderTicketPreview()}
        {renderBtnSection()}
      </form>
      {/* {renderImgPreViewModal()} */}
      {renderModalContainer()}
    </div>
  );
};

export default RaiseTicketThroughQR;
