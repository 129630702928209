import React from 'react';
import styles from './styles.module.css';
import PropTypes from 'prop-types';

const Image = (props) => {
  const { url, alt, imgContainerStyle, imgStyle, onClick, children } = props;
  return (
    <div
      className={[imgContainerStyle, styles.imgContainerStyle].join(' ')}
      onClick={onClick}
    >
      <img
        src={url}
        alt={alt}
        className={[styles.imgStyle, imgStyle].join(' ')}
      />
      {children && children}
    </div>
  );
};

Image.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string,
  onClick: PropTypes.func,
  imgContainerStyle: PropTypes.string,
  imgStyle: PropTypes.string,
  children: PropTypes.node,
};

export default Image;
