import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const NumberInput = (props) => {
  const {
    name,
    type,
    value,
    onChange = () => {},
    placeholder,
    hasError,
    lableText,
    lableTextStyle,
    ...rest
  } = props;
  return (
    <div className={styles.inputContainerStyle}>
      {lableText ? (
        <p className={[styles.lableTextStyle, lableTextStyle].join(' ')}>
          {lableText}
        </p>
      ) : null}
      <div className={styles.inputSubContainerStyle}>
        <p className={styles.countryCodeStyle}>+91</p>
        <div className={styles.deviderStyle}></div>
        <input
          type={type || 'text'}
          name={name}
          className={styles.inputStyle}
          value={value}
          onChange={onChange}
          placeholder={placeholder || ''}
          {...rest}
        />
      </div>
      {hasError && <p className={styles.errorTextStyle}>{hasError}</p>}
    </div>
  );
};

NumberInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  hasError: PropTypes.string,
  lableText: PropTypes.string,
  lableTextStyle: PropTypes.string,
};

export default NumberInput;
