import React, { useRef, useState } from 'react';
import styles from './styles.module.css';
import {
  OutlineAllAssetsIcon,
  dottedMenuIcon,
  rightArrowDarkIcon,
} from 'resources/images/Images';
import Popup from 'components/popup';
import { useStringData } from 'providers/stringsprovider';
const ChatHeader = (props) => {
  const {
    title,
    image,
    assetUid,
    actionOptions,
    backAction,
    tabOnBackAction = () => {},
    setSelectedOption,
  } = props;
  const { strings } = useStringData();
  const [showPopUp, setShowPopUp] = useState(false);
  const popupRef = useRef(null);

  const popUpShown = () => {
    setShowPopUp(!showPopUp);
  };

  const renderAssetOptionsPopUpSection = () => {
    return (
      <Popup
        show={showPopUp}
        showOverlay={false}
        onClose={popUpShown}
        showCloseButton={false}
        popupRef={popupRef}
        customPopUpStyles={styles.optionsPopUpStyles}
      >
        <div className={styles.assignPopUpStyles}>
          {actionOptions.map((item, index) => {
            return (
              <p
                key={index}
                onClick={() => {
                  setSelectedOption(item.text);
                  setShowPopUp(false);
                }}
                className={styles.optionTextViewStyle}
              >
                {item.text}
              </p>
            );
          })}
        </div>
      </Popup>
    );
  };
  return (
    <div className={styles.rightTopHeaderContainerStyles}>
      <div className={styles.rightHeaderLeftBlockStyles}>
        {backAction && (
          <div onClick={tabOnBackAction} className={styles.backImgViewStyle}>
            <img
              src={rightArrowDarkIcon}
              alt="Asset Img"
              className={styles.imgStyle}
            />
          </div>
        )}
        <div className={styles.assetImgViewStyle}>
          <div className={styles.imgViewStyle}>
            <img
              src={image ? image : OutlineAllAssetsIcon}
              alt="Asset Img"
              className={styles.imgStyle}
            />
          </div>
        </div>
        <div className={styles.rightHeaderLeftSubBlockStyles}>
          <h5 className={styles.headerTextStyle}>{title}</h5>
          <p className={styles.headerSubTextStyle}>
            Serial no:
            <span className={styles.headerSubTextStyles2}>
              {' #'}
              {assetUid}
            </span>
          </p>
        </div>
      </div>
      <div onClick={popUpShown} className={styles.rightHeaderRightBlockStyles}>
        <div className={styles.imgViewStyle}>
          <img
            src={dottedMenuIcon}
            alt="menuIcon"
            className={styles.imageStyles}
          />
        </div>
        {actionOptions && renderAssetOptionsPopUpSection()}
      </div>
    </div>
  );
};

export default ChatHeader;
