import React, { useEffect, useState } from 'react';
import {
  addAssetsIcon,
  addUserIcon,
  allAssetsIcon,
  allTicketsIcon,
  checkInOutIcon,
  crossIcon,
  dashboardIcon,
  getStartedIcon,
  logo,
  lostFoundIcon,
  maintenanceIcon,
  needHelpIcon,
  plansPricingIcon,
  plusIcon,
  raiseTicketIcon,
  reportingAnalysisIcon,
  reservationIcon,
  rightArrowFillIcon,
  rightArrowIcon,
  searchIcon2,
  toolsSettingIcon,
} from 'resources/images/Images';
import { useLocation, useNavigate } from 'react-router';
import { useAppDataContext } from 'hooks/useAppDataContext';
import { useAuthContext } from 'hooks/useAuthContext';
import useWindowDimensions from 'hooks/useDimensionHook';
import Input from 'components/input';
import styles from './styles.module.css';
import { account_types } from 'utils/constants';
import { useStringData } from 'providers/stringsprovider';
import SearchInput from 'components/searchInput';

const SideBar = ({ toggleSidebar, isOpen, showCloseIcon, showSearchBlock }) => {
  //strings context
  const { strings } = useStringData();
  const { width } = useWindowDimensions();
  const {
    setScreenName,
    setSideBar,
    setIndividualId,
    showOverLayModel,
    navBarOpen,
    setNavBarOpen,
    setShowOverLay,
  } = useAppDataContext();
  const { logout, userType } = useAuthContext();
  const navigation = useNavigate();
  const { pathname } = useLocation();
  const [currrentRoute, setCurrentRoute] = useState(pathname);

  useEffect(() => {
    if (pathname) {
      let data = [...quickActionsData, ...navigationTypesData];
      let screenData = data.find(
        (a) => a.route === pathname || a.children.includes(pathname)
      );
      setScreenName(screenData?.actionName);
      setCurrentRoute(screenData?.route);
      if (
        screenData?.route === '/dashboard' ||
        screenData?.route === '/adduser' ||
        screenData?.route === '/addasset' ||
        screenData?.route === '/add-company' ||
        screenData?.route === '/raise-ticket'
      ) {
        setSideBar(true);
      } else {
        setSideBar(false);
      }
    }
  }, [pathname]);

  const quickActionsData = [
    {
      actionIcon: plusIcon,
      actionName: strings.addCompany,
      route: '/add-company',
      children: [],
      user_types: [account_types.super_admin],
    },
    {
      actionIcon: addAssetsIcon,
      actionName: strings.addAsset,
      route: '/addasset',
      children: [],
      user_types: [account_types.super_admin, account_types.admin],
    },
    {
      actionIcon: addUserIcon,
      actionName: strings.addUser,
      route: '/adduser',
      children: [],
      user_types: [account_types.super_admin, account_types.admin],
    },
    {
      actionIcon: raiseTicketIcon,
      actionName: strings.raiseTicket,
      route: '/raise-ticket',
      children: [],
      user_types: [
        account_types.super_admin,
        account_types.admin,
        account_types.employee,
      ],
    },
  ];
  const navigationTypesData = [
    {
      actionIcon: getStartedIcon,
      actionName: strings.getStarted,
      route: '',
      children: [],
      user_types: [
        account_types.super_admin,
        account_types.admin,
        account_types.employee,
      ],
    },
    {
      actionIcon: dashboardIcon,
      actionName: strings.dashboard,
      route: '/dashboard',
      children: [],
      user_types: [
        account_types.super_admin,
        account_types.admin,
        account_types.employee,
      ],
    },
    {
      actionIcon: getStartedIcon,
      actionName: 'All companies',
      route: '/companiesList',
      children: [],
      user_types: [account_types.super_admin],
    },
    {
      actionIcon: allAssetsIcon,
      actionName: strings.allAssets,
      route: '/assetlist',
      children: ['/asset-overview'],
      user_types: [
        account_types.super_admin,
        account_types.admin,
        account_types.employee,
      ],
    },
    {
      actionIcon: allAssetsIcon,
      actionName: strings.allEmployees,
      route: '/employee-list',
      children: ['/employee-overview'],
      user_types: [account_types.super_admin, account_types.admin],
    },
    {
      actionIcon: allTicketsIcon,
      actionName: strings.allTickets,
      route: '/all-tickets',
      children: [],
      user_types: [
        account_types.super_admin,
        account_types.admin,
        account_types.employee,
      ],
    },
    {
      actionIcon: maintenanceIcon,
      actionName: strings.maintenance,
      route: '/maintenance',
      children: [],
      user_types: [
        account_types.super_admin,
        account_types.admin,
        account_types.employee,
      ],
    },
    {
      actionIcon: reservationIcon,
      actionName: strings.reservationSystem,
      route: '',
      children: [],
      user_types: [
        account_types.super_admin,
        account_types.admin,
        account_types.employee,
      ],
    },
    {
      actionIcon: checkInOutIcon,
      actionName: strings.checkInOut,
      route: '',
      children: [],
      user_types: [
        account_types.super_admin,
        account_types.admin,
        account_types.employee,
      ],
    },
    {
      actionIcon: lostFoundIcon,
      actionName: strings.lostAndFound,
      route: '/lost&found',
      children: [],
      user_types: [
        account_types.super_admin,
        account_types.admin,
        account_types.employee,
      ],
    },
    {
      actionIcon: reportingAnalysisIcon,
      actionName: strings.reportingAndAnalysis,
      route: '',
      children: [],
      user_types: [
        account_types.super_admin,
        account_types.admin,
        account_types.employee,
      ],
    },
    {
      actionIcon: plansPricingIcon,
      actionName: strings.plansAndPricing,
      route: '',
      children: [],
      user_types: [
        account_types.super_admin,
        account_types.admin,
        account_types.employee,
      ],
    },
    {
      actionIcon: toolsSettingIcon,
      actionName: strings.toolsAndSettings,
      route: '',
      children: [],
      user_types: [
        account_types.super_admin,
        account_types.admin,
        account_types.employee,
      ],
    },
    {
      actionIcon: needHelpIcon,
      actionName: strings.logOut,
      route: '',
      children: [],
      user_types: [account_types.super_admin, account_types.admin],
      user_types: [
        account_types.super_admin,
        account_types.admin,
        account_types.employee,
      ],
    },
    // {
    //   actionIcon: needHelpIcon,
    //   actionName: 'Log out',
    //   route: '',
    //   children: [],
    //   user_types: [account_types.super_admin, account_types.admin],
    // },
  ];

  // function to close the sidebar when icon is cliked

  const handleCloseSidebar = () => {
    toggleSidebar();
  };

  const filterRoutesData = (e) => {
    const searchText = e.target.value.toLowerCase();
    const filteredData =
      quickActionsData &&
      navigationTypesData.filter((item) => {
        return JSON.stringify(item).toLowerCase().includes(searchText);
      });
    console.log('data', filteredData);
  };

  const renderLogoBlock = () => {
    return (
      <div className={styles.logoMainContainerStyles}>
        <div className={styles.logoBlockStyles}>
          <img
            src={logo}
            alt={strings.trakrrLogo}
            className={styles.logoStyles}
          />
        </div>
        {showCloseIcon && (
          <div
            className={styles.crossIconBlockStyles}
            onClick={handleCloseSidebar}
          >
            <img
              src={crossIcon}
              alt={strings.crossIcon}
              className={styles.logoStyles}
            />
          </div>
        )}
      </div>
    );
  };

  const renderSearchBlock = () => {
    return (
      <SearchInput
        placeholder="Search anything here."
        // onChange={(e) => filterRoutesData(e)}
        leftIcon={searchIcon2}
      />
    );
  };

  const renderQuickActionsBlock = () => {
    return (
      <div className={styles.quickActionsBlockStyles}>
        <div className={styles.quickActionTextBlockStyles}>
          <p className={styles.quickActionTextStyles}>{strings.quickActions}</p>
        </div>
        <div className={styles.quickActionBottomStyles}>
          {quickActionsData &&
            quickActionsData.map((item, index) => (
              <React.Fragment key={item.route + index}>
                {item.user_types.includes(userType) && (
                  <div
                    onClick={() => {
                      setNavigation(item);
                      setNavBarOpen(false);
                      setShowOverLay(false);
                    }}
                    key={index}
                    className={[
                      styles.quickActionStyles,
                      currrentRoute === item.route && styles.activeRouteStyle,
                    ].join(' ')}
                  >
                    <div className={styles.rightArrowBlockStyles}>
                      <img
                        src={
                          currrentRoute === item.route
                            ? rightArrowFillIcon
                            : rightArrowIcon
                        }
                        alt={strings.rightArrowIcon}
                        className={styles.rightArrowIconStyles}
                      />
                    </div>
                    <div className={styles.actionIconBlockAtyles}>
                      <img
                        src={item.actionIcon}
                        alt={strings.rightArrowIcon}
                        className={styles.actionIconAtyles}
                      />
                    </div>
                    <div className={styles.actionTextBlockStyles}>
                      <p className={styles.actionTextStyles}>
                        {item.actionName}
                      </p>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
        </div>
      </div>
    );
  };

  const setNavigation = (item) => {
    if (item.route !== '') {
      setScreenName(item.actionName);
      navigation(item.route);
      setIndividualId(null);
    }
    if (item.actionName === 'Log out') {
      logout();
    }
  };
  const renderNavigationBlock = () => {
    return (
      <div className={styles.navigationsBlockStyles}>
        <div className={styles.navigationTextBlockStyles}>
          <p className={styles.navigationTextStyles}>{strings.navigation}</p>
        </div>
        <div className={styles.navigationBottomStyles}>
          {navigationTypesData &&
            navigationTypesData.map((item, index) => (
              <React.Fragment key={item.route + index}>
                {item.user_types.includes(userType) && (
                  <div
                    key={index}
                    onClick={() => {
                      setNavigation(item);
                      setNavBarOpen(false);
                      setShowOverLay(false);
                    }}
                    className={[
                      styles.navigationStyles,
                      currrentRoute === item.route && styles.activeRouteStyle,
                    ].join(' ')}
                  >
                    <div className={styles.rightArrowBlockStyles}>
                      <img
                        src={
                          currrentRoute === item.route
                            ? rightArrowFillIcon
                            : rightArrowIcon
                        }
                        alt="rightArrowIcon"
                        className={styles.rightArrowIconStyles}
                      />
                    </div>
                    <div className={styles.actionIconBlockAtyles}>
                      <img
                        src={item.actionIcon}
                        alt="rightArrowIcon"
                        className={styles.actionIconAtyles}
                      />
                    </div>
                    <div className={styles.actionTextBlockStyles}>
                      <p className={styles.actionTextStyles}>
                        {item.actionName}
                      </p>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
        </div>
      </div>
    );
  };

  return (
    <div
      className={
        showOverLayModel
          ? styles.sidebarOuterContainerStyles
          : styles.sidebarOuterContainerStyles2
      }
      style={{ display: width <= 1080 ? (isOpen ? 'block' : 'none') : 'block' }}
    >
      <div className={styles.sidebarInnerContainerStyles}>
        {renderLogoBlock()}
        {showSearchBlock && renderSearchBlock()}
        {renderQuickActionsBlock()}
        {renderNavigationBlock()}
      </div>
    </div>
  );
};

export default SideBar;
