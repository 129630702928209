import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { avathar1, avathar2, profileImg } from 'resources/images/Images';
import Button from 'components/button';
import Input from 'components/input';
import Select from 'react-select';
import { employeeListApi } from 'networking/api/employee';
import { useAuthContext } from 'hooks/useAuthContext';
import { useStringData } from 'providers/stringsprovider';
import { useUserDataContext } from 'hooks/userDataContext';

const AssignAssetPopUp = (props) => {
  const { strings } = useStringData();
  const { otherUsersData } = useUserDataContext();
  const {
    // headerText,
    // descriptionText,
    // lableText,
    cancelButtonStyles,
    onCancel = () => {},
    data,
    selectedUser,
    setSelectedUser,
    onConfirm,
    assigned_asset,
  } = props;
  //state
  const [employeesList, setEmployeesList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState();

  const { company } = useAuthContext();

  useEffect(() => {
    arrangEmployees();
  }, [otherUsersData]);

  const arrangEmployees = async () => {
    try {
      let newData = [];
      otherUsersData.map((employee) => {
        newData.push({
          value: employee._id,
          label: employee.full_name,
        });
      });
      setEmployeesList(newData);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <div className={styles.assignAssetStyles}>
      <div className={styles.assignGapStyles}>
        <div className={styles.assignedTypeStyles}>
          <p className={styles.assetTextStyles}>
            {data.asset_uid}: {data.asset_name}
          </p>
        </div>
        <div className={styles.assetDescpStyles}>
          <p className={styles.headerTextStyles}>
            {assigned_asset
              ? strings.assignAssetDifferntUser
              : strings.assignAsset}
          </p>
          <p className={styles.descpTextStyles}>
            {assigned_asset ? strings.note : strings.selectRegisteruser}
          </p>
        </div>
        {assigned_asset && (
          <div className={styles.currentlyAssignedStyles}>
            <p className={styles.currentlyAssignedTextStyles}>
              {strings.currentlyAssignLabel}
            </p>
            <div className={styles.userDetailsStyles}>
              <div className={styles.userLeftStyles}>
                <div className={styles.imgStyles}>
                  <img src={avathar2} alt="" className={styles.imageWidth} />
                </div>
                <div className={styles.userNameEmailStyles}>
                  <p className={styles.nameStyles}>
                    {data.assigned_to.full_name}
                  </p>
                  <p className={styles.mailStyles}>{data.assigned_to.email}</p>
                </div>
              </div>
              <div className={styles.userRightStyles}>
                <Button
                  btnStyle={styles.adminBtnStyles}
                  title={`${data.assigned_to.account_type} acc.`}
                />
              </div>
            </div>
          </div>
        )}
        <div className={styles.assignDiffernetUserStyles}>
          <p className={styles.assignDifferentTextStyles}>
            {assigned_asset
              ? strings.assignToDiffUser
              : strings.assignToRequired}
          </p>
          <div className={styles.inputStyles}>
            <Select
              className={styles.selectStyle}
              options={employeesList !== null ? employeesList : []}
              placeholder={strings.enterNameOrId}
              value={selectedUser}
              onChange={setSelectedUser}
              isSearchable={true}
              styles={{
                placeholder: (base) => ({
                  ...base,
                  fontSize: '1em',
                  color: '#1e1e2066',
                  fontWeight: 400,
                  // border: 'none',
                  // borderColor: 'red',
                }),
              }}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
            <Button
              btnStyle={styles.addNewBtnStyles}
              title={strings.addNewuser}
              type="submit"
              image={profileImg}
            />
          </div>
        </div>
      </div>
      <div className={styles.buttonStyles}>
        <Button
          btnStyle={styles.confirmBtnStyles}
          title={strings.confirm}
          type="submit"
          onClick={onConfirm}
        />
        <Button
          btnStyle={[styles.cancelBtnStyles, cancelButtonStyles].join(' ')}
          title={strings.cancel}
          type="submit"
          color="light"
          onClick={onCancel}
        />
      </div>
    </div>
  );
};

export default AssignAssetPopUp;
