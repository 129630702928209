import React from 'react';
import { closeGrayIcon, xlFilegrayIcon } from 'resources/images/Images';
import styles from './styles.module.css';
const ButtonTab = (props) => {
  const {
    titleText,
    icon,
    custButtonTabContainerStyle,
    customimgContainerStyle,
    customtitleTextStyle,
    onClick = () => {},
    closeAction = () => {},
    image,
    closeBtn,
    customDividerStyles,
  } = props;
  return (
    <div
      onClick={onClick}
      className={[styles.mainContainerStyle, custButtonTabContainerStyle].join(
        ' '
      )}
    >
      {icon && (
        <div
          className={[styles.imgContainerStyle, customimgContainerStyle].join(
            ' '
          )}
        >
          {image ? <>{icon}</> : <img src={icon} className={styles.imgStyle} />}
        </div>
      )}
      <p className={[styles.titleTextStyle, customtitleTextStyle].join(' ')}>
        {titleText}
      </p>
      {closeBtn && (
        <div onClick={closeAction} className={styles.closeBtnContainerStyle}>
          <div
            className={[styles.dividerStyle, customDividerStyles].join(' ')}
          ></div>
          <div className={styles.closeIconwrapperStyle}>
            <img src={closeGrayIcon} className={styles.closeIconStyle} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ButtonTab;
