import React from 'react';
import styles from './styles.module.css';
import { avathar2, link, mail, sendGray } from 'resources/images/Images';
import Input from 'components/input';
import TextAreaInput from 'components/textAreaInput';
const ChatInput = (props) => {
  const {
    type,
    name,
    value,
    setValue,
    sendAction = () => {},
    onInputFocus = () => {},
  } = props;
  return (
    <div className={styles.containerViewStyle}>
      <div className={styles.iconViewStyle}>
        <img src={avathar2} alt="img" className={styles.imgStyle} />
      </div>
      <div className={styles.inputBoxViewStyle}>
        <TextAreaInput
          placeholder="Type and start a conversation ..."
          value={value}
          onChange={(e) => setValue(e.target.value)}
          numberOfRows={2}
          onFocus={onInputFocus}
          customTextAreaStyles={styles.inputViewStyle}
        />
        <div className={styles.iconViewStyle2}>
          <img src={link} alt="img" className={styles.imgStyle} />
        </div>
        <div className={styles.iconViewStyle2}>
          <img src={mail} alt="img" className={styles.imgStyle} />
        </div>
        <div onClick={sendAction} className={styles.iconViewStyle2}>
          <img src={sendGray} alt="send" className={styles.imgStyle} />
        </div>
      </div>
    </div>
  );
};

export default ChatInput;
