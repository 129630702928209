import React from 'react';
import { checkboxIcon } from 'resources/images/Images';
import styles from './styles.module.css';
const CheckBox = (props) => {
  const { onClick = () => {}, is_CheckBoxActive, descText } = props;
  return (
    <div className={styles.checkboxWrapperStyle}>
      <div
        onClick={onClick}
        className={
          is_CheckBoxActive ? styles.activeCheckBoxStyle : styles.checkBoxStyle
        }
      >
        <img src={checkboxIcon} className={styles.imgStyle} />
      </div>
      <p className={styles.checkBoxTextStyle}>{descText}</p>
    </div>
  );
};

export default CheckBox;
