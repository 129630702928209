import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import { account_types } from 'utils/constants';
import { useAuthContext } from 'hooks/useAuthContext';
import {
  markAsCannotSolveApi,
  markAsCompletedApi,
  markAsResolvedApi,
  markAsRevertApi,
  markAsWorkingApi,
} from 'networking/api/tickets';
import Modal from 'components/modal1';
import Button from 'components/button';
import { closeIcon } from 'resources/images/Images';
import FileUploadInput from 'components/fileUploadInput';
import TextAreaInput from 'components/textAreaInput';
import Spinner from 'components/spinner';
const TicketStatus = (props) => {
  const {
    ticketData,
    setTicketData,
    setAllTicketsData,
    allTickets,
    setClosePopUp,
  } = props;
  const { userType, user } = useAuthContext();
  const [selectedStatus, setSelectedStatus] = useState('');
  const [activeOptions, setActiveOptions] = useState([]);
  const [previousStatusOfTicket, setPreviousStatusOfTicket] = useState([]);
  const [alertModal, setAlertModal] = useState(false);
  const [taskModal, setTaskModdal] = useState(false);
  const [uploadedImg, setUploadedImg] = useState('');
  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const popupRef = useRef();

  let commonOptions = [
    {
      title: 'Assigned',
      desc: 'Indicates that admin has assigned the task & assignees are yet to change the status',
      status: 'Assign',
      userTypes: [account_types.admin, account_types.employee],
    },
    {
      title: 'Working (In-progress)',
      desc: 'As an assignee, if you started working on the task, update the status to working',
      status: 'Working',
      userTypes: [account_types.admin, account_types.employee],
    },
    {
      title: 'Task completed (In-review)',
      desc: 'Assignees should update to this if and only when the assigned task is completed',
      status: 'Completed',
      userTypes: [account_types.admin, account_types.employee],
    },
    {
      title: 'Can’t solve (In-review)',
      desc: 'For some reason if the assigned task cannot be solved, assignees should update the status to this',
      status: "Can't solve",
      userTypes: [account_types.admin, account_types.employee],
    },
  ];
  const adminOptions = [
    {
      title: 'Resolved (Close ticket)',
      desc: 'Choose this if the ticket is resolved. This action will also close the ticket permanently',
      status: 'Resolved',
      userTypes: [account_types.admin],
    },
    {
      title: 'Revert to working',
      desc: 'If the completed task is not up to the mark, choose this to push it back to working',
      status: 'Revert',
      userTypes: [account_types.admin],
    },
  ];
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (
      ticketData.ticket_status === 'In review*' ||
      ticketData.ticket_status === 'In review' ||
      ticketData.ticket_status === 'Closed'
    ) {
      setOptions(adminOptions);
    } else {
      setOptions(commonOptions);
    }
    handleAlreadyUpddatedStatus();
  }, [ticketData]);

  useEffect(() => {
    handleOptions();
  }, [previousStatusOfTicket]);

  const handleOptions = () => {
    if (previousStatusOfTicket.length > 0) {
      previousStatusOfTicket.map((item) => {
        if (item === 'Assign') {
          setActiveOptions(['Working']);
        } else if (item === 'Working') {
          setActiveOptions(['Completed', "Can't solve"]);
        } else if (item === 'Completed' || item === "Can't solve") {
          setActiveOptions(['Resolved', 'Revert']);
        } else if (item === 'Resolved') {
          setActiveOptions([]);
        }
      });
    }
  };

  const handleAlreadyUpddatedStatus = async () => {
    if (ticketData.ticket_status === 'Assigned') {
      setPreviousStatusOfTicket(['Assign']);
    } else if (ticketData.ticket_status === 'In progress') {
      setPreviousStatusOfTicket(['Assign', 'Working']);
    } else if (ticketData.ticket_status === 'In review') {
      setPreviousStatusOfTicket(['Assign', 'Working', 'Completed']);
    } else if (ticketData.ticket_status === 'In review*') {
      setPreviousStatusOfTicket(['Assign', 'Working', "Can't solve"]);
    } else if (ticketData.ticket_status === 'Closed') {
      setPreviousStatusOfTicket(['Resolved']);
    }
  };

  const getBgClolor = (type) => {
    if (type === 'Raise') {
      return styles.pinkBgStyle;
    } else if (type === 'Forward') {
      return styles.grayBgStyle;
    } else if (type === 'Assign') {
      return styles.primaryBgStyle;
    } else if (type === 'Working' || type === 'Revert') {
      return styles.greenBgStyle;
    } else if (type === 'Completed') {
      return styles.orangeBgStyle;
    } else if (type === 'Resolved') {
      return styles.blackBgStyle;
    } else {
      return styles.primaryBgStyle;
    }
  };

  const handleChecked = (value) => {
    if (value === 'Working') {
      markAsWorking();
    } else {
      setAlertModal(true);
    }
  };

  const handleAlertActions = () => {
    if (selectedStatus === 'Completed') {
      setAlertModal(false);
      setTaskModdal(true);
    } else if (selectedStatus === "Can't solve") {
      setAlertModal(false);
      setTaskModdal(true);
    } else if (selectedStatus === 'Resolved') {
      markAsResolved();
    } else if (selectedStatus === 'Revert') {
      setAlertModal(false);
      setTaskModdal(true);
    }
  };

  const handleTaskAction = () => {
    if (selectedStatus === 'Completed') {
      markAsCompleted();
    } else if (selectedStatus === "Can't solve") {
      markAsCannotSolve();
    } else if (selectedStatus === 'Revert') {
      markAsRevert();
    }
  };

  const markAsWorking = async () => {
    try {
      let data = {
        ticket: ticketData._id,
      };
      let response = await markAsWorkingApi(data);
      if (response.data.type === 'success') {
        console.log('mark as working response', response.data.data);
        setTicketData({
          ...ticketData,
          activity: response.data.data.activity,
          ticket_status: 'In progress',
        });
        let xxx = [...allTickets];
        let ticketIndex = xxx.findIndex((data) => data._id === ticketData._id);
        xxx[ticketIndex].ticket_status = 'In progress';
        xxx[ticketIndex].last_activity = response.data.data.activity[0];
        setAllTicketsData(xxx);
      } else {
        console.log('mark as working api failed', response.data.message);
      }
    } catch (error) {
      console.log('mark as working api catch', error);
    }
  };

  const markAsCompleted = async () => {
    setIsLoading(true);
    try {
      let data = {
        ticket: ticketData._id,
        image: uploadedImg,
        note: comment,
      };
      let response = await markAsCompletedApi(data);
      if (response.data.type === 'success') {
        console.log('mark as completed api response', response.data.data);
        setTicketData({
          ...ticketData,
          activity: response.data.data.activity,
          ticket_status: 'In review',
        });
        let xxx = [...allTickets];
        let ticketIndex = xxx.findIndex((data) => data._id === ticketData._id);
        xxx[ticketIndex].ticket_status = 'In review';
        xxx[ticketIndex].last_activity = response.data.data.activity[0];
        setAllTicketsData(xxx);
        setTaskModdal(true);
        setClosePopUp(false);
        setIsLoading(false);
      } else {
        setTaskModdal(true);
        setClosePopUp(false);
        setIsLoading(false);
        console.log('mark as completed api failed', response.data.message);
      }
    } catch (error) {
      setTaskModdal(true);
      setClosePopUp(false);
      setIsLoading(false);
      console.log('mark as completed api catch', error);
    }
  };

  const markAsCannotSolve = async () => {
    setIsLoading(true);
    try {
      let data = {
        ticket: ticketData._id,
        image: uploadedImg,
        note: comment,
      };
      let response = await markAsCannotSolveApi(data);
      if (response.data.type === 'success') {
        console.log('mark as cant solve api response', response.data.data);
        setTicketData({
          ...ticketData,
          activity: response.data.data.activity,
          ticket_status: 'In review*',
        });
        let xxx = [...allTickets];
        let ticketIndex = xxx.findIndex((data) => data._id === ticketData._id);
        xxx[ticketIndex].ticket_status = 'In review*';
        xxx[ticketIndex].last_activity = response.data.data.activity[0];
        setAllTicketsData(xxx);
        setTaskModdal(true);
        setClosePopUp(false);
        setIsLoading(false);
      } else {
        setTaskModdal(true);
        setClosePopUp(false);
        setIsLoading(false);
        console.log('mark as cant solve api failed', response.data.message);
      }
    } catch (error) {
      setTaskModdal(true);
      setClosePopUp(false);
      setIsLoading(false);
      console.log('mark as cant solve api catch', error);
    }
  };

  const markAsResolved = async () => {
    try {
      let data = {
        ticket: ticketData._id,
      };
      let response = await markAsResolvedApi(data);
      if (response.data.type === 'success') {
        console.log('mark as resolved api response', response.data.data);
        setTicketData({
          ...ticketData,
          activity: response.data.data.activity,
          ticket_status: 'Closed',
        });
        let xxx = [...allTickets];
        let ticketIndex = xxx.findIndex((data) => data._id === ticketData._id);
        xxx[ticketIndex].ticket_status = 'Closed';
        xxx[ticketIndex].last_activity = response.data.data.activity[0];
        setAllTicketsData(xxx);
        setAlertModal(false);
        setClosePopUp(false);
      } else {
        setAlertModal(false);
        setClosePopUp(false);
        console.log('mark as resolved api failed', response.data.message);
      }
    } catch (error) {
      setAlertModal(false);
      setClosePopUp(false);
      console.log('mark as resolved api catch', error);
    }
  };

  const markAsRevert = async () => {
    setIsLoading(true);
    try {
      let data = {
        ticket: ticketData._id,
        note: comment,
      };
      let response = await markAsRevertApi(data);
      if (response.data.type === 'success') {
        console.log('mark as revort api response', response.data.data);
        setTicketData({
          ...ticketData,
          activity: response.data.data.activity,
          ticket_status: 'In progress',
        });
        let xxx = [...allTickets];
        let ticketIndex = xxx.findIndex((data) => data._id === ticketData._id);
        xxx[ticketIndex].ticket_status = 'In progress';
        xxx[ticketIndex].last_activity = response.data.data.activity[0];
        setAllTicketsData(xxx);
        setTaskModdal(true);
        setClosePopUp(false);
        setIsLoading(false);
      } else {
        setTaskModdal(true);
        setClosePopUp(false);
        setIsLoading(false);
        console.log('mark as revort api failed', response.data.message);
      }
    } catch (error) {
      setTaskModdal(true);
      setClosePopUp(false);
      setIsLoading(false);
      console.log('mark as revort api catch', error);
    }
  };

  const alertMsgHandleer = () => {
    let message = '';
    if (selectedStatus === 'Completed') {
      message = `It seems like not all checklist items are marked as done. Are you sure you want to change the ticket status to ${selectedStatus}?`;
    } else if (selectedStatus === "Can't solve") {
      message = `Only select this option if the task mentioned in the ticket cannot be solved due to some reason. Are you sure you want to proceed?`;
    } else if (selectedStatus === 'Resolved') {
      message = `Once you marked the ticket as resolved, it will be closed permanently.`;
    } else if (selectedStatus === 'Revert') {
      message = `Once you marked the ticket as revert, it will be pushed back to working.`;
    }
    return message;
  };

  const renderAlertModal = () => {
    return (
      <Modal
        show={alertModal}
        showOverlay={true}
        popupRef={popupRef}
        containerStyle={styles.alertModalViewStyle}
      >
        <div className={styles.alertModalStyle}>
          <div className={styles.alertModalTitleViewStyle}>
            <p className={styles.alertModalTitleTextStyle}>
              {selectedStatus === 'Resolved' || selectedStatus === 'Revert'
                ? `Are you sure you want to mark the ticket ${ticketData.ticket_uid}
              as ${selectedStatus}?`
                : 'Warning!'}
            </p>
            <p className={styles.alertModalDescTextStyle}>
              {alertMsgHandleer()}
            </p>
          </div>
          <div className={styles.alertModalBtnViewStyle}>
            <Button
              title="Cancel"
              color="light"
              onClick={() => {
                setAlertModal(false);
                setSelectedStatus('');
              }}
              btnStyle={styles.btnViewStyle}
            />
            <Button
              title={
                selectedStatus === 'Resolved' || selectedStatus === 'Revert'
                  ? 'Yes, proceed'
                  : 'Yes'
              }
              onClick={() => handleAlertActions()}
              btnStyle={[styles.btnViewStyle, getBgClolor(selectedStatus)].join(
                ' '
              )}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const renderTaskModal = () => {
    return (
      <Modal
        show={taskModal}
        showOverlay={true}
        popupRef={popupRef}
        containerStyle={styles.taskModalViewStyle}
      >
        {isLoading && <Spinner />}
        <div className={styles.taskModalStyle}>
          <div className={styles.taskModalTopSectionStyle}>
            <div className={styles.taskModalHeaderStyle}>
              <p className={styles.alertModalTitleTextStyle}>
                {selectedStatus === 'Completed'
                  ? 'Task completed'
                  : selectedStatus === "Can't solve"
                  ? 'Cannot solve'
                  : 'Revert to working'}
              </p>
              <div
                onClick={() => {
                  setTaskModdal(false);
                  setSelectedStatus('');
                }}
                className={styles.closeIconViewStyle}
              >
                <img src={closeIcon} alt="close" className={styles.imgStyle} />
              </div>
            </div>
            <div className={styles.taskMoadlDetailsViewStyle}>
              <div className={styles.taskMsgViewStyle}>
                <p className={styles.msgTextStyle}>
                  {selectedStatus === 'Completed'
                    ? 'We recommend you to proceed only if the issue is resolved. Once you marked the task as complete, the respective authorities will review the fixes before closing the ticket permanently.'
                    : selectedStatus === "Can't solve"
                    ? 'The status opted requires you to describe the reason why the task cannot be solved. However, attaching the image is optional.'
                    : 'As an admin, you need to describe the reason behind asking employee(s) to revert back to working on the task.'}
                </p>
              </div>
              {(selectedStatus === 'Completed' ||
                selectedStatus === "Can't solve") && (
                <FileUploadInput
                  lable="Asset image post resolving the issue*"
                  upLoadImage={uploadedImg}
                  setupLoadImage={setUploadedImg}
                />
              )}
              <TextAreaInput
                lableText="Add comment (Optional)"
                placeholder="Write your message here"
                value={comment}
                lableTextStyle={styles.addCommentLabelStyles}
                customTextAreaStyles={styles.addCommentTextAreaStyles}
                onChange={(e) => setComment(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.taskModalBtnViewStyle}>
            <Button
              title="Cancel"
              color="light"
              onClick={() => {
                setTaskModdal(false);
                setSelectedStatus('');
                setUploadedImg('');
                setComment('');
              }}
              btnStyle={styles.taskBtnViewStyle}
            />
            <Button
              title={'Proceed'}
              onClick={() => handleTaskAction()}
              disabled={
                selectedStatus === 'Revert' ? false : uploadedImg ? false : true
              }
              btnStyle={styles.taskBtnViewStyle}
            />
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div className={styles.containerStyle}>
      {options.map((eachOption, index) => (
        <React.Fragment key={index}>
          {console.log('activeoptions', activeOptions)}
          {eachOption.userTypes.includes(user.account_type) && (
            <div
              className={[
                styles.eachOptionViewStyle,
                (eachOption.status === 'Completed' ||
                  eachOption.status === "Can't solve" ||
                  eachOption.status === 'Resolved' ||
                  eachOption.status === 'Revert') &&
                  styles.eachOptionViewStyle2,
                !activeOptions.includes(eachOption.status) &&
                  styles.disabledViewStyle,
              ].join(' ')}
            >
              <div className={styles.detailsViewStyle}>
                <p className={styles.titleTextStyle}>{eachOption.title}</p>
                <p className={styles.descTextStyle} c>
                  {eachOption.desc}
                </p>
              </div>
              <div>
                <input
                  type="checkbox"
                  className={[
                    styles.customCheckBoxStyle,
                    (selectedStatus === eachOption.status ||
                      previousStatusOfTicket.includes(eachOption.status)) &&
                      getBgClolor(eachOption.status),
                  ].join(' ')}
                  checked={
                    selectedStatus === eachOption.status ||
                    previousStatusOfTicket.includes(eachOption.status)
                  }
                  disabled={!activeOptions.includes(eachOption.status)}
                  onChange={(e) => {
                    selectedStatus === eachOption.status
                      ? setSelectedStatus('')
                      : setSelectedStatus(eachOption.status);
                    handleChecked(eachOption.status);
                  }}
                />
              </div>
            </div>
          )}
        </React.Fragment>
      ))}
      {renderAlertModal()}
      {renderTaskModal()}
    </div>
  );
};

export default TicketStatus;
