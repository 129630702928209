import React from 'react';
import styles from './styles.module.css';

const SelectProfileCard = (props) => {
  const {
    image,
    profileName,
    profileDesignation,
    onSelectProfile,
    isSelected,
  } = props;

  return (
    <div
      className={
        isSelected
          ? styles.selectedProfileCardStyles
          : styles.profileCardContainerStyles
      }
      onClick={() => onSelectProfile(profileName)}
    >
      <div className={styles.leftProfileImgViewStyles}>
        <img src={image} alt="profileImage" className={styles.imageStyles} />
      </div>
      <div className={styles.rightProfileDetailsViewStyles}>
        <h5 className={styles.profileNameTextStyles}>{profileName}</h5>
        <p className={styles.profileDesignationTextStyles}>
          {profileDesignation}
        </p>
      </div>
    </div>
  );
};

export default SelectProfileCard;
