import React from 'react';
import styles from './styles.module.css';
import { useStringData } from 'providers/stringsprovider';

const Tickets = (props) => {
  const { tickets } = props;
  const { strings } = useStringData();

  const renderTicketHeadingSection = () => {
    return (
      <div className={styles.ticketHeadBlockStyles}>
        <h3 className={styles.ticketHeadTextStyles}>{strings.tickets}</h3>
      </div>
    );
  };

  const renderTicketDataSection = () => {
    return (
      <div className={styles.ticketContentBlockStyles}>
        <div className={styles.ticketsListStyles}>
          {tickets.map((ticket, index) => {
            return (
              <div key={index} className={styles.ticketStyles}>
                <div className={styles.ticketIconBlockStyles}>
                  <img
                    src={ticket.icon}
                    alt="ticket_icon"
                    className={styles.ticketIconStyles}
                  />
                </div>
                <div>
                  <p className={styles.ticketContentTextStyles}>
                    {ticket.content}
                  </p>
                  <p className={styles.ticketTimeTextStyles}>{ticket.time}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderViewAllTextSection = () => {
    return (
      <div className={styles.viewallTextBlockStyles}>
        <p className={styles.viewAllTextStyles}>{strings.viewAlltickets}</p>
      </div>
    );
  };
  return (
    <div className={styles.ticketBlockStyles}>
      {renderTicketHeadingSection()}
      {renderTicketDataSection()}
      {renderViewAllTextSection()}
    </div>
  );
};

export default Tickets;
