import React from 'react';
import styles from './styles.module.css';
import PropTypes from 'prop-types';

const Button = (props) => {
  const {
    color = 'primary',
    type = 'button',
    onClick = () => {},
    title,
    image,
    customImageStyle,
    customImageStyles,
    btnStyle: customBtnStyle,
    disabled = false,
  } = props;
  let colorStyle = styles.primaryBtnStyle;
  if (color === 'dark') {
    colorStyle = styles.darkBtnStyle;
  } else if (color === 'light') {
    colorStyle = styles.whiteBtnStyle;
  } else if (disabled) {
    colorStyle = styles.disabledBtnStyle;
  }

  return (
    <button
      type={type}
      onClick={onClick}
      className={[styles.btnStyle, colorStyle, customBtnStyle].join(' ')}
      disabled={disabled}
    >
      {image && (
        <div className={[styles.imageStyle, customImageStyle].join(' ')}>
          <img
            src={image}
            alt=""
            className={[styles.imageWidth, customImageStyles].join(' ')}
          />
        </div>
      )}
      {title}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'dark', 'light']),
  btnStyle: PropTypes.string,
};

export default Button;
