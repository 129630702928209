import React, { useState, useRef, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  addCategoryIcon,
  addIcon,
  addManuallyBlackIcon,
  addManuallyGrayIcon,
  addUserIcon,
  downArrowGrayIcon,
  downArrowIcon,
  importGrayIcon,
  xlFileBlackIcon,
  xlFileGrayIcon,
} from 'resources/images/Images';
import {
  AccountentIcon,
  DesignerIcon,
  DeveloperIcon,
  PlusIcon,
} from 'resources/svg/svg';
import Button from 'components/button';
import ButtonTab from 'components/buttontab';
import Input from 'components/input';
import NumberInput from 'components/numberInput';
import SuggetionBoard from 'components/suggetionborard';
import styles from './styles.module.css';
import { useAuthContext } from 'hooks/useAuthContext';
import { employeeCreateApi } from 'networking/api/employee';
import Modal from 'components/modal';
import { useNavigate } from 'react-router';
import Select from 'react-select';
import { useAppDataContext } from 'hooks/useAppDataContext';
import { useSpinner } from 'providers/spinnerprovider';
import UploadXLSFile from 'components/uploadXLSFile';
import { downloadUsersXLSFile } from 'networking/endpoints';
import { useStringData } from 'providers/stringsprovider';
import { account_types } from 'utils/constants';
import DontHaveAccessPage from 'components/dontHaveAccess';
import { useUserDataContext } from 'hooks/userDataContext';
const EmployeeRegistration = () => {
  const navigate = useNavigate();
  const { strings } = useStringData();
  const { user, company, userType } = useAuthContext();
  const { isOverlayVisible, showOverLayModel } = useAppDataContext();
  const {
    allEmployeesData,
    setAllEmployeesData,
    setOtherUsersData,
    otherUsersData,
  } = useUserDataContext();
  const { setIsShowLoading } = useSpinner();
  const [xlFileTab, setXlFileTab] = useState(true);
  const [addManuallyTab, setAddmanuallyTab] = useState(false);
  const [importTab, setImportTab] = useState(false);
  const [customCategory, setCustomCategory] = useState('');
  const [is_AddCategory, setIs_AddCategory] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEmpType, setSelectedEmpType] = useState('');
  const [selectedOptions, setSelectedOptions] = useState(strings.selecting);
  const [country_code, setCountry_code] = useState('+91');
  const [uploadedXlsSuccess, setUploadedXlsSuccess] = useState(false);
  const [showOption, setShowOption] = useState(false);
  const selectRef = useRef(null);
  useEffect(() => {
    const handleClickoutSide = (e) => {
      if (selectRef.current && !selectRef.current.contains(e.target)) {
        setShowOption(false);
      }
    };
    document.addEventListener('click', handleClickoutSide);
    return () => {
      document.removeEventListener('click', handleClickoutSide);
    };
  }, [showOption]);

  const registerSchema = Yup.object().shape({
    full_name: Yup.string().required(strings.fullNameRequired),
    employee_id: Yup.string().required(strings.employeeIdRequired),
    email: Yup.string()
      .email(strings.validEmail)
      .required(strings.emailRequired),
    account_type: Yup.string().required(strings.accountTypeRequired),
    phone_number: Yup.object().shape({
      country_code: Yup.string().notRequired(),
      value: Yup.string().required(strings.phoneNumRequired),
    }),
    employee_type: Yup.string().required(strings.employeeTypeRequired),
  });

  const handleUserRegistration = async (values, { resetForm }) => {
    try {
      setIsShowLoading(true);
      setActionLoading(true);
      let data = values;
      // data.phone_number = { country_code: '+91', value: data.phone_number };
      data.account_type = selectedOptions;
      const response = await employeeCreateApi(values);
      console.log('ressss', response);
      if (response.data.type === 'success') {
        setApiError('');
        resetForm();
        setIsModalOpen(true);
        setIsShowLoading(false);
        setAllEmployeesData([response.data.data, ...allEmployeesData]);
        setOtherUsersData([response.data.data, ...otherUsersData]);
        setSelectedOptions(strings.selecting);
      } else {
        setApiError(response.data.message);
        setIsShowLoading(false);
      }
      setActionLoading(false);
    } catch (error) {
      setIsShowLoading(false);
      console.log('API call error:', error);
      setActionLoading(false);
      setApiError(error.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      full_name: '',
      employee_id: '',
      email: '',
      account_type: selectedOptions,
      phone_number: {
        country_code: '+91',
        value: '',
      },
      employee_type: '',
      company: company ? company._id : '',
    },
    validationSchema: registerSchema,
    onSubmit: handleUserRegistration,
  });

  const EmployeeCategories = [
    {
      title: strings.acountant,
      key: 'Accountant',
      icon: (
        <PlusIcon
          colorType={
            selectedEmpType === 'Accountant'
              ? '#000000'
              : 'rgba(30, 30, 32, 0.4)'
          }
          fillOpacity={selectedEmpType === 'Accountant' ? '1' : '0.4'}
        />
      ),

      custom: false,
    },
    {
      title: strings.developer,
      key: 'Developer',
      icon: (
        <DeveloperIcon
          colorType={
            selectedEmpType === 'Developer'
              ? '#000000'
              : 'rgba(30, 30, 32, 0.4)'
          }
          fillOpacity={selectedEmpType === 'Developer' ? '1' : '0.4'}
        />
      ),
      custom: false,
    },
    {
      title: strings.designer,
      key: 'Designer',
      icon: (
        <DesignerIcon
          colorType={
            selectedEmpType === 'Designer' ? '#000000' : 'rgba(30, 30, 32, 0.4)'
          }
          fillOpacity={selectedEmpType === 'Designer' ? '1' : '0.4'}
        />
      ),
      custom: false,
    },
  ];

  const [defaultEmployeeCategories, setDefaultEmployeeCategories] =
    useState(EmployeeCategories);

  const renderTabButtons = () => {
    return (
      <div className={styles.tabWrapperStyle}>
        <ButtonTab
          onClick={() => {
            setXlFileTab(true);
            setAddmanuallyTab(false);
            setImportTab(false);
          }}
          icon={xlFileTab ? xlFileBlackIcon : xlFileGrayIcon}
          titleText={strings.importXLSFile}
          custButtonTabContainerStyle={
            xlFileTab
              ? styles.activeCustButtonTabContainerStyle
              : styles.custButtonTabContainerStyle
          }
          customtitleTextStyle={
            xlFileTab
              ? styles.activeCustomtitleTextStyle
              : styles.customtitleTextStyle
          }
        />
        <ButtonTab
          onClick={() => {
            setXlFileTab(false);
            setAddmanuallyTab(true);
            setImportTab(false);
          }}
          icon={addManuallyTab ? addManuallyBlackIcon : addManuallyGrayIcon}
          titleText={strings.addManually}
          custButtonTabContainerStyle={
            addManuallyTab
              ? styles.activeCustButtonTabContainerStyle
              : styles.custButtonTabContainerStyle
          }
          customtitleTextStyle={
            addManuallyTab
              ? styles.activeCustomtitleTextStyle
              : styles.customtitleTextStyle
          }
        />
        <ButtonTab
          onClick={() => {
            setXlFileTab(false);
            setAddmanuallyTab(false);
            setImportTab(true);
          }}
          icon={importGrayIcon}
          titleText={strings.importFromAPI}
          custButtonTabContainerStyle={
            importTab
              ? styles.activeCustButtonTabContainerStyle
              : styles.custButtonTabContainerStyle
          }
          customtitleTextStyle={
            importTab
              ? styles.activeCustomtitleTextStyle
              : styles.customtitleTextStyle
          }
        />
      </div>
    );
  };

  const renderUploadXlFileContainer = () => {
    return (
      <div className={styles.xlsSectionStyle}>
        <UploadXLSFile
          link={downloadUsersXLSFile}
          fileType={'users'}
          setUploadedSuccess={() => setIsModalOpen(true)}
        />
      </div>
    );
  };

  const options = [
    { value: 'Admin', label: strings.adminAccount },
    { value: 'Employee', label: strings.employeeAccount },
  ];
  const handleSelect = (e) => {
    formik.setFieldValue('account_type', e.value);
    // setSelectedOptions(data);
  };
  const renderAddManuallyContainer = () => {
    return (
      <form
        onSubmit={formik.handleSubmit}
        className={styles.employeeFormWrapperStyle}
      >
        <h2 className={styles.employeeDetailsTitleTextStyle}>
          {strings.employeeDetails}
        </h2>
        <div className={styles.employeeGapStyle}>
          <Input
            name="full_name"
            lableText={strings.fullNameEmployeeRequired}
            lableTextStyle={styles.lableTextStyle}
            placeholder={strings.userNameRequired}
            value={formik.values.full_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={
              formik.touched.full_name && formik.errors.full_name
                ? formik.errors.full_name
                : ''
            }
            customInputContainerStyles={styles.inputStyle}
          />
          <Input
            name="employee_id"
            lableText={strings.employeeUIDRequired}
            lableTextStyle={styles.lableTextStyle}
            placeholder={strings.employeeNumUid}
            value={formik.values.employee_id}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={
              formik.touched.employee_id && formik.errors.employee_id
                ? formik.errors.employee_id
                : ''
            }
            customInputContainerStyles={styles.inputStyle}
          />
        </div>
        <div className={styles.employeeGapStyle}>
          <Input
            name="email"
            lableText={strings.emailIdRequired}
            lableTextStyle={styles.lableTextStyle}
            placeholder={strings.emailIdType}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            hasError={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ''
            }
            customInputContainerStyles={styles.inputStyle}
          />
          <div
            className={styles.accountTypeViewStyle}
            style={{ position: showOption && 'relative' }}
          >
            <p className={styles.lableTextStyle}>{strings.accountTypeLabel}</p>
            {/* <Select
              className={
                showOverLayModel || isOverlayVisible
                  ? styles.hideSelectStyles
                  : styles.selectStyle
              }
              options={options}
              placeholder={strings.selecting}
              value={selectedOptions}
              onChange={handleSelect}
              styles={{
                placeholder: (base) => ({
                  ...base,
                  fontSize: '1em',
                  color: '#1e1e2066',
                  fontWeight: 400,
                }),
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: '44px',
                  background: 'var(--white)',
                  border: '1px solid var(--gray91)',
                  borderRadius: '8px',
                }),
              }}
              components={{
                // DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            /> */}
            <div
              className={styles.selectContainerStyle}
              ref={selectRef}
              onClick={() => setShowOption(!showOption)}
            >
              <div
                className={
                  selectedOptions === strings.selecting
                    ? styles.notSelectedInputStyle
                    : styles.selectInputStyle
                }
              >
                {selectedOptions}
              </div>

              <div className={styles.imgWrapperStyle}>
                <img
                  src={downArrowIcon}
                  alt="icon"
                  className={styles.imgStyle}
                />
              </div>
            </div>
            {showOption && (
              <div className={showOption && styles.selectOptionContainerStyle}>
                {options.map((item, index) => {
                  return (
                    <p
                      key={index}
                      onClick={() => {
                        setSelectedOptions(item.value);
                        setShowOption(false);
                      }}
                      className={
                        selectedOptions === item.value
                          ? styles.activeOptionStyles
                          : styles.selectOptionTextStyle
                      }
                    >
                      {item.label}
                    </p>
                  );
                })}
              </div>
            )}
            <p className={styles.errorTextStyle}>
              {formik.touched.account_type && formik.errors.account_type
                ? formik.errors.account_type
                : ''}
            </p>
          </div>
        </div>
        {/* <Input
          name="password"
          lableText={'Password of the employee*'}
          lableTextStyle={styles.lableTextStyle}
          placeholder={'Enter Password'}
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          hasError={
            formik.touched.password && formik.errors.password
              ? formik.errors.password
              : ''
          }
          customInputContainerStyles={styles.passwordInputContainerStyles}
        /> */}
        <NumberInput
          name="phone_number.value"
          lableText={strings.phoneNumCompulsory}
          lableTextStyle={styles.lableTextStyle}
          placeholder={strings.phoneNumHere}
          value={formik.values.phone_number?.value}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onFocus={() => formik.setFieldError('phone_number.value', '')}
          hasError={
            formik.touched.phone_number?.value &&
            formik.errors.phone_number?.value
              ? formik.errors.phone_number?.value
              : ''
          }
        />
        {renderEmployeeTypes()}
        <p className={styles.errorTextStyle}>{apiError}</p>
        {renderBtns()}
      </form>
    );
  };

  const renderEmployeeTypes = () => {
    return (
      <div className={styles.employeeTypesContainerStyle}>
        <p className={styles.lableTextStyle}>{strings.selectEmployeeType}</p>
        <div className={styles.employeeTypesSubContainerStyle}>
          <div className={styles.eachTypeContainerStyle}>
            {defaultEmployeeCategories.map((item, index) => (
              <div key={index}>
                <ButtonTab
                  titleText={item.title}
                  image={true}
                  icon={item.icon}
                  onClick={() => {
                    formik.setFieldValue('employee_type', item.key);
                    setSelectedEmpType(item.key);
                  }}
                  custButtonTabContainerStyle={
                    selected(item)
                      ? styles.selectedCategoryStyle
                      : styles.unSelectedCategoryStyle
                  }
                  customtitleTextStyle={
                    selected(item) && 'employee_type'
                      ? styles.selectTitleTextStyle
                      : null
                  }
                  closeBtn={item.custom}
                  closeAction={() => {
                    removeCustomeType(item);
                  }}
                />
              </div>
            ))}
          </div>
          <div>
            {!is_AddCategory ? (
              <ButtonTab
                titleText={strings.addNewCategory}
                icon={addCategoryIcon}
                onClick={() => setIs_AddCategory(true)}
                customtitleTextStyle={styles.activeCustomtitleTextStyle}
              />
            ) : (
              <div className={styles.categoryInputStyle}>
                <Input
                  lableTextStyle={styles.lableTextStyle}
                  value={customCategory}
                  onChange={(e) => setCustomCategory(e.target.value)}
                  placeholder={strings.addEmployeeType}
                />
                <Button
                  title={strings.add}
                  onClick={() => {
                    addCategory();
                  }}
                  btnStyle={styles.customBtnStyle}
                />
                <Button
                  title={strings.close}
                  onClick={() => {
                    setIs_AddCategory(false);
                    setCustomCategory('');
                  }}
                  color="light"
                  btnStyle={styles.customBtnStyle}
                />
              </div>
            )}
          </div>
        </div>
        <p className={styles.errorTextStyle}>
          {formik.touched.employee_type && formik.errors.employee_type
            ? formik.errors.employee_type
            : ''}
        </p>
      </div>
    );
  };

  const addCategory = () => {
    let data = {
      title: customCategory,
      key: customCategory,
      icon: (
        <DeveloperIcon
          colorType={
            formik.values.employee_type === customCategory
              ? '#000000'
              : 'rgba(30, 30, 32, 0.4)'
          }
          fillOpacity={
            formik.values.employee_type === customCategory ? '1' : '0.4'
          }
        />
      ),
      custom: true,
    };
    setSelectedEmpType(customCategory);
    formik.setFieldValue('employee_type', customCategory);
    setTimeout(() => {
      setIs_AddCategory(false);
      setCustomCategory('');
      setDefaultEmployeeCategories((prve) => [...prve, data]);
    }, 200);
  };

  const selected = (item) => {
    if (formik.values.employee_type === item.key) {
      return true;
    } else {
      return false;
    }
  };

  const removeCustomeType = (item) => {
    if (formik.employee_type === item.key) {
      formik.setFieldValue('employee_type', '');
    }
    setDefaultEmployeeCategories(
      defaultEmployeeCategories.filter((data) => data.key !== item.key)
    );
  };

  const renderBtns = () => {
    return (
      <div className={styles.btnContainerStyle}>
        <Button
          title={strings.cancel}
          color="light"
          btnStyle={styles.customBtnStyle}
        />
        <Button
          title={actionLoading ? strings.loading : strings.addUsers}
          type={'submit'}
          btnStyle={styles.customBtnStyle}
        />
      </div>
    );
  };

  const renderImportFromApiContainer = () => {
    return <div>{strings.importFromAPI}</div>;
  };

  const renderSuggestionBoxContainer = () => {
    return (
      <SuggetionBoard
        title={
          xlFileTab
            ? strings.suggestionBoardTitleForAddUserByXls
            : strings.suggestionBoardTtleForAddUserByManual
        }
        desc={
          xlFileTab
            ? strings.suggestionBoardDescForAddUserByXls
            : strings.suggestionBoardDescForAddUserByManual
        }
      />
    );
  };

  const renderModalContainer = () => {
    return (
      <Modal
        open={isModalOpen}
        title={strings.newEmployeeAddedSuccessful}
        desc={strings.newEmployeeAddedSuccessfulDesc}
        leftBtnTitle={strings.backToDashBoard}
        rightBtnTitle={strings.showSummary}
        onLeftBtnClick={() => {
          setIsModalOpen(false);
          navigate('/dashboard');
        }}
        onRighBtnClick={() => setIsModalOpen(false)}
      />
    );
  };

  return (
    <div className={styles.mainContainerStyle}>
      {userType !== account_types.employee ? (
        <>
          {renderSuggestionBoxContainer()}
          <div className={styles.subContainerStyle}>
            {renderTabButtons()}
            <div
              className={
                xlFileTab
                  ? styles.employeeWrapperForXlfStyle
                  : styles.employeeWrapperStyle
              }
            >
              {xlFileTab
                ? renderUploadXlFileContainer()
                : addManuallyTab
                ? renderAddManuallyContainer()
                : renderImportFromApiContainer()}
            </div>
          </div>
          {renderModalContainer()}
        </>
      ) : (
        <DontHaveAccessPage />
      )}
    </div>
  );
};

export default EmployeeRegistration;
