import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { useAuthContext } from 'hooks/useAuthContext';
import { useNavigate } from 'react-router';
import {
  avathar1,
  avathar2,
  avathar3,
  avathar4,
  filterIcon,
  searchIcon,
  selectIcon,
} from 'resources/images/Images';
import SearchInput from 'components/searchInput';
import ButtonTab from 'components/buttontab';
import AssetEmployeeCard from 'components/individualAeCard';
import LoaderForPages from 'components/loaderForPages';
import { getAllCompaniesListApi } from 'networking/api/company';
import moment from 'moment';
import { account_types } from 'utils/constants';
import DontHaveAccessPage from 'components/dontHaveAccess';
const CompaniesList = () => {
  const { user, company, userType } = useAuthContext();
  const navigation = useNavigate();
  const [companiesData, setCompaniesData] = useState([]);
  const [filteredData, setFilteredData] = useState(companiesData);
  const [loader, setLoader] = useState(true);
  const listOfImages = [avathar1, avathar2, avathar3, avathar4];
  const [currentImageIndex, setCurrentImageIndex] = useState(
    Math.floor(Math.random() * listOfImages.length)
  );

  useEffect(() => {
    setLoader(false);

    getCompaniesList();
    const randomNumber = Math.floor(Math.random() * listOfImages.length);
    setCurrentImageIndex(randomNumber);
  }, [user]);

  const getCompaniesList = async () => {
    try {
      if (user) {
        setLoader(true);
        const response = await getAllCompaniesListApi(user._id);
        if (response.data.type === 'success') {
          console.log('companiesListApi response', response.data.data);
          setCompaniesData(response.data.data);
          setFilteredData(response.data.data);
          setLoader(false);
        } else {
          console.log('get companies List', response.data.message);
          setLoader(false);
        }
      }
    } catch (error) {
      console.log(error.message);
      setLoader(false);
    }
  };

  const filterCompaniesList = (e) => {
    setFilteredData(
      companiesData.filter((company) =>
        JSON.stringify(company)
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      )
    );
  };

  const renderTopSection = () => {
    return (
      <div className={styles.companiesListTopBlockStyle}>
        <SearchInput
          placeholder="Search company here..."
          onChange={filterCompaniesList}
          leftIcon={searchIcon}
        />
        <ButtonTab
          icon={filterIcon}
          titleText="Filter"
          custButtonTabContainerStyle={styles.filterBtnStyle}
        />
        <ButtonTab
          icon={selectIcon}
          titleText="Select"
          custButtonTabContainerStyle={styles.filterBtnStyle}
        />
      </div>
    );
  };

  const renderHeaderSection = () => {
    return (
      <div className={styles.headerBlockStyle}>
        <p className={styles.headerTextStyle}>Companies</p>
      </div>
    );
  };

  const renderListSection = () => {
    return (
      <div className={styles.cListBottomBlockStyle}>
        <table style={{ width: '100%' }}>
          <thead>
            <tr className={styles.tableHeadingStyle}>
              <td>Company Name</td>
              <td>Email</td>
              <td>Date & Time</td>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 &&
              filteredData.map((company, index) => (
                <tr key={'eList' + index} className={styles.cListDataRowStyle}>
                  <td>
                    <div className={styles.userBlockStyle}>
                      <div className={styles.userImgViewStyle}>
                        <img
                          src={listOfImages[currentImageIndex]}
                          className={styles.userImgStyle}
                          alt="View all"
                        />
                      </div>
                      <div className={styles.titleTextViewStyle}>
                        <p className={styles.titleTextStyle}>
                          {company.company_name}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <p className={styles.titleTextStyle}>{company.email}</p>
                  </td>
                  <td>
                    <div className={styles.titleTextViewStyle}>
                      <p className={styles.titleTextStyle}>
                        {moment(company.created_at).format('ll')}
                      </p>
                      <p className={styles.subTitleTextStyle}>
                        {moment(company.created_at).format('LT')}
                      </p>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderCompanyMobileView = () => {
    return (
      <div className={styles.mobileViewStyles}>
        {filteredData.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <AssetEmployeeCard
                userProfile={listOfImages[currentImageIndex]}
                title={item.company_name}
                description={item.email}
                leftLable={'Created on :'}
                leftTitle={moment(item.created_at).format('ll')}
                leftDesc={moment(item.created_at).format('LT')}
                button={true}
                icon={true}
                customAssetBottomStyles={styles.customAssetBottomStyles}
                // onClick={() => {
                //   navigation('/admin/employee-overview', {
                //     state: { empolyeeId: item._id },
                //   });
                //   setIndividualId(item.employee_id);
                // }}
              />
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  return (
    <div className={styles.clConatinerStyle}>
      {userType === account_types.super_admin ? (
        <>
          {renderTopSection()}
          {renderHeaderSection()}
          {loader ? (
            <LoaderForPages />
          ) : (
            <>
              {renderListSection()}
              {renderCompanyMobileView()}
            </>
          )}
        </>
      ) : (
        <DontHaveAccessPage />
      )}
    </div>
  );
};

export default CompaniesList;
