import React from 'react';
import styles from './styles.module.css';
import PropTypes from 'prop-types';

const Capsule = (props) => {
  const { title, onClick, isActive, customWrapperStyle, customTitleStyle } =
    props;
  return (
    <div
      className={[
        styles.capsuleWrapperStyle,
        isActive && styles.capsuleActiveWrapperStyle,
        customWrapperStyle,
      ].join(' ')}
      onClick={onClick}
    >
      <span className={[styles.capsuleTitleStyle, customTitleStyle].join(' ')}>
        {title}
      </span>
    </div>
  );
};

Capsule.propTypes = {
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  customWrapperStyle: PropTypes.string,
  customTitleStyle: PropTypes.string,
};

export default Capsule;
