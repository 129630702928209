import React from 'react';
import styles from './styles.module.css';
const DontHaveAccessPage = () => {
  return (
    <div className={styles.accessTextViewStyle}>
      <p className={styles.accessTextStyle}>
        You don't have the access to this page
      </p>
    </div>
  );
};
export default DontHaveAccessPage;
