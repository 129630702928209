import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const AvatarGroup = (props) => {
  const {
    data,
    onClick = () => {},
    containerStyle,
    imgContainerStyle,
    imgStyle,
    maxItems = 1,
  } = props;

  if (data.length == 0) {
    return null;
  }

  return (
    <div
      onClick={onClick}
      className={classNames(styles.containerStyle, containerStyle)}
    >
      {data.slice(0, maxItems).map((item, index) => (
        <div
          key={index}
          className={classNames(styles.imgContainerStyle, imgContainerStyle)}
        >
          {item?.src ? (
            <img
              src={item.src}
              alt={item.label}
              className={classNames(styles.imgStyle, imgStyle)}
            />
          ) : (
            item.label?.trim().split('')[0].toUpperCase()
          )}
        </div>
      ))}
      {data.length - maxItems > 0 && (
        <div className={styles.imgContainerStyle}>{`+${
          data.length - maxItems
        }`}</div>
      )}
    </div>
  );
};

AvatarGroup.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      src: PropTypes.string,
    })
  ),
  onClick: PropTypes.func,
  containerStyle: PropTypes.string,
  imgContainerStyle: PropTypes.string,
  imgStyle: PropTypes.string,
  maxItems: PropTypes.number,
};
export default AvatarGroup;
