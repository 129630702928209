import React, { useEffect, useRef, useState } from 'react';
import styles from './style.module.css';
import Input from 'components/input';
import { useAuthContext } from 'hooks/useAuthContext';
import { employeeListApi } from 'networking/api/employee';
import ButtonTab from 'components/buttontab';
import {
  OutlineAllAssetsIcon,
  avathar1,
  avathar2,
  avathar3,
  avathar4,
  downArrowIcon,
  filterIcon,
  searchIcon,
  selectIcon,
} from 'resources/images/Images';
import SearchInput from 'components/searchInput';
import { useNavigate } from 'react-router-dom';
import { useAppDataContext } from 'hooks/useAppDataContext';
import AssetEmployeeCard from 'components/individualAeCard';
import moment from 'moment';
import { useSpinner } from 'providers/spinnerprovider';
import { useStringData } from 'providers/stringsprovider';
import LoaderForPages from 'components/loaderForPages';
import { account_types } from 'utils/constants';
import DontHaveAccessPage from 'components/dontHaveAccess';
import SelectPopup from 'components/selectpopup';
import { useUserDataContext } from 'hooks/userDataContext';

const EmployeeList = () => {
  const { strings } = useStringData();
  const { user, company, userType } = useAuthContext();
  const { allEmployeesData } = useUserDataContext();
  const { setIndividualId } = useAppDataContext();
  const navigation = useNavigate();
  // const [employeeData, setEmployeeData] = useState([]);
  const [filteredData, setFilteredData] = useState(allEmployeesData);
  // const [loader, setLoader] = useState(true);
  const listOfImages = [avathar1, avathar2, avathar3, avathar4];
  const [currentImageIndex, setCurrentImageIndex] = useState(
    Math.floor(Math.random() * listOfImages.length)
  );
  const [isEmployeePopupOpen, setEmployeePopupOpen] = useState(false);
  const [selectStaticOption, setSelectStaticOption] = useState({
    icon: OutlineAllAssetsIcon,
    text: 'All',
  });
  const popupRef = useRef(null);

  useEffect(() => {
    setFilteredData(allEmployeesData);
  }, [allEmployeesData]);

  useEffect(() => {
    const randomNumber = Math.floor(Math.random() * listOfImages.length);
    setCurrentImageIndex(randomNumber);
  }, []);

  /*employee options data*/
  const employeeOptions = [{ icon: OutlineAllAssetsIcon, text: 'All' }];

  // const getEmployeeList = async () => {
  //   try {
  //     if (company) {
  //       setLoader(true);
  //       const response = await employeeListApi(company._id);
  //       if (response.data.type === 'success') {
  //         console.log('employees', response.data.data);
  //         setEmployeeData(response.data.data);
  //         setFilteredData(response.data.data);
  //         setLoader(false);
  //       } else {
  //         console.log('get Employee List', response.data.message);
  //         setLoader(false);
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //     setLoader(false);
  //   }
  // };

  const filterEmployeeList = (e) => {
    setFilteredData(
      allEmployeesData.filter((employee) =>
        JSON.stringify(employee)
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleClickOutsideStaticPopup = (event) => {
    if (isEmployeePopupOpen) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setEmployeePopupOpen(false);
      }
    }
  };

  // function toggle stastic popup
  const toggleStaticPopup = () => {
    setEmployeePopupOpen(!isEmployeePopupOpen);
  };

  const renderTopSection = () => {
    return (
      <div className={styles.employeeListTopBlockStyle}>
        <SearchInput
          placeholder={strings.searchEmployeesHere}
          onChange={filterEmployeeList}
          leftIcon={searchIcon}
        />
        <ButtonTab
          icon={filterIcon}
          titleText={strings.filter}
          custButtonTabContainerStyle={styles.filterBtnStyle}
        />
        <ButtonTab
          icon={selectIcon}
          titleText={strings.select}
          custButtonTabContainerStyle={styles.filterBtnStyle}
        />
      </div>
    );
  };

  const renderHeaderSection = () => {
    return (
      <div className={styles.headerBlockStyle}>
        <p className={styles.headerTextStyle}>{strings.employees}</p>
        {/* <p className={styles.headerTextStyle}>Employees</p> */}
        <SelectPopup
          isPopupOpen={isEmployeePopupOpen}
          togglePopup={toggleStaticPopup}
          selectStatsticOption={selectStaticOption}
          downArrowIcon={downArrowIcon}
          options={employeeOptions}
          handleStasticOptionItemClick={(option) => {
            console.log(option);
            setSelectStaticOption(option);
            toggleStaticPopup();
          }}
          popupRef={popupRef}
          handleClickOutside={handleClickOutsideStaticPopup}
          setPopupOpen={setEmployeePopupOpen}
        />
      </div>
    );
  };

  const renderListSection = () => {
    return (
      <div className={styles.eListBottomBlockStyle}>
        <table style={{ width: '100%' }}>
          <thead>
            <tr className={styles.tableHeadingStyle}>
              <td>{strings.userAndEmp}</td>
              <td>{strings.lastAssign}</td>
              <td>{strings.dateAndTime}</td>
            </tr>
          </thead>
          <tbody>
            {filteredData?.length > 0 ? (
              filteredData?.map((data, index) => (
                <tr
                  key={'eList' + index}
                  className={styles.eListDataRowStyle}
                  onClick={() => {
                    navigation('/employee-overview', {
                      state: { empolyeeId: data._id },
                    });
                    setIndividualId(data.employee_id);
                  }}
                >
                  <td>
                    <div className={styles.userBlockStyle}>
                      <div className={styles.userImgViewStyle}>
                        <img
                          src={listOfImages[currentImageIndex]}
                          className={styles.userImgStyle}
                          alt={strings.viewAll}
                        />
                      </div>
                      <div className={styles.userTextViewStyle}>
                        <p className={styles.titleTextStyle}>
                          {data.full_name}
                        </p>
                        <p className={styles.subTitleTextStyle}>
                          {strings.account} {data.employee_type}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>
                    {' '}
                    {data.asset ? (
                      <div className={styles.userTextViewStyle}>
                        <p className={styles.titleTextStyle}>
                          {data.asset?.asset_name}
                        </p>
                        <p className={styles.subTitleTextStyle}>
                          #{data.asset?.asset_uid}
                        </p>
                      </div>
                    ) : (
                      '- - - -'
                    )}
                  </td>
                  <td>
                    {data.assigned_at ? (
                      <div className={styles.userTextViewStyle}>
                        <p className={styles.titleTextStyle}>
                          {moment(data.assigned_at).format('ll')}
                        </p>
                        <p className={styles.subTitleTextStyle}>
                          {moment(data.assigned_at).format('LT')}
                        </p>
                      </div>
                    ) : (
                      '- - - - '
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <p className={styles.emptyEmployeeDataTextStyles}>
                No employees data
              </p>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const renderUsersMobileView = () => {
    return (
      <div className={styles.mobileViewStyles}>
        {filteredData?.length > 0 ? (
          filteredData?.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <AssetEmployeeCard
                  userProfile={listOfImages[currentImageIndex]}
                  title={item.full_name}
                  description={`${strings.account} ${item.employee_type}`}
                  leftLable={strings.lastAssigned}
                  leftTitle={item.asset ? item.asset.asset_name : '- - -'}
                  leftDesc={item.asset ? item.asset.asset_uid : ''}
                  rightTitle={
                    item.assigned_at
                      ? moment(item.assigned_at).format('ll')
                      : '- - -'
                  }
                  rightDesc={
                    item.assigned_at
                      ? moment(item.assigned_at).format('LT')
                      : ''
                  }
                  icon={true}
                  customAssetBottomStyles={styles.customAssetBottomStyles}
                  onClick={() => {
                    navigation('/employee-overview', {
                      state: { empolyeeId: item._id },
                    });
                    setIndividualId(item.employee_id);
                  }}
                />
              </React.Fragment>
            );
          })
        ) : (
          <p className={styles.emptyEmployeeDataTextStyles}>
            No employees data
          </p>
        )}
      </div>
    );
  };

  return (
    <div className={styles.elConatinerStyle}>
      {userType !== account_types.employee ? (
        <>
          {renderTopSection()}
          {renderHeaderSection()}
          {/* {loader ? (
            <LoaderForPages />
          ) : (
            <> */}
          {renderListSection()}
          {renderUsersMobileView()}
          {/* </>
          )} */}
        </>
      ) : (
        <DontHaveAccessPage />
      )}
    </div>
  );
};

export default EmployeeList;
