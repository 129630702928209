import React from 'react';
import styles from './styles.module.css';
import { useAppDataContext } from 'hooks/useAppDataContext';
import Tickets from 'components/Tickets';
import Notifications from 'components/notifications';
import RecentlyAdded from 'components/recentlyadded';
import AssetAndWhatsNew from 'components/assetAndWhatsNew';
import {
  addAssetData,
  notificationData,
  recentlyAddedData,
  ticketsData,
  whatsNewData,
} from 'resources/dammyData/dammyData';

const RightSideBar = () => {
  const { screenName, emptyData } = useAppDataContext();
  const notificationCard = (cardData) => {
    return (
      <>
        {cardData &&
          cardData.map((item, index) => {
            return (
              <div key={index} className={styles.ticketStyles}>
                <div className={styles.ticketIconBlockStyles}>
                  <img
                    src={item.icon}
                    alt="ticket_icon"
                    className={styles.ticketIconStyles}
                  />
                </div>
                <div>
                  <p className={styles.ticketContentTextStyles}>
                    {item.content}
                  </p>
                  <p className={styles.ticketTimeTextStyles}>{item.time}</p>
                </div>
              </div>
            );
          })}
      </>
    );
  };
  {
    /* {ticketsData &&
            ticketsData.map((item, index) => (
              <div key={index} className={styles.ticketStyles}>
                <div className={styles.ticketIconBlockStyles}>
                  <img
                    src={item.ticketIcon}
                    alt="ticket_icon"
                    className={styles.ticketIconStyles}
                  />
                </div>
                <div>
                  <p className={styles.ticketContentTextStyles}>
                    {item.ticket}
                  </p>
                  <p className={styles.ticketTimeTextStyles}>{item.time}</p>
                </div>
              </div>
            ))} */
  }

  const renderTicketBlock = () => {
    return <Tickets tickets={ticketsData} />;
  };

  const renderRecentlyAddedBlock = () => {
    return <RecentlyAdded recentlyAddedData={recentlyAddedData} />;
  };

  const renderNotificationBlock = () => {
    return <Notifications notifications={notificationData} />;
  };

  const renderAddAssetCard = () => {
    return <AssetAndWhatsNew AssetAndWhatsNewData={addAssetData} />;
  };

  const renderWhatsNewAssetCard = () => {
    return <AssetAndWhatsNew AssetAndWhatsNewData={whatsNewData} />;
  };

  return (
    <div className={styles.rightSideBarOuterBlockstyles}>
      {screenName === 'Dashboard' ? (
        <div className={styles.rightSideBarInnerBlockStyles}>
          {!emptyData ? (
            <>
              {renderTicketBlock()}
              {renderRecentlyAddedBlock()}
              {renderNotificationBlock()}
            </>
          ) : (
            renderWhatsNewAssetCard()
          )}
        </div>
      ) : (
        <div className={styles.rightSideBarInnerBlockStyles}>
          {renderAddAssetCard()}
        </div>
      )}
    </div>
  );
};

export default RightSideBar;
