import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { superAdminDetailsApi } from 'networking/api/admin';
import { employeeDetailsWithCompaniesApi } from 'networking/api/employee';
import { setAxiosSession } from 'utils/auth';
import { getDataFromToken, isTokenExpired } from 'utils/jwt';
import { account_types } from 'utils/constants';
import { userLoginApi } from 'networking/api/auth';

const AuthContext = createContext();

const AuthProvider = (props) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [company, setCompany] = useState(null);
  const [userType, setUserType] = useState(null);
  const [authError, setAuthError] = useState(null);
  const [multipleCompanies, setMultipleCompanies] = useState(false);

  const initialize = useCallback(async () => {
    try {
      const token = localStorage.getItem('accessToken');
      if (token && isTokenExpired(token)) {
        setAxiosSession(token);
        const tokenData = await getDataFromToken(token);
        if (tokenData.type === account_types.super_admin) {
          let response = await superAdminDetailsApi();
          if (response.data.type === 'success') {
            setUser(response.data.data);
            if (response.data.data.companies.length === 1) {
              setCompany(response.data.data.companies[0]);
              setIsLoggedIn(true);
            } else {
              let previousCompany = localStorage.getItem('company');
              let findCompany = response.data.data.companies.find(
                (company) => company._id === previousCompany
              );
              if (findCompany) {
                setCompany(findCompany);
                setIsLoggedIn(true);
              } else {
                setMultipleCompanies(true);
              }
            }
            setUserType(tokenData.type);
            setAxiosSession(token);
          } else {
            setAuthError(response.data.message);
          }
        } else {
          let response = await employeeDetailsWithCompaniesApi(tokenData.id);
          if (response.data.type === 'success') {
            setUser(response.data.data);
            setCompany(response.data.data.companies[0]);
            setUserType(tokenData.type);
            setAxiosSession(token);
            setIsLoggedIn(true);
          } else {
            setAuthError(response.data.message);
          }
        }
        setIsInitialized(true);
      } else {
        setIsInitialized(true);
        setIsLoggedIn(false);
      }
    } catch (error) {
      setIsInitialized(true);
      setIsLoggedIn(false);
      console.log(error);
      setAuthError(error.message);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const userLogin = async (loginData) => {
    try {
      setAuthError(null);
      let response = await userLoginApi(loginData);
      const { type, data, token, message } = response.data;
      if (type === 'success') {
        localStorage.setItem('accessToken', token);
        const tokenData = await getDataFromToken(token);
        if (tokenData.type === account_types.super_admin) {
          setUser(data);
          if (response.data.data.companies.length === 1) {
            setIsLoggedIn(true);
            setCompany(response.data.data.companies[0]);
          } else {
            setMultipleCompanies(true);
          }
          setUserType(tokenData.type);
          setAxiosSession(token);
        } else {
          setIsLoggedIn(true);
          setUser(data);
          console.log(data, 'ytytyyyuu');
          setCompany(data.companies[0]);
          setUserType(tokenData.type);
          setAxiosSession(token);
        }
      } else {
        setUser(null);
        setAuthError(message);
        setIsLoggedIn(false);
      }
    } catch (error) {
      setUser(null);
      setAuthError(error.message);
      setIsLoggedIn(false);
    }
  };
  const logout = () => {
    localStorage.removeItem('accessToken');
    setUser(null);
    setCompany(null);
    localStorage.removeItem('company');
    setAxiosSession(null);
    setIsLoggedIn(false);
    setMultipleCompanies(false);
  };

  const memoizedValue = useMemo(
    () => ({
      isInitialized,
      isLoggedIn,
      user,
      company,
      userType,
      authError,
      multipleCompanies,
      setUser,
      setIsLoggedIn,
      setCompany,
      setAuthError,
      setMultipleCompanies,
      userLogin,
      logout,
    }),
    [
      isInitialized,
      isLoggedIn,
      user,
      company,
      userType,
      authError,
      multipleCompanies,
      setUser,
      setIsLoggedIn,
      setCompany,
      setAuthError,
      setMultipleCompanies,
      userLogin,
      logout,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
