import { useAuthContext } from 'hooks/useAuthContext';
import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { io } from 'socket.io-client';

export const socket = io('http://16.16.121.138:3001', {
  autoConnect: false,
  transports: ['websocket'],
});

const ChatDataContext = createContext();

const ChatDataProvider = (props) => {
  const { company, userType, user, isLoggedIn } = useAuthContext();
  let guest_user = localStorage.getItem('guest_user');

  useEffect(() => {
    if (isLoggedIn && user) {
      let data = {
        usr: user?._id,
        is_guest: false,
      };
      socket.connect();
      socket.emit('join_socket', data);
    } else if (guest_user) {
      let data = {
        usr: guest_user,
        is_guest: true,
      };
      socket.connect();
      socket.emit('join_socket', data);
    }

    return () => {
      socket.disconnect();
    };
  }, [user]);

  const memoizedValue = useMemo(() => ({}), []);

  return (
    <ChatDataContext.Provider value={memoizedValue}>
      {props.children}
    </ChatDataContext.Provider>
  );
};

export { ChatDataContext, ChatDataProvider };
