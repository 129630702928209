import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Avatar = (props) => {
  const { url, imgContainerStyle, imgStyle } = props;
  return (
    <div className={[styles.containerStyle, imgContainerStyle].join(' ')}>
      <img src={url} className={[styles.imgStyle, imgStyle].join(' ')} />
    </div>
  );
};

Avatar.propTypes = {
  url: PropTypes.string.isRequired,
  imgContainerStyle: PropTypes.string,
  imgStyle: PropTypes.string,
};

export default Avatar;
