import React, { useRef } from 'react';
import classNames from 'classnames';
import AssetAndUserCard from 'components/assetanduser';
import styles from './styles.module.css';
import SelectedCard from 'components/selectedCard';

const InputWithPopup = (props) => {
  const {
    lableText,
    onClick = () => {},
    removeSelectedData = () => {},
    removeSelectedAdmin = () => {},
    removeSelectedEmployee = () => {},
    title,
    description,
    assetImage,
    placeholder,
    rightIcon,
    customPopUpContainerStyles,
    children,
    popupOpen,
    errorMsg,
    assetUserImg,
    assetUserName,
    adminDetails,
    employeeDetails,
    customLableTextStyles,
    customSubWrapperStyles,
    customWrapperStyles,
    customInputAndPopupBlockStyles,
  } = props;
  const popUpRef = useRef();

  return (
    <div
      className={classNames(styles.tagAssetWrapperStyle, customWrapperStyles)}
    >
      <p className={classNames(styles.lableTextStyle, customLableTextStyles)}>
        {lableText}
      </p>
      <div
        className={
          (styles.tagAssetInputAndPopupStyles, customInputAndPopupBlockStyles)
        }
      >
        <div
          className={classNames(
            styles.tagAssetSubWrapperStyle,
            customSubWrapperStyles
          )}
        >
          {title ? (
            <AssetAndUserCard
              assetImage={assetImage}
              assetUserImg={assetUserImg}
              assetName={title}
              assignedTo={assetUserName}
              assetUid={description}
              closeIcon={true}
              closeAction={() => removeSelectedData()}
            />
          ) : adminDetails && adminDetails.length > 0 ? (
            <div className={styles.adminDetailsContainerStyles}>
              {adminDetails.map((admin, index) => (
                <SelectedCard
                  key={index}
                  selectedName={admin.full_name}
                  removeSelectedData={() => removeSelectedAdmin(index)}
                />
              ))}
            </div>
          ) : employeeDetails && employeeDetails.length > 0 ? (
            <div className={styles.employeeDetailsContainerStyles}>
              {employeeDetails.map((employee, index) => (
                <SelectedCard
                  key={index}
                  selectedName={employee.full_name}
                  removeSelectedData={() => removeSelectedEmployee(index)}
                />
              ))}
            </div>
          ) : (
            <p className={styles.placeHolderTextStyle}>{placeholder}</p>
          )}
          {rightIcon && (
            <div
              onClick={onClick}
              className={
                popupOpen
                  ? styles.animatedDropdownStyle
                  : styles.dropDownImgViewStyle
              }
            >
              <img
                src={rightIcon}
                alt="dropdown"
                className={styles.dropDownImgStyle}
              />
            </div>
          )}
        </div>
        {errorMsg && <p className={styles.errorTextStyle}>{errorMsg}</p>}
        {popupOpen && (
          <div
            ref={popUpRef}
            className={classNames(
              styles.popUpStyles,
              customPopUpContainerStyles
            )}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export default InputWithPopup;
