import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { avathar2, avathar4, closeIcon } from 'resources/images/Images';
import Avatar from 'components/avatar';
import styles from './styles.module.css';
const MultiSelectInput = (props) => {
  // props
  const {
    options,
    placeholder,
    selectedOptions,
    setSelectedOptions,
    customeMultiInputStyles,
  } = props;
  // states:
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  //   function to handle onfocus wont display the options
  const handleInputChange = (inputValue) => {
    setIsMenuOpen(!!inputValue);
  };
  //   function to select the options
  const handleSelect = (data) => {
    let ss = data.map((dd) => {
      return { label: dd.label, value: dd.value };
    });
    setSelectedOptions(ss);
  };
  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <div className={styles.crossImgStyles}>
        <p className={styles.selectedItemStyles}>{props.children}</p>
        <img
          src={closeIcon}
          alt="custom-cross"
          className={styles.imageStyles}
          onClick={() => props.removeProps.onClick(props.data)}
        />
      </div>
    </components.MultiValue>
  );

  const formatOptionLabel = ({ value, label, employeeType }) => (
    <div className={styles.customOptionsViewStyle}>
      <Avatar url={avathar2} />
      <div className={styles.optionDetainsViewStyle}>
        <p className={styles.lableTextStyle}>{label}</p>
        <p className={styles.lableSubTextStyle}>{employeeType}</p>
      </div>
    </div>
  );
  console.log('selectedOptions', selectedOptions);
  return (
    <div className="app">
      <div className="dropdown-container">
        <Select
          options={options}
          placeholder={placeholder}
          value={selectedOptions}
          formatOptionLabel={formatOptionLabel}
          onChange={handleSelect}
          onInputChange={handleInputChange}
          isSearchable={true}
          isMulti
          menuIsOpen={isMenuOpen}
          components={{ MultiValue }}
          className={[
            styles.selectInputContainerStyles,
            customeMultiInputStyles && styles.customInputViewStyle,
          ].join(' ')}
        />
      </div>
    </div>
  );
};
export default MultiSelectInput;
