import axios from 'utils/axios';
import * as endPoints from 'networking/endpoints';

export const getAssetsChatListApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getAssetsChatList + `?company=${id}`));

export const getAssetChatDataApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getAssetChatData.replace('{id}', id)));

export const getChatMessagesApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getChatMessages.replace('{id}', id)));

export const sendMessageApi = async (data) =>
  Promise.resolve(axios.post(endPoints.sendMessage, data));

export const markAsRetunredApi = async (data) =>
  Promise.resolve(axios.post(endPoints.markAsRetunred, data));

export const markAsRecoveredApi = async (data) =>
  Promise.resolve(axios.post(endPoints.markAsRecovered, data));

export const archiveChatApi = async (data) =>
  Promise.resolve(axios.post(endPoints.archiveChat, data));
