import React from 'react';
import styles from './styles.module.css';
import { useStringData } from 'providers/stringsprovider';

const Notifications = (props) => {
  const { strings } = useStringData();
  const { notifications } = props;
  const renderNotificationHeadingSection = () => {
    return (
      <div className={styles.notificationHeadBlockStyles}>
        <h3 className={styles.notificationHeadTextStyles}>
          {strings.notifications}
        </h3>
      </div>
    );
  };

  const renderNotificationDataSection = () => {
    return (
      <div className={styles.notificationContentBlockStyles}>
        <div className={styles.notificationListStyles}>
          {notifications.map((notification, index) => {
            return (
              <div key={index} className={styles.notificationStyles}>
                <div className={styles.notificationIconBlockStyles}>
                  <img
                    src={notification.icon}
                    alt={strings.ticketIcon}
                    className={styles.notificationIconStyles}
                  />
                </div>
                <div>
                  <p className={styles.notificationContentTextStyles}>
                    {notification.content}
                  </p>
                  <p className={styles.notificationTimeTextStyles}>
                    {notification.time}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderViewAllTextSection = () => {
    return (
      <div className={styles.viewallTextBlockStyles}>
        <p className={styles.viewAllTextStyles}>{strings.viewAlltickets}</p>
      </div>
    );
  };

  return (
    <div className={styles.notificationBlockStyles}>
      {renderNotificationHeadingSection()}
      {renderNotificationDataSection()}
      {renderViewAllTextSection()}
    </div>
  );
};

export default Notifications;
