import React from 'react';
import styles from './styles.module.css';
import { rightClickIcon } from 'resources/images/Images';
const RadioButton = (props) => {
  const { isActive, lable, onSelect = () => {} } = props;
  return (
    <div className={styles.containerStyle}>
      <div
        onClick={onSelect}
        className={
          isActive ? styles.activeRadioBtnStyle : styles.radioBtnViewStyle
        }
      >
        <img
          src={rightClickIcon}
          alt="image"
          className={styles.tickIconStyle}
        />
      </div>
      {lable && (
        <p className={isActive ? styles.activeTextStyle : styles.textStyle}>
          {lable}
        </p>
      )}
    </div>
  );
};

export default RadioButton;
