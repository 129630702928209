import React from 'react';
import styles from './style.module.css';
import { useStringData } from 'providers/stringsprovider';
import { leftArrow, logo, trackrThumbnail } from 'resources/images/Images';

const AuthLayout = ({ showBackText, onBackClick, children }) => {
  const { strings } = useStringData();
  return (
    <div className={styles.authContainerStyle}>
      <div className={styles.leftSectionStyle}>
        <div className={styles.logoWrapperStyle}>
          <img src={logo} className={styles.logoStyle} alt="logo" />
        </div>
        {showBackText && (
          <div
            className={styles.backTextAndImgContainerStyles}
            onClick={onBackClick}
          >
            <div className={styles.backArrowImgStyles}>
              <img
                src={leftArrow}
                className={styles.imageWidth}
                alt={strings.backArrowLogo}
              />
            </div>
            <p className={styles.backTextStyles}>{strings.back}</p>
          </div>
        )}
        {children}
      </div>
      <div className={styles.rightSectionStyle}>
        {/* <img
          src={trackrThumbnail}
          alt="Thumbnail image"
          className={styles.imageStyle}
        /> */}
      </div>
    </div>
  );
};

export default AuthLayout;
