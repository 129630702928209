import React, { createContext, useContext, useState } from 'react';
import LoadingSpinner from 'components/loadingSpinner';

export const SpinnerContext = createContext();

const SpinnerProvider = (props) => {
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [spinnerForPages, setIsSpinnerForPages] = useState(false);
  return (
    <SpinnerContext.Provider
      value={{
        isShowLoading,
        setIsShowLoading,
        spinnerForPages,
        setIsSpinnerForPages,
      }}
    >
      {isShowLoading && <LoadingSpinner />}
      {props.children}
    </SpinnerContext.Provider>
  );
};

export default SpinnerProvider;

export const useSpinner = () => {
  return useContext(SpinnerContext);
};
