import React from 'react';
import styles from "./styles.module.css";
import AssetAndWhatsNew from 'components/assetAndWhatsNew';
import { whatsNewData } from 'resources/dammyData/dammyData';

const WhatsNew = () => {
    return (
        <div className={styles.whatsNewContainerStyles}>
            <AssetAndWhatsNew AssetAndWhatsNewData={whatsNewData} />
        </div>
    )
}

export default WhatsNew;