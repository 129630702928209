import { useContext } from 'react';
import { AppDataContext } from 'providers/appdataprovider';

export const useAppDataContext = () => {
  const context = useContext(AppDataContext);

  if (!context)
    throw new Error('useAuthContext context must be use inside AuthProvider');

  return context;
};
