import React from 'react';
import Tickets from 'components/Tickets';
import Notifications from 'components/notifications';
import RecentlyAdded from 'components/recentlyadded';
import { notificationData, recentlyAddedData, ticketsData } from 'resources/dammyData/dammyData';
import styles from "./styles.module.css";

const Recent = () => {
    return (
        <div className={styles.recentContainerStyles}>
            <Tickets tickets={ticketsData} />
            <RecentlyAdded recentlyAddedData={recentlyAddedData} />
            <Notifications notifications={notificationData} />
        </div>
    )
}

export default Recent