import React, { useState, useRef, useEffect } from 'react';
import styles from './style.module.css';
import {
  avathar1,
  deleteIcon,
  downArrowIcon,
  dropDownIcon,
  macBookIcon,
  optionIcon,
  optionIcon2,
  rightArrowFillIcon,
  warningIcon,
  warningRedIcon,
  whiteDownArrow,
  whiteUpArrow,
} from 'resources/images/Images';
import ButtonTab from 'components/buttontab';
import Button from 'components/button';
import AssetEmployeeCard from 'components/individualAeCard';
import Divider from 'components/divider';
import useWindowDimensions from 'hooks/useDimensionHook';
import {
  AssignedIcon,
  MainteinanceIconTwo,
  PlusIcon,
  ReservationIconTwo,
} from 'resources/svg/svg';
import { useAppDataContext } from 'hooks/useAppDataContext';
import AssignAssetPopUp from 'components/assignAssetPopup';
import Popup from 'components/popup';
import { useLocation, useNavigate } from 'react-router';
import {
  assetAssignApi,
  assetAssignToOthersApi,
  assetDetailsApi,
  assetUnAssignApi,
  markAsLostApi,
} from 'networking/api/asset';
import moment from 'moment';
import Modal from 'components/modal';
import { useSpinner } from 'providers/spinnerprovider';
import { useStringData } from 'providers/stringsprovider';
import LoaderForPages from 'components/loaderForPages';
import { useAuthContext } from 'hooks/useAuthContext';
import { account_types } from 'utils/constants';
import DontHaveAccessPage from 'components/dontHaveAccess';
import { useUserDataContext } from 'hooks/userDataContext';

const AssetOverView = () => {
  //context data
  const { userType } = useAuthContext();
  const { navBarOpen, showOverLayModel, setBtnHtml } = useAppDataContext();
  const { strings } = useStringData();
  const { width } = useWindowDimensions();
  const { getAllAssets, getAllEmployeesWithLastAsset } = useUserDataContext();
  const navigation = useNavigate();
  const location = useLocation();
  const individual_asset_id = location.state.assetId;

  const [loader, setLoader] = useState(true);
  //state
  const [activeButton, setActiveButton] = useState(0);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [activeOption, setActiveOption] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [assetData, setAssetData] = useState();
  const [selectedDifferentUser, setSelectedDifferentUser] = useState('');
  const [isUnAssignedPopUp, setIsUnAssignedPopUp] = useState(false);
  const [assetOptionsPopUp, setAssetOptionsPopUp] = useState(false);
  const [markAsLostModal, setMarkAsLostModal] = useState(false);

  const popupRef = useRef(null);
  // function to open a popup
  const actionPopUp = () => {
    setPopupOpen(!isPopupOpen);
  };
  // function to close the popup onclick the outside
  const handleClickOutside = (event) => {
    if (
      isPopupOpen &&
      popupRef.current &&
      !popupRef.current.contains(event.target)
    ) {
      actionPopUp();
    }
  };

  useEffect(() => {
    getAssetData();
  }, []);

  useEffect(() => {
    if (!assetData?.assigned_to && assetData?.assigned_data.length > 0) {
      setBtnHtml(individualAssetBtnSection);
    } else {
      setBtnHtml(null);
    }
    return () => {
      setBtnHtml(null);
    };
  }, [assetData]);

  const getAssetData = async () => {
    try {
      setLoader(true);
      let response = await assetDetailsApi(individual_asset_id);
      if (response.data.type === 'success') {
        setAssetData(response.data.data);
        console.log('ressss', response.data.data);
        setLoader(false);
      } else {
        console.log('get Asset Data api failed', response.data.message);
        setLoader(false);
      }
    } catch (error) {
      console.log('ressss', error.response.data);
      setLoader(false);
    }
  };

  const handleClick = (index) => {
    setActiveButton(index);
  };

  //function to open pop up & change colors

  const tapOnOption = (index) => {
    setActiveOption(index);
    if (index == 0) {
      setTimeout(() => {
        // tapOnAssetUnAssign();
        setIsUnAssignedPopUp(true);
        actionPopUp();
        setActiveOption(null);
      }, 200);
    } else if (index === 1) {
      setTimeout(() => {
        actionPopUp();
        setIsModalOpen(true);
        setActiveOption(null);
      }, 200);
    } else {
      setTimeout(() => {
        actionPopUp();
        setActiveOption(null);
      }, 200);
      console.log('tap on more');
    }
  };

  const tapOnAssetOption = (index) => {
    setActiveOption(index);
    if (index == 0) {
      console.log('Tap on edit asset');
      setAssetOptionsPopUp(false);
    } else if (index === 1) {
      setAssetOptionsPopUp(false);
      setMarkAsLostModal(true);
      console.log('Tap on mark as lost asset');
    } else {
      console.log('Tap on delete asset');
      setAssetOptionsPopUp(false);
    }
  };

  const tapOnMarkAsLostAction = async () => {
    try {
      let markAslostResponse = await markAsLostApi(assetData?._id);
      if (markAslostResponse.data.type === 'success') {
        console.log('markAslostResponse', markAslostResponse.data.data);
        // setAssetData(
        //   (assetData.asset_status = markAslostResponse.data.data.asset_status)
        // );
        setAssetData({
          ...assetData,
          asset_status: markAslostResponse.data.data.asset_status,
        });
        setMarkAsLostModal(false);
        getAllAssets();
        getAllEmployeesWithLastAsset();
      } else {
        console.log(
          'markAslostResponse api failed',
          markAslostResponse.data.message
        );
      }
    } catch (error) {
      console.log('markAslostResponse api catch', error.response.data);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const tapOnAssignNow = () => {
    setIsModalOpen(true);
  };

  const tapOnAssetUnAssign = async () => {
    try {
      let unassignedResponse = await assetUnAssignApi(assetData?._id);
      if (unassignedResponse.data.type === 'success') {
        console.log('Asset UnAssign', unassignedResponse.data.data);
        setAssetData(unassignedResponse.data.data);
        getAllAssets();
        getAllEmployeesWithLastAsset();
      } else {
        console.log(
          'Asset UnAssign api failed',
          unassignedResponse.data.message
        );
      }
    } catch (error) {
      console.log('Asset UnAssign api catch', error.response.message);
    }
  };

  const comfirmAssetAssignToOthers = async () => {
    if (selectedDifferentUser) {
      try {
        let apiData = {
          asset_id: assetData._id,
          employee_id: selectedDifferentUser,
        };
        let assignToOthersResponse = await assetAssignToOthersApi(apiData);
        if (assignToOthersResponse.data.type === 'success') {
          console.log(
            'asset Assign To Others Api',
            assignToOthersResponse.data.data
          );
          setAssetData(assignToOthersResponse.data.data);
          setIsModalOpen(false);
          getAllAssets();
          getAllEmployeesWithLastAsset();
        } else {
          console.log(
            'asset Assign To Others Api failed',
            assignToOthersResponse.data.message
          );
        }
      } catch (error) {
        console.log('asset Assign To Others Api catch', error.response.data);
      }
    }
  };

  const assignAsset = async () => {
    if (selectedDifferentUser) {
      try {
        let apiData = {
          asset_id: assetData._id,
          employee_id: selectedDifferentUser,
        };
        let assignAssetResponse = await assetAssignApi(apiData);
        if (assignAssetResponse.data.type === 'success') {
          console.log('asset Assign Api', assignAssetResponse.data.data);
          setAssetData(assignAssetResponse.data.data);
          setIsModalOpen(false);
          getAllAssets();
          getAllEmployeesWithLastAsset();
        } else {
          console.log(
            'asset Assign Api failed',
            assignAssetResponse.data.message
          );
        }
      } catch (error) {
        console.log('asset Assign Api catch', error.response.message);
      }
    }
  };

  const assetOptionsAction = () => {
    setAssetOptionsPopUp(!assetOptionsPopUp);
  };

  //mapping
  const buttonsData = [
    {
      image: (
        <AssignedIcon
          colorType={activeButton === 0 ? '#000000' : 'rgba(30, 30, 32, 0.4)'}
        />
      ),
      text: strings.assignedTo,
      categeory: true,
    },
    {
      image: (
        <MainteinanceIconTwo
          colorType={activeButton === 1 ? '#000000' : 'rgba(30, 30, 32, 0.4)'}
        />
      ),
      text: strings.maintenance,
      categeory: true,
    },
    {
      image: (
        <ReservationIconTwo
          colorType={activeButton === 2 ? '#000000' : 'rgba(30, 30, 32, 0.4)'}
        />
      ),
      text: strings.reservation,
      categeory: true,
    },
    {
      image: (
        <PlusIcon
          colorType={activeButton === 3 ? '#000000' : 'rgba(30, 30, 32, 0.4)'}
        />
      ),
      text: strings.addFilters,
      categeory: true,
    },
  ];

  const actionOptions = [
    {
      text: strings.unAssign,
    },
    {
      text: strings.assignToOther,
    },
    {
      text: strings.more,
    },
  ];

  const assetOptions = ['Edit details', 'Mark as lost', 'Delete asset'];

  const individualAssetBtnSection = () => {
    return (
      <Button
        title={strings.assignNow}
        onClick={tapOnAssignNow}
        disabled={assetData?.asset_status === 'Marked lost' ? true : false}
      />
    );
  };

  const renderBannerSection = () => {
    return (
      <div className={styles.renderBannerSectionStyle}>
        <div className={styles.bannerleftStyle}>
          <div className={styles.imageSectionStyle}>
            <div className={styles.imageStyle}>
              <img
                src={assetData?.image || macBookIcon}
                alt=""
                className={styles.imageWidth}
              />
            </div>
          </div>
          <div className={styles.assetDescpSection}>
            <div className={styles.titleViewStyle}>
              <h3 className={styles.headerTextStyles}>
                {assetData?.asset_uid}:{assetData?.asset_name}
              </h3>
              <div className={styles.dropDownImgStyles}>
                <img
                  src={downArrowIcon}
                  alt={strings.dropDownImage}
                  className={styles.imageWidth}
                />
              </div>
            </div>
            <p className={styles.regstTextStyles}>
              {strings.assetRegdOn} {moment(assetData?.created_at).format('ll')}
            </p>
          </div>
        </div>
        <div className={styles.bannerRightStyles}>
          <ButtonTab
            icon={optionIcon}
            titleText={strings.options}
            onClick={assetOptionsAction}
            customtitleTextStyle={styles.customtitleTextStyle}
          />
          {renderAssetOptionsPopUpSection()}
        </div>
      </div>
    );
  };

  const renderAssignNowSection = () => {
    return (
      <div className={styles.assignNowSectionStyle}>
        {assetData?.asset_status !== 'Marked lost' ? (
          <>
            {!assetData?.assigned_to && assetData?.assigned_data.length > 0 && (
              <div className={styles.unAssignedStatusViewStyle}>
                <div className={styles.infoImgViewStyle}>
                  <img
                    src={warningIcon}
                    alt={strings.warningIcon}
                    className={styles.infoImgStyle}
                  />
                </div>
                <p className={styles.unAssignedInfoTextStyle}>
                  {strings.assetIsUnassigned}
                </p>
              </div>
            )}
            {!assetData?.assigned_to && !assetData?.assigned_data.length > 0 ? (
              <>{renderNeverAssignAssetMobileSection()}</>
            ) : (
              <>
                {assetData?.asset_status !== 'Marked lost' && (
                  <Button
                    title={strings.assignNow}
                    onClick={() => tapOnAssignNow()}
                    btnStyle={styles.assignNowBtnStyle}
                    disabled={
                      assetData?.asset_status === 'Marked lost' ? true : false
                    }
                  />
                )}
              </>
            )}
          </>
        ) : (
          <div className={styles.markAslostViewStyle}>
            <div className={styles.infoImgViewStyle}>
              <img
                src={warningRedIcon}
                alt={strings.warningIcon}
                className={styles.infoImgStyle}
              />
            </div>
            <p className={styles.unAssignedInfoTextStyle}>
              {strings.assetMarkAsLost}
            </p>
          </div>
        )}
      </div>
    );
  };

  const renderNeverAssignAssetMobileSection = () => {
    return (
      <div className={styles.neverAssignedContainerStyles}>
        <div className={styles.neverAssignedDescAndImgStyles}>
          <div className={styles.infoImgViewStyle}>
            <img
              src={warningIcon}
              alt={strings.warningIcon}
              className={styles.infoImgStyle}
            />
          </div>
          <p className={styles.neverAssignedInfoTextStyle}>
            {strings.assetIsNeverAssigned}
          </p>
        </div>
        <div className={styles.assignNowAndDeleteButtonStyles}>
          <Button
            title={strings.assignNow}
            onClick={tapOnAssignNow}
            btnStyle={styles.assignedNowButtonStyle}
          />
          <Button
            title={strings.deleteAsset}
            // onClick={tapOnAssignNow}
            btnStyle={styles.deleteNowBtnStyle}
          />
        </div>
      </div>
    );
  };

  const renderTabsSection = () => {
    return (
      <div className={styles.buttonWrapperStyles}>
        {buttonsData.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <ButtonTab
                onClick={() => handleClick(index)}
                image={item.categeory}
                icon={item.image}
                titleText={item.text}
                custButtonTabContainerStyle={`
              ${
                activeButton === index
                  ? styles.customActiveTabStyle
                  : styles.customTabStyle
              }`}
                customtitleTextStyle={` ${
                  activeButton === index
                    ? styles.customActiveTextStyle
                    : styles.customTextStyle
                }`}
              />
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  const renderBottomSection = () => {
    return (
      <div className={styles.bottomStyles}>
        {(assetData?.assigned_to || assetData?.assigned_data.length > 0) &&
          renderTabsSection()}
        {assetData?.assigned_to && (
          <>
            {currentAssetSection()}
            {mobileCurrentSection()}
          </>
        )}
        {assetData?.assigned_data?.length > 0 && (
          <>
            {previousAssetSection()}
            {mobilePreviousSection()}
            {width <= 430 ? <Divider /> : ''}
          </>
        )}
      </div>
    );
  };

  const currentAssetSection = () => {
    return (
      <div
        className={
          navBarOpen && showOverLayModel
            ? styles.hiddenStyles
            : styles.currentAssetStyles
        }
      >
        <div className={styles.dateAndTimeTextStyles}>
          <p className={styles.dateTextStyles}>{strings.dateAndTime}</p>
          <p className={styles.currentlyAssignedTextStyles}>
            {strings.currentlyAssignLabel}
          </p>
          <div></div>
        </div>
        <div className={styles.assetsDetailsStyles}>
          <div className={styles.cuurentAssetLeftStyles}>
            <p className={styles.currentMonthTextStyles}>
              {moment(assetData?.assigned_at).format('ll')}
            </p>
            <p className={styles.currentTimeTextStyles}>
              {moment(assetData?.assigned_at).format('LT')}
            </p>
          </div>
          <div
            className={styles.cuurentAssetMiddleStyles}
            onClick={() => {
              if (assetData) {
                navigation('/employee-overview', {
                  state: { empolyeeId: assetData.assigned_to._id },
                });
              }
            }}
          >
            <div className={styles.userImgStyles}>
              <img src={avathar1} alt="" className={styles.imageWidth} />
            </div>
            <div className={styles.userDetailsStyles}>
              <p className={styles.currentUserTextStyles}>
                {assetData?.assigned_to?.full_name}
              </p>
              <p className={styles.currentMailTextStyles}>
                {assetData?.assigned_to?.email}
              </p>
            </div>
          </div>
          <div>
            <Button
              title={strings.actions}
              onClick={actionPopUp}
              btnStyle={styles.customBtnStyles}
              image={whiteDownArrow}
              disabled={
                assetData?.asset_status === 'Marked lost' ? true : false
              }
              customImageStyle={styles.customImageStyles}
            />
            {/* {modalSection()} */}
            {renderpopUpSection()}
          </div>
        </div>
      </div>
    );
  };

  const renderpopUpSection = () => {
    return (
      <Popup
        show={isPopupOpen}
        showOverlay={false}
        onClose={actionPopUp}
        showCloseButton={false}
        popupRef={popupRef}
        customPopUpStyles={styles.assetPopUpStyles}
        // handleClickOutside={handleClickOutside}
      >
        <div className={styles.assignPopUpStyles}>
          {actionOptions.map((item, index) => {
            return (
              <p
                key={index}
                onClick={() => {
                  tapOnOption(index);
                }}
                className={
                  activeOption === index
                    ? styles.unAssignActiveStyles
                    : styles.unAssignUnActiveStyles
                }
              >
                {item.text}
              </p>
            );
          })}
        </div>
      </Popup>
    );
  };

  const renderAssetOptionsPopUpSection = () => {
    return (
      <Popup
        show={assetOptionsPopUp}
        showOverlay={false}
        onClose={assetOptionsAction}
        showCloseButton={false}
        popupRef={popupRef}
        customPopUpStyles={styles.assetOptionsPopUpStyles}
        // handleClickOutside={handleClickOutside}
      >
        <div className={styles.assignPopUpStyles}>
          {assetOptions.map((item, index) => {
            return (
              <p
                key={index}
                onClick={() => {
                  tapOnAssetOption(index);
                }}
                className={
                  item === 'Delete asset'
                    ? styles.deleteAssetOptionTextStyle
                    : styles.unAssignUnActiveStyles
                }
              >
                {item}
              </p>
            );
          })}
        </div>
      </Popup>
    );
  };

  const renderModalSection = () => {
    return (
      <Popup
        show={isModalOpen}
        showOverlay={true}
        showCloseButton={false}
        popupRef={popupRef}
        customPopUpStyles={styles.popUpStyles}
        customOverLayStyles={styles.serachBarOverLayStyles}
      >
        <AssignAssetPopUp
          data={assetData}
          onCancel={closeModal}
          setSelectedUser={(data) => {
            setSelectedDifferentUser(data.value);
          }}
          assigned_asset={assetData?.assigned_to ? true : false}
          onConfirm={
            assetData?.assigned_to ? comfirmAssetAssignToOthers : assignAsset
          }
        />
      </Popup>
    );
  };

  const renderUnAssignedPopUpSection = () => {
    return (
      <Modal
        open={isUnAssignedPopUp}
        title={`${strings.areUnAssignAsset}#${assetData?.asset_uid}?`}
        desc={`${strings.theAssetIsCurrentlyUnAssign} @${assetData?.assigned_to?.full_name}. ${strings.confirmUnAssignAsset}`}
        leftBtnTitle={strings.confirm}
        rightBtnTitle={strings.cancel}
        onLeftBtnClick={() => {
          setIsUnAssignedPopUp(false);
          tapOnAssetUnAssign();
        }}
        onRighBtnClick={() => setIsUnAssignedPopUp(false)}
        modalBlockStyles={styles.unAssignModalStyles}
      />
    );
  };

  const previousAssetSection = () => {
    return (
      <div className={styles.previousAssetStyles}>
        <div className={styles.dateAndTimeTextStyles}>
          <p className={styles.dateTextStyles}>{strings.dateAndTime}</p>
          <p className={styles.currentlyAssignedTextStyles}>
            {strings.previouslyAssignedTo}
          </p>
          <div></div>
        </div>
        {assetData?.assigned_data.map((item, index) => {
          return (
            // <React.Fragment >
            <div key={index} className={styles.previousDetailsStyles}>
              <div className={styles.previousAssetLeftStyles}>
                <p className={styles.currentMonthTextStyles}>
                  {moment(item.assigned_at).format('ll')}
                </p>
                <p className={styles.currentTimeTextStyles}>
                  {moment(item.assigned_at).format('LT')}
                </p>
              </div>
              {/* <div className={styles.previousAssetMiddleStylesOne}> */}
              <div
                className={styles.previousAssetMiddleStyles}
                onClick={() => {
                  if (item) {
                    navigation('/employee-overview', {
                      state: { empolyeeId: item.assigned_to._id },
                    });
                  }
                }}
              >
                <div className={styles.userImgStyles}>
                  <img src={avathar1} alt="" className={styles.imageWidth} />
                </div>
                <div className={styles.userDetailsStyles}>
                  <p className={styles.currentUserTextStyles}>
                    {item.assigned_to.full_name}
                  </p>
                  <p className={styles.currentMailTextStyles}>
                    {item.assigned_to.email}
                  </p>
                </div>
              </div>
              {/* </div> */}
              <div className={styles.imgDeleteStyles}>
                <div className={styles.deleteImgWidthStyles}>
                  <img src={deleteIcon} alt="" className={styles.imageWidth} />
                </div>
              </div>
            </div>
            // </React.Fragment>
          );
        })}
      </div>
    );
  };

  const mobileCurrentSection = () => {
    return (
      <div
        className={
          navBarOpen && showOverLayModel
            ? styles.mobileCurrentStylesTwo
            : styles.mobileCurrentStyles
        }
      >
        <div className={styles.currentDescpStyles}>
          <p className={styles.currentlyTextStyles}>
            {strings.currentlyAssignLabel}
          </p>
          <div className={styles.upArrowImgStyles}>
            <img
              src={rightArrowFillIcon}
              alt=""
              className={styles.imageWidth}
            />
          </div>
        </div>
        <AssetEmployeeCard
          userProfile={avathar1}
          title={assetData?.assigned_to?.full_name}
          description={assetData?.assigned_to?.email}
          leftTitle={moment(assetData?.assigned_at).format('ll')}
          leftDesc={moment(assetData?.assigned_at).format('LT')}
          button={true}
          btnAction={actionPopUp}
          btnDisabled={assetData?.asset_status === 'Marked lost' ? true : false}
        />
        {renderpopUpSection()}
      </div>
    );
  };

  const mobilePreviousSection = () => {
    return (
      <div className={styles.mobilePreviousStyles}>
        <div className={styles.previousDescpStyles}>
          <p className={styles.previousTextStyles}>
            {strings.previouslyAssignedTo}
          </p>
          <div className={styles.upArrowImgStyles}>
            <img
              src={rightArrowFillIcon}
              alt=""
              className={styles.imageWidth}
            />
          </div>
        </div>
        <div className={styles.mobilePreviousGapStyles}>
          {assetData?.assigned_data.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <AssetEmployeeCard
                  userProfile={
                    item.assigned_to?.image ? item.assigned_to?.image : avathar1
                  }
                  title={item.assigned_to?.full_name}
                  description={item.assigned_to?.email}
                  leftTitle={moment(item.assigned_at).format('ll')}
                  leftDesc={moment(item.assigned_at).format('LT')}
                  icon={true}
                  button={true}
                  customAssetBottomStyles={styles.customAssetBottomStyles}
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  };

  const renderOptionSection = () => {
    return (
      <div
        className={
          navBarOpen && showOverLayModel
            ? styles.optionStylesTwo
            : styles.optionStyles
        }
      >
        <div className={styles.optionImgStyles}>
          <img src={optionIcon2} alt="" className={styles.imageWidth} />
        </div>
        <p className={styles.optionTextStyles}>{strings.options}</p>
        <div className={styles.whiteUpArrowStyles}>
          <img src={whiteUpArrow} alt="" className={styles.imageWidth} />
        </div>
      </div>
    );
  };

  const renderUnAssignedSection = () => {
    return (
      <div className={styles.unAssignedStyles}>
        <div className={styles.insideUnAssignedStyles}>
          <div className={styles.unAssignedDescpStyles}>
            <div className={styles.warningImgStyles}>
              <img src={warningIcon} alt="" className={styles.imageWidth} />
            </div>
            <p className={styles.notAssignedTextStyles}>
              {strings.assetIsNeverAssigned}
            </p>
          </div>
          <div className={styles.assetBtnStyles}>
            <Button
              title={strings.assignNow}
              onClick={() => tapOnAssignNow()}
              btnStyle={styles.assignBtnStyles}
            />
            <Button
              title={strings.deleteAsset}
              btnStyle={styles.deleteBtnStyles}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderMarkAsLostModalSection = () => {
    return (
      <Modal
        open={markAsLostModal}
        title={strings.deleteAssetAlertTitle}
        desc={strings.deleteAssetAlertDesc}
        leftBtnTitle={strings.markAsLost}
        rightBtnTitle={strings.cancel}
        onLeftBtnClick={() => {
          setMarkAsLostModal(false);
          tapOnMarkAsLostAction();
        }}
        onRighBtnClick={() => setMarkAsLostModal(false)}
        modalBlockStyles={styles.unAssignModalStyles}
      />
    );
  };
  return (
    <div className={styles.containerStyle}>
      {/* {userType !== account_types.employee ? ( */}
      <>
        {renderBannerSection()}
        {loader ? (
          <LoaderForPages />
        ) : (
          <>
            {renderAssignNowSection()}
            {assetData?.assigned_to || assetData?.assigned_data.length > 0
              ? renderBottomSection()
              : renderUnAssignedSection()}
            {width <= 430 ? renderOptionSection() : ''}
            {renderModalSection()}
            {renderUnAssignedPopUpSection()}
            {renderMarkAsLostModalSection()}
          </>
        )}
      </>
      {/* ) : (
        <DontHaveAccessPage />
      )} */}
    </div>
  );
};
export default AssetOverView;
