import React from 'react';
import { closeGrayIcon } from 'resources/images/Images';
import styles from './styles.module.css';

const SelectedCard = (props) => {
  const { selectedName, removeSelectedData } = props;
  return (
    <div className={styles.selectedProfileDetailsViewStyles}>
      <p className={styles.selectedProfileNameTextStyles}>{selectedName}</p>
      <div className={styles.rightDividerStyles}></div>
      <div className={styles.closeImage} onClick={() => removeSelectedData()}>
        <img
          src={closeGrayIcon}
          alt="closeGrayIcon"
          className={styles.imageStyles}
        />
      </div>
    </div>
  );
};

export default SelectedCard;
