import React, { createContext, useState, useMemo } from 'react';

const AppDataContext = createContext();

const AppDataProvider = (props) => {
  const [screenName, setScreenName] = useState(null);
  const [sideBar, setSideBar] = useState(false);
  const [individualId, setIndividualId] = useState(null);
  const [navBarOpen, setNavBarOpen] = useState(false);
  const [mobileHeaderClicked, setMobileHeaderClicked] = useState(false);
  const [emptyData, setEmptyData] = useState(false);
  const [showOverLayModel, setShowOverLayModel] = useState(false);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [showOverLay, setShowOverLay] = useState(false);
  const [btnHtml, setBtnHtml] = useState(null);
  const [selectedTicket, setSelectedTicket] = useState();
  const memoizedValue = useMemo(
    () => ({
      screenName,
      setScreenName,
      sideBar,
      setSideBar,
      individualId,
      setIndividualId,
      navBarOpen,
      setNavBarOpen,
      mobileHeaderClicked,
      setMobileHeaderClicked,
      emptyData,
      setEmptyData,
      showOverLayModel,
      setShowOverLayModel,
      isOverlayVisible,
      setOverlayVisible,
      showOverLay,
      setShowOverLay,
      btnHtml,
      setBtnHtml,
      selectedTicket,
      setSelectedTicket,
    }),
    [
      screenName,
      setScreenName,
      sideBar,
      setSideBar,
      individualId,
      setIndividualId,
      navBarOpen,
      setNavBarOpen,
      mobileHeaderClicked,
      setMobileHeaderClicked,
      emptyData,
      setEmptyData,
      showOverLayModel,
      setShowOverLayModel,
      isOverlayVisible,
      setOverlayVisible,
      showOverLay,
      setShowOverLay,
      btnHtml,
      setBtnHtml,
      selectedTicket,
      setSelectedTicket,
    ]
  );

  return (
    <AppDataContext.Provider value={memoizedValue}>
      {props.children}
    </AppDataContext.Provider>
  );
};

export { AppDataContext, AppDataProvider };
