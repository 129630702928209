import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { useNavigate } from 'react-router';
const QrLinkedOrNot = () => {
  const navigation = useNavigate();
  const [qrLinked, setQrLinked] = useState(true);
  let qrCode = '';
  useEffect(() => {
    if (qrLinked) {
      navigation('/scannedAsset', { state: qrCode });
    } else {
      navigation('/linkAsset', { state: qrCode });
    }
  }, []);
  return <div></div>;
};

export default QrLinkedOrNot;
