import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuthContext } from 'hooks/useAuthContext';
import { account_types } from 'utils/constants';
import LoadingSpinner from 'components/loadingSpinner';

const GuestGuard = (props) => {
  const { userType: guardUserType, children } = props;
  const { isLoggedIn, isInitialized, userType, setAuthError } =
    useAuthContext();
  useEffect(() => {
    if (isLoggedIn) {
      if (!guardUserType.includes(userType)) {
        setAuthError('Please logout from other user type');
      }
    }
  }, [isLoggedIn, guardUserType, userType, setAuthError]);

  let dashBoardRoute = '/dashboard';
  if (
    guardUserType.includes(account_types.admin) ||
    guardUserType.includes(account_types.super_admin) ||
    guardUserType.includes(account_types.employee)
  ) {
    dashBoardRoute = '/dashboard';
  }

  if (!isInitialized) {
    return <LoadingSpinner />;
  }

  if (isLoggedIn) {
    if (guardUserType.includes(userType)) {
      return <Navigate to={dashBoardRoute} />;
    }
  }
  return <>{children}</>;
};

GuestGuard.propTypes = {
  userType: PropTypes.arrayOf(
    PropTypes.oneOf([
      account_types.admin,
      account_types.super_admin,
      account_types.employee,
    ])
  ),
  children: PropTypes.node,
};

export default GuestGuard;
