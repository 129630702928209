import {
  OutlineAllAssetsIcon,
  OutlineCheckInOutIcon,
  avathar1,
  avathar2,
  avathar3,
  avathar4,
  loadingImg,
  outlineBadge,
  outlineMessage,
  triangleIcon,
  rhombusIcon,
  hexagonIcon,
} from 'resources/images/Images';

const lostAndFoundData = [
  {
    asset_info: 'Apple M1 Macbook Air 13',
    id: '#AST106',
    current_status: 'Marked lost',
    updated_by: 'Harry White',
    updated_date: 'on Dec 25, 2022',
  },
  {
    asset_info: 'Dell Inspiron 14 inch',
    id: '#AST110',
    current_status: 'Asset recovered',
    updated_by: 'Daniel Watt',
    updated_date: 'on Dec 15, 2022',
  },
  {
    asset_info: 'Sony 1000XM4',
    id: '#AST132',
    current_status: 'Asset deleted',
    updated_by: 'James Carter',
    updated_date: 'on Dec 25, 2022',
  },
  {
    asset_info: 'HP Pavilion 15',
    id: '#AST099',
    current_status: 'Found,unrecovered',
    updated_by: 'Dan Christian',
    updated_date: 'on Dec 25, 2022',
  },
  {
    asset_info: 'Apple M1 Macbook Air 13',
    id: '#AST106',
    current_status: 'Marked lost',
    updated_by: 'Harry White',
    updated_date: 'on Dec 25, 2022',
  },
  {
    asset_info: 'Dell Inspiron 14 inch',
    id: '#AST110',
    current_status: 'Asset deleted',
    updated_by: 'Daniel Watt',
    updated_date: 'on Dec 15, 2022',
  },
  {
    asset_info: 'Sony 1000XM4',
    id: '#AST132',
    current_status: 'Asset recovered',
    updated_by: 'James Carter',
    updated_date: 'on Dec 25, 2022',
  },
  {
    asset_info: 'HP Pavilion 15',
    id: '#AST099',
    current_status: 'Found,unrecovered',
    updated_by: 'Dan Christian',
    updated_date: 'on Dec 25, 2022',
  },
];

const ticketsData = [
  {
    icon: outlineMessage,
    content: 'You have 2 messages from Daniel Watt',
    time: 'Just now',
  },
  {
    icon: outlineBadge,
    content:
      'James Carter has requested a registration of asset with Serial no. AST-111reservation of the',
    time: '1.5 hours ago',
  },
  {
    icon: OutlineAllAssetsIcon,
    content: 'AST-111 is marked as lost by Emma Watson',
    time: '9 hours ago',
  },
  {
    icon: OutlineCheckInOutIcon,
    content: 'AST-108 is ready for the check-in from Harry White',
    time: 'Today, 01:00 am',
  },
];

const recentlyAddedData = [
  { avathar: avathar1, name: 'Daniel Watt', email: 'danielwatt@cmail.com' },
  { avathar: avathar2, name: 'Emma Watson', email: 'emmawatson@cmail.com' },
  { avathar: avathar3, name: 'James Carter', email: 'jamescarter@cmail.com' },
  { avathar: avathar4, name: 'Harry White', email: 'harrywhite@cmail.com' },
];

const notificationData = [
  {
    icon: outlineMessage,
    content: 'You have 2 messages from Daniel Watt',
    time: 'Just now',
  },
  {
    icon: outlineBadge,
    content:
      'James Carter has requested a registration of asset with Serial no. AST-111reservation of the',
    time: '1.5 hours ago',
  },
  {
    icon: outlineMessage,
    content: 'You have 2 messages from Daniel Watt',
    time: 'Just now',
  },
  {
    icon: outlineBadge,
    content:
      'James Carter has requested a registration of asset with Serial no. AST-111reservation of the',
    time: '1.5 hours ago',
  },
];

const addAssetData = [
  {
    name: 'Tip #01 label',
    assetImage: loadingImg,
    assetDesc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque auctor diam ipsum, vitae condimentum leo egestas id. Pellentesque quis nunc sit amet erat luctus ultricies convallis porta magna. Cras egestas eros quam, at volutpat ante molestie sed.',
  },
  {
    name: 'Tip #02 label',
    assetImage: loadingImg,
    assetDesc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque auctor diam ipsum, vitae condimentum leo egestas id. Pellentesque quis nunc sit amet erat luctus ultricies convallis porta magna. Cras egestas eros quam, at volutpat ante molestie sed.',
  },
];

const whatsNewData = [
  {
    name: 'What’s new #01',
    assetImage: loadingImg,
    assetDesc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque auctor diam ipsum, vitae condimentum leo egestas id. Pellentesque quis nunc sit amet erat luctus ultricies convallis porta magna. Cras egestas eros quam, at volutpat ante molestie sed.',
  },
  {
    name: 'What’s new #02',
    assetImage: loadingImg,
    assetDesc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque auctor diam ipsum, vitae condimentum leo egestas id. Pellentesque quis nunc sit amet erat luctus ultricies convallis porta magna. Cras egestas eros quam, at volutpat ante molestie sed.',
  },
];

const conversationsData = [
  {
    name: 'Harry White',
    message:
      ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque auctor diam ipsum, vitae condimentum leo egestas id.',
    time: '09:00 pm',
    avatar: avathar1,
  },
  {
    name: 'James Carter',
    message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    time: '01:55 pm',
    avatar: avathar2,
  },
  {
    name: 'Emma Watson',
    message:
      ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque auctor diam ipsum, vitae condimentum leo egestas id.  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque auctor diam ipsum, vitae condimentum leo egestas id. ',

    time: '10:24 am',
    avatar: avathar3,
  },
  {
    name: 'Daniel Watt',
    message:
      ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque auctor diam ipsum, vitae condimentum leo egestas id.',
    time: '12:00 pm',
    avatar: avathar4,
  },
];

const allTicketsData = [
  {
    ticketSerialNo: '#TKT99111',
    ticketStatus: 'Unresolved',
    ticketAssignProduct: 'Monitor',
    ticketRaiseDesc:
      'James Carter has raised a ticket with subject “Monitor screen flickering ”',
  },
  {
    ticketSerialNo: '#TKT99109',
    ticketStatus: 'In progress',
    ticketAssignProduct: 'Accessories',
    ticketRaiseDesc:
      'Daniel Watt (Admin) has transferred the ticket to inventory department',
  },
  {
    ticketSerialNo: '#TKT99108',
    ticketStatus: 'In review',
    ticketAssignProduct: 'Trash bin',
    ticketRaiseDesc:
      'Daniel Watt (Admin) has marked this ticket resolved and closed it permanently',
  },
  {
    ticketSerialNo: '#TKT99103',
    ticketStatus: 'Closed',
    ticketAssignProduct: 'Accessories',
    ticketRaiseDesc:
      'You have marked this ticket resolved and closed it permanently',
  },
  {
    ticketSerialNo: '#TKT99103',
    ticketStatus: 'Closed',
    ticketAssignProduct: 'Accessories',
    ticketRaiseDesc:
      'You have marked this ticket resolved and closed it permanently',
  },
  {
    ticketSerialNo: '#TKT99103',
    ticketStatus: 'Closed',
    ticketAssignProduct: 'Accessories',
    ticketRaiseDesc:
      'You have marked this ticket resolved and closed it permanently',
  },
  {
    ticketSerialNo: '#TKT99103',
    ticketStatus: 'Closed',
    ticketAssignProduct: 'Accessories',
    ticketRaiseDesc:
      'You have marked this ticket resolved and closed it permanently',
  },
  {
    ticketSerialNo: '#TKT99103',
    ticketStatus: 'Closed',
    ticketAssignProduct: 'Accessories',
    ticketRaiseDesc:
      'You have marked this ticket resolved and closed it permanently',
  },
];

const commentsData = [
  {
    commentPersonImage: avathar1,
    commentPersonName: 'Daniel Watt (You)',
    commentTime: '2m ago',
    commentDesc:
      'Hi James! I will make sure to resolve the problem at the earliest, meantime collect a temporary laptop from my office.',
    commentReply: 'Reply',
  },
  {
    commentPersonImage: avathar2,
    commentPersonName: 'Dan Christian',
    commentTime: '• 30m ago',
    commentDesc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque auctor diam ipsum, vitae condimentum leo egestas id. Pellentesque quis nunc sit amet.',
    commentReply: '2 replies',
  },
];

const repliesData = [
  {
    commentPersonImage: avathar1,
    commentPersonName: 'Daniel Watt (You)',
    commentTime: '2m ago',
    commentDesc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque auctor diam ipsum, vitae condimentum leo egestas id. Pellentesque quis nunc sit amet.',
  },
  {
    commentPersonImage: avathar2,
    commentPersonName: 'Dan Christian',
    commentTime: 'Just now',
    commentDesc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
];

const selectRegisterAdminsData = [
  {
    adminImage: avathar1,
    adminName: 'James Carter',
    adminDesignation: 'Human Resources',
  },
  {
    adminImage: avathar2,
    adminName: 'Daniel Watt',
    adminDesignation: 'Product Management',
  },
  {
    adminImage: avathar3,
    adminName: 'Emma Watson',
    adminDesignation: 'IT Support & Cloud Engineering',
  },
  {
    adminImage: avathar4,
    adminName: 'Harry White',
    adminDesignation: 'Inventory Management ',
  },
];
const selectRegisterEmployeeData = [
  {
    employeeImage: avathar1,
    employeeName: 'James Carter',
    employeeDesignation: 'Human Resources',
  },
  {
    employeeImage: avathar2,
    employeeName: 'Daniel Watt',
    employeeDesignation: 'Product Management',
  },
  {
    employeeImage: avathar3,
    employeeName: 'Emma Watson',
    employeeDesignation: 'IT Support & Cloud Engineering',
  },
  {
    employeeImage: avathar4,
    employeeName: 'Harry White',
    employeeDesignation: 'Inventory Management ',
  },
];

const selectDepartmentData = [
  {
    departmentName: 'Hardware',
  },
  {
    departmentName: 'Software',
  },
  {
    departmentName: 'IT Support',
  },
  {
    departmentName: 'Human Resources',
  },
  {
    departmentName: 'Inventory',
  },
  {
    departmentName: 'Cloud Engineer',
  },
  {
    departmentName: 'Security',
  },
  {
    departmentName: 'Management',
  },
];

export {
  lostAndFoundData,
  ticketsData,
  recentlyAddedData,
  notificationData,
  addAssetData,
  whatsNewData,
  conversationsData,
  allTicketsData,
  commentsData,
  repliesData,
  selectRegisterAdminsData,
  selectDepartmentData,
  selectRegisterEmployeeData,
};
