import React, { useEffect } from 'react';
import styles from './styles.module.css';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Modal = (props) => {
  const {
    children,
    show,
    onClose,
    popupRef,
    showOverlay,
    handleClickOutSide = () => {},
    containerStyle,
    overlayStyle,
  } = props;

  useEffect(() => {
    if (show && popupRef?.current) {
      document.addEventListener('mousedown', handleClickOutSide);
    } else {
      document.removeEventListener('mousedown', handleClickOutSide);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  }, [show, handleClickOutSide, popupRef]);

  if (!show) return null;

  return (
    <React.Fragment>
      <div
        className={classNames(
          styles.overlayStyle,
          showOverlay && styles.overlayBgStyle,
          showOverlay && overlayStyle
        )}
        onClick={handleClickOutSide}
      />
      <div
        ref={popupRef}
        className={classNames(styles.containerStyle, containerStyle)}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </React.Fragment>
  );
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  popupRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  onClose: PropTypes.func,
  showOverlay: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  handleClickOutSide: PropTypes.func,
  containerStyle: PropTypes.string,
  overlayStyle: PropTypes.string,
};

export default Modal;
