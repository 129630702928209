import React from 'react';
import Button from 'components/button';
import styles from './styles.module.css';
const SuggetionBoard = (props) => {
  const {
    dashBoardimage,
    title,
    desc,
    suggetionBoardStyle,
    leftBtnTitle,
    rightBtnTitle,
    leftBtnImg,
    rightBtnImg,
    suggetionBoardBottomStyles,
    btnStyles1,
    btnStyles2,
    onRightButtonClick,
  } = props;

  return (
    <div>
      <div className={styles.dashBoardImgMainContainerStyles}>
        {dashBoardimage && (
          <div className={styles.dashBoardImgBlockStyles}>
            <img src={dashBoardimage} alt="" className={styles.imageWidth} />
          </div>
        )}
      </div>
      <div
        className={[styles.suggetionBoardStyle, suggetionBoardStyle].join(' ')}
      >
        <div className={styles.suggetionBoardTopStyle}>
          {title && <h4 className={styles.suggetionHeadTextStyle}>{title}</h4>}
          {desc && <p className={styles.suggetionDescStyle}>{desc}</p>}
        </div>
        {props.leftBtnTitle && props.rightBtnTitle && (
          <div
            className={[
              styles.suggetionBoardBottomStyles,
              suggetionBoardBottomStyles,
            ].join(' ')}
          >
            <Button
              title={leftBtnTitle}
              btnStyle={[styles.btnStyles, btnStyles1].join(' ')}
              image={leftBtnImg}
            />
            <Button
              title={rightBtnTitle}
              color="light"
              btnStyle={[styles.btnStyles, btnStyles2].join(' ')}
              image={rightBtnImg}
              onClick={onRightButtonClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SuggetionBoard;
